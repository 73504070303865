import * as React from "react";
import * as classNames from "classnames";

interface ReasoningToolBarButtonProps {
  iconName?: string;
  isActive?: boolean;
  name: string;
  onToggle?: React.MouseEventHandler<HTMLAnchorElement>;
  openLink?: string;
  shouldShow: boolean;
};

export default function ReasoningToolBarButton({
  iconName,
  isActive = false,
  name = "",
  onToggle,
  openLink,
  shouldShow,
}: ReasoningToolBarButtonProps) {
  if (!shouldShow) { return null; }

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (openLink) {
      window.open(openLink, "_blank");
    } else if(onToggle) {
      onToggle(e);
    }
  };

  return (
    <a href="#" className={classNames("reasoning-tool-bar-button", {active: isActive})} onClick={onClick}>
      {iconName && <>
        <span className={iconName.slice(0,3) === "fa " ? iconName : `icon-lg ${iconName}`} />
        <br />
      </>}
      <span className="button-name">{name}</span>
    </a>
  );
}
