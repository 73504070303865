import {
  newAddStyleChange,
  newDeleteStyleChange,
} from "../changes";

import {} from "../Functions/base";

import * as selection from "../selection";

import * as R from "ramda";

import * as RangeInlineJSON from "../Functions/RangeInlineJSON";

import {
  xor,
} from "lodash";

export default function toggleStyleAtSelection(style: StyleName, editActionContext: EditActionContext) {
  const {
    data,
    variantDataById,
    currentMenu,
    addChanges,
    setCurrentDocSelection,
    nodeDefinitions,
    newActiveStyles = [],
    newInactiveStyles = [],
    setNewActiveStyles,
    setNewInactiveStyles,
  } = editActionContext;

  const currentDocSelection = selection.getDocSelection();

  if (!currentDocSelection || currentMenu) { return false; }

  const dataToUse = currentDocSelection.variantId ? variantDataById[currentDocSelection.variantId] : data;

  const activeStylesFromChanges = RangeInlineJSON.stylesCoveringDocSelection(dataToUse, currentDocSelection);
  const activeStyles = xor(R.keys(activeStylesFromChanges).concat(newActiveStyles), newInactiveStyles);

  if (selection.isCollapsed(currentDocSelection)) {
    if (!R.keys(activeStylesFromChanges).includes(style)) {
      setNewActiveStyles(xor(newActiveStyles, [style]));
    } else {
      setNewInactiveStyles(xor(newInactiveStyles, [style]));
    }
  } else {
    const {
      uid: startUID,
      contentKey: startKey,
      index: startIndex,
    } = currentDocSelection.start;

    const {
      uid: endUID,
      contentKey: endKey,
      index: endIndex,
    } = currentDocSelection.end;

    const newStyleChange = activeStyles.includes(style) ? newDeleteStyleChange : newAddStyleChange;

    addChanges([newStyleChange(currentDocSelection, style)], currentDocSelection.variantId);
  }

  return true;
}
