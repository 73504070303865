import * as React from "react";

import * as DocConvertRangeInlineJSON from "../../../../helpers/Doc/Convert/RangeInlineJSON";

import {
  compressRanges
} from "../../../../helpers/Doc/Functions/Range";

interface DocStyledTextEditorProps {
  uid?: string;
  content: InlineNode;
  contentKey?: string;

  disableStyleTextEditing?: boolean;

  editingContext: EditingContext;

  independentlyEditable?: boolean;
  placeholder?: string;

  variantId?: number;

  returnPlainText?: boolean;
};

export default function DocStyledTextEditor(props: DocStyledTextEditorProps) {
  const {
    content="",
    contentKey,
    disableStyleTextEditing,
    editingContext,
    independentlyEditable,
    placeholder="EMPTY",
    uid,
    variantId,
  } = props

  let isAllEmpty;
  if (typeof content !== "string" && content.inlineIrrlevantRanges) {
    const firstRange = compressRanges(content.inlineIrrlevantRanges.map(x => x.range))[0];
    if (firstRange) {
      isAllEmpty = firstRange.start === 0 && firstRange.end === content.text.length;
    }
  }

  const htmlContent = typeof content !== "string"
    ? DocConvertRangeInlineJSON.toHTML(content, editingContext.colorLookup)
    : content;

  let editableData = {}
  if (independentlyEditable) {
    if (!disableStyleTextEditing) {
      editableData = {
        contentEditable: true,
        "data-is-independently-editable": true,
        style: {
          "userSelect": "auto",
          "WebkitUserSelect": "auto",
        }
      };
    } else {
      editableData = {
        style: {
          "userSelect": "text",
          "WebkitUserSelect": "text",
        }
      };
    }
  }

  const sharedProps = {
    id: `${uid}//${contentKey}`,
    className: "doc-styled-text",
    'data-is-styled-text': true,
    'data-uid': uid,
    'data-contentkey': contentKey,
    ...editableData,
    autoComplete: "off",
    autoCorrect: "off",
    autoCapitalize: "off",
    spellCheck: false,
    draggable: false,
    onClick: (e: React.MouseEvent<HTMLSpanElement>) => e.stopPropagation(),
  }

  if (variantId) {
    sharedProps['data-variantid'] = variantId
  }

  if (isAllEmpty || htmlContent.length === 0) {
    return (
      <span
        {...sharedProps}
        className="doc-styled-text doc-styled-text-placeholder"
        data-is-empty
        suppressContentEditableWarning
      >
        <span>&nbsp;</span>
        <span contentEditable={false} style={{"userSelect": "auto", "WebkitUserSelect": "auto"}}>{placeholder}</span>
      </span>
    );
  } else {
    return (
      <span
        {...sharedProps}
        className="doc-styled-text"
        dangerouslySetInnerHTML={{__html: htmlContent.replace(/\ $/, "&nbsp;").replace(/\ </, "&nbsp;<")}}
      />
    );
  }
}
