import * as React from "react";

import * as classNames from "classnames";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

interface DocHorizontalRuleProps extends DocHorizontalRuleType, BlockNodeAll {}

function DocHorizontalRule(props: DocHorizontalRuleProps) {
  return (
    <hr
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-horizontal-rule", props.uid, {"doc-horizontal-rule-edit": props.editingContext.isEditing})}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    />
  );
}

export default React.memo(DocHorizontalRule);
