import consumer from "./consumer"

export default function createAutomatedSummaryStatementSubscription({summaryStatementId, onSummaryStatementFeedback, onConnected, onDisconnected, onRejected}) {
  return consumer.subscriptions.create({ channel: "AutomatedSummaryStatementChannel", summary_statement_id: summaryStatementId }, {
    received(data) {
      onSummaryStatementFeedback(data);
    },

    connected() {
      onConnected();
    },

    disconnected() {
      onDisconnected();
    },

    rejected() {
      onRejected();
    },

    getFeedback(summaryStatementData) {
      this.perform("getFeedback", summaryStatementData)
    },
  });
}
