import * as React from "react";

import {
  filter,
  includes,
  keys,
  mapValues,
  pickBy,
} from "lodash";

import * as classNames from "classnames";

import DocGradedFeedbackBanner from "./CategoryMatcher/GradedFeedbackBanner";

import DocStyledText from "../BlockNodeHelpers/StyledText";

import { newSetKeyValueChange, backgroundStyleFromNode } from "../../../helpers/Doc/changes";

import * as DocConvertPlainText from "../../../helpers/Doc/Convert/PlainText";

const addItemToCategory = (mapping: Record<string, Array<string>>, categoryUID: string, itemUID: string) => {
  let newMapping = {...mapping};

  newMapping = mapValues(newMapping, (categoryArray) => {
    return filter(categoryArray, (itemUid) => itemUID !== itemUid);
  });

  if (newMapping[categoryUID]) {
    newMapping[categoryUID] = newMapping[categoryUID].concat(itemUID);
  } else {
    newMapping[categoryUID] = [itemUID];
  }

  return newMapping;
};

interface DocCategoryMatcherProps extends DocCategoryMatcherType<InlineNode>, BlockNodeAll {}

function DocCategoryMatcher(props: DocCategoryMatcherProps) {
  const {
    uid,
    answer,
    contentCategories,
    contentItems,
    inactiveBool,
    graded,
  } = props;

  const value = props.value || {};

  const contentItemsExpanded = contentItems.map((contentItem) => {
    const categoryItemValue = keys(
      pickBy(
        value,
        (contentItemArray) => includes(contentItemArray, contentItem.uid),
      ),
    )[0] || "default";

    const categoryItemAnswer = keys(
      pickBy(
        answer,
        (contentItemArray) => includes(contentItemArray, contentItem.uid),
      ),
    )[0];

    const isCorrect = categoryItemValue === categoryItemAnswer;

    return ({
      ...contentItem,
      categoryItemValue,
      categoryItemAnswer,
      isCorrect,
    });
  });

  const rows = contentItemsExpanded.map((contentItem, index) => {
    const includeInAllNodes = {
      key: contentItem.uid,
      documentContext: props.documentContext,
      currentVersion: props.currentVersion,
      editingContext: props.editingContext,
      userContext: props.userContext,
    };

    const selectOptions = contentCategories.map((contentCategory) => {
      return (
        <option value={contentCategory.uid} key={contentCategory.uid}>
          {DocConvertPlainText.fromAnyType(contentCategory.content)}
        </option>
      );
    });

    const addDocumentUserState = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = addItemToCategory(value, event.target.value, contentItem.uid);

      props.userContext.addState({[uid]: {value: newValue}});
    };

    const updateAnswer = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        editingContext,
      } = props;

      const newAnswer = addItemToCategory(props.answer, event.target.value, contentItem.uid);

      if (editingContext.isEditing === true) {
        editingContext.addChange(newSetKeyValueChange(uid, "answer", newAnswer));
      }
      event.stopPropagation();
    };

    const answer = filter(contentCategories, (contentCategory) => {
      return (contentCategory.uid === contentItem.categoryItemAnswer);
    })[0];

    return (
      <div
        key={contentItem.uid}
        id={contentItem.uid}
        className={classNames("doc-category-matcher-row", uid)}
        style={backgroundStyleFromNode({...includeInAllNodes, ...contentItem})}
      >
        <span>
          <DocStyledText
            uid={contentItem.uid}
            content={contentItem.content}
            contentKey={"content"}
            editingContext={props.editingContext}
            placeholder={"Enter question text here (use the Return key to add more questions)"}
            independentlyEditable
            disableStyleTextEditing={props.disableStyleTextEditing}
          />
        </span>
        <select value={contentItem.categoryItemValue} onChange={addDocumentUserState} disabled={inactiveBool}>
          <option disabled value="default" key="default"> -- select an option -- </option>
          {selectOptions}
        </select>
        {
          props.editingContext.isEditing
          && <div key="correct-answer">Correct Answer:
            <select value={contentItem.categoryItemAnswer} onChange={updateAnswer}>
              <option disabled value="default" key="default"> -- select an option -- </option>
              {selectOptions}
            </select>
          </div>
        }
        {
          graded
          && <span className={classNames("doc-category-matcher-row-answer", {correct: contentItem.isCorrect, incorrect: !contentItem.isCorrect})}>
            {answer && DocConvertPlainText.fromAnyType(answer.content)}
          </span>
        }
      </div>
    );
  });

  let categoryRows = null;
  if (props.editingContext.isEditing) {
    categoryRows = contentCategories.map((contentCategory, index) => {
      const includeInAllNodes = {
        key: contentCategory.uid,
        documentContext: props.documentContext,
        currentVersion: props.currentVersion,
        editingContext: props.editingContext,
        userContext: props.userContext,
      };

      return (
        <div
          key={contentCategory.uid}
          id={contentCategory.uid}
          className={classNames("doc-category-matcher-category-row", uid)}
          style={backgroundStyleFromNode({...includeInAllNodes, ...contentCategory})}
        >
          <span>
            <DocStyledText
              uid={contentCategory.uid}
              content={contentCategory.content}
              contentKey={"content"}
              editingContext={props.editingContext}
              placeholder={"Enter answer text here (use the Return key to add more answers)"}
              independentlyEditable
              disableStyleTextEditing={props.disableStyleTextEditing}
              returnPlainText
            />
          </span>
        </div>
      );
    });

    categoryRows = (
      <div className="doc-category-matcher-category-rows">
        <h5>Question Options</h5>
        {categoryRows}
      </div>
    );
  }

  let gradedFeedbackBanner = null;
  if (graded) {
    const isEachCorrect = contentItemsExpanded.map((contentItem) => {
      return (contentItem.isCorrect);
    });

    if (isEachCorrect.reduce((a, b) => a && b)) {
      gradedFeedbackBanner = <DocGradedFeedbackBanner type="correct" />;
    } else if (isEachCorrect.reduce((a, b) => a || b)) {
      gradedFeedbackBanner = <DocGradedFeedbackBanner type="partial" />;
    } else {
      gradedFeedbackBanner = <DocGradedFeedbackBanner type="incorrect" />;
    }
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-category-matcher", uid, {inactive: inactiveBool})}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    >
      {gradedFeedbackBanner}
      {rows}
      {categoryRows}
    </div>
  );
}

export default React.memo(DocCategoryMatcher);
