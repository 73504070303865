import React from 'react';
import * as edit from 'react-edit';
import * as sort from 'sortabular';
import {
  cloneDeep, findIndex
} from 'lodash';

function sortHeader(sortable, getSortingColumns) {
  return (value, { columnIndex }) => {
    const sortingColumns = getSortingColumns() || [];

    const tooltipText = sortingColumns[columnIndex] && sortingColumns[columnIndex].tooltipText;
    return (
      <div style={{ display: 'inline' }}>
        <span className="value">{value}</span>
        {tooltipText &&
          <span className="tooltip2 question-tooltip"><span className="tooltiptext">{tooltipText}</span></span>
        }
        {React.createElement(
          'span',
          sortable(
            value,
            {
              columnIndex
            },
            {
              style: { float: 'right' }
            }
          )
        )}
        {sortingColumns[columnIndex] &&
        <span className="sort-order" style={{ marginLeft: '0.5em', float: 'right' }}>
            {sortingColumns[columnIndex].position + 1}
          </span>
        }
      </div>
    );
  };
}

export function sortingHeader() {
  const sortable = sort.sort({
    getSortingColumns: () => this.state.sortingColumns || [],
    onSort: selectedColumn => {
      this.setState({
        sortingColumns: sort.byColumn({ // sort.byColumns would work too
          sortingColumns: this.state.sortingColumns,
          selectedColumn
        })
      });
    }
  });
  return sortHeader(sortable, () => this.state.sortingColumns);
}

export function editingCell(controlling_attribute) {
  return edit.edit({
    isEditing: ({ columnIndex, rowData }) => columnIndex === rowData.editing,
    onActivate: ({ columnIndex, rowData }) => {
      const index = findIndex(this.state.rows, { id: rowData.id });
      const rows = cloneDeep(this.state.rows);

      if (!controlling_attribute || rows[index][controlling_attribute]) {
        rows[index].editing = columnIndex;
      }

      this.setState({ rows });
    },
    onValue: ({ value, rowData, property }) => {
      const index = findIndex(this.state.rows, { id: rowData.id });
      const rows = cloneDeep(this.state.rows);

      rows[index][property] = value;
      rows[index].editing = false;

      this.setState({ rows });
    }
  });
}

export function Checkbox(ref) {
  var value = ref.value,
    onValue = ref.onValue;

  onValue(!value);

  return null;
};

export function AddNameAttribute(property, params, prefix, other) {
  return <div>
    <input
      type="hidden"
      name={`${prefix}[][${property}]`}
      value={params.rowData[property]}
    ></input>
    {other}
  </div>
};

export function ActionLink(toBeWrapped, extra, action, switch_key) {
  if (extra.rowData[switch_key]) {
    return <a href="javascript: void(0)" data-action={action}>{toBeWrapped}</a>
  } else {
    return toBeWrapped
  }
};

export function Button(type, innards, action, target, className, disabled) {
  return <button className={className} type={type} data-target={target} data-action={action} disabled={disabled}>{innards}</button>
};
