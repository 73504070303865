import _ from 'lodash';

export function currentTimeInSeconds(){
  return Math.floor(new Date().getTime() / 1000)
}

export function intToDateString(intTime){
  return intTime && (new Date(intTime)).toISOString().split("T")[0]
}

export function intToDateTimeString(intTime){
  let h = (new Date(intTime)).toString().split(' ')[4].split(':')[0]
  let m = (new Date(intTime)).toString().split(' ')[4].split(':')[1]
  let month = (new Date(intTime)).toString().split(' ')[1]
  let day = (new Date(intTime)).toString().split(' ')[2]
  let year = (new Date(intTime)).toString().split(' ')[3]
  let dd = 'AM'
  if (h >= 12) {
    h = h - 12
    dd = 'PM'
  }
  if (h === 0) {
    h = 12
  }
  return intTime && month + ' ' + day + ' ' + year + ' ' + h + ':' + m + ' ' + dd + ' '
}

export function intTimeToHHMMSS(intTime){
  let secondsDiff = Math.floor(intTime / 1000)

  let hours = Math.floor(secondsDiff / 3600)
  secondsDiff -= (hours * 3600)

  let minutes = Math.floor(secondsDiff / 60)
  secondsDiff -= minutes * 60

  let seconds = secondsDiff

  return hours + ":" + _.padStart(minutes, 2, "0") + ":" + _.padStart(seconds, 2, "0")
}

export function intTimeToHHMMSSOrHours(intTime, hours) {
  if (hours * 60 * 60 * 1000 < intTime) {
    return "Greater than " + hours + " hours"
  } else {
    return intTimeToHHMMSS(intTime)
  }
}
