import React from 'react';
import ReactDOM from 'react-dom';
import GenSortTable from 'Gen/SortTable/component'
import TeachingPointModal from 'NewEditDoc/TeachingPointModal/component'

import TableDownArrowImage from "images/table-down_arrow.png";
import TableUpArrowImage from "images/table-up_arrow.png";
import CustomMultiSelect from './CustomMultiSelect';

class CohortProgressesDeliveriesTable extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      cohorts: this.props.cohorts,
      search: '',
      cohortFiltered: this.props.rows,
      filteredData: this.props.rows,
      sortCol: 'status',
      sortDir: 'asc',
      assessment_date: [],
      discipline: [],
      system: [],
      clinical_focus: [],
      purpose: [],
    };
  }

  get cohortFilters() {
    return ['assessment_date','discipline','purpose']
  }

  get clcFractionNames() {
    return [
      'excel_fraction',
      'competent_fraction',
      'unsure_fraction',
      'novice_fraction',
      'warning_fraction'
    ]
  }

  sortDeliveries = (sortCol, sortDir) =>{
    var data = this.state.filteredData
    if(sortCol == "clc") {
      return this.sortByClc(sortDir)
    }
    data.sort((a, b) => {
      let fa = a[sortCol].toLowerCase(),
          fb = b[sortCol].toLowerCase();
      if (fa < fb) {
          return sortDir == 'asc' ? -1 : 1;
      }
      if (fa > fb) {
        return sortDir == 'asc' ? 1 : -1;
      }
      return 0;
    });
    return data
  }

  sortImage = (sortCol)=>{
    return this.state.sortCol == sortCol ? this.state.sortDir == 'asc' ? TableDownArrowImage : TableUpArrowImage : TableDownArrowImage
  }

  sortByClc(sortDir) {
    let data = this.state.filteredData
    data.sort((a, b) => {
      let dir = 0
      for (const name of this.clcFractionNames) {
        let fa = a[name],
            fb = b[name]
        if (fa < fb) {
          dir = sortDir == 'asc' ? -1 : 1
        }
        if (fa > fb) {
          dir = sortDir == 'asc' ? 1 : -1
        }
        if (dir == 0) continue
        break
      }
      return dir
    })
    return data
  }

  applyFilters() {
    const { system, clinical_focus, search, assessment_date, discipline, purpose, cohortFiltered } = this.state;
    const baseData = (assessment_date.length === 0 && discipline.length === 0 && purpose.length === 0)? this.props.rows : cohortFiltered;

    const filteredData = baseData.filter((item) => {
      const matchesSystem = system.length === 0 || system.some(sys => item.system?.includes(sys));
      const matchesClinicalFocus = clinical_focus.length === 0 || clinical_focus.some(cf => item.clinical_focus?.includes(cf));

      const matchesSearch = (item.learning_objective || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.teaching_point_url_name || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.case || '').toLowerCase().includes(search.toLowerCase());

      return matchesSystem && matchesClinicalFocus && matchesSearch;
    });

    this.setState({ filteredData });
  }


  handleFilterChange(filterName, values) {
    this.setState({ [filterName]: values }, () => {
      if (['assessment_date', 'discipline', 'purpose'].includes(filterName)) {
        // Call setFilters for cohort-related filtering logic
        this.setFilters(filterName, values);
      } else {
        // Otherwise, apply the general filters
        this.applyFilters();
      }
    });
  }

  async getFilteredDetails(ids) {
    let params = "?"
    for (const id of ids) {
      params += `&ids[]=${id}`
    }
    const response = await fetch(this.props.filter_url + params)
    return response.json()
  }

  filtersRow() {
    if (this.props.multiple_cohorts) {
      return (
        <div>
          <div className="filtersRow reportSelectFilters">
            <div className="form-inline">
              <CustomMultiSelect
                options={this.props.assessment_date_options}
                selectedOptions={this.state.assessment_date}
                onChange={(selectedOptions) => this.handleFilterChange('assessment_date', selectedOptions)}
                placeholder="Assessment Date"
              />

              <CustomMultiSelect
                options={this.props.discipline_options.map(option => (option.replace('Aquifer ', '')))}
                selectedOptions={this.state.discipline}
                onChange={(selectedOptions) => this.handleFilterChange('discipline', selectedOptions)}
                placeholder="Discipline"
              />

              <CustomMultiSelect
                options={this.props.purpose_options}
                selectedOptions={this.state.purpose}
                onChange={(selectedOptions) => this.handleFilterChange('purpose', selectedOptions)}
                placeholder="Purpose"
              />

              <CustomMultiSelect
                options={this.props.system_options}
                selectedOptions={this.state.system}
                onChange={(selectedOptions) => this.handleFilterChange('system', selectedOptions)}
                placeholder="System"
              />
              <CustomMultiSelect
                options={this.props.clinical_focus_options}
                selectedOptions={this.state.clinical_focus}
                onChange={(selectedOptions) => this.handleFilterChange('clinical_focus', selectedOptions)}
                placeholder="Clinical Focus"
              />
            </div>
          </div>

          <div className="educatorSelectedFilters">

            {this.state.assessment_date.length > 0 && this.state.assessment_date.map((selectedAssessmentDate, index) => (
              <p key={index} onClick={() => {
                const updatedssessmentDates = this.state.assessment_date.filter(ad => ad !== selectedAssessmentDate);
                this.handleFilterChange('assessment_date', updatedssessmentDates);
              }} className='educatorfilterSelectedVal'>{selectedAssessmentDate}&nbsp;&nbsp;&nbsp; X</p>
            ))}

            {this.state.discipline.length > 0 &&
              this.state.discipline.map((selectedDiscipline, index) => (
                <p key={index} onClick={() => {
                  const updatedDisciplines = this.state.discipline.filter(d => d !== selectedDiscipline);
                  this.handleFilterChange('discipline', updatedDisciplines);
                }} className='educatorfilterSelectedVal'>{selectedDiscipline}&nbsp;&nbsp;&nbsp; X</p>
              ))}

            {this.state.system.length > 0 && this.state.system.map((selectedSystem, index) => (
              <p key={index} onClick={() => {
                const updatedSystems = this.state.system.filter(s => s !== selectedSystem);
                this.handleFilterChange('system', updatedSystems);
              }} className='educatorfilterSelectedVal'>{selectedSystem}&nbsp;&nbsp;&nbsp; X</p>
            ))}

            {this.state.purpose.length > 0 && this.state.purpose.map((selectedPurpose, index) => (
              <p key={index} onClick={() => {
                const updatedPurpose = this.state.purpose.filter(s => s !== selectedPurpose);
                this.handleFilterChange('purpose', updatedPurpose);
              }} className='educatorfilterSelectedVal'>{selectedPurpose}&nbsp;&nbsp;&nbsp; X</p>
            ))}

            {this.state.clinical_focus.length > 0 && this.state.clinical_focus.map((selectedClinicalFocus, index) => (
              <p key={index} onClick={() => {
                const updatedClinicalFocus = this.state.clinical_focus.filter(cf => cf !== selectedClinicalFocus);
                this.handleFilterChange('clinical_focus', updatedClinicalFocus);
              }} className='educatorfilterSelectedVal'>{selectedClinicalFocus}&nbsp;&nbsp;&nbsp; X</p>
            ))}
          </div>
        </div>
      )
    }
  }

  getCorrectResponses(row) {
    if (row.accuracy_fraction.length === 0) {
      return "N/A"
    } else if (this.props.multiple_cohorts) {
      return row.accuracy_fraction
    } else {
      return `${row.accuracy_percentage}%`
    }
  }

  getClcStyle(percentage) {
    if (percentage <= 0) {
      return { display: 'none' }
    }
    return { width: `${percentage}%` }
  }

  setFilters(currentFilterCol, value) {
    var filters = {}
    filters[currentFilterCol] = value
    if(this.cohortFilters.includes(currentFilterCol)) {
      let ids = [];
      const { system, clinical_focus } = this.state;
      switch (currentFilterCol) {
        case 'assessment_date':
          ids = this.props.cohorts.filter((cohort) => {
            const dateMatch = value.length === 0 || value.includes(cohort.date_range);
            const courseNameMatch = this.state.discipline.length === 0 ||
              this.state.discipline.some(d => cohort.course_name.replace('Aquifer ', '').includes(d.trim()));
            const purposeMatch = this.state.purpose.length === 0 ||
              this.state.purpose.some(p => cohort.purpose.includes(p));
            return dateMatch && courseNameMatch && purposeMatch;
          }).map(c => c.delivery_id);
          break;
        case 'discipline':
          ids = this.props.cohorts.filter((cohort) => {
            const dateMatch = this.state.assessment_date.length === 0 || this.state.assessment_date.some(a => cohort.date_range.includes(a));
            const courseNameMatch = value.length === 0 || value.includes(cohort.course_name.replace('Aquifer ', ''));
            const purposeMatch = this.state.purpose.length === 0 ||
              this.state.purpose.some(p => cohort.purpose.includes(p));
            return dateMatch && courseNameMatch && purposeMatch;
          }).map(c => c.delivery_id);
          break;
        case 'purpose':
          ids = this.props.cohorts.filter((cohort) => {
            const dateMatch = this.state.assessment_date.length === 0 || this.state.assessment_date.some(a => cohort.date_range.includes(a));
            const courseNameMatch = this.state.discipline.length === 0 ||
              this.state.discipline.some(d => cohort.course_name.replace('Aquifer ', '').includes(d.trim()));
            const purposeMatch = value.length === 0 || value.includes(cohort.purpose);
            return dateMatch && courseNameMatch && purposeMatch;
          }).map(c => c.delivery_id);
          break;
      }
      this.getFilteredDetails(ids).then((data) => {
        filters.cohortFiltered = data.details
        filters.filteredData = filters.cohortFiltered.filter((item) => (system.length === 0 || system.some(sys => item.system?.includes(sys))) &&
          ((item.learning_objective || '').toLowerCase().indexOf(this.state.search) > -1 ||
          (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search) > -1 ||
          (item.case || '').toLowerCase().indexOf(this.state.search) > -1) &&
          (clinical_focus.length === 0 || clinical_focus.some(cf => item.clinical_focus?.includes(cf)))
        )
        this.setState(filters)
      })
    }

    return filters
  }

  getTeachingPointModal(row) {
    if (row.teaching_point_url_name != 'N/A') {
      return <TeachingPointModal
        title={row.teaching_point_url_name}
        docType="ReusableObject"
        documentId={row.teaching_point?.id}
        versionId={row.teaching_point_publish_variant_id}
        currentUser={this.props.user}
        isAdmin={this.props.admin}
        currentRoleName="Student"
        permissions={{}}
        displayUid={row.teaching_point?.uid}
      />
    }
  }

  render() {

    return <>
      <div className="detailsSection">
        <div className="col-md-12">
          <div className="sectionTitleRow">
            <div className="educator-detail-heading">
              <h2 className="reportTopSectionTitle">{this.props.multiple_cohorts ? 'Item Detail' : `Item Detail - ${this.props.students_count} students`} </h2>
              <div className="info-bubble">
                <span className="tooltip-box small after above-center tab-2">
                  % correct responses and CLC breakdown are based on submitted assessments only. The item details shown in the table below reflect the students who submitted responses for that item only.
                  {this.props.multiple_cohorts ? " As such, the denominators may be different from the number of students in a given cohort." : ""}
                </span>
              </div>
            </div>
            <div className="form-group" style={{width: 400}}>
              <label className="bottomSectionGraphLegendText">Search</label>
              <input type="text" name="search" placeholder='Search' className="form-control" onChange={e => this.setState({ search: e.target.value }, this.applyFilters)}/>
            </div>
          </div>

          {this.filtersRow()}

          <table className="detailsTable">
            <thead>
              <tr>
                <th className="bottomSectionGraphLegendText">Learning Objective</th>
                <th className="bottomSectionGraphLegendText">Teaching Point</th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol'
                  onClick={() => {
                    this.setState({
                    sortCol: 'clinical_focus',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('clinical_focus', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }}
                >
                  <div>Clinical Focus <img src={this.sortImage('clinical_focus')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol'
                  onClick={() => this.setState({
                    sortCol: 'system',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('system', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }
                >
                  <div>System <img src={this.sortImage('system')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th className='bottomSectionGraphLegendText'>{this.props.multiple_cohorts ? "Correct Responses" : "% Correct Responses"}</th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol clc'
                  onClick={() => this.setState({
                    sortCol: 'clc',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('clc', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }
                >
                  <div>Clc Breakdown <img src={this.sortImage('clc')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th className="bottomSectionGraphLegendText">Aquifer Case</th>
                <th className="bottomSectionGraphLegendText">% of Students Completing Case</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filteredData.map((row, i) => {
                return <tr key={`cohortProgressTable${i}`}>
                  <td>{row.learning_objective}</td>
                  <td>
                    {this.getTeachingPointModal(row)}
                  </td>
                  <td><p className="metadata-box clinical-focus">{row.clinical_focus || 'N/A'}</p></td>
                  <td><p className="metadata-box system" style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{row.system || 'N/A'}</p></td>
                  <td className="centerColumn">{this.getCorrectResponses(row)}</td>
                  <td>
                    <div className="row graphSectionRow">
                      <div className="overallPerformancesBar excel" style={this.getClcStyle(row.excel_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.excel_fraction)}%</p></div>
                      <div className="overallPerformancesBar competent" style={this.getClcStyle(row.competent_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.competent_fraction)}%</p></div>
                      <div className="overallPerformancesBar unsure" style={this.getClcStyle(row.unsure_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.unsure_fraction)}%</p></div>
                      <div className="overallPerformancesBar novice" style={this.getClcStyle(row.novice_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.novice_fraction)}%</p></div>
                      <div className="overallPerformancesBar warning" style={this.getClcStyle(row.warning_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.warning_fraction)}%</p></div>
                    </div>
                  </td>
                  <td style={{color: "#110EBF"}}>{row.document_set_document_relation_path != 'N/A' ? <a style={{color: '#1b5599', textDecoration: 'underline'}} target="_blank" href={row.document_set_document_relation_path}>{row.case}</a> : 'N/A'}</td>
                  <td className="centerColumn">{Math.round(row.case_completed_fraction)}%</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  }
}

export default CohortProgressesDeliveriesTable;
