import * as React from "react";

import { last, startCase } from "lodash";

import * as classNames from "classnames";

import GenParagraph from "../../Gen/Paragraph/component";

import DocList from "../BlockNodes/List";
import DocParagraph from "../BlockNodes/Paragraph";

import { scrollToLineage } from "../../../helpers/Doc/scrollTo";

import {
  weakMemoizedGenerateEditingContextAllFalse,
} from "../../../helpers/Doc/context";

interface ReasoningToolNoteSummaryProps {
  content: string;
  isEditing: boolean;
  startNodeEdit: () => void;
  endNodeEdit: React.FocusEventHandler<HTMLInputElement>;
  noteNodeLineage: Array<DocBlockNode<InlineNode>>;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
  currentVersion: CurrentVersion;
  userContext: UserContext;
  mediaContext: MediaContext;
  featureFlag: FeatureFlag;
};

export default function ReasoningToolNoteSummary(props: ReasoningToolNoteSummaryProps) {
  const {
    content = "",
    isEditing,
    startNodeEdit,
    endNodeEdit,
    noteNodeLineage,
    currentVersion,
    displayContext,
    documentContext,
    userContext,
    mediaContext,
    featureFlag,
  } = props;

  let displayData, onClick;
  if (noteNodeLineage) {
    const noteNode = last(noteNodeLineage);

    onClick = () => {
      scrollToLineage(noteNodeLineage, userContext.addState);
      displayContext.setFocusedBookmarkUid(noteNode.uid);
    };

    const editingContext = weakMemoizedGenerateEditingContextAllFalse();
    switch (noteNode.type) {
      case "paragraph":
        displayData = (
          <DocParagraph
            key={noteNode.uid}
            simple
            currentVersion={currentVersion}
            documentContext={documentContext}
            displayContext={displayContext}
            editingContext={editingContext}
            userContext={userContext}
            mediaContext={mediaContext}
            featureFlag={featureFlag}
            {...noteNode}
          />
        );
        break;
      case "numberedList":
      case "regularList":
        displayData = (
          <DocList
            key={noteNode.uid}
            simple
            currentVersion={currentVersion}
            documentContext={documentContext}
            displayContext={displayContext}
            editingContext={editingContext}
            userContext={userContext}
            mediaContext={mediaContext}
            featureFlag={featureFlag}
            {...noteNode}
          />
        );
        break;
      case "image":
      case "table":
      case "video":
      case "audio":
      default:
        displayData = <p>{startCase(noteNode.type)} note. Click to view.</p>;
    }
    displayData = (
      <>
        <h6>Excerpt:</h6>
        {displayData}
        <hr />
      </>
    );
  }

  return (
    <div className={classNames("reasoning-tool-note-summary", {noted: noteNodeLineage})} onClick={onClick}>
      {displayData}
      <h6>Your Notes:</h6>
      <GenParagraph
        value={content}
        placeholder="Enter Note..."
        shouldAutoFocus
        isEditing={isEditing}
        onSpanClick={startNodeEdit}
        onBlur={endNodeEdit}
        onEnterKeyPress={endNodeEdit}
      >
        {content}
      </GenParagraph>
    </div>
  );
}
