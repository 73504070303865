import * as React from "react";

import * as classNames from "classnames";

import DocTableRow from "./Table/Row";

import { newAddTableRowChange, newAddTableColumnChange, newDeleteTableColumnChange, backgroundStyleFromNode } from "../../../helpers/Doc/changes";

interface DocTableProps extends DocTableType<InlineNode>, BlockNodeAll {
  relevantColumnIndices: Array<number>;
};

function DocTable(props: DocTableProps) {
  const {
    editingContext,
  } = props;

  const rows = props.rows.map((row, index) => {
    return (
      <DocTableRow
        key={row.uid}
        {...row}
        documentContext={props.documentContext}
        currentVersion={props.currentVersion}
        editingContext={editingContext}
        userContext={props.userContext}
        displayContext={props.displayContext}
        parentTableUID={props.uid}
        firstRow={index === 0}
        disableStyleTextEditing={props.disableStyleTextEditing}
        featureFlag={props.featureFlag}
        mediaContext={props.mediaContext}
      />
    );
  });

  if (editingContext.isEditing) {
    const editCells = [0].concat(props.relevantColumnIndices.map((index) => index + 1)).map((editColumnIndex) => {
      const addColumnAfterButton = (
        <div
          key="doc-table-cell-add-column-after"
          className="doc-table-cell-add-column-after no-document-selection"
          onClick={() => {
            editingContext.clearCurrentDocSelection();
            editingContext.addChange(newAddTableColumnChange(props.uid, editColumnIndex));
          }}
        ><i className="fa fa-plus"></i></div>
      );

      if (editColumnIndex === 0) {
        return (
          <td key={editColumnIndex} className="doc-table-edit-column-cell doc-table-edit-row-cell">
            {addColumnAfterButton}
            <div
              key="doc-table-cell-add-row-after"
              className="doc-table-cell-add-row-after no-document-selection"
              onClick={() => {
                editingContext.clearCurrentDocSelection();
                editingContext.addChange(newAddTableRowChange(props.uid, 0));
              }}
            ><i className="fa fa-plus"></i></div>
          </td>
        );
      } else {
        return (
          <td key={editColumnIndex} className="doc-table-edit-column-cell">
            {addColumnAfterButton}
            <div
              key="doc-table-cell-remove-column"
              className="doc-table-cell-remove-column no-document-selection"
              onClick={() => {
                editingContext.clearCurrentDocSelection();
                editingContext.addChange(newDeleteTableColumnChange(props.uid, editColumnIndex - 1));
              }}
            ><i className="fa fa-times"></i></div>
          </td>
        );
      }
    });

    rows.unshift(<tr key="edit-row">
      {editCells}
    </tr>);
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames(props.simple ? "doc-table-simple" : "doc-table", props.uid)}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    >
      <table>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(DocTable);
