import * as React from "react";

import DocTextInput from "../BlockNodes/TextInput";
import DocRatingBar from "../BlockNodes/RatingBar";

import {
  weakMemoizedGenerateEditingContextAllFalse,
} from "../../../helpers/Doc/context";

interface ReasoningToolFeedbackSummaryProps {
  index: number;
  question: string;
  value: number;
  submitted: boolean;
  comment: string;
  maxValue?: number;
  userContext: UserContext;
  displayContext: DisplayContext;
  currentVersion: CurrentVersion;
  documentContext: DocumentContext;
  featureFlag: FeatureFlag;
  mediaContext: MediaContext;
};

export default function ReasoningToolFeedbackSummary({
  index,
  question = "",
  value = 0,
  submitted = false,
  comment = "",
  maxValue = 5,
  userContext,
  displayContext,
  currentVersion,
  documentContext,
  featureFlag,
  mediaContext,
}: ReasoningToolFeedbackSummaryProps) {
  return (
    <div className={`reasoning-tool-feedback-summary ${submitted && "submitted"}`}>
      {question}

      <DocRatingBar
        type={"ratingBar"}
        uid={`feedback_rating_bar_${index}`}
        value={value}
        inactiveBool={submitted}
        maxValue={maxValue}
        editingContext={weakMemoizedGenerateEditingContextAllFalse()}
        userContext={userContext}
        displayContext={displayContext}
        currentVersion={currentVersion}
        documentContext={documentContext}
        featureFlag={featureFlag}
        mediaContext={mediaContext}
      />

      <h5>Comment (optional)</h5>
      <DocTextInput
        type={"textInput"}
        uid={`feedback_text_input_${index}`}
        value={comment}
        inactiveBool={submitted}
        characterLimit={1000}
        editingContext={weakMemoizedGenerateEditingContextAllFalse()}
        userContext={userContext}
        displayContext={displayContext}
        currentVersion={currentVersion}
        documentContext={documentContext}
        featureFlag={featureFlag}
        mediaContext={mediaContext}
      />
    </div>
  );
}
