import * as React from "react";

import { padStart } from "lodash";

import { currentTimeInSeconds } from "../../../helpers/time_format";

interface ReasoningToolTimerProps {
  timeLimit: number;
  timeUsedBeforeCurrentSegment: number;
  currentTimeSegmentStart: number;
  displayContext: DisplayContext;
  shouldShow: boolean;
};

interface ReasoningToolTimerState {
  timeLeftInSeconds?: number;
};

export default class ReasoningToolTimer extends React.Component<ReasoningToolTimerProps, ReasoningToolTimerState> {
  timer?: ReturnType<typeof setTimeout>;

  constructor(props: ReasoningToolTimerProps) {
    super(props);

    const timeLeft = this.calculateTimeLeft();
    if (timeLeft) {
      this.state = {timeLeftInSeconds: timeLeft};
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    if ( this.props.timeLimit ) {
      this.timer = setInterval(this.tick.bind(this), 1000);
    }
  }

  componentWillUnmount() {
    if ( this.timer ) {
      clearInterval(this.timer);
    }
  }

  tick() {
    const timeLeft = this.calculateTimeLeft();
    if (timeLeft) {
      this.setState({timeLeftInSeconds: timeLeft});
    }
  }

  calculateTimeLeft() {
    if (this.props.timeLimit) {
      let timeLeft = this.props.timeLimit;
      if (this.props.timeUsedBeforeCurrentSegment) {
        timeLeft = timeLeft - this.props.timeUsedBeforeCurrentSegment;
      }

      if (this.props.currentTimeSegmentStart) {
        timeLeft = timeLeft - (currentTimeInSeconds() - this.props.currentTimeSegmentStart);
      }

      return timeLeft;
    } else {
      return null;
    }
  }

  render() {
    const {
      displayContext,
      shouldShow = true,
    } = this.props;

    if (!shouldShow) { return null; }

    if (this.state.timeLeftInSeconds && displayContext.isOnline) {
      if (this.state.timeLeftInSeconds > 0) {
        const hrs = padStart(Math.floor(this.state.timeLeftInSeconds / 3600).toString(), 2, "0");
        const mins = padStart(Math.floor((this.state.timeLeftInSeconds % 3600) / 60).toString(), 2, "0");
        const secs = padStart((this.state.timeLeftInSeconds % 60).toString(), 2, "0");

        let timeFormat = `${mins}:${secs}`;
        if (Number(hrs) > 0) {
          timeFormat = `${hrs}:${timeFormat}`;
        }

        return (
          <div className="reasoning-tool-bar-button reasoning-tool-timer">
            <p>Time Remaining</p>
            <span className="reasoning-tool-timer-time">
              {timeFormat}
            </span>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
