import * as React from "react";

import * as R from "ramda";

import GenModal from "../Gen/Modal/component";
import GenButton from "../Gen/Button/component";
import { newSetKeyValueChange } from "../../helpers/Doc/changes";

import { CAUSAL_EXPLANATION_CLASSES, CORE_CONCEPT_CLASSES } from "../../helpers/RO/content_type";

interface ConvertToRLOModalProps {
  handleCancel: () => void;
  editingContextExtra: EditingContextExtra;
  editingContext: EditingContextInEditor;
  mappingContext: MappingContext;
  rlo: ReusableObject;
};

export default function ConvertToRLOModal(props: ConvertToRLOModalProps) {
  const {
    handleCancel,
    editingContextExtra,
    editingContext,
    rlo,
  } = props

  const mappingContext = props.mappingContext as DocumentMappingContext;

  const disableFields = !!rlo
  const [name, setName] = React.useState(rlo?.name || '')
  const [description, setDescription] = React.useState(rlo?.description || '')
  const [learningObjectiveId, setLearningObjectiveId] = React.useState('')
  const [nameError, setNameError] = React.useState('')
  const [currentRO, setCurrentRO] = React.useState<DocSectionType<InlineNode> | null>(null)
  const [errorModalOpen, setErrorModalOpen] = React.useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false)

  if (editingContextExtra.currentRO && (!currentRO || currentRO != editingContextExtra.currentRO)) {
    setCurrentRO(editingContextExtra.currentRO)
    editingContextExtra.setCurrentRO(editingContextExtra.currentRO)
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
    setNameError(null);
  }

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }

  const handleLearingObjectiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLearningObjectiveId(e.target.value)
  }

  const closeErrorModal = () => {
    setErrorModalOpen(false)
  }

  const learningObjectiveReusableObjects = mappingContext.learningObjectiveReusableObjects.filter(loro => loro.reusable_object.uid === currentRO?.uid)

  const handleContinue = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!currentRO) { return; }

    const roData = {
      name,
      description,
      uid: currentRO.uid,
      learning_objective_id: parseInt(learningObjectiveId),
    }

    let loroToBeSaved: ProposedLearningObjectiveReusableObject = {
      learning_objective_id: parseInt(learningObjectiveId),
      reusable_object_id: (currentRO as any).roId, // TODO: Remove any
    }

    let newProposedLoros = mappingContext.proposedLearningObjectiveReusableObjects || [];
    newProposedLoros = newProposedLoros.concat([loroToBeSaved]);
    mappingContext.setProposedLearningObjectiveReusableObjects(newProposedLoros);

    editingContext.addChange(newSetKeyValueChange(currentRO.uid, "roData", roData))
    setConfirmationModalOpen(false)
    handleCancel()
  }

  const rloClasses = rlo?.classes || editingContextExtra?.currentRO?.classes || [];
  const shouldMapToLearningObjectives = R.isEmpty(R.intersection(rloClasses, [...CAUSAL_EXPLANATION_CLASSES, ...CORE_CONCEPT_CLASSES]))

  const handleSave = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!name) {
      setNameError('Reusable Content name is required');
    } else if (shouldMapToLearningObjectives && learningObjectiveReusableObjects.length === 0 && !learningObjectiveId) {
      setErrorModalOpen(true)
    } else {
      setConfirmationModalOpen(true)
    }
  }

  return (<>
    <GenModal open >
      <div className="gen-modal-panel gen-modal-media-menu" style={{marginTop: 90 + 'px'}}>
        <h4>Reusable Content details</h4>
        <div>
          <form action="">
            {rlo && (<>
              <p style={{whiteSpace: 'normal'}}> Source Course: {rlo.document_sets?.name} </p>
              <p style={{whiteSpace: 'normal'}}> Source Case: {rlo.documents[0]?.title} </p>
            </>)}
            <div>
              Name:
              <input
                type="text"
                name="name"
                disabled={disableFields}
                placeholder="Enter Reusable Content Name"
                onChange={handleNameChange}
                value={name}
              />
              <span style={{color: 'red'}}>{nameError}</span>
            </div>
            <div style={{display: 'grid'}}>
              Description:
              <textarea
                name="description"
                disabled={disableFields}
                placeholder="Enter keyword description for Reusable Content"
                onChange={handleDescriptionChange}
                value={description}
              />
            </div>
            {
              shouldMapToLearningObjectives &&
              <div style={{display: 'grid'}}>
                Map Reusable Content to Learning Objective(s):
                <select style={{width: '100%'}} value={learningObjectiveId} onChange={handleLearingObjectiveChange} disabled={learningObjectiveReusableObjects.length !== 0}>
                  <option value="">Select case learning objective</option>
                  {mappingContext.learningObjectives.map((lo)=>(
                    <option key={lo.id} value={lo.id}>{lo.name}</option>
                  ))}
                </select>
              </div>
            }
            <div style={{display: 'grid'}}>
              {learningObjectiveReusableObjects.length !== 0 && <>
                <div>Reusable Content Learning Objective(s):</div>
                <p style={{whiteSpace: 'pre-wrap'}}>{learningObjectiveReusableObjects[0].learning_objective?.name}</p>
              </>}
            </div>
          </form>
          <div className="gen-button-left-group">
            <GenButton name="Save" highlighted small onClick={handleSave}/>
            <GenButton name="Cancel" highlighted small onClick={handleCancel} />
          </div>
        </div>
      </div>
    </GenModal>
    <GenModal open={errorModalOpen} >
      <div className="gen-modal-panel gen-modal-media-menu" style={{marginTop: 90 + 'px'}}>
        <div style={{"textAlign": "center"}}>
          <p style={{"fontWeight": "bold", "fontSize": "1.2vw"}}>
            Please map this reusable content to a case learning objective before saving.
          </p>
          <div><button onClick={closeErrorModal}>OK</button></div>
        </div>
      </div>
    </GenModal>
    <GenModal open={confirmationModalOpen} >
      <div className="gen-modal-panel gen-modal-media-menu" style={{marginTop: 90 + 'px'}}>
        <div style={{"textAlign": "center"}}>
          <p>
            Click <b>Continue</b> to save this Reusable Content or <b>Cancel</b> to discard
          </p>
          <div className="gen-button-left-group" style={{position: 'relative', marginLeft: 36 + '%'}}>
            <GenButton name="Continue" highlighted small onClick={handleContinue}/>
            <GenButton name="Cancel" highlighted small onClick={handleCancel} />
          </div>
        </div>
      </div>
    </GenModal>
  </>);
}
