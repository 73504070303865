import * as pluralize from 'pluralize'

function generateReasoningToolStateFunctions(type: string, addState: AddStateFunction, rootUID: string) {
  const pluralType = pluralize.plural(type)

  return {
    add: (uid: string, data={}) => {
      addState({
        [uid]: {type, uid, ...data},
        [rootUID]: {[pluralType]: (nodeUIDs=[]) => [...nodeUIDs, uid]}
      })
    },
    delete: (uid: string) => {
      addState({
        [rootUID]: {[pluralType]: (nodeUIDs=[]) => nodeUIDs.filter(nodeUID => nodeUID !== uid)}
      })
    },
    update: (uid: string, data={}) => {
      addState({
        [uid]: data
      })
    }
  }
}

export function generateNotesStateFunctions(addState: AddStateFunction, rootUID: string) {
  const noteFunctions = generateReasoningToolStateFunctions("note", addState, rootUID)

  return {
    addNote: noteFunctions.add,
    deleteNote: noteFunctions.delete,
    updateNote: noteFunctions.update,
  }
}

export function generateFindingsStateFunctions(addState: AddStateFunction, rootUID: string) {
  const findingFunctions = generateReasoningToolStateFunctions("finding", addState, rootUID)

  return {
    addFinding: findingFunctions.add,
    deleteFinding: findingFunctions.delete,
    updateFinding: findingFunctions.update,
  }
}

export function generateDiagnosesStateFunctions(addState: AddStateFunction, rootUID: string) {
  const diagnosisFunctions = generateReasoningToolStateFunctions("diagnosis", addState, rootUID)

  return {
    addDiagnosis: diagnosisFunctions.add,
    deleteDiagnosis: diagnosisFunctions.delete,
    updateDiagnosis: diagnosisFunctions.update,
    setIsDangerousDiagnosis: (uid: string, value: boolean) => {
      addState({
        [uid]: {isDangerousDiagnosis: value}
      })
    },
    setIsLeadingDiagnosis: (uid: string) => {
      addState({
        [rootUID]: {leadingDiagnosis: uid}
      })
    }
  }
}
