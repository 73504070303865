import * as React from "react";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

interface DocEditHeaderFalseOrNumberInputProps {
  contentKey: string;
  default: false | number;
  disableMetadataEdit: boolean;
  editingContext: EditingContextInEditor;
  label: string;
  uid: string;
  value: false | number;
};

export default function DocEditHeaderFalseOrNumberInput(props: DocEditHeaderFalseOrNumberInputProps) {
  const value = typeof(props.value) === "number" ? props.value : props.default || false;

  return (
    <div className="doc-edit-header-input doc-edit-header-false-or-number-input">
      <label htmlFor={props.label}>{props.label}</label>
      <input type="checkbox" name={props.label} checked={value !== false} disabled={props.disableMetadataEdit} onChange={() => {
        props.editingContext.addChange(newSetKeyValueChange(props.uid, props.contentKey, value === false ? 1 : false));
      }} />
      {(value !== false) && <input type="text" value={value || ""} onChange={(e) => {
          let newNumber = parseInt(e.target.value);
          if (isNaN(newNumber)) {
            newNumber = 0;
          }

          if (newNumber < 0) {
            newNumber = newNumber * -1;
          }

          props.editingContext.addChange(newSetKeyValueChange(props.uid, props.contentKey, newNumber));
        }} />
      }
    </div>
  );
}
