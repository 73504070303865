import * as React from "react";

import * as classNames from "classnames";
import analyticsModule from "../../../../../application/analytics";

import {
  find,
  includes,
  intersection,
  isEmpty,
  kebabCase,
  replace,
  upperCase,
} from "lodash";

import IconCircleExpandImage from "images/icon-circle-expand-1.svg";
import IconCircleCollapseImage from "images/icon-circle-collapse-1.svg";

import MultiUseImage from "images/multi-use.jpg";
import SingleUseImage from "images/single-use.jpg";

import { OPENED_SECTION_LOG } from "../../../../helpers/DocumentUserLog";

import { MEDIA_PICKER } from "../../../../helpers/Doc/menu";

import DocStyledText from "../../BlockNodeHelpers/StyledText";

import { TEACHING_POINT_CLASSES } from "../../../../helpers/RO/content_type";

import AqButton from '../../../Aq/Button';

interface DocSectionHeaderProps extends DocSectionType<InlineNode>, BlockNodeAll {
  integrationInfo: IntegrationInfo;
  openSiblingSectionUIDs: Array<string>;
  paused?: boolean;
  roData: RoData;
  roDocumentCount: number;
  submitted?: boolean;
};

function DocSectionHeader(props: DocSectionHeaderProps) {
  const {
    currentVersion,
    editingContext,
    displayContext,
    userContext,
    classes,
    collapsed,
    collapsible,
    inactiveBool,
    level,
    title,
    uid,
    openSiblingSectionUIDs,
    disableStyleTextEditing,
    roData,
    roDocumentCount = 1,
  } = props;

  const onHeaderClick = () => {
    displayContext.setFocusedBookmarkUid(null);

    if (inactiveBool) { return null; }

    if (collapsible) {
      const newState = { [uid]: {collapsed: !collapsed} };

      if (collapsed) {
        userContext.logEvent(OPENED_SECTION_LOG, {node_uid: uid});

        if(classes && classes.includes("mod_map_card")) {
          analyticsModule.track("open_mod_map_from_within_script", { case: document.title, source: 'Integrated Illness Script', page: window.location.href })
        }

        if(classes && classes.includes("core_concept_header")) {
          analyticsModule.track("open_core_concept", { case: document.title, source: 'Integrated Illness Script', page: window.location.href })
        }

        if (currentVersion.onlyOneUncollapsedSection && level === 1 && openSiblingSectionUIDs && !displayContext.canBeEditing) {
          openSiblingSectionUIDs.forEach((uid) => {
            newState[uid] = {collapsed: true};
          });
        }

        if(classes && classes.includes("expert_comment")) {
          analyticsModule.track("clicks_on_deep_dives", { case: document.title, source: 'Case', page: window.location.href })
        }
      }

      userContext.addState(newState);
    }
  };

  let collapsedIndicator;
  if (collapsible) {
    if (!isEmpty(intersection(["neutral_text", "core_concept_header"], classes))) {
      if (collapsed) {
        collapsedIndicator = <span className="doc-section-collapsed-indicator"><img src={IconCircleExpandImage} alt="collapse" className="neutral-text-collapse-icon" /></span>;
      } else {
        collapsedIndicator = <span className="doc-section-collapsed-indicator"><img src={IconCircleCollapseImage} alt="expand" className="neutral-text-collapse-icon" /></span>;
      }
    } else {
      if (collapsed) {
        collapsedIndicator = <span className="doc-section-collapsed-indicator"><span className="icon-open-box"></span></span>;
      } else {
        collapsedIndicator = <span className="doc-section-collapsed-indicator"><span className="icon-close-box"></span></span>;
      }
    }
  }

  let coverImage;
  if (level === 0 && editingContext.isEditing) {
    const startCoverEditAction = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      editingContext.setCurrentMenu(MEDIA_PICKER, { mediaType: "image", uid: uid });
    };

    coverImage = (
      <div className="doc-section-header-cover-image" onClick={startCoverEditAction}>
        <img src={currentVersion.coverImage} />
      </div>
    );
  }

  let addLOButton;
  if (props?.featureFlag?.saq_phase_1 && editingContext.isEditing && classes?.includes("learning_objectives_card")) {
    addLOButton = (
      <span style={{marginRight: '10px'}}>
        <AqButton
          className="pink outline small"
          onClick={() => {
            editingContext.setCurrentMenu("LEARNING_OBJECTIVES_ADD_NEW");
          }}
        >
          <div className="aq-button-text">
            ADD LO
          </div>
        </AqButton>
      </span>
    );
  }

  let rightHeader = null;
  const cardClass = find(classes, (cl) => {return cl.includes("_card");});
  if (cardClass) {
    const cardC = replace(cardClass, "_card", "");
    const cardText = upperCase(cardC);

    const cardIcon = <span className={kebabCase(`icon-${cardC}`)}></span>;

    rightHeader = (
      <div className="doc-section-header-right">
        <h5 className="doc-section-header-right-title">{cardText}{cardIcon}</h5>
      </div>
    );
  }

  let topper = null;
  if (!isEmpty(intersection([...TEACHING_POINT_CLASSES,"expert_comment"], classes))) {

    let icon = "icon-star-circle";
    let topperTitle = "TEACHING POINT";
    let faIcon = null;
    let topperCollapsedIndicator = null;
    let sectionTitle = null;

    if (includes(classes, "underlying_core_concepts")) {
      icon = "";
      faIcon = <i key="fa-list-ul" className="fa fa-list-ul"></i>;
      topperTitle = "Underlying Core Concepts";

      topper = (
        <div className="core-concepts-topper">
          <span className={`core-concepts-topper-icon ${icon}`}>{faIcon}</span>
          <span className="core-concepts-topper-title">{topperTitle} </span>
        </div>
      );
    } else if (includes(classes, "expert_comment")) {
      icon = "";
      faIcon = <i key="fa-anchor-icon" className="fa fa-anchor"></i>;
      topperTitle = "Deep Dive";
      sectionTitle = (
        <span className="teaching-point-section-topper-title">
          <DocStyledText
            uid={uid}
            content={title}
            contentKey={"title"}
            editingContext={editingContext}
            placeholder={"Enter Deep Dive title here"}
            independentlyEditable
            disableStyleTextEditing={disableStyleTextEditing}
          />
        </span>
      );

      if (collapsible) {
        if (collapsed) {
          topperCollapsedIndicator = <img src={IconCircleExpandImage} alt="collapse" className="expert-comment-collapse-icon" />;
        } else {
          topperCollapsedIndicator = <img src={IconCircleCollapseImage} alt="expand" className="expert-comment-collapse-icon" />;
        }
      }
    }

    topper = (
      <div className="teaching-point-topper" onClick={onHeaderClick}>
        <div className="teaching-point-topper-content">
          {topperCollapsedIndicator}
          {roData && <img src={roDocumentCount > 1 ? MultiUseImage : SingleUseImage}  height="25" width="25"/>}
          <span className={`teaching-point-topper-icon ${icon}`}>{faIcon}</span>
          <span className="teaching-point-topper-title">{topperTitle}</span>
          {sectionTitle}
        </div>
        <div className="teaching-point-topper-border" />
      </div>
    );
  }

  const sectionPlaceholder = () => {
    if (level === 1) {
      return "Enter Page title here";
    } else if (level > 1) {
      if (includes(classes, "neutral_text")) {            return "Enter Neutral text here";}
      else if (includes(classes, "core_concept_header")) {return "Enter Core Concept header here"; }
      else if (includes(classes, "dialog_box")) {         return "Enter Dialog Box text here"; }
      else if (includes(classes, "alert")) {              return "Enter Alert text here"; }
      else if (includes(classes, "clinical_reasoning")) { return "Enter Clinical Reasoning text here"; }
      else if (includes(classes, "clinical_skills")) {    return "Enter Clinical Skills text here"; }
      else if (includes(classes, "knowledge")) {          return "Enter Knowledge text here"; }
      else if (includes(classes, "management")) {         return "Enter Management text here"; }
      else if (includes(classes, "principles")) {         return "Enter Principles text here"; }
      else if (includes(classes, "studies")) {            return "Enter Studies text here"; }
      else                                                return "Enter Section text here";
    } else {
      return "Enter Section text here";
    }
  };

  return (
    <div
      id={`${uid}_header`}
      className={classNames("doc-section-header", { "display-root": level === 0 })}
      onClick={onHeaderClick}
    >
      {topper}
      <div className="doc-section-header-banner">
        <h1 className="doc-section-header-title">
          {coverImage}
          {collapsedIndicator}
          <span>
            <DocStyledText
              uid={uid}
              content={title}
              contentKey={"title"}
              placeholder={sectionPlaceholder()}
              editingContext={editingContext}
              independentlyEditable
              disableStyleTextEditing={disableStyleTextEditing}
            />
          </span>
        </h1>
        <span style={{marginRight: '10px'}}>
          {addLOButton}
        </span>
        {rightHeader}
      </div>
    </div>
  );
}

export default React.memo(DocSectionHeader);
