import * as React from "react";

import * as R from "ramda";

import PoweredByAqueductImage from "images/Powered-by-Aqueduct.png";

import { full_url } from "../../helpers/url";

import { OPENED_LAB_VALUES_LOG } from "../../helpers/DocumentUserLog";

import { findNode } from "../../helpers/Doc/Functions/base";

import {
  // EDITOR PANELS
  AET_ADMIN_TOOLS,
  ASSESSMENT_QUESTION_DATA,
  CASE_LEARNING_OBJECTIVES,
  CASE_TEACHING_POINTS,
  CASE_MAP,
  CASE_METADATA,
  EDITORS_CHECKLIST,
  SUMMARY_OF_EDITS,
  STUDENT_FEEDBACK,

  // STUDENT PANELS
  BOOKMARKS,
  DIAGNOSES,
  FEEDBACK,
  FINDINGS,
  LABVALUES,
  NOTES,
} from "../../reducers/ui/reasoningTool";

import {
  weakMemoizedApplyAllChanges,
  changesDisplayModes,
} from "../../helpers/Doc/changes";

import ReasoningToolAdminToolsPanel from "./ReasoningTool/AdminToolsPanel";
import ReasoningToolAssessmentQuestionDataPanel from "./ReasoningTool/AssessmentQuestionDataPanel";
import ReasoningToolBarButton from "./ReasoningTool/BarButton";
import ReasoningToolBookmarksPanel from "./ReasoningTool/BookmarksPanel";
import ReasoningToolCaseMetadataPanel from "./ReasoningTool/CaseMetadataPanel";
import ReasoningToolCaseTeachingPointsPanel from "./ReasoningTool/CaseTeachingPointsPanel";
import ReasoningToolCaseLearningObjectivesPanel from "./ReasoningTool/CaseLearningObjectivesPanel";
import ReasoningToolCaseMapPanel from "./ReasoningTool/CaseMapPanel";
import ReasoningToolFeedbackPanel from "./ReasoningTool/FeedbackPanel";
import ReasoningToolLabValuesPanel from "./ReasoningTool/LabValuesPanel";
import ReasoningToolTextListPanel from "./ReasoningTool/TextListPanel";
import ReasoningToolStudentFeedbackPanel from "./ReasoningTool/StudentFeedbackPanel";
import ReasoningToolSummaryOfEditsPanel from "./ReasoningTool/SummaryOfEditsPanel";
import ReasoningToolEditorsChecklist from './ReasoningTool/EditorsChecklist'
import ReasoningToolTimer from "./ReasoningTool/Timer";
import analyticsModule from "../../../application/analytics.js";

const reasoningToolPanelsMap = {
  // EDITOR PANELS
  [AET_ADMIN_TOOLS]: ReasoningToolAdminToolsPanel,
  [ASSESSMENT_QUESTION_DATA]: ReasoningToolAssessmentQuestionDataPanel,
  [CASE_METADATA]: ReasoningToolCaseMetadataPanel,
  [CASE_TEACHING_POINTS]: ReasoningToolCaseTeachingPointsPanel,
  [CASE_LEARNING_OBJECTIVES]: ReasoningToolCaseLearningObjectivesPanel,
  [CASE_MAP]: ReasoningToolCaseMapPanel,
  [SUMMARY_OF_EDITS]: ReasoningToolSummaryOfEditsPanel,
  [STUDENT_FEEDBACK]: ReasoningToolStudentFeedbackPanel,
  [EDITORS_CHECKLIST]: ReasoningToolEditorsChecklist,

  // STUDENT PANELS
  [BOOKMARKS]: ReasoningToolBookmarksPanel,
  [DIAGNOSES]: ReasoningToolTextListPanel,
  [FEEDBACK]: ReasoningToolFeedbackPanel,
  [FINDINGS]: ReasoningToolTextListPanel,
  [LABVALUES]: ReasoningToolLabValuesPanel,
  [NOTES]: ReasoningToolTextListPanel,
};

interface ReasoningToolProps {
  changeData: ChangeData;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
  editingContext: EditingContext;
  editingContextExtra: EditingContextExtra;
  currentVersion: CurrentVersion,
  mergedData: MergedData;
  reasoningToolContext: ReasoningToolContext;
  userContext: UserContext;
  featureFlag: FeatureFlag;
  userState: UserState;
  mediaContext: MediaContext,
  mappingContext: MappingContext,
  projectContext: ProjectContext,
};

export default function ReasoningTool(props: ReasoningToolProps) {
  const {
    documentContext,
    editingContext,
    editingContextExtra,
    displayContext,
    userContext,
    projectContext,
    reasoningToolContext,
    mergedData,
    changeData,
    userState,
  } = props;

  const {
    activePanel,
    togglePanel,
  } = reasoningToolContext;

  const {
    disableDiagnoses = false,
    disableFindings = false,
    disableNotes = false,
    disableBookmarks = false,
    disableFeedback = true,
    disableLabValues = false,
    timeLimit = null,
    currentTimeSegmentStart = null,
    timeUsedBeforeCurrentSegment = 0,
  } = mergedData;

  const assessmentQuestionSignatureVersionId = editingContextExtra?.assessmentQuestionSignatureVersion?.documentId || documentContext.id;
  let reasoningToolSideNavChanges = props.featureFlag.new_landing_pages ? "reasoning-tool-side-nav" : ""

  const panelButtons = (
    <>
      <ReasoningToolBarButton
        key="AET ADMIN TOOLS"
        name="AET ADMIN TOOLS"
        isActive={activePanel === AET_ADMIN_TOOLS}
        onToggle={()=>{togglePanel(AET_ADMIN_TOOLS);}}
        shouldShow={editingContext.isEditing && userContext.isAdmin}
      />
      <ReasoningToolBarButton
        key="CASE METADATA"
        name="CASE METADATA"
        isActive={activePanel === CASE_METADATA}
        onToggle={()=>{togglePanel(CASE_METADATA);}}
        shouldShow={editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="CASE MAP"
        name="CASE MAP"
        isActive={activePanel === CASE_MAP}
        onToggle={()=>{togglePanel(CASE_MAP);}}
        shouldShow={editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="CASE LEARNING OBJECTIVES"
        name="CASE LEARNING OBJECTIVES"
        isActive={activePanel === CASE_LEARNING_OBJECTIVES}
        onToggle={()=>{togglePanel(CASE_LEARNING_OBJECTIVES);}}
        shouldShow={editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="CASE TEACHING POINTS"
        name="CASE TEACHING POINTS"
        isActive={activePanel === CASE_TEACHING_POINTS}
        onToggle={()=>{togglePanel(CASE_TEACHING_POINTS);}}
        shouldShow={editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="CASE ASSESSMENT QUESTIONS"
        name="CASE ASSESSMENT QUESTIONS"
        openLink={full_url(`/documents/${assessmentQuestionSignatureVersionId}/assessment_questions`)}
        shouldShow={editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="ASSESSMENT QUESTION DATA"
        name="ASSESSMENT QUESTION DATA"
        isActive={activePanel === ASSESSMENT_QUESTION_DATA}
        onToggle={()=>{togglePanel(ASSESSMENT_QUESTION_DATA);}}
        shouldShow={editingContext.isEditing && documentContext.kind === "assessment_question_mcq"}
      />
      <ReasoningToolBarButton
        key="StudentFeedback"
        name="Student Feedback"
        isActive={activePanel === STUDENT_FEEDBACK}
        onToggle={()=>{togglePanel(STUDENT_FEEDBACK);}}
        shouldShow={editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="SummaryOfEdits"
        name="Summary of Edits"
        iconName="icon-notes"
        isActive={activePanel === SUMMARY_OF_EDITS}
        onToggle={()=>{togglePanel(SUMMARY_OF_EDITS);}}
        shouldShow={editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="Editor's Checklist"
        name="Editor's Checklist"
        iconName='fa fa-lg fa-list-alt'
        isActive={activePanel === EDITORS_CHECKLIST}
        onToggle={()=>{togglePanel(EDITORS_CHECKLIST);}}
        shouldShow={!!projectContext.activeMemberId && projectContext.activeUserId === userContext.id}
      />
      <ReasoningToolBarButton
        key="Diagnoses"
        name="Diagnoses"
        iconName="icon-diagnosis"
        isActive={activePanel === DIAGNOSES}
        onToggle={()=>{onToggle(DIAGNOSES);}}
        shouldShow={!disableDiagnoses && !editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="Findings"
        name="Findings"
        iconName="icon-findings"
        isActive={activePanel === FINDINGS}
        onToggle={()=>{onToggle(FINDINGS);}}
        shouldShow={!disableFindings && !editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="Notes"
        name="Notes"
        iconName="icon-notes"
        isActive={activePanel === NOTES}
        onToggle={()=>{onToggle(NOTES);}}
        shouldShow={!disableNotes && !editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="Bookmarks"
        name="Bookmarks"
        iconName="icon-bookmark"
        isActive={activePanel === BOOKMARKS}
        onToggle={()=>{onToggle(BOOKMARKS);}}
        shouldShow={!disableBookmarks && !editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="Feedback"
        name="Feedback"
        iconName="fa fa-lg fa-comment"
        isActive={activePanel === FEEDBACK}
        onToggle={()=>{togglePanel(FEEDBACK);}}
        shouldShow={!disableFeedback && !editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="LabValues"
        name="Lab Values"
        iconName="fa fa-lg fa-flask"
        isActive={activePanel === LABVALUES}
        onToggle={()=>{
          if (activePanel !== LABVALUES) { userContext.logEvent(OPENED_LAB_VALUES_LOG); }
          onToggle(LABVALUES);
        }}
        shouldShow={!disableLabValues && !editingContext.isEditing}
      />
      <ReasoningToolTimer
        key="Timer"
        timeLimit={timeLimit}
        currentTimeSegmentStart={currentTimeSegmentStart}
        timeUsedBeforeCurrentSegment={timeUsedBeforeCurrentSegment}
        displayContext={displayContext}
        shouldShow={!editingContext.isEditing}
      />
      <ReasoningToolBarButton
        key="AQUIFER STYLE GUIDE"
        name="AQUIFER STYLE GUIDE"
        openLink={full_url(`/document_set_document_relations/${displayContext.styleGuideId}`)}
        shouldShow={!!(editingContext.isEditing && displayContext.styleGuideId)}
      />
    </>
  );

  const onToggle = (panel: PanelType) => {
    if(activePanel != panel) {
      trackEvent(panel);
    }
    togglePanel(panel);
  }

  const trackEvent = (panel: PanelType) => {
    const caseName = documentContext.title

    switch(panel) {
      case DIAGNOSES:
        analyticsModule.track('click_on_diagnoses', { page: window.location.href, source: 'Case', case: caseName})
        break;
      case FINDINGS:
        analyticsModule.track('click_on_findings', { page: window.location.href, source: 'Case', case: caseName})
        break;
      case NOTES:
        analyticsModule.track('click_on_notes_in_sidebar', { page: window.location.href, source: 'Case', case: caseName})
        break;
      case BOOKMARKS:
        analyticsModule.track('click_on_bookmark_on_sidebar', { page: window.location.href, source: 'Case', case: caseName})
        break;
      case LABVALUES:
        analyticsModule.track('click_on_lab_values', { page: window.location.href, source: 'Case', case: caseName})
        break;
    }
  }

  let openPanel;
  if (activePanel && reasoningToolPanelsMap[activePanel]) {
    let extraData: any = {}; // TODO: Remove any
    switch (activePanel) {
      case DIAGNOSES:
        extraData = {
          diagnoses: mergedData.diagnoses,
          leadingDiagnosis: mergedData.leadingDiagnosis,
        };
        break;
      case FINDINGS:
        extraData = {
          findings: mergedData.findings,
        };
        break;
      case NOTES:
        extraData = {
          notes: mergedData.notes,
        };
        break;
      case EDITORS_CHECKLIST:
        extraData = {
          currentAssignmentId: projectContext.currentAssignmentId,
          nextVersionId: projectContext.nextVersionId,
          checklistContents: projectContext.checklistContents,
          activeMemberId: projectContext.activeMemberId,
        }
        break;
      case FEEDBACK:
        extraData = {
          userState: userState,
        }
        break;
      case CASE_LEARNING_OBJECTIVES:
        if (documentContext.kind !== "assessment_question_mcq") {
          const compiledChangeData = changeData && !R.isEmpty(changeData) ?
            weakMemoizedApplyAllChanges(changeData, changesDisplayModes.HIGHLIGHT_ALL_CHANGES) :
            mergedData;

          const learningObjectivesList = findNode(mergedData, (node) => node.type === "learningObjectivesList");

          extraData = {
            learningObjectivesList,
          }
        }

        break;
    }

    const OpenPanel = reasoningToolPanelsMap[activePanel];
    openPanel = <OpenPanel {...props} {...extraData} />;
  }

  return (
    <div className={`reasoning-tool ${reasoningToolSideNavChanges}`}>
      <div className="reasoning-tool-buttons-bar" style={{overflow: "auto"}}>
        {panelButtons}
        <div className="reasoning-tool-buttons-bar-watermark">
          <img src={PoweredByAqueductImage} />
        </div>
      </div>
      {openPanel}
    </div>
  );
}
