import { range } from "lodash";

export function stringReplaceByRange(string: string, range: DocRange, newString: string) {
  return string.substr(0,range.start) + newString + string.substr(range.end);
}

export function isEqualRanges(range1: DocRange, range2: DocRange) {
  return (range1.start === range2.start) && (range1.end === range2.end);
}

//TODO isRangeCollapsed vs isRangeEmpty
export function isRangeCollapsed(range: DocRange) {
  return range.start === range.end;
}

export function isRangeEmpty(range: DocRange) {
  return range.start >= range.end;
}

export function rangeLength(range: DocRange) {
  return range.end - range.start;
}

export function rangesDoTouch(range1: DocRange, range2: DocRange) {
  return range1.end >= range2.start && range1.start <= range2.end;
}

export function rangesDoOverlap(range1: DocRange, range2: DocRange) {
  return range1.end > range2.start && range1.start < range2.end;
}

export function rangesIntersection(range1: DocRange, range2: DocRange) {
  return rangesDoTouch(range1, range2) ? {start: Math.max(range1.start, range2.start), end: Math.min(range1.end, range2.end)} : null;
}

export function rangesUnion(range1: DocRange, range2: DocRange) {
  return rangesDoTouch(range1, range2) ? {start: Math.min(range1.start, range2.start), end: Math.max(range1.end, range2.end)} : null;
}

export function rangesExcept(range1: DocRange, range2: DocRange) {
  if (rangesDoTouch(range1, range2)) {
    let newRanges: Array<DocRange> = [];

    if (range1.start < range2.start) {
      newRanges.push({
        start: range1.start,
        end: range2.start,
      })
    }

    if (range1.end > range2.end) {
      newRanges.push({
        start: range2.end,
        end: range1.end,
      })
    }

    return newRanges;
  } else {
    return [range1];
  }
}

export function extendRangeAtIndexByValue(range: DocRange, index: number, value: number) {
  return {
    start: range.start > index ? range.start + value : range.start,
    end: range.end >= index ? range.end + value: range.end,
  }
}

export function splitAndShiftRangeAtIndexByValue(range: DocRange, index: number, value: number) {
  if (range.end <= index) {
    return [range];
  } else if(range.start >= index) {
    return [shiftRange(range, value)];
  } else {
    return [
      {
        start: range.start,
        end: index,
      },
      {
        start: index + value,
        end: range.end + value
      }
    ]
  }
}

export function deleteRangeAtIndexByValue(range: DocRange, index: number, value: number) {
  const newStart = range.start > index ? Math.max(range.start - value, index) : range.start;
  const newEnd = range.end > index ? Math.max(range.end - value, index) : range.end;

  if (newStart !== newEnd) {
    return {
      start: newStart,
      end: newEnd
    }
  } else {
    return null;
  }
}

export function isInOrEndRange(range: DocRange, index: number) {
  return index > range.start && index <= range.end;
}

export function collapsedRangeAtIndex(index: number) {
  return {
    start: index,
    end: index,
  };
}

export function collapseRangeToStart(range: DocRange) {
  return {
    start: range.start,
    end: range.start,
  }
}

export function collapseRangeToEnd(range: DocRange) {
  return {
    start: range.end,
    end: range.end,
  }
}

export function shiftRange(range: DocRange, value: number) {
  return {
    start: range.start + value,
    end: range.end + value,
  }
}

export function offsetOfTouchStart(range1: DocRange, range2: DocRange) {
  if (rangesDoTouch(range1, range2)) {
    if (range1.start < range2.start ) {
      return range2.start - range1.start;
    } else {
      return 0;
    }
  } else {
    return null;
  }
}

export function offsetOfTouchEnd(range1: DocRange, range2: DocRange) {
  if (rangesDoTouch(range1, range2)) {
    if (range1.end > range2.end ) {
      return range2.end - range1.start;
    } else {
      return rangeLength(range1);
    }
  } else {
    return null;
  }
}

export function offsetRangeOfTouch(range1: DocRange, range2: DocRange) {
  if (rangesDoTouch(range1, range2)) {
    return {
      start: offsetOfTouchStart(range1, range2),
      end: offsetOfTouchEnd(range1, range2),
    };
  } else {
    return null;
  }
}

export function rangesSortByStart(ranges: Array<DocRange>) {
  return ranges.sort((range1, range2) => {return range1.start - range2.start;});
}

export function rangesSortByEnd(ranges: Array<DocRange>) {
  return ranges.sort((range1, range2) => {return range1.end - range2.end;});
}

export function compressRanges(ranges: Array<DocRange>) {
  return rangesSortByStart(ranges).reduce((compressedRanges, range) => {
    const prevRange = compressedRanges.pop();
    if (!prevRange) {
      return [range];
    } else {
      const unionOfRanges = rangesUnion(prevRange, range);

      if (unionOfRanges) {
        compressedRanges.push(unionOfRanges);
      } else {
        compressedRanges.push(prevRange, range);
      }

      return compressedRanges;
    }
  }, [] as Array<DocRange>);
}

export function isRangeCoveredByRanges(range: DocRange, coverRanges: Array<DocRange>) {
  const compressedCoverRanges = compressRanges(coverRanges);
  const relevantCoverRange = compressedCoverRanges.find((coverRange) => coverRange.end > range.start);

  return (
    !!relevantCoverRange
    && relevantCoverRange.start <= range.start
    && relevantCoverRange.end >= range.end
  );
}

// withRange functions

export function rejectItemsWithEmptyRanges<T extends WithDocRange>(withRanges: Array<T>) {
  return withRanges.filter((withRange) => !isRangeEmpty(withRange.range));
}

export function sortItemsWithRangesByStart<T extends WithDocRange>(withRanges: Array<T>) {
  return withRanges.sort((withRange1, withRange2) => {return withRange1.range.start - withRange2.range.start;});
}
