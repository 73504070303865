import * as React from 'react';

interface Props {
  infoText: string

  onMouseEnter: () => void
}

export default function AqInfoBubble(props: Props): React.ReactElement {
  const {
    infoText,
    onMouseEnter,
  } = props;

  return (
    <div className="aq-info-bubble" onMouseEnter={onMouseEnter}>
      <span className="tooltip-box small above after wider">
        {infoText}
      </span>
    </div>
  );
}