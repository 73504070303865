import { cloneDeep } from "lodash";

import memoize from "memoize-weak";

import { reflow as reflowSignatureCase } from "./Kind/SignatureCase";

export const weakMemoizedReflow = memoize(reflow);
export function reflow(json, documentKind: DocumentKind, documentId: number) {
  const clonedJson = cloneDeep(json);

  switch (documentKind) {
    case "signature_case":
    case "signature_case_cme":
      return reflowSignatureCase(clonedJson, documentId);
    default:
      return clonedJson;
  }
}
