import * as React from "react";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

import DocStyledText from "../BlockNodeHelpers/StyledText";

interface DocCDQFeaturesProps extends BlockNodeAll {
  type: string;
  uid: string;
  classes?: Array<string>;
}

export default function DocCDQFeatures(props: DocCDQFeaturesProps) {
  if (!props.cdqFeatures || !props.editingContext.isEditing) { return null; }

  const cdqFeatureRows = props.cdqFeatures.map((cdqFeature, index) => {
    const includeInAllNodes = {
      key: cdqFeature.uid,
      documentContext: props.documentContext,
      currentVersion: props.currentVersion,
      editingContext: props.editingContext,
      userContext: props.userContext,
    };

    return (
      <li
        id={cdqFeature.uid}
        key={cdqFeature.uid}
        className="doc-cdq-key-feature-row"
        style={backgroundStyleFromNode({...includeInAllNodes, ...cdqFeature})}
      >
        <span>
          <DocStyledText
            uid={cdqFeature.uid}
            content={cdqFeature.content}
            contentKey={"content"}
            editingContext={props.editingContext}
            independentlyEditable
            disableStyleTextEditing={props.disableStyleTextEditing}
            returnPlainText
          />
        </span>
      </li>
    );
  });

  return (
    <div
      className="doc-cdq-key-feature-rows"
      contentEditable={false}
    >
      <h5>Features</h5>
      <ul>
        {cdqFeatureRows}
      </ul>
    </div>
  );
}
