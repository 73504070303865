import * as React from "react";

import DocContentMappingAssessmentMap from "./ContentMappingTool/AssessmentMap";
import DocContentMappingLOTPMap from "./ContentMappingTool/LOTPMap";

interface DocContentMappingToolProps {
  documentContext: DocumentContext;
  editingContextExtra: EditingContextExtra;
  mappingContext: MappingContext;
  title: InlineRangeNode;
  userContext: UserContext;
  featureFlag: FeatureFlag
};

interface DocContentMappingToolState {
  isCollapsed: boolean;
};

export default class DocContentMappingTool extends React.Component<DocContentMappingToolProps, DocContentMappingToolState> {
  constructor(props: DocContentMappingToolProps) {
    super(props);

    this.state = {
      isCollapsed: true,
    };
  }

  render() {
    const {
      documentContext,
      editingContextExtra,
      mappingContext,
    } = this.props;

    const {
      isCollapsed,
    } = this.state;

    const isAssessmentQuestion = documentContext.kind === "assessment_question_mcq";
    let doclotpEditorSideNavChanges = this.props.featureFlag.new_landing_pages ? "doc-lotp-editor-side-nav" : ""

    let collapsibleTools
    if (!isCollapsed) {
      let contentMappingChild;
      if ('setLearningObjectiveReusableObjectId' in mappingContext) {
        contentMappingChild = (
          <DocContentMappingAssessmentMap
            learningObjectiveReusableObjects={mappingContext.learningObjectiveReusableObjects}
            currentLearningObjectiveReusableObjectId={mappingContext.currentLearningObjectiveReusableObjectId}
            proposedLearningObjectiveReusableObjectId={mappingContext.proposedLearningObjectiveReusableObjectId}
            setLearningObjectiveReusableObjectId={mappingContext.setLearningObjectiveReusableObjectId}
            assessmentTitle={this.props.title.text}
            assessmentMappingToolPermission={this.props.userContext.permissions.assessmentMappingTool}
            usedAvailableForm={this.props.editingContextExtra.usedAvailableForm}
          />
        );
      } else {
        let activeLearningObjective
        if (editingContextExtra.currentlyEditingNodeLineage) {
          let activeLoId = (editingContextExtra.currentlyEditingNodeLineage.find(node => node.type === "learningObjective") as DocLearningObjectiveType<InlineNode>)?.id
          activeLearningObjective = mappingContext.learningObjectives.find( lo => lo.id === activeLoId)
        }
        contentMappingChild = (
          <DocContentMappingLOTPMap
            reusableObjects={mappingContext.reusableObjects}
            currentLearningObjectiveReusableObjects={mappingContext.learningObjectiveReusableObjects}
            proposedLearningObjectiveReusableObjects={mappingContext.proposedLearningObjectiveReusableObjects || []}
            setProposedLearningObjectiveReusableObjects={mappingContext.setProposedLearningObjectiveReusableObjects}
            activeLearningObjective={activeLearningObjective}
            userContext={this.props.userContext}
          />
        );
      }

      collapsibleTools = (
        <div className="doc-lotp-mapping">
          {contentMappingChild}
        </div>
      );
    }

    return (
      <div className={`doc-lotp-editor ${doclotpEditorSideNavChanges}`}>
        <div className="doc-lotp-editor-header" onClick={() => this.setState({isCollapsed: !isCollapsed})}>
          <h5>
            <span className={isCollapsed ? "icon-open-box" : "icon-close-box"}></span>
            <span className="doc-lotp-editor-title">Content Mapping Tool</span>
          </h5>
        </div>
        {collapsibleTools}
      </div>
    );
  }
}
