import * as React from "react";

import * as classNames from "classnames";

import DocStyledText from "../BlockNodeHelpers/StyledText";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

interface DocCheckboxProps extends DocCheckboxType<InlineNode>, BlockNodeAll {}

function DocCheckbox(props: DocCheckboxProps) {
  const onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.userContext.addState({
      [props.uid]: {value: e.target.checked},
    });
  };

  const onClick = (e: React.MouseEvent<HTMLInputElement | HTMLLabelElement>) => { e.stopPropagation(); };

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-checkbox", props.uid, {inactive: props.inactiveBool})}
      style={backgroundStyleFromNode(props)}
    >
      <input type="checkbox" id={`${props.uid}_checkbox`} disabled={props.inactiveBool} checked={props.value} onChange={onChanged} onClick={onClick} />
      <label htmlFor={`${props.uid}_checkbox`} onClick={onClick}>
        <span className="doc-checkbox-outer"><span className="doc-checkbox-inner"></span></span>
        <span className="doc-checkbox-title">
          <DocStyledText
            uid={props.uid}
            content={props.content}
            contentKey={"content"}
            placeholder={"Enter Checkbox text here"}
            editingContext={props.editingContext}
          />
        </span>
      </label>
    </div>
  );
}

export default React.memo(DocCheckbox);
