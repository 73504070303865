export const ADD_NODE = "ADD_NODE"
export const EDIT_COMMENT = "EDIT_COMMENT"
export const EDIT_LINK = "EDIT_LINK"
export const FIND_AND_REPLACE = "FIND_AND_REPLACE"
export const LIST_RLO_MODAL = "LIST_RLO_MODAL"
export const MEDIA_MANAGER = "MEDIA_MANAGER"
export const MEDIA_PICKER = "MEDIA_PICKER"
export const NEW_COMMENT_THREAD = "NEW_COMMENT_THREAD"
export const NEW_STUDENT_FEEDBACK = "NEW_STUDENT_FEEDBACK"
export const NEW_SUMMARY_OF_EDITS = "NEW_SUMMARY_OF_EDITS"
export const RLO_MODAL = "RLO_MODAL"
export const TEXT_COLOR_PICKER = "TEXT_COLOR_PICKER"
export const EDIT_RO_NAME_MODAL = "EDIT_RO_NAME_MODAL"
export const LEARNING_OBJECTIVES_CONFIRM_EDIT = "LEARNING_OBJECTIVES_CONFIRM_EDIT";
export const LEARNING_OBJECTIVES_ADD_NEW = "LEARNING_OBJECTIVES_ADD_NEW";
