import * as React from "react";

import { filter } from "lodash";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

import GenButton from "../../../Gen/Button/component";

interface DocEnumTagsInputProps {
  contentKey: string;
  default: Array<string>;
  disableMetadataEdit: boolean;
  editingContext: EditingContextInEditor;
  label: string;
  options: Array<string>;
  tags: Array<string>;
  uid: string;
};

interface DocEnumTagsInputState {
  editWindowOpen: boolean;
};

export default class DocEnumTagsInput extends React.Component<DocEnumTagsInputProps, DocEnumTagsInputState>  {
  constructor(props: DocEnumTagsInputProps) {
    super(props);
    this.state = {
      editWindowOpen: false,
    };
  }

  toggleEditWindow() {
    if (!this.props.disableMetadataEdit) {
      this.setState({editWindowOpen: !this.state.editWindowOpen});
    }
  }

  render() {
    const value = this.props.tags || this.props.default || [];

    let editWindow = null;
    if (this.state.editWindowOpen) {
      const options = this.props.options.map((optionName) => {
        const isChecked = value.includes(optionName);
        return (
          <div key={optionName} className="doc-edit-header-enum-tags-input-option">
            <input type="checkbox" name={optionName} checked={isChecked} onChange={() => {
              let newValue = value;
              if (isChecked) {
                newValue = filter(newValue, (val) => val !== optionName);
              } else {
                newValue.push(optionName);
              }

              this.props.editingContext.addChange(newSetKeyValueChange(this.props.uid, this.props.contentKey, newValue));
            }} />
            <label htmlFor={optionName}>{optionName}</label>
          </div>
        );
      });

      editWindow = (
        <div className="doc-edit-header-edit-window" onClick={(e) => {e.stopPropagation();}}>
          {options}
          <GenButton
            className="close-edit-window"
            name={"Close"}
            highlighted
            small
            onClick={this.toggleEditWindow.bind(this)}
          />
        </div>
      );
    }

    return (
      <div className="doc-edit-header-input doc-edit-header-enum-tags-input" onClick={this.toggleEditWindow.bind(this)}>
        <label>{this.props.label}</label>
        <p className="doc-edit-header-options-input-summary">{value.length > 0 ? value.join(", ") : <span className="input-summary-empty">None</span>}</p>
        {editWindow}
      </div>
    );
  }
}
