import { applyMiddleware, createStore, compose } from 'redux'
import { thunk } from 'redux-thunk'
import promise from 'redux-promise-middleware'
// import { createLogger } from 'redux-logger'


import reducer from 'reducers/indexNewDocEdit'

const middleware = applyMiddleware(
  thunk,
  promise,
  // createLogger({}),
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  const store = createStore(reducer, composeEnhancers(middleware))
  return store
}
