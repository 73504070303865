import * as R from "ramda";

import * as selection from "../selection";

import { batch } from "react-redux";

import {
  newSetKeyValueChange,
} from "../changes";

import {
  findNodeLineageByUid,
} from "../Functions/base";

export default function tabAtSelection({
  data,
  variantDataById,
  currentMenu,
  addChanges,
  setCurrentDocSelection,
  nodeDefinitions,
}: EditActionContext) {
  const currentDocSelection = selection.getDocSelection();

  if (!currentDocSelection || currentMenu) { return false; }

  const dataToUse = currentDocSelection.variantId ? variantDataById[currentDocSelection.variantId] : data;

  const {
    uid: startUID,
    contentKey: startKey,
    index: startIndex,
  } = currentDocSelection.start;

  const {
    uid: endUID,
    contentKey: endKey,
    index: endIndex,
  } = currentDocSelection.end;

  if (selection.isInOneInline(currentDocSelection)) {
    const nodeLineage = findNodeLineageByUid(dataToUse, startUID);
    const nodeLineageToListItem = R.dropLastWhile((node: any) => node.type !== "listItem", nodeLineage); // TODO: remove any
    const listItem = R.last(nodeLineageToListItem);

    if (listItem) {
      batch(() => {
        addChanges([newSetKeyValueChange(listItem.uid, "listIndentation", ((listItem.listIndentation || 0) + 1))], currentDocSelection.variantId);
      });
    }
  }

  return true;
}
