import * as React from "react";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

interface DocEditHeaderTextInputProps {
  beforeSaveFunction: (newValue: string) => string;
  contentKey: string;
  default: string;
  disableMetadataEdit: boolean;
  editingContext: EditingContextInEditor;
  label: string;
  uid: string;
  value: string;
};

interface DocEditHeaderTextInputState {
  inputValue?: string;
};

export default class DocEditHeaderTextInput extends React.Component<DocEditHeaderTextInputProps, DocEditHeaderTextInputState> {
  constructor(props: DocEditHeaderTextInputProps) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    this.handleBlur();
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({inputValue: e.target.value});
  }

  handleBlur() {
    if (this.state.inputValue) {
      let newValue = this.state.inputValue;
      if (this.props.beforeSaveFunction) {
        newValue = this.props.beforeSaveFunction(newValue);
      }

      this.props.editingContext.addChange(newSetKeyValueChange(this.props.uid, this.props.contentKey, newValue));

      this.setState({inputValue: undefined});
    }
  }

  render() {
    let value = this.state.inputValue;
    if (value === undefined) { value = this.props.value || this.props.default; }

    return (
      <div className="doc-edit-header-input doc-edit-header-text-input">
        <label>{this.props.label}</label>
        <input type="text" value={value} disabled={this.props.disableMetadataEdit} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)} />
      </div>
    );
  }
}
