export default class BaseObserver<Delegate> {
  delegates: Array<Delegate>;
  isRunning?: boolean;

  constructor() {
    this.delegates = [];
  }

  start() {
    if (!this.isRunning) {
      this.isRunning = true;
      this.startEventListeners();
    }
  }

  startEventListeners() {}

  stop() {
    if (this.isRunning) {
      this.isRunning = false;
      this.stopEventListeners();
    }
  }

  stopEventListeners() {}

  registerDelegate(delegate: Delegate) {
    if (!this.delegates.includes(delegate)) {
      this.delegates.push(delegate);
      this.start();
      this.afterRegistrationInitializer(delegate);
    }
  }

  afterRegistrationInitializer(delegate: Delegate) {}

  unregisterDelegate(delegate: Delegate) {
    this.delegates = this.delegates.filter(arrayDelegate => arrayDelegate !== delegate);
    if (this.delegates.length === 0) {
      this.stop();
    }
  }
}
