import * as React from "react";

import * as R from "ramda";

import {
  findNodeLineageByUid,
} from "../../../helpers/Doc/Functions/base";

import GenButton from "../../Gen/Button/component";

import ReasoningToolPanel from "./Panel";

import ReasoningToolSummaryOfEdit from "./SummaryOfEdit";

import DocAuthorHeader from "../AuthorHeader";
import DocCommentInput from "../ChangeSummaryColumn/CommentInput";

import {
  newAddSummaryOfEditChange,
} from "../../../helpers/Doc/changes";

import { NEW_SUMMARY_OF_EDITS } from "../../../helpers/Doc/menu";

interface ReasoningToolSummaryOfEditsPanelProps {
  documentContext: DocumentContext;
  userContext: UserContext;
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  mergedData: MergedData;
};

export default function ReasoningToolSummaryOfEditsPanel(props: ReasoningToolSummaryOfEditsPanelProps) {
  const {
    documentContext,
    userContext,
    editingContext,
    editingContextExtra,
    mergedData,
  } = props;


  let newSummaryOfEdit = null;
  if (editingContextExtra.currentMenu?.menu === NEW_SUMMARY_OF_EDITS) {
    const author = userContext;
    newSummaryOfEdit = (
        <div className="reasoning-tool-summary-of-edit">
        <DocAuthorHeader
          authorName={`${author.firstName} ${author.lastName}`}
          colorArray={editingContext.colorLookup[author.id]}
        />
        <DocCommentInput
          isActive
          onAddClick={(text) => {
            editingContext.addChange(newAddSummaryOfEditChange(
              editingContextExtra.currentDocSelection?.start?.uid,
              editingContextExtra.currentDocSelection?.start?.contentKey,
              editingContextExtra.currentDocSelection?.start?.index,
              text,
            ));
            editingContext.closeCurrentMenu();
          }}
          onCancelClick={() => editingContext.closeCurrentMenu()}
          onFocus={() => {}}
        />
      </div>
    );
  }

  let summaryOfEdits = mergedData.summary_of_edits || [];
  summaryOfEdits = R.sortBy((summaryOfEdit: any) => summaryOfEdit.created_at, summaryOfEdits).reverse(); // TODO: Remove any

  const panelChildren = summaryOfEdits.map((summaryOfEdit) => {
    return (
      <ReasoningToolSummaryOfEdit
        key={summaryOfEdit.change_id}
        userContext={userContext}
        editingContext={editingContext}
        summaryOfEdit={summaryOfEdit}
        summaryOfEditLineage={summaryOfEdit.uid && findNodeLineageByUid(mergedData, summaryOfEdit.uid)}
      />
    );
  });

  return (
    <ReasoningToolPanel
      key="SUMMARY_OF_EDITS"
      title="Summary of Edits"
      headerButton={
        <GenButton
          key="Add Summary Of Edits"
          name="New Summary"
          highlighted={editingContextExtra.currentMenu?.menu === NEW_SUMMARY_OF_EDITS}
          small
          onClick={() => { editingContext.setCurrentMenu(NEW_SUMMARY_OF_EDITS); }}
        />
      }
    >
      {newSummaryOfEdit}
      {panelChildren}
    </ReasoningToolPanel>
  );
}
