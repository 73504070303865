import * as R from "ramda";

///////////////////
// DOM SELECTION //
///////////////////

function getDOMSelection() {
  const domSelection = window.getSelection();
  return domSelection && domSelection.rangeCount > 0 ? domSelection : null;
}

export function getDOMRange() {
  const domSelection = getDOMSelection();
  if (domSelection) {
    return domSelection.getRangeAt(0);
  } else {
    return null;
  }
}

function setDOMRange(domRange: Range) {
  const domSelection = window.getSelection();
  domSelection?.removeAllRanges();
  domSelection?.addRange(domRange);
}

function clearAllDOMRanges() {
  const domSelection = window.getSelection();
  domSelection?.removeAllRanges();
}

export function areDOMRangesEqual(domRange1?: Range, domRange2?: Range) {
  const toCompareKeys = [
    "commonAncestorContainer",
    "endContainer",
    "endOffset",
    "startContainer",
    "startOffset",
  ];

  return domRange1 && domRange2 && R.equals(R.pickAll(toCompareKeys, domRange1), R.pickAll(toCompareKeys, domRange2));
}

///////////////////
// Doc Selection //
///////////////////

// DOC_SELECTION
// {
//   start: SELECTION_BOUNDRY
//   end: SELECTION_BOUNDRY
//   variantId?: Number
// }
//
// DOC_SELECTION_BOUNDRY
// {
//   uid: UID
//   index: INDEX
//   contentKey: CONTENT_KEY // OPTIONAL
// }
//
// INLINE vs BLOCK SELECTION

export function getDocSelection() {
  return domRangeToDocSelection(getDOMRange());
}

export function clearDocSelection() {
  if (getDocSelection()) {
    clearAllDOMRanges();
  }
}

function domRangeToDocSelection(domRange?: Range | null) {
  if (!domRange) { return null; }

  const commonIndependentlyEditableAncestor = getIndependentlyEditableAncestor(domRange.commonAncestorContainer);

  if (!commonIndependentlyEditableAncestor) { return null; }

  const startBoundry = domRangeBoundryToDocSelectionBoundry(domRange.startContainer, domRange.startOffset, commonIndependentlyEditableAncestor);
  const endBoundry = domRangeBoundryToDocSelectionBoundry(domRange.endContainer, domRange.endOffset, commonIndependentlyEditableAncestor);

  if (startBoundry === null || endBoundry === null ) {
    if (startBoundry || endBoundry) {
      //TODO ADD ERROR FOR ONE SIDED SELECTION
    }
    return null;
  }

  const returnSelection: DocSelection = {
    start: startBoundry,
    end: endBoundry,
  }

  const variantId = domRangeBoundryToVariantId(domRange.startContainer, commonIndependentlyEditableAncestor)
  if (typeof variantId === 'number') {
    returnSelection.variantId = variantId
  }

  return returnSelection;
}

function domRangeBoundryToVariantId(node: Node, commonIndependentlyEditableAncestor: Node) {
  if (isInNoDocumentSelection(node)) { return; }

  const thisIndependentlyEditableAncestor = getIndependentlyEditableAncestor(node);
  const directlyInCommonIndependentlyEditableAncestor = thisIndependentlyEditableAncestor === commonIndependentlyEditableAncestor;

  if (directlyInCommonIndependentlyEditableAncestor) {
    const ancestorStyledTextNode = getAncestorStyledTextNodeFromChildNode(node);
    if (ancestorStyledTextNode instanceof HTMLElement) {
      const variantId = ancestorStyledTextNode.dataset.variantid;
      return variantId && parseInt(variantId);
    }
  }

  return;
}

function domRangeBoundryToDocSelectionBoundry(node: Node, offset: number, commonIndependentlyEditableAncestor: Node) {
  if (isInNoDocumentSelection(node)) { return null; }

  const thisIndependentlyEditableAncestor = getIndependentlyEditableAncestor(node);
  const directlyInCommonIndependentlyEditableAncestor = thisIndependentlyEditableAncestor === commonIndependentlyEditableAncestor;

  if (directlyInCommonIndependentlyEditableAncestor) {
    const ancestorStyledTextNode = getAncestorStyledTextNodeFromChildNode(node);
    if (ancestorStyledTextNode instanceof HTMLElement && ancestorStyledTextNode.dataset.uid) {
      const index = 0;
      const nodeOffset = ancestorStyledTextNode.dataset.isEmpty !== "true"
        ? findTextOffsetNodeInNode(ancestorStyledTextNode, node) : 0;

      if (nodeOffset === null) { return null; }

      return {
        uid: ancestorStyledTextNode.dataset.uid,
        contentKey: ancestorStyledTextNode.dataset.contentkey,
        index: ancestorStyledTextNode.dataset.isEmpty !== "true" ? nodeOffset + offset : 0,
      };
    }

    const ancestorDocNode = getAncestorDocNodeFromChildNode(node);
    if (ancestorDocNode instanceof HTMLElement && ancestorDocNode.dataset.uid) {
      return {
        uid: ancestorDocNode.dataset.uid,
        index: offset > 0 ? 1 : 0,
      };
    }

    const nextDocNodeSibling = findNextDocNodeSibling(node);
    if (nextDocNodeSibling instanceof HTMLElement && nextDocNodeSibling.dataset.uid) {
      return {
        uid: nextDocNodeSibling.dataset.uid,
        index: 0,
      };
    }

    const previousDocNodeSibling = findPreviousDocNodeSibling(node);
    if (previousDocNodeSibling instanceof HTMLElement && previousDocNodeSibling.dataset.uid) {
      return {
        uid: previousDocNodeSibling.dataset.uid,
        index: 1,
      };
    }
  } else {
    const ancestorDocNode = findDeepestDocNodeDirectlyInIndependentlyEditableNode(commonIndependentlyEditableAncestor, node);
    if (ancestorDocNode instanceof HTMLElement && ancestorDocNode.dataset.uid) {
      return {
        uid: ancestorDocNode.dataset.uid,
        index: offset > 0 || ancestorDocNode !== node ? 1 : 0,
      };
    }
  }

  return null;
}

export function areDocSelectionsEqual(selection1: DocSelection, selection2: DocSelection) {
  return selection1 && selection2 && R.equals(selection1, selection2);
}

export function isCollapsed(selection: DocSelection) {
  return selection && R.equals(selection.start, selection.end);
}

export function isInOneInline(selection: DocSelection) {
  return R.equals(selection.start.uid, selection.end.uid) && !!selection.start.contentKey && R.equals(selection.start.contentKey, selection.end.contentKey);
}

export function areBothInlineBoundries(selection: DocSelection) {
  return !!(selection.start.contentKey && selection.end.contentKey);
}

export function isInOneNode(selection: DocSelection) {
  return R.equals(selection.start.uid, selection.end.uid);
}

export function isInlineBoundry(boundry: DocSelectionBoundry) {
  return !!boundry.contentKey;
}

export function isBlockBoundry(boundry: DocSelectionBoundry) {
  return !boundry.contentKey;
}

export function toRange(selection: DocSelection) {
  return {
    start: selection.start.index,
    end: selection.end.index,
  };
}

export function isDocSelectionWithinStyledText(docSelection: DocSelection) {
  return docSelection && docSelection
    && docSelection.start.uid === docSelection.end.uid
    && docSelection.start.contentKey === docSelection.end.contentKey;
}

export function newCollapsedDocSelection(uid: string, contentKey: string, index: number) {
  return {
    start: newDocSelectionBoundry(uid, contentKey, index),
    end: newDocSelectionBoundry(uid, contentKey, index),
  };
}

export function newDocSelectionBoundry(uid: string, contentKey: string, index: number) {
  return {
    uid,
    contentKey,
    index,
  };
}

export function collapseDocSelectionToStart(docSelection: DocSelection) {
  return {
    start: docSelection.start,
    end: docSelection.start,
  };
}

export function shiftDocSelectionBy(docSelection: DocSelection, shiftCount: number) {
  return {
    start: shiftDocSelectionBoundryBy(docSelection.start, shiftCount),
    end: shiftDocSelectionBoundryBy(docSelection.end, shiftCount),
  };
}

export function shiftDocSelectionStartBy(docSelection: DocSelection, shiftCount: number): DocSelection {
  return {
    start: shiftDocSelectionBoundryBy(docSelection.start, shiftCount),
    end: docSelection.end,
  };
}

export function shiftDocSelectionEndBy(docSelection: DocSelection, shiftCount: number) {
  return {
    start: docSelection.start,
    end: shiftDocSelectionBoundryBy(docSelection.end, shiftCount),
  };
}

export function shiftDocSelectionBoundryBy(docSelectionBoundry: DocSelectionBoundry, shiftCount: number) {
  return {
    ...docSelectionBoundry,
    index: docSelectionBoundry.index + shiftCount,
  };
}

export function setDocSelection(docSelection?: DocSelection) {
  const domRange = docSelectionToDOMRange(docSelection);

  if (domRange) { setDOMRange(domRange); }
}

export function docSelectionToDOMRange(docSelection?: DocSelection) {
  if (!docSelection) { return null; }

  const startBoundry = getDOMBoundryFromDocSelectionBoundry(docSelection.start);
  const endBoundry = getDOMBoundryFromDocSelectionBoundry(docSelection.end);

  if (!startBoundry || !endBoundry) { return null; }

  const domRange = document.createRange();
  domRange.setStart(...startBoundry);
  domRange.setEnd(...endBoundry);
  return domRange;
}

function getDOMBoundryFromDocSelectionBoundry(docBoundry: DocSelectionBoundry) {
  const parentNode = getAncestorStyledTextNodeFromUIDAndContentKey(docBoundry.uid, docBoundry.contentKey);

  if (!parentNode) { return null; }

  return findNodeAndOffsetFromTextOffset(parentNode, docBoundry.index);
}


////////////////////////////////////////////////////////
// Doc Selection and Range Selection Shared Functions //
////////////////////////////////////////////////////////

function nodeTextLength(node: Node) {
  if (node.nodeType === Node.TEXT_NODE && node.textContent) {
    return node.textContent.length;
  } else {
    return 0;
  }
}

function findTextOffsetNodeInNode(parentNode: Node, childNode: Node) {
  if (parentNode === childNode) {return 0;}

  const newWalker = document.createTreeWalker(parentNode, NodeFilter.SHOW_ALL);

  let offset = 0;
  while (newWalker.nextNode()) {
    const currentNode = newWalker.currentNode;

    if (currentNode === childNode) {
      return offset;
    } else {
      offset = offset + nodeTextLength(currentNode);
    }
  }

  return null;
}

function isNodeDocNode(node: Node) {
  return node instanceof HTMLElement && !!node.dataset?.isNode;
}

function isNodeBlockNodes(node: Node) {
  return node instanceof HTMLElement && !!node.dataset?.isBlockNodes;
}

function isNodeStyledText(node: Node) {
  return node instanceof HTMLElement && !!node.dataset?.isStyledText;
}

function isNodeIndpendentlyEditable(node: Node) {
  return node instanceof HTMLElement && !!node.dataset?.isIndependentlyEditable;
}

function getIndependentlyEditableAncestor(node: Node | null) : Node | null {
  if (!node) { return null; }

  return isNodeIndpendentlyEditable(node) ? node : getIndependentlyEditableAncestor(node.parentNode);
}

function getAncestorStyledTextNodeFromChildNode(node: Node | null) : Node | null {
  if (!node) { return null; }

  return isNodeStyledText(node) ? node : getAncestorStyledTextNodeFromChildNode(node.parentNode);
}

function isInNoDocumentSelection(node: Node | null) : boolean {
  if (!node) { return false; }

  return isNoDocumentSelection(node) || isInNoDocumentSelection(node.parentNode);
}

function isNoDocumentSelection(node: Node) {
  return node instanceof HTMLElement && node.classList?.contains("no-document-selection")
}

function getAncestorDocNodeFromChildNode(node: Node | null) : Node | null {
  if (!node || isNodeBlockNodes(node)) { return null; }

  return isNodeDocNode(node) ? node : getAncestorDocNodeFromChildNode(node.parentNode);
}

function findDeepestDocNodeDirectlyInIndependentlyEditableNode(independentlyEditableNode: Node, startNode: Node | null, currentDeepest: Node | null = null) {
  if (!startNode) { return null; }

  if (!currentDeepest && isNodeDocNode(startNode)) {
    currentDeepest = startNode;
  }

  if (independentlyEditableNode === startNode) { return currentDeepest; }

  return findDeepestDocNodeDirectlyInIndependentlyEditableNode(independentlyEditableNode, startNode.parentNode, isNodeIndpendentlyEditable(startNode) ? null : currentDeepest);
}

function findNextDocNodeSibling(node: Node | null) {
  if (!node || isNodeIndpendentlyEditable(node)) { return null; }
  if (isNodeDocNode(node)) { return node; }

  return findNextDocNodeSibling(node.nextSibling ? node.nextSibling : node.parentNode);
}

function findPreviousDocNodeSibling(node: Node | null) {
  if (!node || isNodeIndpendentlyEditable(node)) { return null; }
  if (isNodeDocNode(node)) { return node; }

  return findPreviousDocNodeSibling(node.previousSibling ? node.previousSibling : node.parentNode);
}

function getAncestorStyledTextNodeFromUIDAndContentKey(uid, contentKey: string) {
  return document.querySelector(`[data-uid="${uid.replaceAll("\"", "\\\"")}"][data-contentkey="${contentKey}"].doc-styled-text`);
}

function findNodeAndOffsetFromTextOffset(parentNode: Node, textOffset: number): [Node, any] | null { // TODO: Remove any
  if (textOffset === 0) {
    return [parentNode, 0];
  }

  const newWalker = document.createTreeWalker(parentNode, NodeFilter.SHOW_ALL);

  let currentOffset = 0;
  while (newWalker.nextNode()) {
    const currentNode = newWalker.currentNode;

    const textLength = nodeTextLength(currentNode);

    const newOffset = currentOffset + textLength;
    if (newOffset >= textOffset) {
      return [currentNode, textOffset - currentOffset];
    } else {
      currentOffset = newOffset;
    }
  }

  return null;
}
