import * as React from "react";

import DocChangeSummary from "./ChangeSummaryColumn/ChangeSummary";

interface DocChangeSummaryColumnProps {
  editingContext: EditingContext;
  userContext: UserContext;
  displayContext: DisplayContext;
  changeSummaries: Array<OffsetChangeSummary>;
  currentVersion: CurrentVersion;
  featureFlag: FeatureFlag;
};

export default function DocChangeSummaryColumn(props: DocChangeSummaryColumnProps) {
  const {
    editingContext,
    userContext,
    displayContext,
    changeSummaries = [],
    currentVersion,
  } = props;

  if (!editingContext.isEditing || !displayContext.canBeEditing) { return; }

  return <>
    {
      changeSummaries.map((offsetChangeSummary) => {
        return (
          <DocChangeSummary
            key={offsetChangeSummary.changeSummary.change_set_id}
            editingContext={editingContext}
            userContext={userContext}
            changeSummary={offsetChangeSummary.changeSummary}
            currentVersion={currentVersion}
            offset={offsetChangeSummary.offset}
          />
        );
      })
    }
  </>
}
