import * as React from "react";

import { NOTES } from "../../../reducers/ui/reasoningTool";

import * as classNames from "classnames";
import generateUUID from "../../../helpers/generateUUID";

interface DocNoteProps {
  noteUid?: string;
  uid: string;

  displayContext: DisplayContext;
  userContext: UserContext;
};

export default function DocNote(props: DocNoteProps) {
  const {
    displayContext,
    noteUid,
    uid,
    userContext,
  } = props;

  if (displayContext.canBeEditing || displayContext.isEmbeded) { return null; }

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (noteUid) {
      userContext.deleteNote(noteUid);
      displayContext.setFocusedBookmarkUid(null);
    } else {
      const newUid = generateUUID();
      userContext.addNote(newUid, {notedNodeUid: uid});
      displayContext.togglePanel(NOTES);
      displayContext.panelNodeEditStart(newUid);
      displayContext.setFocusedBookmarkUid(uid);
    }

    e.stopPropagation();
  };

  return (
    <div
      id={`note${uid}`}
      className={classNames("doc-note-button", {noted: noteUid})}
      onClick={onClick}
      contentEditable={false}
    >
      <div className="icon-notes" />
    </div>
  );
}
