import * as React from "react";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

interface DocEditHeaderBooleanInputProps {
  contentKey: string;
  default: boolean;
  disableMetadataEdit: boolean;
  editingContext: EditingContextInEditor;
  label: string;
  uid: string;
  value: boolean;
};

export default function DocEditHeaderBooleanInput(props: DocEditHeaderBooleanInputProps) {
  const value = typeof(props.value) === "boolean" ? props.value : props.default || false;

  return (
    <div className="doc-edit-header-input doc-edit-header-boolean-input">
      <label htmlFor={props.label}>{props.label}</label>
      <input type="checkbox" name={props.label} checked={value} disabled={props.disableMetadataEdit} onChange={() => {
        props.editingContext.addChange(newSetKeyValueChange(props.uid, props.contentKey, !value));
      }} />
    </div>
  );
}
