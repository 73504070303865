import * as React from "react";

import { ChromePicker, ColorResult } from 'react-color'

import { newAddStyleChange } from "../../../../helpers/Doc/changes";

import GenButton from "../../../Gen/Button/component";

import {
  TEXT_COLOR_PICKER,
} from "../../../../helpers/Doc/menu"

interface DocEditHeaderColorPickerProps {
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;

};

export default function DocEditHeaderColorPicker(props: DocEditHeaderColorPickerProps) {
  const {
    editingContext,
    editingContextExtra,
  } = props;

  const currentDocSelection = editingContextExtra.currentDocSelection

  const isColorPickerOpen = editingContextExtra.currentMenu?.menu === TEXT_COLOR_PICKER;
  const currentColor = (editingContextExtra?.activeStylesFromChanges?.color as StyleRangeColor)?.args?.hexCode || "#333333";

  const [newColor, setNewColor] = React.useState<string>();

  React.useEffect(() => {
    if (newColor) {
      setNewColor(undefined);
    }
  }, [currentDocSelection]);

  const selectedColor = newColor || currentColor;

  const handleChange = (color: ColorResult) => {
    setNewColor(color.hex);
  }

  const handleSave = () => {
    editingContext.addChange(
      newAddStyleChange(
        currentDocSelection,
        "color",
        {hexCode: selectedColor}
      ),
      currentDocSelection.variantId
    );

    editingContext.closeCurrentMenu();
  }

  const handleCancel = () => {
    editingContext.closeCurrentMenu();
  }

  let colorPicker;
  if (isColorPickerOpen) {
    colorPicker = (
      <div className="doc-edit-text-color-input-box" onClick={(e) => {e.stopPropagation();}}>
        <ChromePicker
          color={ selectedColor }
          onChange={ handleChange }
        />
        <div className="text-color-input-buttons">
          <GenButton
            name="Select"
            highlighted
            small
            onClick={handleSave}
          />
          <GenButton
            name="Cancel"
            highlighted
            small
            onClick={handleCancel}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      key="color-style"
      style={{
        backgroundColor: selectedColor,
        width: "25px",
        height: "25px",
        border: "solid 2px white",
        position: "relative"
      }}
      onClick={() => {
        if (editingContextExtra.currentMenu?.menu !== TEXT_COLOR_PICKER) {
          editingContext.setCurrentMenu(TEXT_COLOR_PICKER);
        } else {
          editingContext.closeCurrentMenu();
        }
      }}
    >
      {colorPicker}
    </div>
  )
}
