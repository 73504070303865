import ahoy from "ahoy.js"

function analyticsModule() {
  function track(eventName, data){
    ahoy.track(eventName, data)
  }

  return { track };
}

window.analytics = analyticsModule()

export default analyticsModule();
