import {
  cloneDeep,
  times,
} from "lodash";

import * as R from "ramda";

import { DTRef } from "../Define/base";

import {
  nodeDefinitions,
} from "../Define/base";

export function generateTableRowWithNTableCells(cellCount: number) {
  const rowDefinition = cloneDeep(nodeDefinitions["tableRow"]);
  rowDefinition.objectModel.cells.initWith(times(cellCount, () => DTRef("tableCell").init));
  return rowDefinition.generate(nodeDefinitions, {});
}

export function generateNTableCells(cellCount: number) {
  const cellDefinition = cloneDeep(nodeDefinitions["tableCell"]);
  return times(cellCount, () => cellDefinition.generate(nodeDefinitions, {}));
}

export function generateTableCell() {
  const cellDefinition = cloneDeep(nodeDefinitions["tableCell"]);
  return cellDefinition.generate(nodeDefinitions, {})
}

function anyRowOrColumnMultiSpan(table: DocTableType<unknown>) {
  return table.rows.some(row =>
    row.cells.some(cell =>
      (cell.columnSpan && cell.columnSpan !== 1) || (cell.rowSpan && cell.rowSpan !== 1)
    )
  )
}

function allRowsHaveSameNumberOfCells(table: DocTableType<unknown>) {
  return R.uniq(table.rows.map(row => row.cells.length)).length === 1
}

function getColumnCount(table: DocTableType<unknown>) {
  return R.sum(table.rows[0].cells.map(cell => cell.columnSpan || 1))
}

function getRowCount(table: DocTableType<unknown>) {
  const firstOfEachColumn = table.rows.map(row => row.cells[0])
  const reduceResult = firstOfEachColumn.reduce(
    (prev, cell) => {
      if (prev.cellsToSkip > 0) {
        return {
          rowCount: prev.rowCount,
          cellsToSkip: prev.cellsToSkip - 1,
        }
      } else {
        const rowCount = cell.rowSpan || 1

        return {
          rowCount: prev.rowCount + rowCount,
          cellsToSkip: rowCount - 1,
        }
      }

    },
    {cellsToSkip: 0, rowCount: 0}
  )

  return reduceResult.rowCount
}

function inASpan(columnIndex: number, rowIndex: number, span: DocTableSpan) {
  return (
    ((columnIndex >= span.columnIndex) && (columnIndex < span.columnIndex + span.columnSpan)) &&
    ((rowIndex >= span.rowIndex) && (rowIndex < span.rowIndex + span.rowSpan))
  )
}

export function addMissingCellsToTable<T>(table: DocTableType<T>) {
  if(anyRowOrColumnMultiSpan(table)) {
    if (!allRowsHaveSameNumberOfCells(table)) {
      const rowCount = getRowCount(table)
      const columnCount = getColumnCount(table)

      const spansToSearch: Array<DocTableSpan> = []

      R.range(0, rowCount).forEach(rowIndex => {
        if (!table.rows[rowIndex]) {
          const newRow = generateTableRowWithNTableCells(0)
          table = R.over(R.lensPath(["rows"]), R.insert(rowIndex, newRow), table)
        }

        R.range(0, columnCount).forEach(columnIndex => {
          if (spansToSearch.some(span => inASpan(columnIndex, rowIndex, span))) {
            const newCell = generateTableCell()
            table = R.over(R.lensPath(["rows", rowIndex, "cells"]), R.insert(columnIndex, newCell), table)
          } else {
            const cell = table.rows[rowIndex].cells[columnIndex]

            const columnSpan = cell.columnSpan || 1
            const rowSpan = cell.rowSpan || 1

            if (columnSpan !== 1 || rowSpan !== 1) {
              spansToSearch.push({
                rowIndex: rowIndex,
                rowSpan: rowSpan,
                columnIndex: columnIndex,
                columnSpan: columnSpan,
              })
            }
          }
        })
      })
    }
  }

  return table;
}
