import React from 'react'

import GenGen from 'Gen/component'

export default function GenSpan(props) {
  const {
    value,
    onChange,
    canEdit=true,
    isEditing=false,
    onSpanClick,
    placeholder='',
    children='',
    onBlur,
    onEnterKeyPress,
    shouldAutoFocus=false,
    className,
    isControlled=false
  } = props

  function onKeyPress(e) {
    if ((e.which || e.keyCode) === 13) {
      e.preventDefault()
      onEnterKeyPress(e)
    }
  }

  return <GenGen {...props}>
    {canEdit && isEditing ? (
      isControlled ? (
        <input
          key='gen-span-input'
          className='gen-span-input'
          type='text'
          value={children}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          autoFocus={shouldAutoFocus}
        />
      ) : (
        <input
          key='gen-span-input'
          className='gen-span-input'
          type='text'
          defaultValue={children}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          autoFocus={shouldAutoFocus}
        />
      )
    ):(
      <span onClick={onSpanClick}>{children}</span>
    )}
  </GenGen>
}
