// Based on https://reactabular.js.org/

import * as React from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import {
  cloneDeep, findIndex, orderBy, keys, values, transform
} from 'lodash';
import * as Table from 'reactabular-table';
import * as search from 'searchtabular';
import * as sort from 'sortabular';
import * as resolve from 'table-resolver';
import VisibilityToggles from 'react-visibility-toggles';

import {
  Paginator, PrimaryControls, paginate
} from './helpers';
import { TABLE_DEFS } from './table_defs';

export default class AqueductTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: props.rows,
      searchColumn: 'all',
      query: {}, // search query
      sortingColumns: props.first_column_sort && {0: {direction: props.first_column_sort, position: 0, tooltipText: props.selection_tooltip}}, // reference to the sorting columns
      columns: this.getColumns(), // initial columns
      pagination: { // initial pagination settings
        page: 1,
        perPage: 999999999
      }
    };

    this.onRow = this.onRow.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.onColumnChange = this.onColumnChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onPerPage = this.onPerPage.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onToggleColumn = this.onToggleColumn.bind(this);
  }

  componentDidMount() {
    // Some table cells (<td> elements) have children elements
    // that receive the <td> element's click events
    // when we would not like them to.
    // The only way to prevent those click events from reaching
    // the children is to stop propagation at the <td> level.
    // We identify such <td> elements by adding the "disable-change"
    // class to one of the <td> element's descendants.
    for (const el of ReactDOM.findDOMNode(this).getElementsByClassName('disable-change')) {
      el.closest('td').addEventListener('click', (event) => {
        event.stopPropagation();
      })
    }
  }

  getColumns() {
    return TABLE_DEFS[this.props.table_def].call(this);
  }

  toggleSelect(rowsToToggle, doSelect) {
    let rowIdsToToggle = rowsToToggle.map(row => row.id);
    let rows = cloneDeep(this.state.rows).map(row => {
      if (rowIdsToToggle.includes(row.id)) {
        row.selected = doSelect
      }
      return row;
    });

    this.setState({ rows });
  }

  gatherRows() {
    const {
      columns, rows, pagination, sortingColumns, searchColumn, query
    } = this.state;
    const cols = columns.filter(column => column.visible);
    return compose(
      paginate(pagination),
      sort.sorter({ columns: cols, sortingColumns, sort: orderBy }),
      search.highlighter({ columns: cols, matches: search.matches, query }),
      search.multipleColumns({ columns: cols, query }),
      resolve.resolve({
        columns: cols,
        method: (extra) => compose(
          resolve.byFunction('cell.resolve')(extra),
          resolve.nested(extra)
        )
      })
    )(rows);
  }

  render() {
    const {
      columns, rows, pagination, sortingColumns, searchColumn, query
    } = this.state;
    const cols = columns.
      filter(column => column.visible).
      map(column => {
        if (column && column.property && (!('filterable' in column) || column.filterable)) {
          column.filterPlaceholder = 'type to filter';
        }
        return column;
      });
    const paginated = this.gatherRows();
    const windowTableName = this.props.window_table_name || 'AqueductTable';
    const tableHeight = this.props.table_height || 'fixed-height-table';

    return (
      <div ref={() => { window[windowTableName] = this }}>
        {this.props.selectable_columns &&
          <VisibilityToggles
            columns={columns}
            onToggleColumn={this.onToggleColumn}
          />
        }
        <PrimaryControls
          className="controls"
          perPage={pagination.perPage}
          column={searchColumn}
          query={query}
          columns={cols}
          rows={rows}
          onPerPage={this.onPerPage}
          onColumnChange={this.onColumnChange}
          onSearch={this.onSearch}
          show_pagination={this.props.show_pagination}
        />

        <div className={tableHeight}>
        <Table.Provider
          className="pure-table pure-table-striped"
          columns={cols}
          style={{ overflowX: 'auto' }}
        >
          <Table.Header
            id={windowTableName}
          >
            <search.Columns query={query} columns={cols} onChange={this.onSearch} />
          </Table.Header>

          <Table.Body
            onRow={this.onRow}
            rows={paginated.rows}
            rowKey="id"
          />

          <CustomFooter columns={cols} rows={paginated.rows} />
        </Table.Provider>
        </div>

        <div className="controls">
          {this.props.show_pagination &&
            <Paginator
              pagination={pagination}
              pages={paginated.amount}
              onSelect={this.onSelect}
            />
          }
        </div>
      </div>
    );
  }
  onRow(row, { rowIndex }) {
    return {
      className: row.row_class,
      'data-target' : this.props.row_target,
      onClick: () => this.onRowSelected(row)
    };
  }
  onRowSelected(row) {
    // console.log('clicked row', row);
  }
  onColumnChange(searchColumn) {
    this.setState({
      searchColumn
    });
  }
  onSearch(query) {
    this.setState({
      query
    });
  }
  onSelect(page) {
    const pages = Math.ceil(
      this.state.rows.length / this.state.pagination.perPage
    );

    this.setState({
      pagination: {
        ...this.state.pagination,
        page: Math.min(Math.max(page, 1), pages)
      }
    });
  }
  onPerPage(value) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        perPage: parseInt(value, 10)
      }
    });
  }
  onRemove(id) {
    const rows = cloneDeep(this.state.rows);
    const idx = findIndex(rows, { id });

    // this could go through flux etc.
    rows.splice(idx, 1);

    this.setState({ rows });
  }
  onToggleColumn({ columnIndex }) {
    const columns = cloneDeep(this.state.columns);
    const column = columns[columnIndex];

    column.visible = !column.visible;

    const query = cloneDeep(this.state.query);
    delete query[column.property];

    this.setState({ columns, query });
  }
}

const CustomFooter = ({ columns, rows }) => {
  return (
    <tfoot>
    <tr>
      {columns.map((column, i) =>
        <td key={`footer-${i}`}>{column.footer ? column.footer(rows) : null}</td>
      )}
    </tr>
    </tfoot>
  );
}
