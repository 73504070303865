import React from 'react'

import ReusableObjectSummary from 'Summary/component'

export const reusableObjectListDefaults = {
  itemsURL:"/reusable_objects",
  itemView: () => <ReusableObjectSummary rlo={rlo} key={rlo.id}/>,
  isInfiniteScroll: true,
  search:true,
  searchByLO: true,
};
