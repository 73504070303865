import * as React from 'react';

import Select from 'react-select';

import AqButton from '../../Aq/Button';
import GenModal from '../../Gen/Modal/component';
import GenLoadingSpinner from '../../Gen/LoadingSpinner/component';

import generateUUID from "../../../helpers/generateUUID";

import { generateLearningObjectiveActions } from "../../../reducers/learningObjectives";

import { newAddNodeChange } from "../../../helpers/Doc/changes";

import { findNode } from "../../../helpers/Doc/Functions/base";

export type DocLearningObjectivesAddNewModalProps = {
  currentVersion: CurrentVersion;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  mappingContext: DocumentMappingContext;
  projectContext: ProjectContext;
  mediaContext: MediaContext;
  mergedData: MergedData;
  userContext: UserContext;
  allLearningObjectiveOptions?: Array<{id: number, name: string, documents: Array<{id: number, title: string}>}>;
  learningObjectiveActionsGenerator: typeof generateLearningObjectiveActions;


  featureFlag: FeatureFlag;
};

export default function DocLearningObjectivesAddNewModal(props: DocLearningObjectivesAddNewModalProps) {
  const {
    editingContext,
    mappingContext,
    allLearningObjectiveOptions,
    mergedData,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [reusableObjectId, setReusableObjectId] = React.useState(null);
  const [learningObjectiveId, setLearningObjectiveId] = React.useState(null);
  const [newLearningObjectiveText, setNewLearningObjectiveText] = React.useState("");

  const createLO = () => {
    setIsLoading(true)

    const addInLearningObjective = (loId) => {
      const newVariantablePlaceholder = {
        type: "variantPlaceholder",
        uid: generateUUID(),
        variantableId: loId,
        variantableType: "LearningObjective",
      }

      const learningObjectivesList = findNode(mergedData, (node) => node.type === "learningObjectivesList");

      const newIndex = learningObjectivesList.content.length
      editingContext.addChange(newAddNodeChange(newVariantablePlaceholder, learningObjectivesList.uid, "content", newIndex));

      if (reusableObjectId) {
        let loroToBeSaved: ProposedLearningObjectiveReusableObject = {
          learning_objective_id: loId,
          reusable_object_id: reusableObjectId.value,
        }

        let newProposedLoros = mappingContext.proposedLearningObjectiveReusableObjects || [];
        newProposedLoros = newProposedLoros.concat([loroToBeSaved]);
        mappingContext.setProposedLearningObjectiveReusableObjects(newProposedLoros);
      }
    }

    if (newLearningObjectiveText.trim().length > 0) {
      const TEMP_ID = 0
      const learningObjectiveActions = props.learningObjectiveActionsGenerator(TEMP_ID)
      learningObjectiveActions.startNewLearningObjective()
      learningObjectiveActions.setEditDataLearningObjective({name: newLearningObjectiveText.trim()})
      learningObjectiveActions.createLearningObjective((data) => addInLearningObjective(data.value.data.id))
    } else {
      addInLearningObjective(learningObjectiveId.value)
      // USE GIVEN ID
    }

    setIsLoading(false)
    editingContext.closeCurrentMenu()
  }

  const activeLearningObjective = learningObjectiveId ? allLearningObjectiveOptions.find((lo) => lo.id === learningObjectiveId.value) : null;

  const learningObjectiveOptions = allLearningObjectiveOptions.map((loOption) => (
    {
      label: loOption.name,
      value: loOption.id,
    }
  ))

  const reusableObjectOptions = mappingContext.reusableObjects.filter((ro) => ro.kind === "teaching_point" ).map((ro) => (
    {
      value: ro.id,
      label: ro.content_title,
    }
  ))

  return (
    <GenModal open>
      <div className="gen-modal-panel">
        <div className="aq-card-header black">
          CREATE NEW LEARNING OBJECTIVE
        </div>
        <div>
          <div className="aq-field-label">
            Select Existing Objective To Add To This Case
          </div>
          <Select
            options={learningObjectiveOptions}
            onChange={(newValue)=>{setLearningObjectiveId(newValue)}}
          />
          {
            activeLearningObjective && (
              <div>
                <div className="aq-field-label">Case/s where selected LO is used:</div>
                <ul>
                  {activeLearningObjective.documents.map((document) => <li key={document.id}><a href={`/documents/${document.id}`} target="_blank">{document.title}</a></li>)}
                </ul>
              </div>
            )
          }
        </div>
        <div className="space-or">or</div>
        <div>
          <label className="aq-field-label" htmlFor="newLO">create new learning objective</label>
          <textarea
            name="newLO"
            id="newLO"
            cols={30}
            rows={5}
            value={newLearningObjectiveText}
            onChange={(e) => setNewLearningObjectiveText(e.target.value)}
          />
        </div>

        <div className="aq-dividing-line"></div>

        <div>
          <div className="aq-field-label">
            Map to teaching point/s
          </div>
          <Select
            options={reusableObjectOptions}
            onChange={(newValue)=>{ setReusableObjectId(newValue) }}
          />
        </div>
        <div className="aq-button-bar bottom-right">
          <AqButton onClick={createLO}>{isLoading ? <GenLoadingSpinner /> : "Save & Create LO"}</AqButton>
          <AqButton className="outline black" onClick={editingContext.closeCurrentMenu}>Cancel</AqButton>
        </div>
      </div>
    </GenModal>
  );
}
