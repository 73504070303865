import * as React from "react";

import * as classNames from "classnames";

import DocBookmark from "../BlockNodeHelpers/Bookmark";
import DocNote from "../BlockNodeHelpers/Note";
import DocStyledText from "../BlockNodeHelpers/StyledText";

import GenButton from "../../Gen/Button/component";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";
import { MEDIA_PICKER } from "../../../helpers/Doc/menu";

interface DocAudioProps extends DocAudioType<InlineNode>, BlockNodeAll {}

function DocAudio(props: DocAudioProps) {
  const {
    disableStyleTextEditing,
    editingContext,
    source,
    title,
    uid,
    userContext,
  } = props;

  switch (source) {
    case "local":
      return (
        <div
          id={props.uid}
          data-is-node
          data-uid={props.uid}
          className={classNames("doc-audio", "doc-audio-local", props.uid, {"focused-bookmark": props.isFocusedBookmark && !props.simple})}
          contentEditable={false}
        >
          <div className="doc-audio-wrapper-outer">
            <div className="doc-audio-wrapper-inner">
              <div className="comment-container" style={backgroundStyleFromNode(props)}>
                <audio key={props.url} preload="none" controls>
                  <source src={props.url} />
                </audio>
              </div>
              {editingContext.isEditing && userContext.permissions.viewMediaAssets && <GenButton
                  key="picker-button"
                  className="doc-audio-picker-button"
                  name={<i className="fa fa-music"></i>}
                  highlighted
                  small
                  onClick={(e) => {
                    editingContext.setCurrentMenu(MEDIA_PICKER, { mediaType: "audio", uid: uid });
                    e.stopPropagation();
                  }}
                />}
            </div>
          </div>
          {(typeof title === "string" && title.length > 0) || editingContext.isEditing && (
            <figcaption>
              <DocStyledText
                uid={uid}
                content={title}
                contentKey={"title"}
                placeholder={"Add Audio text here"}
                editingContext={editingContext}
                independentlyEditable
                disableStyleTextEditing={disableStyleTextEditing}
              />
            </figcaption>
          )}
          <DocBookmark {...props} />
          <DocNote {...props} />
        </div>
      );
    default:
      Rollbar.warning(`DocAudio does not recognize source of type ${props.source}`);
      return null;
  }
}

export default React.memo(DocAudio);
