import BaseObserver from './baseObserver'

interface SelectionObserverDelegate {
  selectionDidChange: (mode: Event) => void;
}

export default class SelectionObserver extends BaseObserver<SelectionObserverDelegate> {
  startEventListeners() {
    document.addEventListener("selectionchange", this.notifySelectionChange.bind(this), true);
  }

  stopEventListeners() {
    document.removeEventListener("selectionchange", this.notifySelectionChange.bind(this), true);
  }

  notifySelectionChange(event: Event) {
    this.delegates.forEach(delegate => delegate.selectionDidChange(event));
  }
}
