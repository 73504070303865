import {
  compact,
  findLast,
  flatten,
  groupBy,
  isPlainObject,
  values,
} from "lodash";

import * as R from "ramda";

import {
  rangesUnion,
  rejectItemsWithEmptyRanges,
  sortItemsWithRangesByStart,
  isInOrEndRange,
  compressRanges,
  isRangeCoveredByRanges,
} from "./Range";

import {
  findNodePathByUid,
  findNodeAtPath,
} from "./base";

import * as selection from "../selection";

function styleRangeCompressKey(styleRange: StyleRange) {
  switch (styleRange.type) {
    case "link":
      return [styleRange.type, styleRange.args.url];
      break;
    case "color":
      return [styleRange.type, styleRange.args.hexCode];
      break;
    default:
      return styleRange.type;
  }
}

// Convert To Use compressRanges function
export function compressStyleRanges(rangeInlineJSON: InlineRangeNode) {
  const styleRanges = rangeInlineJSON.styleRanges;
  const preppedStyleRanges = rejectItemsWithEmptyRanges(styleRanges);

  const styleRangesGroups = groupBy(preppedStyleRanges, (styleRange) => styleRangeCompressKey(styleRange));

  const allCompressedStyleRanges = sortItemsWithRangesByStart(flatten(values(styleRangesGroups).map((styleRangesGroup) => {
    return sortItemsWithRangesByStart(styleRangesGroup).reduce((compressedStyleRanges, styleRange) => {
      if (compressedStyleRanges.length === 0) {
        return [styleRange];
      } else {
        const prevStyleRange = compressedStyleRanges.pop();
        const unionOfStyleRanges = rangesUnion(prevStyleRange.range, styleRange.range);

        if (unionOfStyleRanges) {
          const newStyleRange = {...prevStyleRange};
          newStyleRange.range = unionOfStyleRanges;
          compressedStyleRanges.push(newStyleRange);
        } else {
          compressedStyleRanges.push(prevStyleRange, styleRange);
        }

        return compressedStyleRanges;
      }
    }, []);
  })));

  rangeInlineJSON.styleRanges = allCompressedStyleRanges;
  return rangeInlineJSON;
}

export function visibleIndexLessEqualGivenIndex(rangeInlineJSON, endIndex: number) {
  const hideRanges = rangeInlineJSON.styleRanges.filter((styleRange) => styleRange.style === "HIDE_TEXT").map((styleRange) => styleRange.range);
  const compressedRanges = compressRanges(hideRanges);

  const lastRelevanteHideRange = findLast(compressedRanges, (range) => range.end < endIndex);

  if (lastRelevanteHideRange && lastRelevanteHideRange.end < endIndex) {
    return lastRelevanteHideRange.start;
  } else {
    return endIndex;
  }
}

export function stylesAtIndex(rangeInlineJSON: InlineRangeNode, index: number) {
  const styleRangesAtIndex = rangeInlineJSON.styleRanges.filter((styleRange) => isInOrEndRange(styleRange.range, index));
  return styleRangesAtIndex.map((styleRange) => styleRange.type);
}

export function stylesCoveringRangeWithHideText(rangeInlineJSON: InlineRangeNode, range) { // TODO: REVISE WHEN InlineRangeNode Split in two
  const styleRanges = rangeInlineJSON.styleRanges || [];
  const preppedStyleRanges = rejectItemsWithEmptyRanges(styleRanges);

  const styleRangesGroups = groupBy(preppedStyleRanges, (styleRange) => styleRangeCompressKey(styleRange));

  let hideTextRanges = [];
  if (styleRangesGroups["HIDE_TEXT"]) {
    hideTextRanges = styleRangesGroups["HIDE_TEXT"].map((hideTextStyleRange) => hideTextStyleRange.range);
    delete styleRangesGroups["HIDE_TEXT"];
  }

  return R.fromPairs(compact(values(styleRangesGroups).map((styleRangeGroup) => {
    const styleRanges = styleRangeGroup.map((hideTextStyleRange) => hideTextStyleRange.range);
    return isRangeCoveredByRanges(range, styleRanges.concat(hideTextRanges)) ? [styleRangeGroup[0].type, styleRangeGroup[0]] : null;
  })));
}

export function stylesCoveringDocSelection(data: DocBlockNode<InlineRangeNode>, docSelection: DocSelection) {
  const {
    uid: startUID,
    contentKey: startKey,
    index: startIndex,
  } = docSelection.start;

  const {
    uid: endUID,
    contentKey: endKey,
    index: endIndex,
  } = docSelection.end;

  const startNodePath = findNodePathByUid(data, startUID);

  if (!startNodePath) { return {} }

  const startNode = findNodeAtPath(data, startNodePath);

  if (selection.isInOneInline(docSelection)) {
    return startNode[startKey] ? stylesCoveringRangeWithHideText(startNode[startKey], {start: startIndex, end: endIndex}) : {};
  } else {
    const endNodePath = findNodePathByUid(data, endUID);
    const endNode = findNodeAtPath(data, endNodePath);

    const startStyles = startKey ? stylesCoveringRangeWithHideText(startNode[startKey], {start: startIndex, end: startNode[startKey].text.length}) : {};
    const endStyles = endKey ? stylesCoveringRangeWithHideText(endNode[endKey], {start: 0, end: endIndex}) : {};

    // TODO EXPAND TO COVER MIDDLE NODES

    return R.pick(R.keys(endStyles), startStyles);
  }
}


export function isRangeInlineJSON(possibleRangeInlineJSON: InlineNode): possibleRangeInlineJSON is InlineRangeNode {
  return typeof possibleRangeInlineJSON !== "string" && ("text" in possibleRangeInlineJSON) && (typeof possibleRangeInlineJSON.text === "string");
}

export function rangeInlineJSONWithText(text = "") {
  return {
    text: text,
    styleRanges: [],
  };
}
