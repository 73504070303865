import * as React from "react";

import * as classNames from "classnames";
import analyticsModule from "../../../../application/analytics.js";

interface DocBookmarkProps {
  uid: string;
  bookmarked?: boolean;

  currentVersion: CurrentVersion;
  userContext: UserContext;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
};

export default function DocBookmark(props: DocBookmarkProps) {
  const {
    currentVersion,
    userContext,
    uid,
    bookmarked,
    displayContext,
    documentContext,
  } = props;

  if (currentVersion.disableBookmarks || displayContext.canBeEditing || displayContext.isEmbeded) { return null; }

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const newBookmarkState = !bookmarked;

    userContext.addState({
      [uid]: { bookmarked: newBookmarkState },
    });

    displayContext.setFocusedBookmarkUid(newBookmarkState ? uid : null);

    analyticsModule.track('use_bookmark_feature_on_the_page', { page: window.location.href, source: 'Case', case: documentContext.title})

    e.stopPropagation();
  };

  return (
    <div
      id={`bookmark ${uid}`}
      className={classNames("doc-bookmark-button", {bookmarked: bookmarked})}
      onClick={onClick}
      contentEditable={false}
    >
      <div className="icon-bookmark" />
    </div>
  );
}
