import * as React from "react";

import DocLearningObjectivesAddNewModal from "./Modals/LearningObjectivesAddNew";
import DocLearningObjectivesConfirmEdit from "./Modals/LearningObjectivesConfirmEdit";

import { generateLearningObjectiveActions } from "../../reducers/learningObjectives";

export type DocModalsProps = {
  currentVersion: CurrentVersion;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
  editingContext: EditingContext;
  editingContextExtra: EditingContextExtra;
  mappingContext: MappingContext;
  projectContext: ProjectContext;
  mediaContext: MediaContext;
  mergedData: MergedData;
  userContext: UserContext;
  allLearningObjectiveOptions?: Array<{id: number, name: string, documents: Array<{id: number, title: string}>}>;
  learningObjectiveActionsGenerator: typeof generateLearningObjectiveActions;

  featureFlag: FeatureFlag;
};

export default function DocModals(props: DocModalsProps) {
  const {
    editingContext,
    editingContextExtra,
  } = props;

  if (!editingContextExtra.currentMenu || !editingContext.isEditing) { return; }

  switch (editingContextExtra.currentMenu.menu) {
    case "LEARNING_OBJECTIVES_CONFIRM_EDIT":
      return (
        <DocLearningObjectivesConfirmEdit
          {...props}
          editingContext={editingContext}
        />
      )
    case "LEARNING_OBJECTIVES_ADD_NEW":
      const mappingContext = props.mappingContext as DocumentMappingContext; // TODO: Remove when resolved

      return (
        <DocLearningObjectivesAddNewModal
          {...props}
          editingContext={editingContext}
          mappingContext={mappingContext}
        />
      )
    default:
      return null
  }
}
