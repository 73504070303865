import {
  assign,
  camelCase,
  mapKeys,
  omit,
  snakeCase,
  upperFirst,
  pick,
} from 'lodash'

import axios from 'axios'

import * as pluralize from 'pluralize';

import { Dispatch, AnyAction } from "redux";
import { ThunkAction } from 'redux-thunk'

import {full_url} from '../helpers/url'

export const LIST_LOAD = 'LIST_LOAD'

export const SEED = 'relier-frontend/item/SEED'
export const LOAD = 'relier-frontend/item/LOAD'
export const CREATE = 'relier-frontend/item/CREATE'
export const UPDATE = 'relier-frontend/item/UPDATE'
export const DESTROY = 'relier-frontend/item/DESTROY'

export const NEW_START = 'relier-frontend/item/NEW_START'
export const NEW_CANCEL = 'relier-frontend/item/NEW_CANCEL'
export const EDIT_START = 'relier-frontend/item/EDIT_START'
export const EDIT_CANCEL = 'relier-frontend/item/EDIT_CANCEL'

export const SET_ALL_DATA_WITH_FUNCTION = 'relier-frontend/item/SET_ALL_DATA_WITH_FUNCTION'

export const SET_SYNC_DATA_FIELDS = 'relier-frontend/item/SET_SYNC_DATA_FIELDS'
export const SET_SYNC_DATA_WITH_FUNCTION = 'relier-frontend/item/SET_SYNC_DATA_WITH_FUNCTION'

export const SET_EDIT_DATA_FIELDS = 'relier-frontend/item/SET_EDIT_DATA_FIELDS'
export const SET_EDIT_DATA_WITH_FUNCTION = 'relier-frontend/item/SET_EDIT_DATA_WITH_FUNCTION'
export const RESET_EDIT_DATA = 'relier-frontend/item/RESET_EDIT_DATA'

export const SET_EXTRA_DATA_FIELDS = 'relier-frontend/item/SET_EXTRA_DATA_FIELDS'
export const SET_EXTRA_DATA_WITH_FUNCTION = 'relier-frontend/item/SET_EXTRA_DATA_WITH_FUNCTION'
export const RESET_EXTRA_DATA = 'relier-frontend/item/RESET_EXTRA_DATA'

export function defaultInTransformer<Item>(data: object): Item {
  return mapKeys(data, (value, key) => camelCase(key)) as Item // TODO: Forced narrowing to Item
}

const initialCollectionState = {}

function collectionReducer<Item extends {id: number}, ItemExtraData>(state: ItemCollectionState<Item, ItemExtraData> = initialCollectionState, action: Partial<ItemReducerAction<Item>> = {}, options: ReducerOptions<Item> ={}) { // TODO: REMOVE Partial from Partial<ItemReducerAction<Item>>
  const {
    inTransformer=defaultInTransformer,
    itemType="noItem",
    keepKeysOnUpdateFulfilled,
  } = options

  if (!action.meta || !action.meta.itemType || action.meta.itemType !== itemType) {
    return state
  }

  switch (action.type) {
    case SEED:
    case NEW_START:
    case LOAD + '_PENDING':
      return {...state,
        [action.meta.id]: itemReducer(state[action.meta.id], action, {inTransformer, itemType, keepKeysOnUpdateFulfilled})
      }
    case LIST_LOAD + '_FULFILLED':
      let newState: ItemCollectionState<Item, ItemExtraData> = {}

      action.payload.data.forEach((item: Item) => {
        newState[item.id] = {
          syncData: inTransformer({...item}),
          syncMeta: {
            isSyncing: false,
            lastSyncedAt: Date.now(),
            errors: [],
          },
          extraData: {},
        }
      })

      return {...state,
        ...newState
      }
    case CREATE + '_FULFILLED':
      return {...(omit(state, [action.meta.id])),
        [action.payload.id]: itemReducer(state[action.meta.id], action, {inTransformer, itemType, keepKeysOnUpdateFulfilled})
      }
    case NEW_CANCEL:
    case DESTROY + '_FULFILLED':
      return omit(state, [action.meta.id])
    case EDIT_START:
    case EDIT_CANCEL:
    case LOAD + '_FULFILLED':
    case LOAD + '_REJECTED':
    case CREATE + '_PENDING':
    case CREATE + '_REJECTED':
    case UPDATE + '_PENDING':
    case UPDATE + '_FULFILLED':
    case UPDATE + '_REJECTED':
    case DESTROY + '_PENDING':
    case DESTROY + '_REJECTED':
    case SET_ALL_DATA_WITH_FUNCTION:
    case SET_SYNC_DATA_FIELDS:
    case SET_SYNC_DATA_WITH_FUNCTION:
    case SET_EDIT_DATA_FIELDS:
    case SET_EDIT_DATA_WITH_FUNCTION:
    case RESET_EDIT_DATA:
    case SET_EXTRA_DATA_FIELDS:
    case SET_EXTRA_DATA_WITH_FUNCTION:
    case RESET_EXTRA_DATA:
    default:
      if (state[action.meta.id]) {
        return {...state,
          [action.meta.id]: itemReducer(state[action.meta.id], action, {inTransformer, itemType, keepKeysOnUpdateFulfilled})
        }
      } else {
        return state
      }
  }
}

const initialItemState = {extraData: {}}

function itemReducer<Item, ItemExtraData>(state: ReducerItem<Item, ItemExtraData> = initialItemState, action: Partial<ItemReducerAction<Item>> = {}, options: ReducerOptions<Item> = {}): ReducerItem<Item, ItemExtraData> { // TODO: REMOVE Partial from Partial<ItemReducerAction<Item>>
  const {
    inTransformer=defaultInTransformer,
    itemType,
    keepKeysOnUpdateFulfilled,
  } = options

  if (!action.meta || !action.meta.itemType || action.meta.itemType !== itemType) {
    return state
  }

  switch (action.type) {
    case EDIT_START:
      return {...state,
        editData: {}
      }
    case EDIT_CANCEL:
      return omit(state, ['editData'])
    case LOAD + '_PENDING':
    case CREATE + '_PENDING':
    case UPDATE + '_PENDING':
    case DESTROY + '_PENDING':
      return {...state,
        syncMeta: {
          ...state.syncMeta,
          isSyncing: true,
          editSinceStartOfLastSync: false,
        },
      }
    case SEED:
    case LOAD + '_FULFILLED':
      return {...state,
        syncData: inTransformer({...action.payload.data}),
        syncMeta: {
          isSyncing: false,
          lastSyncedAt: Date.now(),
          errors: [],
        },
      }
    case CREATE + '_FULFILLED':
    case UPDATE + '_FULFILLED':
      // THIS SHOULD NOT BE IN THE REDUCER
      // REMOVE WHEN PROGRESS BAR GETS REDESIGNED
      if (action.payload.data.progress !== undefined && action.payload.data.progress !== 0) {
        const progressBar = document.querySelector('.progress-text')
        const progress = action.payload.data.progress + '%';

        if(progressBar && progress == '100%') {
          document.querySelector('.progress-text').classList.add('hide');
          document.querySelector('.progress-box').classList.add('hide');
          document.querySelector('.complete-progress-icon').classList.remove('hide');
          document.querySelector('.my-time').classList.remove('hide');
          document.querySelector('.my-time-content').innerHTML = "My time: <br/> &nbsp;&nbsp;&nbsp; " + Math.round((action.payload.data.time_on_case / 1000.0 / 60.0)).toString() + " min";
          document.getElementById('resetQuestionButton').classList.remove('hide')
        } else if (progressBar) {
          document.querySelector('.progress-box').classList.remove('hide');
          document.querySelector('.progress-text').textContent = progress + " Complete";
          document.querySelector<HTMLElement>('.progress-bar-length').style.width = progress;
        }
      }
      return {...state,
        syncData: inTransformer({...action.payload.data}),
        syncMeta: {
          isSyncing: false,
          lastSyncedAt: Date.now(),
          errors: [],
        },
        editData: keepKeysOnUpdateFulfilled && state.editData && state.syncMeta?.editSinceStartOfLastSync ? pick(state.editData, keepKeysOnUpdateFulfilled) : {},
      }
    case LOAD + '_REJECTED':
    case CREATE + '_REJECTED':
    case UPDATE + '_REJECTED':
    case DESTROY + '_REJECTED':
      return {...state,
        syncMeta: {
          ...state.syncMeta,
          isSyncing: false,
          errors: [action.payload.message]
        }
      }
    case SET_ALL_DATA_WITH_FUNCTION:
      return {...state,
        ...action.payload.fn(state.syncData, state.editData, state.extraData),
        syncMeta: {
          ...state.syncMeta,
          editSinceStartOfLastSync: true,
        },
      }
    case SET_SYNC_DATA_FIELDS:
      return {...state,
        syncData: {...state.syncData,
          ...action.payload.fields
        }
      }
    case SET_SYNC_DATA_WITH_FUNCTION:
      return {...state,
        syncData: action.payload.fn(state.syncData, state.editData, state.extraData)
      }
    case SET_EDIT_DATA_FIELDS:
      return {...state,
        editData: {...state.editData,
          ...action.payload.fields
        },
        syncMeta: {
          ...state.syncMeta,
          editSinceStartOfLastSync: true,
        },
      }
    case SET_EDIT_DATA_WITH_FUNCTION:
      return {...state,
        editData: action.payload.fn(state.editData, state.syncData, state.extraData),
        syncMeta: {
          ...state.syncMeta,
          editSinceStartOfLastSync: true,
        },
      }
    case RESET_EDIT_DATA:
      return {...state,
        editData: {},
      }
    case SET_EXTRA_DATA_FIELDS:
      return {...state,
        extraData: {...state.extraData,
          ...action.payload.fields
        }
      }
    case SET_EXTRA_DATA_WITH_FUNCTION:
      return {...state,
        extraData: action.payload.fn(state.extraData, state.syncData, state.editData)
      }
    case RESET_EXTRA_DATA:
      return {...state,
        extraData: {},
      }
    case NEW_START:
      return {
        editData: action.meta.newItemState,
        extraData: {},
      }
    default:
      return state
  }
}


export function defaultOutTransformer<Item extends object>(data: Item): object {
  return mapKeys(data, (value, key) => snakeCase(key))
}

export function startNewItem<Item>(itemType: ItemType, newItemState: Partial<Item>, tempId: ItemTempId) {
  return {
    type: NEW_START,
    meta: {
      itemType: itemType,
      newItemState: newItemState,
      id: tempId
    },
  }
}

export function cancelNewItem(itemType: ItemType, tempId: ItemId) {
  return {
    type: NEW_CANCEL,
    meta: {
      itemType: itemType,
      id: tempId
    },
  }
}

export function seedItem<Item>(itemType: ItemType, id: ItemId, data: Item) {
  return {
    type: SEED,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      data: data
    }
  }
}

export function startEditItem(itemType: ItemType, id: ItemId) {
  return {
    type: EDIT_START,
    meta: {
      itemType: itemType,
      id: id
    },
  }
}

export function cancelEditItem(itemType: ItemType, id: ItemId) {
  return {
    type: EDIT_CANCEL,
    meta: {
      itemType: itemType,
      id: id
    },
  }
}

export function setAllDataWithFunction<Item, ItemExtraData>(itemType: ItemType, id: ItemId, fn: (syncData: Item, editData: Partial<Item>, extraData: ItemExtraData) => ReducerItem<Item, ItemExtraData>) {
  return {
    type: SET_ALL_DATA_WITH_FUNCTION,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      fn: fn
    }
  }
}

export function setSyncDataItem<Item>(itemType: ItemType, id: ItemId, fields: Partial<Item>) {
  return {
    type: SET_SYNC_DATA_FIELDS,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      fields: fields
    }
  }
}

export function setSyncDataWithFunction<Item, ItemExtraData>(itemType: ItemType, id: ItemId, fn: (syncData: Item, editData: Partial<Item>, extraData: ItemExtraData) => Item) {
  return {
    type: SET_SYNC_DATA_WITH_FUNCTION,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      fn: fn
    }
  }
}

export function setEditDataItem<Item>(itemType: ItemType, id: ItemId, fields: Partial<Item>) {
  return {
    type: SET_EDIT_DATA_FIELDS,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      fields: fields
    }
  }
}

export function setEditDataWithFunction<Item, ItemExtraData>(itemType: ItemType, id: ItemId, fn: (editData: Partial<Item>, syncData: Item, extraData: ItemExtraData) => Partial<Item>) {
  return {
    type: SET_EDIT_DATA_WITH_FUNCTION,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      fn: fn
    }
  }
}

export function resetEditDataItem(itemType: ItemType, id: ItemId) {
  return {
    type: RESET_EDIT_DATA,
    meta: {
      itemType: itemType,
      id: id
    }
  }
}

export function setExtraDataItem(itemType: ItemType, id: ItemId, fields: Partial<ExtraData>) {
  return {
    type: SET_EXTRA_DATA_FIELDS,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      fields: fields
    }
  }
}

export function setExtraDataWithFunction<Item, ItemExtraData>(itemType: ItemType, id: ItemId, fn: (extraData: ItemExtraData, syncData: Item, editData: Partial<Item>) => ExtraData) {
  return {
    type: SET_EXTRA_DATA_WITH_FUNCTION,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: {
      fn: fn
    }
  }
}

export function resetExtraDataItem(itemType: ItemType, id: ItemId) {
  return {
    type: RESET_EXTRA_DATA,
    meta: {
      itemType: itemType,
      id: id
    }
  }
}

export function loadItem(itemType: ItemType, endpoint: ItemEndpoint, id: ItemId) {
  return {
    type: LOAD,
    meta: {
      itemType,
      id,
    },
    payload: axios({
      url: full_url('/' + endpoint + (id || '')),
      method: 'get',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    }),
  }
}

function createItemWithData<Item extends object>(itemType: ItemType, endpoint: ItemEndpoint, tempId: ItemTempId, data: Partial<Item>, outTransformer: (outItem: Partial<Item>) => object=defaultOutTransformer) {
  return {
    type: CREATE,
    meta: {
      itemType,
      tempId,
    },
    payload: axios({
      url: full_url('/' + endpoint),
      data: outTransformer(data),
      method: 'post',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    })
  }
}

export function createItem<Item extends object>(itemType: ItemType, endpoint: ItemEndpoint, tempId: ItemTempId, outTransformer: (outItem: Partial<Item>) => object=defaultOutTransformer, afterDispatch) {
  return (dispatch: Dispatch, getState: () => ReducerState) => {
    let state = getState()
    let item = itemFromState<Item, unknown>(itemType, tempId, state)

    const response: any = dispatch(createItemWithData(itemType, endpoint, tempId, item.editData || {}, outTransformer))

    if (afterDispatch) {
      response.then((data) => afterDispatch(data))
    }
  }
}

export function updateItemWithData<Item extends object>(itemType: ItemType, endpoint: ItemEndpoint, id: ItemId, data: Partial<Item>, outTransformer: (outItem: Partial<Item>) => object=defaultOutTransformer) {
  return {
    type: UPDATE,
    meta: {
      itemType,
      id,
    },
    payload: axios({
      url: full_url('/' + endpoint + (id || '')),
      data: {[snakeCase(itemType)]: outTransformer(data)},
      method: 'put',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    })
  }
}

export function updateItem<Item extends object>(itemType: ItemType, endpoint: ItemEndpoint, id: ItemId, outTransformer: (outItem: Partial<Item>) => object=defaultOutTransformer): ThunkAction<void, ReducerState, unknown, AnyAction> {
  return (dispatch: Dispatch, getState: () => ReducerState) => {
    let state = getState()
    let item = itemFromState<Item, unknown>(itemType, id, state)

    dispatch(updateItemWithData(itemType, endpoint, id, item.editData || {}, outTransformer))
  }
}

export function destroyItem(itemType: ItemType, endpoint: ItemEndpoint, id: ItemId) {
  return {
    type: DESTROY,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: axios({
      url: full_url('/' + endpoint + (id || '')),
      method: 'delete',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    })
  }
}

export function shouldLoadItem<Item, ItemExtraData>(itemType: ItemType, state: ReducerState, id: ItemId) {
  const item = itemFromState<Item, ItemExtraData>(itemType, id, state)
  if (!item) {
    return true
  } else if (item.syncMeta?.isSyncing) {
    return false
  } else if (item.syncMeta?.lastSyncedAt && (Date.now() - item.syncMeta?.lastSyncedAt > 30000)) {
    return true
  } else {
    return false
  }
}

export function loadIfNeededItem(itemType: ItemType, endpoint: ItemEndpoint, id: ItemId) {
  return (dispatch: Dispatch, getState: () => ReducerState) => {
    if (shouldLoadItem(itemType, getState(), id)) {
      return dispatch(loadItem(itemType, endpoint, id))
    }
  }
}

export function itemFromState<Item, ItemExtraData>(itemType: ItemType, id: ItemId, state: ReducerState): ReducerItem<Item, ItemExtraData> {
  return state[pluralize(itemType)] && state[pluralize(itemType)][id]
}

export function itemSyncDataFromState<Item>(itemType: ItemType, id: ItemId, state: ReducerState) {
  let item = itemFromState<Item, unknown>(itemType, id, state)
  if (item) {
    return item.syncData
  }
}

export function itemSyncMetaFromState<Item, ItemExtraData>(itemType: ItemType, id: ItemId, state: ReducerState) {
  let item = itemFromState<Item, ItemExtraData>(itemType, id, state)
  if (item) {
    return item.syncMeta
  }
}

export function itemEditDataFromState<Item>(itemType: ItemType, id: ItemId, state: ReducerState) {
  let item = itemFromState<Item, unknown>(itemType, id, state)
  if (item) {
    return item.editData
  }
}

export function itemExtraDataFromState<ItemExtraData>(itemType: ItemType, id: ItemId, state: ReducerState) {
  let item = itemFromState<unknown, ItemExtraData>(itemType, id, state)
  if (item) {
    return item.extraData
  }
}

export function itemMergedDataFromState<Item>(itemType: ItemType, id: ItemId, state: ReducerState, mergeFunction=(data?: Item, editData?: Partial<Item>) => assign({}, data, editData)) {
  let item = itemFromState<Item, unknown>(itemType, id, state)
  if (item ) {
    return mergeFunction(item.syncData, item.editData)
  }
}

export function itemFromStatePlus<Item, ItemExtraData>(itemType: ItemType, id: ItemId, state: ReducerState, mergeFunction=(data?: Item, editData?: Partial<Item>) => assign({}, data, editData)) {
  let item = itemFromState<Item, ItemExtraData>(itemType, id, state)
  if (item) {
    return {
      id,
      ...item,
      mergedData: mergeFunction(item.syncData, item.editData)
    }
  } else {
    return {
      id,
      extraData: {},
      mergedData: undefined,
    }
  }
}


export function generateReducer<Item extends {id: number}, ItemExtraData extends object>(
  itemType: ItemType,
  options: GenerateReducerOptions<Item> = {},
) {
  const {
    inTransformer = defaultInTransformer,
    keepKeysOnUpdateFulfilled,
  } = options

  return (state: ItemCollectionState<Item, ItemExtraData>, action: ItemReducerAction<Item>) => collectionReducer(state, action, {inTransformer, itemType, keepKeysOnUpdateFulfilled})
}

export function generateActions<Item extends object, ItemExtraData extends object>(
  itemType: ItemType,
  id: ItemId,
  endpoint: ItemEndpoint,
  newItemState: Partial<Item> = {},
  options: GenerateActionOptions<Item> = {},
) {
  const {
    outTransformer = defaultOutTransformer,
    editDataMergeFunction,
  } = options

  return {
    startNewItem: () => startNewItem(itemType, newItemState, id),
    cancelNewItem: () => cancelNewItem(itemType, id),
    startEditItem: () => startEditItem(itemType, id),
    cancelEditItem: () => cancelEditItem(itemType, id),
    setAllDataWithFunctionItem: (fn: (syncData: Item, editData: Partial<Item>, extraData: ItemExtraData) => ReducerItem<Item, ItemExtraData>) => setAllDataWithFunction(itemType, id, fn),
    setSyncDataItem: (fields: Partial<Item>) => setSyncDataItem(itemType, id, fields),
    setSyncDataWithFunctionItem: (fn: (syncData: Item, editData: Partial<Item>, extraData: ItemExtraData) => Item) => setSyncDataWithFunction(itemType, id, fn),
    setEditDataItem: (fields: Partial<Item>) => setEditDataItem(itemType, id, fields),
    setEditDataWithFunctionItem: (fn: (editData: Partial<Item>, syncData: Item, extraData: ItemExtraData) => Partial<Item>) => setEditDataWithFunction(itemType, id, fn),
    resetEditDataItem: () => resetEditDataItem(itemType, id),
    setExtraDataItem: (fields: Partial<ItemExtraData>) => setExtraDataItem(itemType, id, fields),
    setExtraDataWithFunctionItem: (fn: (extraData: ItemExtraData, syncData: Item, editData: Partial<Item>) => ExtraData) => setExtraDataWithFunction(itemType, id, fn),
    resetExtraDataItem: () => resetExtraDataItem(itemType, id),
    loadItem: () => loadItem(itemType, endpoint, id),
    createItem: (afterDispatch=null) => createItem(itemType, endpoint, id, outTransformer, afterDispatch),
    updateItem: () => updateItem(itemType, endpoint, id, outTransformer),
    destroyItem: () => destroyItem(itemType, endpoint, id),
    loadIfNeededItem: () => loadIfNeededItem(itemType, endpoint, id),
    itemFromState: (state: ReducerState) => itemFromState(itemType, id, state),
    itemSyncDataFromState: (state: ReducerState) => itemSyncDataFromState<Item>(itemType, id, state),
    itemSyncMetaFromState: (state: ReducerState) => itemSyncMetaFromState(itemType, id, state),
    itemEditDataFromState: (state: ReducerState) => itemEditDataFromState<Item>(itemType, id, state),
    itemMergedDataFromState: (state: ReducerState) => itemMergedDataFromState<Item>(itemType, id, state, editDataMergeFunction),
    itemExtraDataFromState: (state: ReducerState) => itemExtraDataFromState<ItemExtraData>(itemType, id, state),
  }
}


