import * as React from "react";

import * as classNames from "classnames";

import DocBookmark from "../BlockNodeHelpers/Bookmark";
import DocNote from "../BlockNodeHelpers/Note";
import DocListItem from "./List/ListItem";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

interface DocListProps extends DocListType<InlineNode>, BlockNodeAll {};

function DocList(props: DocListProps) {
  const listItems = props.content.map((listItem, index) => {
    return (
      <DocListItem
        {...listItem}
        key={listItem.uid}
        simple={props.simple}
        documentContext={props.documentContext}
        currentVersion={props.currentVersion}
        editingContext={props.editingContext}
        userContext={props.userContext}
        displayContext={props.displayContext}
      />
    );
  });

  switch (props.type) {
    case "numberedList":
      return (
        <ol
          id={props.uid}
          data-is-node
          data-uid={props.uid}
          className={classNames(props.simple ? "doc-numbered-list-simple" : "doc-numbered-list", props.uid, props.classes, {"focused-bookmark": props.isFocusedBookmark && !props.simple})}
          style={backgroundStyleFromNode(props)}
        >
          {listItems}
          {!props.simple && <DocBookmark {...props} />}
          {!props.simple && <DocNote {...props} />}
        </ol>
      );
    case "regularList":
    default:
      return (
        <ul
          id={props.uid}
          data-is-node
          data-uid={props.uid}
          className={classNames(props.simple ? "doc-regular-list-simple" : "doc-regular-list", props.uid, props.classes, {"focused-bookmark": props.isFocusedBookmark && !props.simple})}
          style={backgroundStyleFromNode(props)}
        >
          {listItems}
          {!props.simple && <DocBookmark {...props} />}
          {!props.simple && <DocNote {...props} />}
        </ul>
      );
  }
}

export default React.memo(DocList);
