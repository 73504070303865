import * as React from "react";

import * as classNames from "classnames";

import DocStyledText from "../BlockNodeHelpers/StyledText";

interface DocVariantPlaceholderProps extends DocVariantPlaceholderType, BlockNodeAll {
}

function DocVariantPlaceholder(props: DocVariantPlaceholderProps) {
  const {
    uid,
    classes,
    editingContext,
    variantableId,
    variantableType,
  } = props;

  if (!editingContext.isEditing) { return null }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-variant-placeholder", uid, classes)}
      contentEditable={false}
    >
      <span>Loading...</span>
    </div>
  );
}

export default React.memo(DocVariantPlaceholder);
