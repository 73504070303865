export function lastLeaf(treeInlineNodes: Array<InlineTreeNodeSimple>) : InlineTreeNodeSimple | null {
  const rightNode = treeInlineNodes.slice(-1)[0];
  if (rightNode) {
    if (rightNode.type === "text") {
      return rightNode;
    } else if (rightNode.type === "link") {
      return lastLeaf(rightNode.title) || rightNode;
    } else {
      return lastLeaf(rightNode.content) || rightNode;
    }
  } else {
    return null;
  }
}

export function contentRightNDeep(treeInlineNodes: Array<InlineTreeNodeSimple>, n: number) {
  if (n <= 0) {
    return treeInlineNodes;
  } else {
    const rightNode = treeInlineNodes.slice(-1)[0];
    if (rightNode) {
      switch (rightNode.type) {
        case "text":
          return null;
          break;
        case "link":
          return contentRightNDeep(rightNode.title, n - 1);
          break;
        default:
          return contentRightNDeep(rightNode.content, n - 1);
      }
    } else {
      return null;
    }
  }
}

export function nodeRightNDeep(treeInlineNodes: Array<InlineTreeNodeSimple>, n: number) {
  const content = contentRightNDeep(treeInlineNodes, n);
  return content && content.slice(-1)[0];
}

export function appendTextToNode(treeInlineNode: InlineTreeNodeSimple, text: string) {
  switch (treeInlineNode.type) {
    case "text":
      treeInlineNode.content += text;
      break;
    case "link":
      treeInlineNode.title.push({
        type: "text",
        content: text,
      });
      break;
    default:
      treeInlineNode.content.push({
        type: "text",
        content: text,
      });
  }
  return treeInlineNode;
}

export function isTreeInlineJSON(possibleTreeInlineJSON: InlineNodeAll): possibleTreeInlineJSON is Array<InlineTreeNode> {
  return (
    Array.isArray(possibleTreeInlineJSON) &&
    (
      possibleTreeInlineJSON.length === 0 ||
      [
        "text",
        "bold",
        "italic",
        "underline",
        "subscript",
        "superscript",
        "link",
        "color",
        "HIDE_TEXT",
        "SELECTION_MIMIC",
        "ADD_COMMENT_THREAD",
        "ADD_CONTENT",
        "ADDED_TEXT",
        "CHANGED_TEXT",
        "DELETE_CONTENT",
        "DELETED_TEXT",
        "ADDED_STYLE",
        "DELETED_STYLE",
      ].includes(possibleTreeInlineJSON[0].type)
    )
  );
}
