import * as React from 'react';

import AqButton from '../../Aq/Button';

import GenModal from '../../Gen/Modal/component';

export type DocLearningObjectivesConfirmEditModalProps = {
  currentVersion: CurrentVersion;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  mappingContext: MappingContext;
  projectContext: ProjectContext;
  mediaContext: MediaContext;
  mergedData: MergedData;
  userContext: UserContext;
  featureFlag: FeatureFlag;
};

export default function DocLearningObjectivesConfirmEdit(props: DocLearningObjectivesConfirmEditModalProps) {
  const {
    editingContext,
    editingContextExtra,
  } = props;

  const currentMenu = editingContextExtra.currentMenu
  if (currentMenu?.menu !== "LEARNING_OBJECTIVES_CONFIRM_EDIT") {
    return null;
  }

  return (
    <GenModal open>
      <div className="gen-modal-panel">
        <div className="aq-card-header black" style={{textTransform: "none"}}>
          Are you sure you want to unlock this learning objective for editing?
        </div>
        <div>
          <p>
            We're just checking, because it's kind of a big deal.
          </p>
          <p>
            Learning objectives are used across the Aquifer system and can have implications beyond just this case.
          </p>
        </div>
        <div className="aq-button-bar bottom-right">
          <AqButton onClick={() => {
            editingContextExtra.setAllowLearningObjectivesEdit(currentMenu.learningObjectiveUid)
            editingContext.setCurrentDocSelection({start: {uid: currentMenu.learningObjectiveUid, contentKey: "content", index: 0}, end: {uid: currentMenu.learningObjectiveUid, contentKey: "content", index: 0}})
            editingContext.closeCurrentMenu()
          }}>I'm sure, let's do this</AqButton>
          <AqButton className="outline black" onClick={editingContext.closeCurrentMenu}>Nope, I want to go back</AqButton>
        </div>
      </div>
    </GenModal>
  )
}
