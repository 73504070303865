import React from 'react'

export default function GenLoadingSpinner({ variant = 'big' }) {
  return(
    <div className={`gen-loading-spinner ${variant}`}>
      <div className="gen-loading-spinner-bounce-1" />
      <div className="gen-loading-spinner-bounce-2" />
      <div className="gen-loading-spinner-bounce-3" />
    </div>
  );
}
