import * as React from "react";

import GenModal from "../../Gen/Modal/component";
import ReasoningToolPanel from "./Panel";
import NewEditDocContainer from "../Container";
import { changesDisplayModes } from "../../../helpers/Doc/changes";

interface CaseTeachingPointsPanelProps {
  userContext: UserContext;
  mergedData: MergedData;
  mappingContext: MappingContext;
};

interface CaseTeachingPointsPanelState {
  selectedTeachingPoint?: ReusableObject;
};

export default class CaseTeachingPointsPanel extends React.Component<CaseTeachingPointsPanelProps, CaseTeachingPointsPanelState> {
  constructor(props: CaseTeachingPointsPanelProps) {
    super(props);

    this.state = {};
  }

  closeTeachingPointModal(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.setState({selectedTeachingPoint: undefined})
  }

  openTeachingPointModal(reusableObject: ReusableObject) {
    this.setState({selectedTeachingPoint: reusableObject})
  }

  render() {
    let modal
    if (this.state.selectedTeachingPoint) {
      modal = <GenModal open={this.state.selectedTeachingPoint}>
        <div className="gen-modal-panel gen-modal-media-menu" style={{marginTop: 90 + 'px'}}>
          <div>
            <div style={{"fontWeight": "bold", "fontSize": "1.2vw"}}>
              <NewEditDocContainer
                docType="ReusableObject"
                documentId={this.state.selectedTeachingPoint.id}
                versionId={this.state.selectedTeachingPoint.review_variant_id}
                currentUser={{
                  id: this.props.userContext.id,
                  first_name: this.props.userContext.firstName,
                  last_name: this.props.userContext.lastName,
                  email: this.props.userContext.email,
                }}
                isAdmin={this.props.userContext.isAdmin}
                permissions={{}}
                isEmbeded
                defaultChangesDisplayMode={changesDisplayModes.HIGHLIGHT_ALL_CHANGES}
              />
            </div>
            <div>
              <button onClick={this.closeTeachingPointModal.bind(this)}>Close</button>
            </div>
          </div>
        </div>
      </GenModal>
    }

    return (<>
      {modal}
      <ReasoningToolPanel
        key="CASE TEACHING POINTS"
        title="CASE TEACHING POINTS"
      >
        <h3>{this.props.mergedData.title.text}</h3>
        <ul>
          {
            this.props.mappingContext.reusableObjects.map((reusableObject) => {
                let styleLink = reusableObject.reusable_object_edited ? {color: 'red'} : {}
                return <li key={reusableObject.id}>
                  <a href={""} style={styleLink}
                     onClick={() => this.openTeachingPointModal(reusableObject)}>{reusableObject.content_title}</a>
                </li>
              })
          }
        </ul>
      </ReasoningToolPanel>
    </>);
  }
}

