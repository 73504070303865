import * as React from "react";

import { newDeleteStyleChange, newAddStyleChange } from "../../../../helpers/Doc/changes";

import GenButton from "../../../Gen/Button/component";

interface DocEditHeaderLinkInputProps {
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
};

interface DocEditHeaderLinkInputState {
  link: string;
};

export default class DocEditHeaderLinkInput extends React.Component<DocEditHeaderLinkInputProps, DocEditHeaderLinkInputState> {
  constructor(props: DocEditHeaderLinkInputProps) {
    super(props);
    this.state = {
      link: (props.editingContextExtra?.activeStylesFromChanges?.link as StyleRangeLink)?.args?.url || "",
    };
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({link: e.target.value});
  }

  render() {
    const {
      editingContext,
      editingContextExtra,
    } = this.props;

    return (
      <div className="doc-edit-link-input">
        <div className="doc-edit-link-input-box">
          <input
            key="link-input-input"
            className="link-input"
            type="text"
            value={this.state.link}
            onChange={this.handleChange.bind(this)}
            placeholder="http://www.example.com"
            autoFocus
          />
          <div className="link-buttons">
            <div className="link-buttons-left">
              <GenButton
                className="unlink-button"
                name="Unlink"
                highlighted
                small
                onClick={() => {
                  const currentDocSelection = editingContextExtra.currentDocSelection

                  editingContext.addChange(
                    newDeleteStyleChange(
                      currentDocSelection,
                      "link"
                    ),
                    currentDocSelection.variantId
                  );

                  editingContext.closeCurrentMenu();
                }}
              />
            </div>
            <div className="link-buttons-right">
              <GenButton
                className="save-button"
                name="Save"
                highlighted
                small
                disabled={this.state.link.length === 0}
                onClick={() => {
                  let link = this.state.link;

                  const currentDocSelection = editingContextExtra.currentDocSelection

                  editingContext.addChange(
                    newAddStyleChange(
                      currentDocSelection,
                      "link",
                      {url: link}
                    ),
                    currentDocSelection.variantId
                  );

                  editingContext.closeCurrentMenu();
                }}
              />
              <GenButton
                className="cancel-button"
                name="Cancel"
                highlighted
                small
                onClick={() => { editingContext.closeCurrentMenu(); }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
