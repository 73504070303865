import { batch } from "react-redux";

import {
  newChangeTextAndNodesChange
} from "../changes";

import * as selection from "../selection";

import * as R from "ramda";

import {
  findNodePathByUid,
  findNodeAtPath,
} from "../Functions/base";

import {
  genrateNodesForAfterReturnNodeWithTextLines,
} from "./shared";

export default function pasteAtSelection(dataTransfer, {
  data,
  variantDataById,
  currentMenu,
  addChanges,
  setCurrentDocSelection,
  nodeDefinitions,
}: EditActionContext) {
  const currentDocSelection = selection.getDocSelection();

  if (!currentDocSelection || currentMenu) { return false; }

  const dataToUse = currentDocSelection.variantId ? variantDataById[currentDocSelection.variantId] : data;

  const {
    uid: startUID,
    contentKey: startKey,
    index: startIndex,
  } = currentDocSelection.start;

  let newStyledText = {
    text: "",
    styleRanges: [],
  }
  let newNodes = [];

  let newSelectionOffset = 0;
  const dataTypes = dataTransfer.types;
  if (dataTypes.includes("text/plain")) {
    const pasteText = dataTransfer.getData("text/plain");

    const pasteTextLines = clipboardPlainTextToTextLines(pasteText);

    const pasteStartText = pasteTextLines[0];
    newStyledText = {
      text: pasteStartText,
      styleRanges: []
    }

    newSelectionOffset = pasteStartText.length;

    let pasteRestLines = R.tail(pasteTextLines);

    if (pasteRestLines.length > 0) {
      const startNodePath = findNodePathByUid(dataToUse, startUID);
      const startNode = findNodeAtPath(dataToUse, startNodePath);

      newNodes = genrateNodesForAfterReturnNodeWithTextLines(nodeDefinitions, startNode, pasteRestLines);
    }
  } else {
    return false
  }

  if (newStyledText.text.length === 0 && newNodes.length === 0) { return false; }

  if (!selection.isInlineBoundry(currentDocSelection.start)) { return true; }

  batch(() => {
    addChanges([newChangeTextAndNodesChange(currentDocSelection, newStyledText, newNodes)], currentDocSelection.variantId);
    setCurrentDocSelection(selection.shiftDocSelectionBy(selection.collapseDocSelectionToStart(currentDocSelection), newSelectionOffset));
  });

  return true;
}

function clipboardHtmlToTextLines(htmlDataString: string) {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlDataString, 'text/html');
}

function clipboardPlainTextToTextLines(plainText: string) {
  let lines = plainText.split(/\r?\n/);
  lines = lines.map((line) => R.trim(line.replace(/[\n\r\s]+/gm, " ")));
  return lines.filter((line) => line !== "" && line != " ");
}
