import React, { useState } from 'react'
import * as R from "ramda";
import {startCase} from "lodash";

import GenModal from 'Gen/Modal/component'
import ReusableObjectDetail from 'Detail/component'
import GenButton from 'Gen/Button/component'

const DescriptionModal = ({ rlo, closeDescModal }) =>
  <CommonModal
    title='Reusable Content Description'
    rlo={rlo}
    handleClose={closeDescModal}
  >
    <p><b>Description</b></p>
    <p>{rlo.description}</p>
  </CommonModal>

const MultipleUsedDesc = ({rlo}) => {
  if (rlo.documents.length === 0) {
    return(<p>This Reusable Content is not used in any locations</p>);
  } else if (rlo.documents.length === 1) {
    return(<p>This Reusable Content is used in the single location shown above</p>);
  } else {
    const documentRows = rlo.documents.map((document) => {
      const learningObjectives = rlo.learning_objective_reusable_objects.map(loro => document.learning_objectives.find(lo => lo.id === loro.learning_objective_id)).filter(n => n);
      return (
        <tr key={document.id}>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>
            <a href={`/versions/${document.scheduled_review_version_id}/edit?tn=${rlo.uid}`} onClick={(e) => { e.stopPropagation(); return true } }>
              <b dangerouslySetInnerHTML={{__html: document.title}} />
            </a>
          </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal', fontWeight: 'bold'}}>
            <ul>
              { learningObjectives.map((lo) => ( <li key={lo.id}> {lo.name} </li> )) }
            </ul>
          </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.age_groups} </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.clinical_locations} </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.clinical_disciplines} </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.systems} </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.presenting_problems} </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.final_diagnoses} </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.additional_diagnoses} </td>
          <td style={{border: '1px solid #ccc', whiteSpace: 'normal'}}> {document.clinical_focuses} </td>
        </tr>
      )
    })

    return (
      <table>
        <tbody>
          <tr style={{border: '1px solid #ccc'}}>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Where Used </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal', width: '35%'}}>Learning Objective </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Age </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Clinical Locations </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Clinical Discipline </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>System </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Presenting Problem </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Final Diagnosis </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Additoinal Diagnosis </th>
            <th style={{border: '1px solid #ccc', whiteSpace: 'normal'}}>Clinical Focus </th>
          </tr>
          {documentRows}
        </tbody>
      </table>
    )
  }
}

const CommonModal = ({title, rlo, handleClose, children}) => {
  const allDocumentsLearningObjectives = R.uniqBy(lo => lo.id, rlo.documents.flatMap(document => document.learning_objectives));
  const loroLearningObjectives = rlo.learning_objective_reusable_objects.map(loro => allDocumentsLearningObjectives.find(lo => lo.id === loro.learning_objective_id)).filter(n => n);

  return (
    <GenModal open>
      <div className="gen-modal-panel gen-modal-panel-large" >
        <div style={{overflow: "auto", paddingBottom: "5px"}}>
          <button style={{float: "right"}} onClick={handleClose}>X</button>
        </div>
        <div style={{backgroundColor: '#0095c9', display: "flex"}}>
          <h1 style={{color: "#FFF", textAlign: 'center', flexGrow: "1"}}>{title}</h1>
          <div style={{margin: '10px'}}>
            <GenButton
              name={"Export"}
              href={`/reusable_objects/${rlo.id}/usage_report.pdf`}
              className={"grey-button"}
            />
          </div>
        </div>
        <div style={{color: "#333", backgroundColor: '#ddd', textAlign: 'left', padding: "5px"}}>
          <h3>{startCase(rlo.kind)}: {rlo.name}</h3>
        </div>
        <div style={{padding: "5px"}}>
          <p><strong>Source Course:</strong> {rlo.document_sets?.name} </p>
          <p><strong>Source Case:</strong> {rlo.documents[0]?.title} </p>
          <p><strong>Source Learning Objective:</strong></p>
          <ul>
            { loroLearningObjectives.map((lo) => ( <li key={lo.id}><b style={{whiteSpace: 'normal'}}>{lo.name}</b></li> )) }
          </ul>
        </div>
          {children}
        <div style={{textAlign: "center"}}>
          <button onClick={handleClose}>Close</button>
        </div>
      </div>
    </GenModal>
  )
}

const WhereUsedModal = ({ rlo, closeWhereUsedModal }) =>
  <CommonModal
    title='Reusable Content Description and Where it is used'
    rlo={rlo}
    handleClose={closeWhereUsedModal}
  >
    <MultipleUsedDesc rlo={rlo} />
  </CommonModal>

const ReusableObjectSummary = ({
  rlo,
  context,
  permissions,
  ...props
}) => {
  const [descModalOpen, setDescModalOpen] = useState(false)
  const closeDescModal = () => setDescModalOpen(false)

  const [whereUsedModalOpen, setWhereUsedModalOpen] = useState(false)
  const closeWhereUsedModal = () => setWhereUsedModalOpen(false)

  return (
    <ReusableObjectDetail {...props} {...{rlo, context, permissions}}>
      <ul>
        <li>
          <a onClick={() => setDescModalOpen(true)}>
            Description
          </a>
        </li>
        <li>
          <a onClick={() => setWhereUsedModalOpen(true)}>
            Uses
          </a>
        </li>
      </ul>
      {descModalOpen && <DescriptionModal {...{rlo, closeDescModal}}/>}
      {whereUsedModalOpen && <WhereUsedModal {...{rlo, closeWhereUsedModal}}/>}
    </ReusableObjectDetail>
  )
}

export default ReusableObjectSummary
