import * as R from "ramda";

const colors: Array<[number, number, number]> = [
  [255,0,0], // Red
  [0,0,255], // Blue
  [0,128,0], // Green
  [255,255,0], // Yellow
  [128,0,128], // Purple
  [255,165,0], // Orange
  [0,255,255], // Aqua
  [255,0,255], // Fuchsia
  [0,255,0], // Lime
  [128,0,0], // Maroon
  [0,128,128], // Teal
  [0,0,128], //Navy
];

export function generateColorLookup(keys: Array<number>) {
  const repeatCount = Math.ceil(keys.length / colors.length);
  const longEnoughColorList = R.unnest(R.repeat(colors, repeatCount));

  return R.zipObj(keys, longEnoughColorList);
}
