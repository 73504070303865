import React from 'react'

export default function IntegrationsTags(props) {
  let finalDiagnosesInfo = (props.final_diagnoses && props.final_diagnoses.length!==0) ?
    <div>
      <hr/>
      Final Diagnosis: {props.final_diagnoses.map((final_diagnosis) => {
      return <span key={final_diagnosis} className="final-diagnosis">{final_diagnosis}</span>
    })}
    </div> : ""

  let clinicalExcellenceInfo = (props.clinical_excellence && props.clinical_excellence.length!==0) ?
    <div>
      <hr/>
      Clinical Excellence Curricular Thread: {props.clinical_excellence.map((obj) => {
      return <span key={obj} className="clinical-excellence">{obj}</span>
    })}
    </div> : ""

  let basicScienceDisciplineInfo = (props.basic_science_disciplines && props.basic_science_disciplines.length!==0) ?
    <div>
      <hr/>
      Basic Science Curricular Thread: {props.basic_science_disciplines.map((obj) => {
      return <span key={obj} className="basic_science_discipline">{obj}</span>
    })}
    </div> : ""

  return <>
    {finalDiagnosesInfo}
    {clinicalExcellenceInfo}
    {basicScienceDisciplineInfo}<br/>
    </>
}