import * as R from "ramda";

import { batch } from "react-redux";

import {
  newChangeTextAndNodesChange
} from "../changes";

import {
  findNodePathByUid,
  findNodeAtPath,
  findNodeParentByUid,
  findNodeSiblingBeforeByPath,
} from "../Functions/base";

import {
  rangesSortByEnd,
  isInOrEndRange,
} from "../Functions/Range";

import * as selection from "../selection";

function backIndexUntillNotInDeletionRange(node: DocBlockNode<InlineRangeNode>, index: number) {
  if (node.type === "paragraph") {
    const deleteRanges = node.content.inlineChangeHighlights.filter((value) => value.type === "DELETE_CONTENT").map((highlight) => highlight.range);
    const irrelevantRanges = node.content.inlineIrrlevantRanges.map((irrelevantRagne) => irrelevantRagne.range);
    const sortedRanges = rangesSortByEnd(deleteRanges.concat(irrelevantRanges)).reverse();

    return sortedRanges.reduce((tempIndex, range) => isInOrEndRange(range, tempIndex) ? range.start : tempIndex, index);
  } else {
    return index;
  }
}

export default function deleteAtSelection({
  data,
  variantDataById,
  currentMenu,
  addChanges,
  setCurrentDocSelection,
  nodeDefinitions,
}: EditActionContext) {
  const currentDocSelection = selection.getDocSelection();

  if (!currentDocSelection || currentMenu) { return false; }

  const dataToUse = currentDocSelection.variantId ? variantDataById[currentDocSelection.variantId] : data;

  const {
    uid: startUID,
    contentKey: startKey,
    index: startIndex,
  } = currentDocSelection.start;

  let deletionSelection = currentDocSelection;
  let newSelection = selection.collapseDocSelectionToStart(currentDocSelection);

  if (selection.isCollapsed(currentDocSelection)) {
    const startNodePath = findNodePathByUid(dataToUse, startUID);
    const startNodeIndex: any = R.last(startNodePath); // TODO: Remove any
    const startNode = findNodeAtPath(dataToUse, startNodePath);

    const adjustedIndex = backIndexUntillNotInDeletionRange(startNode, deletionSelection.start.index)
    deletionSelection = selection.newCollapsedDocSelection(deletionSelection.start.uid, deletionSelection.start.contentKey, adjustedIndex)
    newSelection = selection.collapseDocSelectionToStart(deletionSelection);

    deletionSelection = selection.shiftDocSelectionStartBy(deletionSelection, -1);

    if (startNode.type === "paragraph") {
      if (deletionSelection.start.index < 0) {
        const parentNode = findNodeParentByUid(dataToUse, startUID);
        const previousSibling = findNodeSiblingBeforeByPath(dataToUse, startNodePath);

        if (startNodeIndex === 0 && parentNode.type === "listItem") {
          if (parentNode.blockChangeHighlight?.type !== "DELETE_CONTENT") {
            // DELETE LIST ITEM INSTEAD OF THE PARAGRAPH
            deletionSelection = selection.shiftDocSelectionBy(deletionSelection, -1);
          }
        } else if (previousSibling?.type === "paragraph") {
          const effectiveLength = previousSibling.content.text.replace(/\#$/, '').length
          newSelection = selection.newCollapsedDocSelection(previousSibling.uid, "content", effectiveLength);
        } else {
          // COULD MOVE SELECITON TO LINE BEFORE?
          return true;
        }
      } else {
        newSelection = selection.shiftDocSelectionBy(newSelection, -1)
      }
    } else {
      if (startIndex === 0) {
        // COULD MOVE SELECTION TO LINE BEFORE?
        return true;
      } else {
        newSelection = selection.shiftDocSelectionBy(newSelection, -1)
      }
    }
  }

  batch(() => {
    addChanges([newChangeTextAndNodesChange(deletionSelection, null)], currentDocSelection.variantId); // TODO: Convert null to styledText
    setCurrentDocSelection(newSelection);
  });

  return true;
}
