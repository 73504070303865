import * as React from "react";

import GenButton from "../Gen/Button/component";
import GenModal from "../Gen/Modal/component";

import { EDIT_RO_NAME_MODAL } from "../../helpers/Doc/menu";

interface Props {

};

export default function DocROName(props) {
  const {
    documentContext,
    editingContext,
    editingContextExtra,
  } = props;

  if (!editingContext.isEditing || documentContext.docType !== "ReusableObject") { return null; }

  const [newNameState, setNewNameState] = React.useState(documentContext.name);

  let editRoNameModal;
  if (editingContextExtra.currentMenu?.menu === EDIT_RO_NAME_MODAL) {
    editRoNameModal = (
      <GenModal open>
        <div className="gen-modal-panel">
          <div className="gen-modal-header">
            <div className="gen-modal-header-left">
              <h1 className="title">Update Reusable Content Title</h1>
            </div>
            <div className="gen-modal-header-right">
              <button
                type="button"
                className="close-button"
                onClick={() => editingContext.closeCurrentMenu()}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
          <hr />
          <label htmlFor="ro-name">
            New Title:
            <input
              id="ro-name"
              type="text"
              value={newNameState}
              onChange={(e) => setNewNameState(e.target.value)}
            />
          </label>
          <p>Updates to Reusable Content titles take immediate effect and do not need to be published.</p>
          <div className="gen-modal-choice-buttons">
            <button
              type="button"
              onClick={() => {
                editingContextExtra.updateRoName(newNameState);
                editingContext.closeCurrentMenu();
              }}
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => editingContext.closeCurrentMenu()}
            >
              Cancel
            </button>
          </div>
        </div>
      </GenModal>
    );
  }

  return (
    <div className="doc-ro-name">
      <h6>Reusable Content Title:</h6>
      <div className="doc-ro-name-container">
        <h1>
          {documentContext.name}
        </h1>
        <div>
          <GenButton
            name={<i className="fa fa-edit" />}
            highlighted
            small
            onClick={() => editingContext.setCurrentMenu(EDIT_RO_NAME_MODAL)}
          />
        </div>
      </div>
      {editRoNameModal}
    </div>
  );
}
