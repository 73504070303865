import * as React from 'react';
import ReactOnRails from 'react-on-rails';

import GenLoadingSpinner from 'Gen/LoadingSpinner/component';

export default class GenPostButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  static defaultProps = {
    highlighted: false,
    disabled: false,
    loading: false,
    data: {},
  };

  handleSubmit = (event) => {
    // Prevent default form submission behavior
    event.preventDefault();

    // Set loading state to true
    this.setState({ isLoading: true });

    // Submit the form after setting loading state
    event.target.submit();
  };

  render() {
    let classes = "gen-button";

    if (this.props.disabled) {
      classes += " disabled";
    }

    if (this.props.highlighted) {
      classes += " highlighted";
    }

    if (this.props.small) {
      classes += " small";
    }

    if (this.props.className) {
      classes += " ";
      classes += this.props.className;
    }

    const authToken = ReactOnRails.authenticityToken();

    if (this.state.isLoading || this.props.loading) {
      return (
        <div className={classes}>
          <span className="is-loading"><GenLoadingSpinner variant='small'/></span>
        </div>
      );
    } else {
      return (
        <div className={classes}>
          <form
            action={this.props.url}
            method="POST"
            target={this.props.openInNewTab ? "_blank" : "_self"}
            onSubmit={this.handleSubmit}
          >
            {Object.keys(this.props.data).map((key) => {
              return (
                <input
                  type="hidden"
                  key={key}
                  name={key}
                  value={JSON.stringify(this.props.data[key])}
                />
              );
            })}
            <input
              key="submit"
              type="submit"
              value={this.props.name}
              disabled={this.props.disabled}
              data-confirm={this.props.confirmMessage}
            />
            <input
              type="hidden"
              name="authenticity_token"
              value={authToken || ""}
            />
          </form>
        </div>
      );
    }
  }
}
