import * as React from "react";
import { HotKeys } from "react-hotkeys";

import { filter, uniq, concat } from "lodash";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

import GenButton from "../../../Gen/Button/component";

interface DocEditHeaderTagsInputProps {
  contentKey: string;
  disableMetadataEdit: boolean;
  editingContext: EditingContextInEditor;
  label: string;
  tags: Array<string>;
  uid: string;
};

interface DocEditHeaderTagsInputState {
  inputTag: string;
  editWindowOpen: boolean;

};

export default class DocEditHeaderTagsInput extends React.Component<DocEditHeaderTagsInputProps, DocEditHeaderTagsInputState> {
  constructor(props: DocEditHeaderTagsInputProps) {
    super(props);
    this.state = {
      inputTag: "",
      editWindowOpen: false,
    };
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({inputTag: e.target.value});
  }

  setTags(newTags: Array<string>) {
    this.props.editingContext.addChange(newSetKeyValueChange(this.props.uid, this.props.contentKey, newTags));
  }

  onMinusClick(toDeleteTag: string) {
    this.setTags(filter(this.props.tags, (tag) => {return tag !== toDeleteTag;}));
  }

  onPlusClick() {
    if (this.state.inputTag.length > 0) {
      this.setTags(uniq(concat(this.props.tags, this.state.inputTag)));
      this.setState({inputTag: ""});
    }
  }

  toggleEditWindow() {
    if (!this.props.disableMetadataEdit) {
      this.setState({editWindowOpen: !this.state.editWindowOpen});
    }
  }

  render() {
    let editWindow = null;
    if (this.state.editWindowOpen) {
      const keyHandlers = { return: (event: KeyboardEvent) => {this.onPlusClick(); return false;} };

      const tags = this.props.tags.map((tag) => {
        return (
          <span key={tag} className="doc-edit-tag-wrapper">
            <span className="doc-edit-tag">{tag}</span>
            <i className="fa fa-trash" onClick={(e) => {this.onMinusClick(tag);}}></i>
          </span>
        );
      });

      const HotKeysAnyType: any = HotKeys; // TODO: Remove when able to import type from HotKeys
      editWindow = (
        <div className="doc-edit-header-edit-window" onClick={(e) => {e.stopPropagation();}}>
          {tags}
          <HotKeysAnyType handlers={keyHandlers}>
            <div className="doc-edit-tag-wrapper">
              <input
                className="doc-edit-tag-wrapper"
                type="text"
                value={this.state.inputTag}
                onChange={this.handleChange.bind(this)}
                placeholder="Add Class..."
              />
              <GenButton
                className="tag-input-add-button"
                name={<i className="fa fa-plus"></i>}
                highlighted
                small
                disabled={this.state.inputTag.length === 0}
                onClick={this.onPlusClick.bind(this)}
              />
            </div>
          </HotKeysAnyType>
          <GenButton
            className="close-edit-window"
            name={"Close"}
            highlighted
            small
            onClick={this.toggleEditWindow.bind(this)}
          />
        </div>
      );
    }

    return (
      <div className="doc-edit-header-input doc-edit-header-tags-input" onClick={this.toggleEditWindow.bind(this)}>
        <label>{this.props.label}</label>
        <p className="doc-edit-header-tags-input-summary">{this.props.tags.length > 0 ? this.props.tags.join(", ") : <span className="input-summary-empty">None</span>}</p>
        {editWindow}
      </div>
    );
  }
}
