import { generateReducer, generateActions, itemFromStatePlus } from './itemReducer'

const itemKey = 'learningObjective'
const endpoint = 'learning_objectives/'

export function generateLearningObjectiveActions(id: ItemId) {
  const learningObjectiveActions = generateActions<LearningObjective, LearningObjectiveExtraData>(itemKey, id, endpoint)

  return {
    startNewLearningObjective: learningObjectiveActions.startNewItem,
    cancelNewLearningObjective: learningObjectiveActions.cancelNewItem,
    startEditLearningObjective: learningObjectiveActions.startEditItem,
    cancelEditLearningObjective: learningObjectiveActions.cancelEditItem,
    setAllDataWithFunctionLearningObjective: learningObjectiveActions.setAllDataWithFunctionItem,
    setSyncDataLearningObjective: learningObjectiveActions.setSyncDataItem,
    setSyncDataWithFunctionLearningObjective: learningObjectiveActions.setSyncDataWithFunctionItem,
    setEditDataLearningObjective: learningObjectiveActions.setEditDataItem,
    setEditDataWithFunctionLearningObjective: learningObjectiveActions.setEditDataWithFunctionItem,
    resetEditDataLearningObjective: learningObjectiveActions.resetEditDataItem,
    setExtraDataLearningObjective: learningObjectiveActions.setExtraDataItem,
    setExtraDataWithFunctionLearningObjective: learningObjectiveActions.setExtraDataWithFunctionItem,
    resetExtraDataLearningObjective: learningObjectiveActions.resetExtraDataItem,
    loadLearningObjective: learningObjectiveActions.loadItem,
    createLearningObjective: learningObjectiveActions.createItem,
    updateLearningObjective: learningObjectiveActions.updateItem,
    destroyLearningObjective: learningObjectiveActions.destroyItem,
    loadIfNeededLearningObjective: learningObjectiveActions.loadIfNeededItem,
    learningObjectiveFromState: learningObjectiveActions.itemFromState,
    learningObjectiveSyncDataFromState: learningObjectiveActions.itemSyncDataFromState,
    learningObjectiveSyncMetaFromState: learningObjectiveActions.itemSyncMetaFromState,
    learningObjectiveEditDataFromState: learningObjectiveActions.itemEditDataFromState,
    learningObjectiveMergedDataFromState: learningObjectiveActions.itemMergedDataFromState,
    learningObjectiveExtraDataFromState: learningObjectiveActions.itemExtraDataFromState,
  }
}

export function learningObjectiveFromStatePlus(id: ItemId, state: ReducerState) {
  return itemFromStatePlus<LearningObjective, LearningObjectiveExtraData>(itemKey, id, state)
}

export const learningObjectiveCollectionReducer = generateReducer<LearningObjective, LearningObjectiveExtraData>(itemKey);
