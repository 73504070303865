import * as React from "react";

import * as classNames from "classnames";

import DocBlockNodes from "../BlockNodes";

interface DocLearningObjectivesListProps extends DocLearningObjectivesListType<InlineNode>, BlockNodeAll {}

function DocLearningObjectivesList(props: DocLearningObjectivesListProps) {
  const {
    uid,
    classes,
    content,
  } = props;

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-learning-objectives-list", uid, classes)}
      contentEditable={false}
      style={{marginBottom: '10px'}}
    >
      <p>Upon completion of this module, the learner should be able to:</p>

      <DocBlockNodes
        {...props}
        content={content}
        contentKey="content"
        independentlyEditable
        simple
        shouldHaveAddNodes={false}
      />
    </div>
  );
}

export default React.memo(DocLearningObjectivesList);
