import {
  assign,
  assignWith,
  mapValues,
} from "lodash";

import * as R from "ramda";

import {
  weakMemoizedCloneAndNormalizeDoc,
} from "./changes";

import {
  denormalizeDocNode,
} from "./Functions/base";

export function mergeStates<T extends UserState | UserStateStripped>(stateA: T, stateB: T) {
  return assignWith({}, stateA, stateB, (nodeDataA, nodeDataB, uid) => {
    if (nodeDataA && nodeDataB) {
      return assignWith({}, nodeDataA, nodeDataB, (keyDataA, keyDataB, key) => {
        if (keyDataA && keyDataB) {
          if (keyDataA["updatedAt"] > keyDataB["updatedAt"]) {
            return keyDataA;
          } else {
            return keyDataB;
          }
        } else {
          return keyDataA || keyDataB;
        }
      });
    } else {
      return nodeDataA || nodeDataB;
    }
  });
}

// TODO CONPOSE WITH BELOW FUNCTIONS
export function stripState(state: UserState) {
  return mapValues(state, (nodeData) => {
    return mapValues(nodeData, (valueObject) => {
      return valueObject.value;
    });
  });
}

export function mergeStrippedStates(stateA: UserStateStripped, stateB: UserStateStripped) {
  return assignWith({}, stateA, stateB, (nodeDataA, nodeDataB, uid) => {
    if (nodeDataA && nodeDataB) {
      return assign({}, nodeDataA, nodeDataB);
    } else {
      return nodeDataA || nodeDataB;
    }
  });
}

// TODO: Remove function if not used
export function stripStateSingleNode(nodeState) {
  return mapValues(nodeState, (valueObject) => valueObject.value);
}

// TODO: Remove function if not used
export function mergeStrippedSingleNode(nodeStateA, nodeStateB) {
  if (nodeStateA && nodeStateB) {
    return assign({}, nodeStateA, nodeStateB);
  } else {
    return nodeStateA || nodeStateB;
  }
}

export function addDocumentUserState(doc, state: UserState) {
  const rootNodeUid = doc.uid;
  const normalizedDoc = weakMemoizedCloneAndNormalizeDoc(doc);

  const strippedState = stripState(state);
  const mergedNormalizedVersion = mergeStrippedStates(normalizedDoc, strippedState);

  return denormalizeDocNode(mergedNormalizedVersion, rootNodeUid);
}

export function wrapStateAsSetNodeKVPairActions(state: UserStateStripped): Array<DocActionSetNodeKVPair> {
  return R.toPairs(state).map(([uid, valueMap]) => (
    R.toPairs(valueMap).map(([key, value]) => {
      const returnAction: DocActionSetNodeKVPair = {
        type: "action",
        action: "setNodeKVPair",
        arguments: {
          uid,
          key,
          value,
        },
      }

      return returnAction;
    })
  )).flat();
}

// const DENORMALIZE_KEYS = ["diagnoses", "findings", "notes"]
// export function denormalizeDocumentUserState(strippedState) {
//   mapValues(strippedState, valuesHash => {
//     return DENORMALIZE_KEYS.forEach(denormalizeKey => {
//       if(valuesHash[denormalizeKey]) {
//         valuesHash = {
//           ...valuesHash,
//           [valuesHash[denormalizeKey]]: valuesHash[denormalizeKey].map(uid => strippedState[uid])
//         }
//       }
//     })
//   })
// }
//
// export function applyDocumentUserState(docNode, documentUserState) {
//   let returnNode = docNode
//
//   if (documentUserState[returnNode.uid]) {
//     returnNode = {
//       ...returnNode,
//       documentUserState[returnNode.uid]
//     }
//   }
// }
