import React from 'react'
import {startCase} from 'lodash'

import * as classNames from 'classnames'

import MultiUseImage from "images/multi-use.jpg";
import SingleUseImage from "images/single-use.jpg";

const ReusableObjectDetail = ({
  rlo,
  onClick,
  children,
  context,
  permissions,
  setAddModalOpen,
}) => {
  return (
    <tr className = { classNames('gen-select-item', 'document-summary-row') } onClick = { onClick } >
      <td className = "checkbox col_xsm" style={{cursor: 'default'}} >
        {context == 'editor'
          ? permissions?.insertRloToDocuments && <a onClick={() => setAddModalOpen(rlo)} className = "button small" style={{cursor: 'pointer'}} >Add</a>
          : permissions?.openRloInReadOnlyMode && <a href={`/reusable_objects/${rlo.id}`} className = "button small" >Open</a>
        }
      </td>
      <td style={{cursor: 'default'}}>
        { rlo.documents.length > 0 &&
          <img src={rlo.documents.length > 1 ? MultiUseImage : SingleUseImage} height="50" width="50"/>
        }
      </td>
      <td className = "title" style={{cursor: 'default', whiteSpace: 'break-spaces'}} >
        <p><b>{startCase(rlo.kind)}</b>: {rlo.name}</p>
      </td>
      <td className = "docs" > { children } </td>
    </tr>
  )
}

export default ReusableObjectDetail
