import * as React from "react";

import { takeRightWhile } from "lodash";

import GenButton from "../../Gen/Button/component";

import DocEditHeaderMetadataInputs from "./EditHeader/MetadataInputs";

import { findNodePathByUid } from "../../../helpers/Doc/Functions/base";
import {
  newDeleteNodeChange,
  newMoveNodeUpChange,
  newMoveNodeDownChange,
} from "../../../helpers/Doc/changes";

interface DocEditHeaderProps {
  editingContext: EditingContext;
  editingContextExtra: EditingContextExtra;
  userContext: UserContext;
};

export default function DocEditHeader(props: DocEditHeaderProps) {
  const {
    editingContext,
    editingContextExtra,
    userContext,
  } = props;

  if (!editingContext.isEditing || !editingContextExtra.currentlyEditingNodeLineage) { return null; }
  if (!userContext.permissions.editContentNodes) { return null; }
  const nodeLineage = editingContextExtra.currentlyEditingNodeLineage;

  if (nodeLineage.length <= 1) { return null; }

  let nodesSinceLastSection: Array<[DocBlockNode<any>, number]> = takeRightWhile(nodeLineage.map((node, index) => [node, index]), ([node, index]) => node.type !== "section"); // TODO: REMOVE ANY
  if (nodesSinceLastSection.length === 0) {
    const lastElementIndex = nodeLineage.length - 1;
    nodesSinceLastSection = [[nodeLineage[lastElementIndex], lastElementIndex]];
  }

  const toEditNodePairs = nodesSinceLastSection.filter(([node, index]) => !["tableRow", "tableColumn", "tableCell"].includes(node.type));
  const indexOfToEditNodes = toEditNodePairs.map(([node, index]) => index);

  const allNodesEditHeaders = indexOfToEditNodes.map((indexOfToEditNode) => {
    const node = nodeLineage[indexOfToEditNode];

    if (node.type === "section" && node.level === 0 && !userContext.permissions.editPages) { return null; }

    let nonRootActionButtons = null;
    if (indexOfToEditNode > 0) {
      const parent = nodeLineage[indexOfToEditNode - 1];
      const parentUID = parent.uid;
      const pathFromParent = findNodePathByUid(parent, node.uid);

      const parentContentKey = pathFromParent[0];
      const displaySiblingIndex = pathFromParent[1];
      const displaySiblingCount = parent[parentContentKey].length;

      let displaySiblingBeforeIndex = 0;
      if (displaySiblingIndex > 0) {
        const displaySiblingBefore = parent[parentContentKey][displaySiblingIndex - 1];
        displaySiblingBeforeIndex = displaySiblingBefore.editIndex;
      }

      let displaySiblingAfterIndex = displaySiblingCount - 1;
      if (displaySiblingIndex + 1 !== displaySiblingCount) {
        const displaySiblingAfter = parent[parentContentKey][displaySiblingIndex + 1];
        displaySiblingAfterIndex = displaySiblingAfter.editIndex;
      }

      nonRootActionButtons = [
        <GenButton
          key="up-arrow"
          name={<i className="fa fa-arrow-up"></i>}
          highlighted
          small
          disabled={displaySiblingIndex === 0 || (node.type === "section" && node.level === 1 && !userContext.permissions.editPages)}
          onClick={() => {
            editingContext.addChange(newMoveNodeUpChange(node.variantUid || node.uid));
          }}
        />,
        <GenButton
          key="down-arrow"
          name={<i className="fa fa-arrow-down"></i>}
          highlighted
          small
          disabled={displaySiblingIndex + 1 === displaySiblingCount || (node.type === "section" && node.level === 1 && !userContext.permissions.editPages)}
          onClick={() => {
            editingContext.addChange(newMoveNodeDownChange(node.variantUid || node.uid));
          }}
        />,
        <GenButton
          key="trash"
          name={<i className="fa fa-trash"></i>}
          highlighted
          small
          disabled={!userContext.permissions.deleteContentNodes || (node.type === "section" && node.level === 1 && !userContext.permissions.deletePages)}
          onClick={() => {
            editingContext.clearCurrentDocSelection();
            editingContext.addChange(newDeleteNodeChange(node.variantUid || node.uid));
          }}
        />,
      ];
    }

    const editWithInputs = (
      <DocEditHeaderMetadataInputs
        editingContext={editingContext}
        userContext={userContext}
        node={node}
      />
    );

    let divider = null;
    if (indexOfToEditNode !== indexOfToEditNodes[indexOfToEditNodes.length - 1]) {
      divider = <div className="edit-bar-control-bar-divider" />;
    }

    return (
      <div key={node.uid} className="edit-header-one-node">
        <div className="edit-header-action-buttons">
          <label className="edit-header-class-name">{node.type} ({node.uid})</label>
          {nonRootActionButtons}
        </div>
        {editWithInputs}
        {divider}
      </div>
    );
  });

  const editHeader = (
    <div className="edit-header">
      {allNodesEditHeaders}
    </div>
  );

  return (
    <div className="edit-bar-control-bar-nodes">
      <div className="edit-bar-control-bar-divider" />
      {editHeader}
    </div>
  );
}
