import * as React from "react";

import { intToDateTimeString } from "../../helpers/time_format";

interface GenColorDotProps {
  colorArray: ColorArray;
};

function GenColorDot(props: GenColorDotProps) {
  const dotStyle = {
    height: "10px",
    width: "10px",
    backgroundColor: `rgba(${props.colorArray})`,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
    marginLeft: "5px",
  };

  return (
    <span
      className="dot"
      style={dotStyle}
    />
  );
}

interface DocAuthorHeaderProps {
  colorArray: ColorArray;
  authorName: string;
  createdAt?: number;
  children?: React.ReactNode;
};

export default function DocAuthorHeader(props: DocAuthorHeaderProps) {
  return (
    <div style={{display: "flex"}}>
      <div style={{flexGrow: 1}}>
        <h6 style={{marginBottom: 0}}>
          {props.authorName}
          <GenColorDot colorArray={props.colorArray} />
        </h6>
        <p style={{fontSize: ".8em", color: "darkgray", marginBottom: 0}}>{props.createdAt && intToDateTimeString(props.createdAt)}</p>
      </div>
      <div>
        {props.children}
      </div>
    </div>
  );
}
