import * as React from 'react'

export default class GenUpdateOnBlurTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentWillUnmount() {
    this.handleBlur()
  }

  handleChange(e) {
    this.setState({inputValue: e.target.value})
  }

  handleBlur() {
    if (this.state.inputValue) {
      this.props.updateOnBlur(this.state.inputValue)
      this.setState({inputValue: null})
    }
  }

  render() {
    let value = this.state.inputValue || this.props.value

    return <input type="text" value={value} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)} placeholder={this.props.placeholder}/>
  }
}
