import * as React from "react";
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import * as R from "ramda";

interface DocContentMappingLOTPMapProps {
  activeLearningObjective?: LearningObjective;
  currentLearningObjectiveReusableObjects: Array<LearningObjectiveReusableObject>;
  proposedLearningObjectiveReusableObjects: Array<ProposedLearningObjectiveReusableObject>;
  reusableObjects: Array<ReusableObject>;
  setProposedLearningObjectiveReusableObjects: (loros: Array<ProposedLearningObjectiveReusableObject>) => void;
  userContext: UserContext;
};

interface DocContentMappingLOTPMapState {
  addingNewLoro?: boolean;
  selectedReusableObjectId?: number;
};

function equalProposedLearningObjectiveReusableObject(a: ProposedLearningObjectiveReusableObject, b: ProposedLearningObjectiveReusableObject) {
  return a.learning_objective_id === b.learning_objective_id && a.reusable_object_id === b.reusable_object_id
}

export default class DocContentMappingLOTPMap extends React.Component<DocContentMappingLOTPMapProps, DocContentMappingLOTPMapState> {
  constructor(props: DocContentMappingLOTPMapProps) {
    super(props);
    this.state = {
      addingNewLoro: false,
      selectedReusableObjectId: undefined,
    };
  }

  handleReusableObjectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({selectedReusableObjectId: event.target.value !== "" ? parseInt(event.target.value) : null })
  };

  handleDeleteWithAlert(loro: ProposedLearningObjectiveReusableObject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='react-confirm-alert-body'>
            <h1>You are deleting an LOTP dyad</h1>
            <p>Breaking this LOTP dyad could have additional curriculum mapping implications. Click the Continue button to proceed or Cancel to discard the deletion of this dyad.</p>
            <button className='aq-button'
              style={{marginRight: '5px'}}
              onClick={() => {
                this.handleDeleteLoro(loro);
                onClose();
              }}
            >
              Continue
            </button>
            <button className='aq-button' onClick={onClose}>Cancel</button>
          </div>
        );
      }
    });
  }

  handleDeleteLoro(loro: ProposedLearningObjectiveReusableObject) {
    const {
      proposedLearningObjectiveReusableObjects,
      setProposedLearningObjectiveReusableObjects,
    } = this.props;

    const newProposedLoros = proposedLearningObjectiveReusableObjects.filter((proposedLoro) => !equalProposedLearningObjectiveReusableObject(proposedLoro, loro))
    setProposedLearningObjectiveReusableObjects(newProposedLoros)
  }

  handleRestoreLoro(loro: ProposedLearningObjectiveReusableObject) {
    const {
      proposedLearningObjectiveReusableObjects,
      setProposedLearningObjectiveReusableObjects,
    } = this.props;

    const newProposedLoros = proposedLearningObjectiveReusableObjects.concat([loro])
    setProposedLearningObjectiveReusableObjects(newProposedLoros)
  }

  handleSaveWithAlert() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='react-confirm-alert-body'>
            <p>When published, the selected content mappings for this Learning Objective will be available throughout the Aquifer curriculum. Click the Continue button to proceed or Cancel to discard the deletion of this dyad.</p>
            <button className='aq-button'
              style={{marginRight: '5px'}}
              onClick={() => {
                this.handleSaveNewLoro();
                onClose();
              }}
            >
              Continue
            </button>
            <button className='aq-button' onClick={onClose}>Cancel</button>
          </div>
        );
      }
    });
  }

  handleSaveNewLoro() {
    const {
      activeLearningObjective,
      proposedLearningObjectiveReusableObjects,
      setProposedLearningObjectiveReusableObjects,
    } = this.props;

    const {
      selectedReusableObjectId,
    } = this.state;

    if (!selectedReusableObjectId) {
      alert("Please select a reusable object.");
      return;
    }

    let loroToBeSaved = {
      learning_objective_id: activeLearningObjective.id,
      reusable_object_id: selectedReusableObjectId,
    }

    const newProposedLoros = proposedLearningObjectiveReusableObjects.concat([loroToBeSaved])
    setProposedLearningObjectiveReusableObjects(newProposedLoros)
    this.setState({ addingNewLoro: false, selectedReusableObjectId: undefined })
  }

  handleCancelNewLoro() {
    this.setState({addingNewLoro: false})
  }

  render() {
    const {
      reusableObjects,
      activeLearningObjective,
      currentLearningObjectiveReusableObjects,
      proposedLearningObjectiveReusableObjects,
      userContext,
    } = this.props;

    const {
      addingNewLoro,
      selectedReusableObjectId,
    } = this.state;


    if (!activeLearningObjective) {
      return (
        <div>
          <h5 style={{backgroundColor: "#0095C9", padding: "5px"}}>Please Select a Learning Objective</h5>
        </div>
      );
    }

    const concatedLoros = (currentLearningObjectiveReusableObjects as ProposedLearningObjectiveReusableObject[]).concat(proposedLearningObjectiveReusableObjects).filter((loro) => loro.learning_objective_id === activeLearningObjective.id && loro.reusable_object_id)
    const activeProposedLoros = R.uniqWith(equalProposedLearningObjectiveReusableObject, concatedLoros)

    let tableRows = activeProposedLoros.map((loro) => {
      const isCurrentLoro = currentLearningObjectiveReusableObjects.some((currentLoro) => equalProposedLearningObjectiveReusableObject(currentLoro, loro))
      const isProposedLoro = proposedLearningObjectiveReusableObjects.some((proposedLoro) => equalProposedLearningObjectiveReusableObject(proposedLoro, loro))

      let deleteCell
      let rowStyle = {}
      if (userContext.permissions.allowedEditMode === "Editing") {
        if (!isProposedLoro) {
          deleteCell = <button onClick={() => this.handleRestoreLoro(loro)}>Restore</button>
          rowStyle = {textDecorationLine: 'line-through', textDecorationStyle: 'solid'}
        } else if(!isCurrentLoro || (!loro.questions || loro.questions.length === 0)) {
          deleteCell = <button onClick={() => this.handleDeleteWithAlert(loro)}>Delete</button>
        }
      }

      const reusableObject = reusableObjects.find(x => x.id === loro.reusable_object_id)

      return (
        <tr style={rowStyle}>
          <td>{decodeURI(activeLearningObjective.name)}</td>
          <td>{reusableObject?.name}</td>
          <td>
            <div style={{textAlign: 'center'}}>
              {
                loro.questions?.map((question) => (
                  <p><a href={`/versions/${question.editing_document.scheduled_review_version_id}/edit`}>{question.case_summary_identifier}</a></p>
                ))
              }
            </div>
          </td>
          <td>
            <div style={{textAlign: 'center'}}>
              {deleteCell}
            </div>
          </td>
        </tr>
      );
    })

    let controls, editingRow;
    if (userContext.permissions.allowedEditMode === "Editing") {
      if(!addingNewLoro) {
        controls = (
          <div style={{textAlign: 'center', marginBottom: '5px'}}>
            <button className='aq-button' onClick={() => this.setState({addingNewLoro: true})}>Add Row</button>
          </div>
        )
      } else {
        let existingRoIds = activeProposedLoros.map((loro) => loro.reusable_object_id)
        let reusableObjectOptions = reusableObjects.filter((ro) => !existingRoIds.includes(ro.id))

        editingRow = (
          <tr>
            <td>{decodeURI(activeLearningObjective.name)}</td>
            <td>
              <select onChange={this.handleReusableObjectChange.bind(this)} value={selectedReusableObjectId || ""}>
                <option value="">Select</option>
                {
                  reusableObjectOptions.map((reusableObject) =>
                    <option
                      key={reusableObject.id}
                      value={reusableObject.id}>{reusableObject.content_title}
                    </option>
                  )
                }
              </select>
            </td>
            <td></td>
            <td></td>
          </tr>
        )

        controls = (
          <div style={{textAlign: 'center', marginBottom: '5px'}}>
            <button className='aq-button' style={{marginRight: '5px'}} onClick={() => this.handleSaveWithAlert()} disabled={!selectedReusableObjectId}>Save</button>
            <button className='aq-button' onClick={() => this.handleCancelNewLoro()}>Cancel</button>
          </div>
        )
      }
    }

    return (
      <div>
        <h5 style={{backgroundColor: "#0095C9", padding: "5px"}}>Assessment - LOTP - System Map</h5>
        <div>
          <p style={{fontWeight: "bold"}}>
            The table below displays the Teaching Points that are mapped to this Learning Objective.
            Add another row if you wish to map this Learning Objective to additional Teaching Point(s).
            When you have finished mapping, click the Save button.
          </p>
          <table className="aq-mapping-table">
            <tbody>
              <tr>
                <th className="aq-mapping-table-header">Learning Objective</th>
                <th className="aq-mapping-table-header">Existing Map to Teaching Point</th>
                <th className="aq-mapping-table-header">Assessment Questions Mapped to this Dyad</th>
                <th className="aq-mapping-table-header"></th>
              </tr>
              {tableRows}
              {editingRow}
            </tbody>
          </table>
          {controls}
        </div>
      </div>
    );
  }
}
