import * as React from "react";

import ActiveEditor from "./ActiveEditors/ActiveEditor";

import GenButton from "../Gen/Button/component";

interface ActiveEditorsProps {
  userContext: UserContext;
  activeEditors: Array<PressenceEditor>;
  lockState: LockState;
  lockIfFree: () => void;
  unlockIfOwned: () => void;
  forceLock: () => void;
};

export default function ActiveEditors(props: ActiveEditorsProps) {
  const {
    userContext,
    activeEditors,
    lockState,
    lockIfFree,
    unlockIfOwned,
    forceLock,
  } = props;

  const lockIsLocked = lockState.lockUserId === null;

  return (
    <div className="active-editors-wrapper">
      {activeEditors.map((editor) => (
        <ActiveEditor
          key={`versionEditor${editor.id}`}
          userContext={userContext}
          editor={editor}
          hasLock={editor.id === lockState.lockUserId}
          hasLockInSession={editor.id === lockState.lockUserId && userContext.sessionId === lockState.lockSessionId}
          lockIfFree={lockIfFree}
          unlockIfOwned={unlockIfOwned}
          forceLock={forceLock}
        />
      ))}
      { lockIsLocked && (
        <GenButton
          name="LOCK!"
          className="highlighted small"
          onClick={lockIfFree}
        />
      )}
    </div>
  )
}
