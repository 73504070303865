import * as React from "react";

import { capitalize } from "lodash";

import DocStyledText from "../../BlockNodeHelpers/StyledText";

import DocList from "../../BlockNodes/List";
import DocParagraph from "../../BlockNodes/Paragraph";

import { scrollToLineage } from "../../../../helpers/Doc/scrollTo";

import {
  weakMemoizedGenerateEditingContextAllFalse,
} from "../../../../helpers/Doc/context";

interface ReasoningToolBookmarkSummaryProps {
  documentContext: DocumentContext;
  currentVersion: CurrentVersion;
  userContext: UserContext;
  displayContext: DisplayContext;
  mediaContext: MediaContext;
  featureFlag: FeatureFlag;
  bookmarkedNode: DocBlockNode<InlineNode>;
  bookmarkedNodeLineage: Array<DocBlockNode<InlineNode>>;
};

export default function ReasoningToolBookmarkSummary(props: ReasoningToolBookmarkSummaryProps) {
  const {
    documentContext,
    currentVersion,
    userContext,
    displayContext,
    mediaContext,
    featureFlag,
    bookmarkedNode,
    bookmarkedNodeLineage,
  } = props;

  const editingContext = weakMemoizedGenerateEditingContextAllFalse();

  const onClick = () => {
    scrollToLineage(bookmarkedNodeLineage, userContext.addState);
    displayContext.setFocusedBookmarkUid(bookmarkedNode.uid);
  };

  let displayData = null;
  switch (bookmarkedNode.type) {
    case "paragraph":
      displayData = (
        <DocParagraph
          key={bookmarkedNode.uid}
          simple
          documentContext={documentContext}
          currentVersion={currentVersion}
          userContext={userContext}
          editingContext={editingContext}
          displayContext={displayContext}
          mediaContext={mediaContext}
          featureFlag={featureFlag}
          {...bookmarkedNode}
        />
      );
      break;
    case "numberedList":
    case "regularList":
      displayData = (
        <DocList
          key={bookmarkedNode.uid}
          simple
          documentContext={documentContext}
          currentVersion={currentVersion}
          userContext={userContext}
          editingContext={editingContext}
          displayContext={displayContext}
          mediaContext={mediaContext}
          featureFlag={featureFlag}
          {...bookmarkedNode}
        />
      );
      break;
    default:
      displayData = <p>{capitalize(bookmarkedNode.type)} bookmark. Click to view.</p>;
  }

  if (!displayData) { return null; }

  const ancestorSections = bookmarkedNodeLineage.filter((ancestor): ancestor is DocSectionType<InlineNode> => ancestor.type === "section");
  const firstLevelSectionTitle = ancestorSections[1] ? ancestorSections[1].title : "";

  return (
    <div className="reasoning-tool-bookmark-summary" onClick={onClick}>
      <h6 className="reasoning-tool-bookmark-summary-card-title">
        <DocStyledText
          content={firstLevelSectionTitle}
          editingContext={editingContext}
        />
      </h6>
      {displayData}
    </div>
  );
}
