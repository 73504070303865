import * as React from "react";

import { startCase } from "lodash";

import { newSetKeyValueChange } from "../../../helpers/Doc/changes";

import GenModal from "../../Gen/Modal/component";
import GenToggleView from "../../Gen/ToggleView/component";
import GenUpdateOnBlurTextField from "../../Gen/UpdateOnBlurTextField/component";

import { MEDIA_PICKER } from "../../../helpers/Doc/menu";

interface EditBarMediaPickerProps {
  userContext: UserContext;
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  mediaContext: MediaContext;
};

export default function EditBarMediaPicker(props: EditBarMediaPickerProps) {
  const {
    userContext,
    editingContext,
    editingContextExtra,
    mediaContext,
  } = props;

  const currentMenu = editingContextExtra.currentMenu;

  if (currentMenu?.menu !== MEDIA_PICKER) { return null; }

  let innerContent;
  switch (currentMenu.mediaType) {
    case "image":
      const images = mediaContext.images.map((imageAsset) => {
        if(!userContext.permissions.editMediaAssets) {
          return (
            <div key={imageAsset.external_identifier}>
              <img src={imageAsset.distribution_url} />
              <p>{imageAsset.title}</p>
            </div>
          );
        }

        const makeImage = (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement>) => {
          e.stopPropagation();

          if (editingContextExtra.currentMenuNode.type === "section") {
            editingContextExtra.setVersionCoverImage(imageAsset.distribution_url);
            editingContext.closeCurrentMenu();
          } else {
            editingContext.addChange(newSetKeyValueChange(currentMenu.uid, "url", imageAsset.distribution_url));
            editingContext.closeCurrentMenu();
          }
        };

        return (
          <div key={imageAsset.external_identifier}>
            <img onClick={makeImage} src={imageAsset.distribution_url} />
            <p>{imageAsset.title} <button onClick={makeImage}>Select</button></p>
          </div>
        );
      });

      innerContent = images.length > 0 ? images : <div key="no-images"><h3>No Image Files</h3><h6>Upload files to Case</h6></div>;
      break;
    case "audio":
      const audios = mediaContext.audios.map((audioAsset) => {
        if(!userContext.permissions.editMediaAssets) {
          return (
            <div key={audioAsset.external_identifier}>
              <audio preload="none" controls>
                <source src={audioAsset.distribution_url} />
              </audio>
              <p>{audioAsset.title}</p>
            </div>
          );
        }

        const makeAudio = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          editingContext.addChange(newSetKeyValueChange(currentMenu.uid, "url", audioAsset.distribution_url));
          editingContext.closeCurrentMenu();
        };

        return (
          <div key={audioAsset.external_identifier}>
            <audio preload="none" controls>
              <source src={audioAsset.distribution_url} />
            </audio>
            <p>{audioAsset.title} <button onClick={makeAudio}>Select</button></p>
          </div>
        );
      });

      innerContent = audios.length > 0 ? audios : <div key="no-audios"><h3>No Audio Files</h3><h6>Upload files to Case</h6></div>;
      break;
    case "video":
      if (editingContextExtra.currentMenuNode.type !== "video") { return }

      const updateOnBlur = (newValue) => {
        editingContext.addChange(newSetKeyValueChange(currentMenu.uid, "uri", newValue));
        editingContext.closeCurrentMenu();
      };

      const possibleVideos = mediaContext.videos.map((videoAsset) => {
        let captionsTrack = null;
        const captions = mediaContext.captions.filter((c)=>{return c.title === videoAsset.title;});
        if (captions.length) {
          let caption = null;
          if (captions.length > 1) {
            caption = captions.reduce((capn_1, capn) => (capn_1.external_identifier > capn.external_identifier) ? capn_1 : capn);
          } else {
            caption = captions[0];
          }
          captionsTrack = <track key="en" label="English" kind="subtitles" srcLang="en" src={caption.distribution_url} />;
        }

        if(!userContext.permissions.editMediaAssets) {
          return (
            <div key={videoAsset.external_identifier}>
              <video crossOrigin="anonymous" preload="none" controls>
                <source src={videoAsset.distribution_url} />
                {captionsTrack}
              </video>
              <p>{videoAsset.title}</p>
            </div>
          );
        }

        const makeVideo = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();

          editingContext.addChange(newSetKeyValueChange(currentMenu.uid, "url", videoAsset.distribution_url));
          editingContext.closeCurrentMenu();
        };

        return (
          <div key={videoAsset.external_identifier}>
            <video crossOrigin="anonymous" preload="none" controls>
              <source src={videoAsset.distribution_url} />
              {captionsTrack}
            </video>
            <p>{videoAsset.title} <button onClick={makeVideo}>Select</button></p>
          </div>
        );
      });

      innerContent = (
        <GenToggleView
          activeKey={editingContextExtra.currentMenuNode.source}
          views={[{
            key: "local",
            name: "Local",
            onClick: () => {editingContext.addChange(newSetKeyValueChange(editingContextExtra.currentMenuNode.uid, "source", "local"));},
            view: <div>
              {possibleVideos.length > 0 ? possibleVideos : <div key="no-videos"><h3>No Video Files</h3><h6>Upload files to Case</h6></div> }
            </div>,
          },{
            key: "youtube",
            name: "YouTube",
            onClick: () => {editingContext.addChange(newSetKeyValueChange(editingContextExtra.currentMenuNode.uid, "source", "youtube"));},
            view: <div>
              <GenUpdateOnBlurTextField
                value={editingContextExtra.currentMenuNode.uri}
                updateOnBlur={updateOnBlur}
                placeholder={"Insert the YouTube video ID. For example... dQw4w9WgXcQ"}
              />
            </div>,
          },{
            key: "vimeo",
            name: "Vimeo",
            onClick: () => {editingContext.addChange(newSetKeyValueChange(editingContextExtra.currentMenuNode.uid, "source", "vimeo"));},
            view: <GenUpdateOnBlurTextField
              value={editingContextExtra.currentMenuNode.uri}
              updateOnBlur={updateOnBlur}
              placeholder={"Insert the Vimeo video ID. For example... 123456789"}
            />,
          }]}
        />
      );

      break;
    default:
      // Todo Add Error
      return null;
  }

  return (
    <GenModal open>
      <div className="gen-modal-panel gen-modal-media-picker">
        <div className="gen-modal-header">
          <div className="gen-modal-header-left">
            <h1>Pick {startCase(currentMenu.mediaType)}</h1>
          </div>
          <div className="gen-modal-header-right">
            <button onClick={(e) => {e.stopPropagation(); editingContext.closeCurrentMenu();}}>Cancel</button>
          </div>
        </div>
        <hr />
        {innerContent}
      </div>
    </GenModal>
  );
}
