import * as React from "react";

import GenParagraph from "../../Gen/Paragraph/component";

interface ReasoningToolFindingSummaryProps {
  content: string;
  isEditing: boolean;
  startNodeEdit: () => void;
  endNodeEdit: React.FocusEventHandler<HTMLInputElement>;
};

export default function ReasoningToolFindingSummary({
  content = "",
  isEditing,
  startNodeEdit,
  endNodeEdit,
}: ReasoningToolFindingSummaryProps) {
  return (
    <div className="reasoning-tool-finding-summary">
      <GenParagraph
        value={content}
        placeholder="Enter Finding..."
        shouldAutoFocus
        isEditing={isEditing}
        onSpanClick={startNodeEdit}
        onBlur={endNodeEdit}
        onEnterKeyPress={endNodeEdit}
      >
        {content}
      </GenParagraph>
    </div>
  );
}
