import * as React from "react";

import ReasoningToolPanel from "./Panel";

import DocEditHeaderMetadataInputs from "../EditBar/EditHeader/MetadataInputs";

interface ReasoningToolAdminToolsPanelProps {
  editingContext: EditingContextInEditor;
  userContext: UserContext;
  mergedData: MergedData;
};

export default function ReasoningToolAdminToolsPanel(props: ReasoningToolAdminToolsPanelProps) {
  const {
    editingContext,
    userContext,
    mergedData,
  } = props;

  return (
    <ReasoningToolPanel
      key="AET ADMIN TOOLS"
      title="AET ADMIN TOOLS"
    >
      <DocEditHeaderMetadataInputs
        editingContext={editingContext}
        userContext={userContext}
        node={mergedData}
        onlyKeys={[
          "onlyOneUncollapsedSection",
          "disableDiagnoses",
          "disableNotes",
          "disableFindings",
          "disableBookmarks",
          "disableLabValues",
        ]}
      />
    </ReasoningToolPanel>
  );
}
