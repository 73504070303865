import * as React from "react";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

interface DocEditHeaderSelectInputProps {
  contentKey: string;
  default: string;
  disableMetadataEdit: boolean;
  editingContext: EditingContextInEditor;
  label: string;
  options: Array<string>;
  uid: string;
  value: string;
};

export default function DocEditHeaderSelectInput(props: DocEditHeaderSelectInputProps) {
  const value = props.value || props.default || props.options[0];

  const options = props.options.map((optionName) => {
    return <option key={optionName} value={optionName}>{optionName}</option>;
  });

  return (
    <div className="doc-edit-header-input doc-edit-header-select-input">
      <label>{props.label}</label>
      <div className="doc-edit-header-select-input-summary">
        <select value={value} disabled={props.disableMetadataEdit} onChange={(event) => {
          props.editingContext.addChange(newSetKeyValueChange(props.uid, props.contentKey, event.target.value));
        }}>
          {options}
        </select>
      </div>
    </div>
  );
}
