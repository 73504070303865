import * as React from "react";

import GenModal from "../Gen/Modal/component";

import DocEditBarFindAndReplaceControls from "./EditBar/FindAndReplaceControls";
import DocEditBarNodeGeneralControls from "./EditBar/NodeGeneralControls";
import DocEditBarTopControls from "./EditBar/TopControls";
import DocEditBarMediaManager from "./EditBar/MediaManager";
import DocEditMediaPicker from "./EditBar/MediaPicker";

import { FIND_AND_REPLACE } from "../../helpers/Doc/menu";

interface DocEditBarProps {
  currentVersion: CurrentVersion;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
  editingContext: EditingContext;
  editingContextExtra: EditingContextExtra;
  mappingContext: MappingContext;
  projectContext: ProjectContext;
  mediaContext: MediaContext;
  mergedData: MergedData;
  userContext: UserContext;
  featureFlag: FeatureFlag;
};

export default function DocEditBar(props: DocEditBarProps) {
  const {
    editingContext,
  } = props;

  let editBarSideNavChanges = props.featureFlag.new_landing_pages ? {marginLeft: "10px"} : {}
  if (!editingContext.shouldShowEditor) {
    return (
      <div className="edit-bar">
        <DocEditBarTopControls
          {...props}
        />
      </div>
    );
  } else if (props.editingContextExtra.currentMenu?.menu === FIND_AND_REPLACE) {
    return (
      <div className="edit-bar">
        <DocEditBarFindAndReplaceControls
          {...props}
          editingContext={editingContext}
        />
      </div>
    );
  } else {
    return (
      <>
        <GenModal open={props.editingContextExtra.savingErrors.length > 0}>
          <div className="gen-modal-panel">
            <p>{props.editingContextExtra.savingErrors}</p>
          </div>
        </GenModal>
        <DocEditBarMediaManager
          {...props}
          editingContext={editingContext}
        />
        <DocEditMediaPicker
          {...props}
          editingContext={editingContext}
        />
        <div className="edit-bar" style={editBarSideNavChanges}>
          <DocEditBarTopControls
            {...props}
          />
          <DocEditBarNodeGeneralControls
            {...props}
          />
        </div>
      </>
    );
  }
}
