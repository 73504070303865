import * as React from "react";

import DocAuthorHeader from "../AuthorHeader";

import { scrollToLineage } from "../../../helpers/Doc/scrollTo";

import { newDeleteSummaryOfEditChange } from "../../../helpers/Doc/changes";

interface ReasoningToolSummaryOfEditProps {
  userContext: UserContext;
  editingContext: EditingContextInEditor;
  summaryOfEdit: AddSummaryOfEditClothedChange;
  summaryOfEditLineage: Array<DocBlockNode<InlineNode>>;
};

export default function ReasoningToolSummaryOfEdit(props: ReasoningToolSummaryOfEditProps) {
  const {
    userContext,
    editingContext,
    summaryOfEdit,
    summaryOfEditLineage,
  } = props;

  const onClick = () => {
    if (summaryOfEditLineage) {
      scrollToLineage(summaryOfEditLineage, userContext.addState);
    }
  };

  return (
    <div className="reasoning-tool-summary-of-edit" onClick={onClick}>
      <DocAuthorHeader
        authorName={summaryOfEdit.author_full_name}
        colorArray={editingContext.colorLookup[summaryOfEdit.author_id]}
        createdAt={summaryOfEdit.created_at}
      >
        {(editingContext.shouldShowEditor || summaryOfEdit.author_id === userContext.id) && <i style={{marginRight: "5px"}} className="fa fa-trash" onClick={(e) => {
          e.stopPropagation();
          editingContext.addChange(newDeleteSummaryOfEditChange(summaryOfEdit.summary_of_edit_id));
        }}></i>}
      </DocAuthorHeader>
      <p>{summaryOfEdit.text}</p>
    </div>
  );
}
