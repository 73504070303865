import * as React from "react";

import * as classNames from "classnames";

import DocStyledText from "../BlockNodeHelpers/StyledText";

import { newSetKeyValueChange, backgroundStyleFromNode } from "../../../helpers/Doc/changes";

interface DocMultipleChoiceProps extends DocMultipleChoiceType<InlineNode>, BlockNodeAll {};

function DocMultipleChoice(props: DocMultipleChoiceProps) {
  const {
    editingContext,
  } = props;

  const classes = classNames("doc-multiple-choice", {inactive: props.inactiveBool, graded: props.graded});

  const answers = props.content.map((answer, index) => {
    const includeInAllNodes = {
      key: answer.uid,
      simple: true,
      documentContext: props.documentContext,
      currentVersion: props.currentVersion,
      editingContext: editingContext,
      userContext: props.userContext,
    };

    const answerOnClick = () => {
      if (!props.inactiveBool) {
        let uscs = {
          [answer.uid]: {value: !answer.value},
        };

        if (!props.multipleSelect) {
          const newUscs = props.content.filter( (ans) => {
            return (ans.value && (ans.uid !== answer.uid));
          }).reduce((acc, ans) => {
            return ({ ...acc,
              [ans.uid]: {value: false},
            });
          }, {});

          uscs = {...uscs, ...newUscs};
        }

        props.userContext.addState(uscs);
      }
    };

    let gradedInfo = null;
    if (props.graded) {
      gradedInfo = (
        <div className="doc-multiple-choice-answer-graded-info">
          {answer.answer && <span className={classNames("icon-check-circle", "correct")}></span>}
        </div>
      );
    }

    return (
      <li
        key={answer.uid}
        id={answer.uid}
        className={classNames("doc-multiple-choice-answer", answer.uid, {checked: answer.value})}
        onClick={answerOnClick}
        style={backgroundStyleFromNode({...includeInAllNodes, ...answer})}
      >
        {editingContext.isEditing && <input type="checkbox" defaultChecked={answer.answer} onClick={(e) => {
          editingContext.addChange(newSetKeyValueChange(answer.uid, "answer", !answer.answer));
          e.stopPropagation();
        }} />}
        {gradedInfo}
        <span className="doc-multiple-choice-answer-outer"></span>
        <span className="doc-multiple-choice-answer-title">
          <DocStyledText
            uid={answer.uid}
            content={answer.content}
            contentKey={"content"}
            placeholder={"Enter Multiple Choice text here"}
            editingContext={editingContext}
            independentlyEditable
            disableStyleTextEditing={props.disableStyleTextEditing}
          />
        </span>
      </li>
    );
  });


  let gradedFeedback = null;
  if (props.graded) {
    const isAllYes = props.content.map((answer) => {
      return (answer.answer);
    }).reduce((a, b) => a && b);

    const isAllNo = props.content.map((answer) => {
      return (!answer.answer);
    }).reduce((a, b) => a && b);

    const isEachCorrect = props.content.filter((answer) => {
      return (answer.value || answer.answer);
    }).map((answer) => {
      return (answer.value === answer.answer);
    });

    const isSingleCorrect = (props.content.map((answer) => {
      return (answer.answer);
    }).filter((x) => x).length === 1);

    if (isAllNo) {
      gradedFeedback = <p key="graded-feedback-no-single-best" className="graded-feedback">There is no single best answer.</p>;
    } else if (isSingleCorrect) {
      gradedFeedback = <p key="graded-feedback-one-option" className="graded-feedback">The best option is indicated below. Your selections are indicated by the shaded boxes.</p>;
    } else {
      gradedFeedback = <p key="graded-feedback-multiple-options" className="graded-feedback">The best options are indicated below. Your selections are indicated by the shaded boxes.</p>;
    }
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-multiple-choice-wrapper", props.uid)}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    >
      {gradedFeedback}
      <ul className={classes}>
        {answers}
      </ul>
    </div>
  );
}

export default React.memo(DocMultipleChoice);
