import * as React from 'react';

import * as classNames from 'classnames';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  outline?: boolean
}

export default function AqButton(props: Props): React.ReactElement {
  const {
    disabled = false,
    outline = false,
    children,
    onClick,
    className,
  } = props;

  return (
    <button
      className={classNames('aq-button-2', { outline }, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}