import { batch } from "react-redux";

import {
  newAddNodeChange,
  newChangeTextAndNodesChange,
} from "../changes";

import { getSubType } from "../Define/base";

import * as selection from "../selection";

import * as R from "ramda";

import {
  findNodePathByUid,
  findNodeAtPath,
  nodeInlineNotFlowContentKeys,
  nodeParentPathFromPath,
} from "../Functions/base";

import {
  blockNodeFlowContentKey,
  flowInlineKeys,
  flowBlockKeys,
} from "./shared";

import { convertAllInlineNodesToRangeJSON } from '../Convert/RangeInlineJSON'

import * as rangeInlineJSON from "../Functions/RangeInlineJSON";

export default function returnAtSelection({
  data,
  variantDataById,
  currentMenu,
  addChanges,
  setCurrentDocSelection,
  nodeDefinitions,
}: EditActionContext) {
  let currentDocSelection = selection.getDocSelection();

  if (!currentDocSelection || currentMenu) { return false; }

  const dataToUse = currentDocSelection.variantId ? variantDataById[currentDocSelection.variantId] : data;

  const {
    uid: startUID,
    contentKey: startKey,
    index: startIndex,
  } = currentDocSelection.start;

  const startNodePath = findNodePathByUid(dataToUse, startUID);
  const startNode = findNodeAtPath(dataToUse, startNodePath);

  const startParentNodePath = nodeParentPathFromPath(dataToUse, startNodePath);
  const startParentNode = findNodeAtPath(dataToUse, startParentNodePath);

  const newNodes = [convertAllInlineNodesToRangeJSON(newNodeOnReturn(startNode, startParentNode, nodeDefinitions))]

  const selectionNode = findFirstInlineFlowNode(R.last(newNodes));
  const newSelection = selectionNode ? selection.newCollapsedDocSelection(selectionNode.uid, blockNodeFlowContentKey(selectionNode), 0) : undefined

  if (nodeInlineNotFlowContentKeys(startNode).includes(startKey)) {
    const newKey = R.nth(-2, startNodePath);
    const oldIndex: number = R.nth(-1, startNodePath);
    const newIndex = oldIndex + 1;

    batch(() => {
      addChanges([newAddNodeChange(R.last(newNodes), startParentNode.uid, newKey, newIndex)], currentDocSelection.variantId);
      setCurrentDocSelection(newSelection);
    });
  } else {
    // EXCEPTION TO ALLOW LIST ITEMS TO KEEP UID WHEN RETURN AT START
    if (
      selection.isCollapsed(currentDocSelection) &&
      startIndex === 0 &&
      startParentNode?.type === "listItem" &&
      newNodes.length === 1 &&
      newNodes[0]?.type === "listItem"
    ) {
      currentDocSelection = selection.shiftDocSelectionBy(currentDocSelection, -2)
    }

    batch(() => {
      addChanges([newChangeTextAndNodesChange(currentDocSelection, undefined, newNodes)], currentDocSelection.variantId);
      setCurrentDocSelection(newSelection);
    });
  }

  return true;
}

function newNodeOnReturn(node: DocBlockNode<unknown>, parent, nodeDefinitions: NodeDefinitions) {
  let newNodeType;
  if (parent?.type === "listItem") {
    newNodeType = "listItem"
  } else if(node?.type === "button") {
    newNodeType = "paragraph"
  } else {
    newNodeType = getSubType(node);
  }

  const newNode = nodeDefinitions[newNodeType].generate(nodeDefinitions, {});

  if (newNode.type === "listItem") {
    newNode.listUid = parent.listUid;
    newNode.listIndentation = parent.listIndentation;
  }

  return newNode;
}

function findFirstInlineFlowNode(node: DocBlockNode<InlineNode>) {
  const inlineKeys = flowInlineKeys(node);

  if (inlineKeys.length > 0) {
    return node;
  }

  const blockKeys = flowBlockKeys(node);

  if (blockKeys.length > 0) {
    const childNode = node[blockKeys[0]][0]

    if (childNode) {
      return findFirstInlineFlowNode(childNode)
    }
  }

  return null
}
