import * as React from "react";
import { batch } from "react-redux";

import { findNodeLineageByUid } from "../../../helpers/Doc/Functions/base";
import { scrollToLineage } from "../../../helpers/Doc/scrollTo";
import { findAllSelectionsByText } from "../../../helpers/Doc/find";
import { newChangeTextAndNodesChange } from "../../../helpers/Doc/changes";
import { FIND_AND_REPLACE } from "../../../helpers/Doc/menu";
import { full_url } from "../../../helpers/url";

import GenButton from "../../Gen/Button/component";

interface DocEditBarFindAndReplaceControlsProps {
  userContext: UserContext;
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  mergedData: MergedData;
};

export default function DocEditBarFindAndReplaceControls(props: DocEditBarFindAndReplaceControlsProps) {
  const {
    userContext,
    editingContext,
    editingContextExtra,
    mergedData,
  } = props;

  const currentMenu = editingContextExtra.currentMenu;
  if (currentMenu?.menu !== "FIND_AND_REPLACE") { return }

  const findScope = currentMenu.findScope || "document"
  const findText = editingContextExtra.findText || "";
  const replaceText = editingContextExtra.replaceText || "";
  const isFindButtonDisabled = findText.length < 3 || (currentMenu.findMatches?.length === 0);
  const isReplaceButtonDisabled = !currentMenu.findMatches || currentMenu.findMatches.length === 0;

  const handleFindScopeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    editingContext.setCurrentMenu(FIND_AND_REPLACE, {findScope: event.target.value});
  }

  const handleFind = () => {
    switch(findScope){
      case "global" :
        fetch(full_url(`/versions/global_search.csv?search_text=${encodeURIComponent(findText)}`))
        .then(response => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error('Error');
          }
        })
        .then(data => {
          const globalSearchResultCount = data.match(/(?:"(?:[^"]|"")*"|[^,\n]*)(?:,(?:"(?:[^"]|"")*"|[^,\n]*))*\n/g).length - 1;
          if (globalSearchResultCount > 0) {
            const csv = data;
            const csvFile = new Blob([csv], { type: 'text/csv' });
            const downloadLink = document.createElement("a");
            downloadLink.download = "Search Result.csv";
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            alert("Check exported search result");
          }
          editingContext.setCurrentMenu(FIND_AND_REPLACE, { findScope: "global", resultCount: globalSearchResultCount });
        })
        .catch(error => {
          alert("Error");
          console.log(error);
        });
        break;

      case "document" :
        const newFindMatches = !currentMenu.findMatches
          ? findAllSelectionsByText(mergedData, findText)
          : currentMenu.findMatches.slice(1).concat([currentMenu.findMatches[0]]);

        if (newFindMatches.length > 0) {
          const firstMatch = newFindMatches[0];
          editingContext.setCurrentDocSelection(firstMatch);

          const lineage = findNodeLineageByUid(mergedData, firstMatch.start.uid);
          if (lineage) {
            scrollToLineage(lineage, userContext.addState);
          }
        } else {
          alert("No matches found");
        }
        editingContext.setCurrentMenu(FIND_AND_REPLACE, {findMatches: newFindMatches});
    }
  }

  return (
    <div className="edit-bar-control-bar doc-edit-bar-find-and-replace-controls">
      <GenButton
        name={<i className="fa fa-times"></i>}
        onClick={() => editingContext.closeCurrentMenu()}
        small
      />
      <div className="edit-bar-control-bar-divider"></div>
      <select onChange={handleFindScopeChange.bind(this)} style={{marginTop: "20px",height: "35px"}} value={findScope}>
        <option value="">Find within...</option>
        <option value={"document"}>This document</option>
        <option value={"global"}>All courses</option>
      </select>
      <input
        type="text"
        className="find-and-replace-input"
        value={findText}
        onChange={(e) => {
          batch(() => {
            editingContextExtra.setFindText(e.target.value);
            if (currentMenu.findMatches) {
              editingContext.setCurrentMenu(FIND_AND_REPLACE);
            }
          });
        }}
      />
      <GenButton
        name="Find"
        small
        disabled={isFindButtonDisabled}
        onClick={handleFind}
      />
      {
        findScope==="document" ?
          <div>{currentMenu.findMatches && <p style={{margin: "10px"}}>{currentMenu.findMatches.length > 0 ? `${currentMenu.findMatches.length} Found` : "None Found"}</p>}</div> :
          <div>{currentMenu.resultCount!=undefined && <p style={{margin: "10px"}}>{currentMenu.resultCount > 0 ? `${currentMenu.resultCount} Found` : "None Found"}</p>}</div>
      }
      <div className="edit-bar-control-bar-divider"></div>
      {findScope==="document" && <>
        <input
          type="text"
          className="find-and-replace-input"
          value={replaceText}
          onChange={(e) => editingContextExtra.setReplaceText(e.target.value)}
        />
        <GenButton
          name="Replace"
          small
          disabled={isReplaceButtonDisabled}
          onClick={() => {
            const toReplaceSelection = currentMenu.findMatches[0];

            const replaceStyledText = {
              text: replaceText,
              styleRanges: []
            }

            editingContext.addChange(newChangeTextAndNodesChange(toReplaceSelection, replaceStyledText));

            const newFindMatches = currentMenu.findMatches.slice(1);

            if (newFindMatches.length > 0) {
              const firstMatch = newFindMatches[0];
              editingContext.setCurrentDocSelection(firstMatch);

              const lineage = findNodeLineageByUid(mergedData, firstMatch.start.uid);
              if (lineage) {scrollToLineage(lineage, userContext.addState);}
            } else {
              alert("No more matches");
            }

            editingContext.setCurrentMenu(FIND_AND_REPLACE, {findMatches: newFindMatches});
          }}
        />
        <GenButton
          name="Replace All"
          small
          disabled={isReplaceButtonDisabled}
          onClick={() => {
            const allMatches = currentMenu.findMatches;

            const replaceStyledText = {
              text: replaceText,
              styleRanges: []
            }

            editingContext.addChanges(allMatches.map((toReplaceSelection) => newChangeTextAndNodesChange(toReplaceSelection, replaceStyledText)));

            alert("Replaced all.");

            editingContext.setCurrentMenu(FIND_AND_REPLACE, { findMatches: [] });
          }}
        />
      </>}
    </div>
  );
}
