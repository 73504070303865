import * as React from "react";

import * as classNames from "classnames";

import { range, compact, flatten, zip } from "lodash";

import DocBookmark from "../BlockNodeHelpers/Bookmark";
import DocNote from "../BlockNodeHelpers/Note";
import DocImage from "./Image";
import Carousel from 'react-grid-carousel'
import { newAddNodeChange, backgroundStyleFromNode } from "../../../helpers/Doc/changes";

import EmptyImageGalleryImage from "images/EmptyImageGallery.png";

interface DocImageGalleryProps extends DocImageGalleryType<InlineNode>, BlockNodeAll {};

function DocImageGallery(props: DocImageGalleryProps) {
  const {
    currentVersion,
    displayContext,
    documentContext,
    editingContext,
    featureFlag,
    images,
    mediaContext,
    uid,
    userContext,
  } = props;

  let isEditing = editingContext.isEditing
  let renderedImages = images.map((image, index) => {
    const includeInAllNodes = {
      key: image.uid,
      documentContext: documentContext,
      currentVersion: currentVersion,
      editingContext: editingContext,
      userContext: userContext,
      mediaContext: mediaContext,
      displayContext: displayContext,
      featureFlag: featureFlag,
    };

    return (
      <div className="doc-image-gallery-slide" key={`doc-image-gallery-image-${image.uid}`}>
        <DocImage
          {...image}
          {...includeInAllNodes}
          inGallery
          simple
        />
      </div>
    );
  });

  if (editingContext.isEditing) {
    if (renderedImages.length === 0) {
      renderedImages = [
        <div className="doc-image-gallery-slide" key="doc-image-gallery-image-0">
          <img
            src={EmptyImageGalleryImage}
            className="doc-image-gallery-empty-image"
            onClick={() => {
              editingContext.clearCurrentDocSelection();
              editingContext.setCurrentDocSelection({start: {uid: uid, index: 0}, end: {uid: uid, index: 0}})
            }}
          />
        </div>
      ]
    }

    const addImageButtons = range(0, renderedImages.length + 1).map((imageIndex) => {
      return (
        <div
          key={`doc-image-gallery-add-image-${imageIndex}`}
          className="doc-image-gallery-slide add-image-button"
        >
          <div
            key="doc-image-gallery-add-image"
            className="doc-image-gallery-add-image"
            onClick={() => {
              const newNode = editingContext.nodeDefinitions["image"].generate(editingContext.nodeDefinitions, {});
              editingContext.addChange(newAddNodeChange(newNode, uid, "images", imageIndex));
            }}
          ><i className="fa fa-plus"></i></div>
        </div>
      );
    });

    renderedImages = compact(flatten(zip(addImageButtons, renderedImages)));
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-image-gallery", uid)}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    >
      <div className="doc-image-gallery-scroller">
        <Carousel cols={1} rows={1} showDots loop>
          {renderedImages.map((image, index) => (
            renderedImages[index].props.className.indexOf("add-image-button") === -1 &&
            <Carousel.Item key={index}>
              { isEditing &&
                <div className="doc-image-gallery-add-image-buttons-container">
                  {renderedImages[index-1]}
                  {renderedImages[index+1]}
                </div>
              }
              {renderedImages[index]}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <DocBookmark {...props} />
      <DocNote {...props} />
    </div>
  );
}

export default React.memo(DocImageGallery);
