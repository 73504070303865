import * as React from 'react';

import * as classNames from "classnames";

import Confetti from 'react-confetti';

import GreenCheckImage from "images/completed-check.png";

import AqButton from "../../Aq/Button"

import { handleActions } from "../../../helpers/Doc/handleActions";

interface RatingStarProps {
  maxValue: number;
  onClick: (i: number) => void;
  value: number;
}

function RatingStars(props: RatingStarProps) {
  const {
    maxValue,
    onClick,
    value,
  } = props;

  const stars = [];
  for (let i = 0; i < maxValue; i += 1) {
    const className = classNames("doc-rating-bar-star", {"doc-rating-bar-star-active": (i >= maxValue - value)});
    stars.push(<span className={className} key={i} onClick={() => onClick(maxValue - i)}>☆</span>);
  }

  return (
    <div className={"doc-rating-bar"}>
      {stars}
    </div>
  )
}

interface DocFeedbackModalProps {
  displayContext: DisplayContext;
  userContext: UserContext;
  onClick: Array<DocAction>;
};

export default function DocFeedbackModal(props: DocFeedbackModalProps) {
  const {
    displayContext,
    userContext,
    onClick,
  } = props;

  const [rating1, setRating1] = React.useState(0)
  const [rating2, setRating2] = React.useState(0)
  const [comment, setComment] = React.useState("")
  const [showConfetti, setShowConfetti] = React.useState(false)

  let modal
  if (showConfetti) {
    modal = (
      <>
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ position: 'fixed', top: 0, left: 0 }}
        />
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex: 1000
        }}>
          <div style={{fontFamily: "Roboto Condensed",fontSize: "24px",fontWeight: "700"}}>
            Excellent work!
          </div>
          <div style={{fontFamily: "Roboto Condensed",fontSize: "24px",fontWeight: "400"}}>
            This case is now complete.
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={GreenCheckImage} alt="Green Check Mark" />
          </div>
        </div>
      </>
    )
  } else {
    modal = (
      <div className="feedback-modal">
        <div className="doc-section-body">
          <p className="feedback-modal-heading">You’re almost done...</p>

          <p className="feedback-modal-subheading">GIVE US YOUR FEEDBACK TO COMPLETE THIS CASE</p>

          <div className="feedback-modal-blue-separator"></div>

          <p className="feedback-modal-description-heading">We want to know what you think.</p>

          <p className="feedback-modal-description">
            Your rating and optional comment will be reviewed by Aquifer staff and shared with the educators who regularly review this case.
          </p>

          <p style={{fontSize: "16px", fontWeight: 700, fontFamily: "Roboto", marginLeft: "30px"}}>This case was a valuable use of my time.</p>

          <div style={{marginLeft: "45px"}}>
            <RatingStars
              maxValue={5}
              value={rating1}
              onClick={setRating1}
            />
          </div>

          <p style={{fontSize: "16px", fontWeight: 700, fontFamily: "Roboto", marginLeft: "30px"}}>I will apply what I learned to patient care.</p>

          <div style={{marginLeft: "45px"}}>
            <RatingStars
              maxValue={5}
              value={rating2}
              onClick={setRating2}
            />
          </div>

          <p
            style={{
              fontWeight: 700,
              fontSize: "12px",
              fontFamily: "Roboto Condensed",
              color: "black"
            }}
          >Please note any comments, suggestions, or questions related to the case (optional):</p>

          <textarea
            cols={40}
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          <AqButton
            disabled={rating1 < 1 || rating2 < 1}
            onClick={() => {
              setShowConfetti(true)

              userContext.addState({
                feedback_card_1_rating_bar: {value: rating1},
                feedback_card_2_rating_bar: {value: rating2},
              });

              if (comment !== "") {
                userContext.addState({
                  feedback_card_1_text_input_section: {value: comment},
                });
              }

              setTimeout(() => {
                const handleActionsArgs = {
                  displayContext,
                  userContext,
                }

                setShowConfetti(false);
                handleActions(onClick, handleActionsArgs);
              }, 3000); // 3 seconds
            }}
          >
            Submit & Finish Case
          </AqButton>
        </div>
      </div>
    )
  }

  return (
    <div className="feedback-modal-background">
      {modal}
    </div>
  )
}
