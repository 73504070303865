import {
  assign,
  flatten,
  isObject,
  isPlainObject,
  reduce,
} from "lodash";

import * as R from "ramda";

import generateUUID from "../../generateUUID";
import { isRangeInlineJSON } from "./RangeInlineJSON";

type ObjTester = (data: any) => boolean; // TODO: Remove any
type NodeTester = (node: DocBlockNode<unknown>) => boolean;

export function deepFind(data, tester: ObjTester) {
  let result = null;
  if (data instanceof Array) {
    for (let i = 0; i < data.length; i += 1) {
      result = deepFind(data[i], tester);
      if (result) {
        return result;
      }
    }
  } else if (data instanceof Object) {
    if (tester(data)) {
      return data;
    }

    for (const prop in data) {
      if (data[prop] instanceof Object || data[prop] instanceof Array) {
        result = deepFind(data[prop], tester);
        if (result) {
          return result;
        }
      }
    }
  }
  return result;
}

export function deepFindObjectPath(data, tester: ObjTester) {
  if (data instanceof Array) {
    for (let i = 0; i < data.length; i += 1) {
      const result = deepFindObjectPath(data[i], tester);
      if (result) {
        return result;
      }
    }
  } else {
    if (tester(data)) {
      return [data];
    }

    for (const prop in data) {
      if ((data[prop] instanceof Object || data[prop] instanceof Array) && prop !== "contentListNodes") {
        const result = deepFindObjectPath(data[prop], tester);
        if (result) {
          return ([data].concat(result));
        }
      }
    }
  }
  return null;
}

export function deepFindAllObjectPaths(data, tester: ObjTester) {
  if (data instanceof Array) {
    return data.flatMap((datum) => deepFindAllObjectPaths(datum, tester));
  } else {
    let objectPaths = Object.entries(data)
      .filter(([key, datum]) => (datum instanceof Object || datum instanceof Array) && key !== "contentListNodes")
      .flatMap(([key, datum]) => deepFindAllObjectPaths(datum, tester))
      .map((path) => [data].concat(path));

    if (tester(data)) {
      objectPaths = [[data]].concat(objectPaths);
    }

    return objectPaths;
  }
}

export function deepFindPath(data, tester: ObjTester) {
  if (data instanceof Array) {
    for (let i = 0; i < data.length; i += 1) {
      const result = deepFindPath(data[i], tester);
      if (result) {
        return [i].concat(result);
      }
    }
  } else {
    if (tester(data)) {
      return [];
    }

    for (const prop in data) {
      if (data[prop] instanceof Object || data[prop] instanceof Array) {
        const result = deepFindPath(data[prop], tester);
        if (result) {
          return ([prop].concat(result));
        }
      }
    }
  }
  return null;
}

export function deepFindAllPaths(data, tester: ObjTester) {
  if (data instanceof Array) {
    return data.flatMap((datum, index) => deepFindAllPaths(datum, tester).map((indexPaths) => [index].concat(indexPaths)));
  } else {
    let indexPaths = Object.entries(data)
      .filter(([key, datum]) => (datum instanceof Object || datum instanceof Array))
      .flatMap(([key, datum], index) => deepFindAllPaths(datum, tester).map((indexPaths) => [key].concat(indexPaths)));

    if (tester(data)) {
      indexPaths = ([[]]).concat(indexPaths);
    }

    return indexPaths;
  }
  return [];
}


export function deepFindAll(data, tester: ObjTester) {
  let result = [];
  if (data instanceof Array) {
    return data.reduce((acc, curData) => acc.concat(deepFindAll(curData, tester)), []);
  } else if (data instanceof Object) {
    if (tester(data)) {
      result.push(data);
    }

    for (const prop in data) {
      if (data[prop] instanceof Object || data[prop] instanceof Array) {
        const innerResult = deepFindAll(data[prop], tester);
        if (innerResult) {
          result = result.concat(innerResult);
        }
      }
    }
  }
  return result;
}

export function findAtPath(data, indexPath) {
  return R.view(R.lensPath(indexPath), data);
}

export function filterPathsByFunction(data, paths, func) {
  return paths.filter((path) => func(R.view(R.lensPath(path), data)));
}

export function findCommonAncestorPath(pathA, pathB) {
  return R.takeWhile(([a, b]: any) => R.equals(a,b), R.zip(pathA, pathB)).flatMap(R.init); // TODO: Remove any
}

export function isPathChildOfPath(path, possibleAncestorPath) {
  return path.length > possibleAncestorPath.length && R.equals(R.take(possibleAncestorPath.length, path), possibleAncestorPath);
}

////////////////////////////////////////
////////////////////////////////////////

export function normalizeDocNode(node) {
  const toNormalizeKeys = nodeBlockContentKeys(node);

  const toNormalizeChildren = flatten(toNormalizeKeys.map((key) => {
    return node[key] || [];
  }));

  const newNode = {...node};
  toNormalizeKeys.forEach((key) => {
    if (newNode[key]) {
      newNode[key] = newNode[key].map((childNode) => childNode.uid);
    }
  });

  return assign({[node.uid]: newNode}, ...(toNormalizeChildren.map((childNode) => {
    return normalizeDocNode(childNode);
  })));
}

// export function normalizeDocNodeWithAncestorUids(node, ancestorUids = []) {
//   const toNormalizeKeys = nodeBlockContentKeys(node);

//   const toNormalizeChildren = flatten(toNormalizeKeys.map((key) => {
//     return node[key] || [];
//   }));

//   const newNode = {...node,
//     ancestorUids,
//   };
//   toNormalizeKeys.forEach((key) => {
//     if (newNode[key]) {
//       newNode[key] = newNode[key].map((childNode) => childNode.uid);
//     }
//   });

//   return assign({[node.uid]: newNode}, ...(toNormalizeChildren.map((childNode) => {
//     return normalizeDocNodeWithAncestorUids(childNode, concat(ancestorUids, node.uid));
//   })));
// }

export function denormalizeDocNode(nodesObject, rootNodeUid, ancestorUids = []) {
  if (ancestorUids.includes(rootNodeUid)) {
    console.log(`Error: circular node nesting of node with UID: ${rootNodeUid}`);
    return null;
  }

  const newNode = nodesObject[rootNodeUid] && {...nodesObject[rootNodeUid]};

  if (!newNode) {
    console.log(`Error: no node with UID: ${rootNodeUid}`);
    return null;
  }

  const toDenormalizeKeys = nodeBlockContentKeys(newNode);

  toDenormalizeKeys.forEach((key) => {
    if (newNode[key]) {
      newNode[key] = newNode[key].map((childNodeUid) => {
        return denormalizeDocNode(nodesObject, childNodeUid, [...ancestorUids, rootNodeUid]);
      });
    }
  });

  return newNode;
}


export function nodeBlockContentKeys<T>(node: DocBlockNode<T>) {
  switch (node.type) {
    case "section":
      return ["content", "diagnoses", "findings", "notes", "feedback", "cdqFeatures"];
    case "regularList":
    case "numberedList":
    case "multipleChoice":
    case "cdqQuestion":
    case "tableCell":
    case "learningObjectivesList":
      return ["content"];
    case "table":
      return ["content", "rows"];
    case "tableRow":
      return ["content", "cells"];
    case "imageGallery":
      return ["images"];
    case "categoryMatcher":
      return ["contentCategories", "contentItems"];
    case "listItem":
      return !isObject(node.content) || isRangeInlineJSON(node.content as any) ? [] : ["content"]; // TODO: Remove any
    default:
      return [];
  }
}

export function nodeInlineContentKeys<T>(node: DocBlockNode<T>) {
  switch (node.type) {
    case "section":
    case "image":
    case "video":
    case "audio":
    case "button":
      return ["title"];
    case "paragraph":
    case "multipleChoiceAnswer":
    case "checkbox":
    case "matchItem":
    case "cdqQuestionAnswer":
    case "cdqFeature":
    case "learningObjective":
      return ["content"];
    case "listItem":
      return !isObject(node.content) || isRangeInlineJSON(node.content as any) ? ["content"] : []; // TODO: Remove any
    default:
      return [];
  }
}

export function nodeInlineInFlowContentKeys<T>(node: DocBlockNode<T>) {
  switch (node.type) {
    case "paragraph":
      return ["content"];
    case "listItem":
      return !isObject(node.content) || isRangeInlineJSON(node.content as any) ? ["content"] : []; // TODO: Remove any
    case "section":
    case "image":
    case "video":
    case "audio":
    case "button":
    case "multipleChoiceAnswer":
    case "checkbox":
    case "matchItem":
    case "cdqQuestionAnswer":
    default:
      return [];
  }
}

export function nodeInlineNotFlowContentKeys<T>(node: DocBlockNode<T>) {
  switch (node.type) {
    case "section":
    case "image":
    case "video":
    case "audio":
    case "button":
      return ["title"];
    case "multipleChoiceAnswer":
    case "checkbox":
    case "matchItem":
    case "cdqQuestionAnswer":
    case "cdqFeature":
    case "learningObjective":
      return ["content"];
    case "paragraph":
    case "listItem":
    default:
      return [];
  }
}

export function nodeAllContentKeys<T>(node: DocBlockNode<T>) {
  return nodeInlineContentKeys(node).concat(nodeBlockContentKeys(node));
}

export function isNode<T>(node: any): node is DocBlockNode<T> {
  return isPlainObject(node) && node["type"] && node["uid"] && !node["change_id"] && !node.is_change_summary;
}

export function isNodeAtPath(doc: DocBlockNode<unknown>, nodePath: NodePath) {
  return isNode(findNodeAtPath(doc, nodePath));
}

export function findDeepestLineagePathFromPath<T>(rootNode: DocBlockNode<T>, path, nodeTester: NodeTester) {
  const node = findAtPath(rootNode, path);
  if (isNode(node) && nodeTester(node)) {
    return path;
  } else if (path.length > 0) {
    return findDeepestLineagePathFromPath(rootNode, R.init(path), nodeTester);
  } else {
    return null;
  }
}

////////////////////////////////////////
////////////////////////////////////////

// findNode : NODE -> (NODE -> Bool) -> NODE
export function findNode<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  return deepFind(rootNode, (node) => isNode(node) && nodeTester(node));
}

// findNodeByUid : NODE -> UID -> NODE
export function findNodeByUid<T>(rootNode: DocBlockNode<T>, uid: UID) {
  return findNode(rootNode, (node) => node["uid"] === uid);
}

//findNodes : NODE -> (NODE -> Bool) -> [NODE]
export function findNodes<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  return deepFindAll(rootNode, (node) => isNode(node) && nodeTester(node));
}

// findNodesByUids : NODE -> [UID] -> [NODE]
export function findNodesByUids<T>(rootNode: DocBlockNode<T>, uids: UID[]) {
  return findNodes(rootNode, (node) => uids.includes(node["uid"]));
}

export function findNodeLineage<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  return deepFindObjectPath(rootNode, (node) => isNode(node) && nodeTester(node));
}

export function findNodeLineageByUid<T>(rootNode: DocBlockNode<T>, uid: UID) {
  return findNodeLineage(rootNode, (node) => node["uid"] === uid);
}

export function findNodesLineages<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  return deepFindAllObjectPaths(rootNode, (node) => isNode(node) && nodeTester(node));
}

export function findNodeAncestors<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  return findNodeLineage(rootNode, nodeTester).slice(0,-1);
}

export function findNodeAncestorsByUid<T>(rootNode: DocBlockNode<T>, uid: UID) {
  return findNodeAncestors(rootNode, (node) => node["uid"] === uid);
}

export function findNodeParent<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  const ancestors = findNodeAncestors(rootNode, nodeTester);
  return (ancestors && ancestors[ancestors.length - 1]);
}

export function findNodeParentByUid<T>(rootNode: DocBlockNode<T>, uid: UID) {
  const ancestors = findNodeAncestorsByUid(rootNode, uid);
  return (ancestors && ancestors[ancestors.length - 1]);
}

export function findNodeSiblingBeforeByPath<T>(rootNode: DocBlockNode<T>, nodePath: Array<any>) { // TODO: Remove any
  if (typeof R.last(nodePath) === "number" && R.last(nodePath) > 0) {
    const siblingPath = R.adjust(-1, R.dec, nodePath);
    return findNodeAtPath(rootNode, siblingPath);
  } else {
    return undefined;
  }
}

export function findNodeSiblingBefore<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  const nodePath = findNodePath(rootNode, nodeTester);
  return findNodeSiblingBeforeByPath(rootNode, nodePath);
}

export function findNodeSiblingAfterPathByPath<T>(rootNode: DocBlockNode<T>, nodePath) {
  if (typeof R.last(nodePath) === "number") {
    return R.adjust(-1, R.inc, nodePath);
  } else {
    return undefined;
  }
}

export function findNodeSiblingAfterByPath<T>(rootNode: DocBlockNode<T>, path: NodePath) {
  const siblingPath = findNodeSiblingAfterPathByPath(rootNode, path);
  return siblingPath && findNodeAtPath(rootNode, siblingPath);
}

export function findNodeSiblingAfter<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  const nodePath = findNodePath(rootNode, nodeTester);
  return findNodeSiblingAfterByPath(rootNode, nodePath);
}

export function findNodePath<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  return deepFindPath(rootNode, (node) => isNode(node) && nodeTester(node));
}

export function findNodePathByUid<T>(rootNode: DocBlockNode<T>, uid: UID) {
  return findNodePath(rootNode, (node) => node["uid"] === uid);
}

export function findNodesPaths<T>(rootNode: DocBlockNode<T>, nodeTester: NodeTester) {
  return deepFindAllPaths(rootNode, (node) => isNode(node) && nodeTester(node));
}

export function findNodeAtPath<T>(rootNode: DocBlockNode<T>, path: NodePath) {
  return R.view(R.lensPath(path), rootNode);
}

function youngestNodePathFromPath<T>(rootNode: DocBlockNode<T>, path: NodePath) : NodePath | undefined {
  if (isNodeAtPath(rootNode, path)) {
    return path;
  } else {
    return path.length > 0 ? youngestNodePathFromPath(rootNode, R.init(path)) : undefined;
  }
}

export function nodeParentPathFromPath<T>(rootNode: DocBlockNode<T>, path: NodePath) {
  return youngestNodePathFromPath(rootNode, R.init(path))
}

export function nodeIndexFromPath<T>(rootNode: DocBlockNode<T>, path: NodePath) {
  const parentPath = nodeParentPathFromPath(rootNode, path);

  const possibleIndex = R.nth(-1, path);
  if (parentPath && parentPath.length + 2 === path.length && (typeof possibleIndex) === "number") {
    return possibleIndex;
  }

  return undefined;
}

export function nodeContentKeyFromPath<T>(rootNode: DocBlockNode<T>, path: NodePath) {
  const parentPath = nodeParentPathFromPath(rootNode, path);

  if (parentPath) {
    if (parentPath.length + 2 === path.length && typeof R.nth(-2, path) === "string") {
      return R.nth(-2, path);
    } else if(parentPath.length + 1 === path.length && typeof R.nth(-1, path) === "string") {
      return R.nth(-1, path);
    }
  }

  return undefined;
}

export function nodePathToUnfilteredLineagePaths<T>(doc: DocBlockNode<T>, nodePath: NodePath) {
  return R.repeat(nodePath, nodePath.length + 1).map((nodePath, index) => R.take(index, nodePath));
}

export function nodePathToLineagePaths<T>(doc: DocBlockNode<T>, nodePath: NodePath) {
  const unfilteredLineagePaths = nodePathToUnfilteredLineagePaths(doc, nodePath);
  return unfilteredLineagePaths.filter((path) => isNodeAtPath(doc, path));
}

export function nodePathToAncestorPaths<T>(doc: DocBlockNode<T>, nodePath: NodePath) {
  return R.init(nodePathToLineagePaths(doc, nodePath));
}

////////////////////////////////////////
////////////////////////////////////////

export function insertAtPath<T>(rootNode: DocBlockNode<T>, newNode: DocBlockNode<T>, indexPath: NodePath) {
  return insertAllAtPath(rootNode, [newNode], indexPath);
}

export function insertAllAtPath<T>(rootNode: DocBlockNode<T>, newNodes: DocBlockNode<T>[], indexPath: NodePath) {
  const arrayPath = R.init(indexPath);
  const index: any = R.last(indexPath); // TODO: Remove any

  const arrayLens = R.lensPath(arrayPath);

  return R.over(arrayLens, R.insertAll(index, newNodes), rootNode);
}

export function deleteAtPath<T>(rootNode: DocBlockNode<T>, nodePath: NodePath) {
  const arrayPath = R.init(nodePath);
  const index: any = R.last(nodePath); // TODO: Remove any

  const arrayLens = R.lensPath(arrayPath);

  return R.over(arrayLens, R.remove(index, 1), rootNode);
}

export function replaceAtPath<T>(rootNode: DocBlockNode<T>, newNode: DocBlockNode<T>, nodePath: NodePath) {
  const arrayPath = R.init(nodePath);
  const index: any = R.last(nodePath); // TODO: Remove any

  const arrayLens = R.lensPath(arrayPath);

  return R.over(arrayLens, R.adjust(index, R.always(newNode)), rootNode);
}

export function moveAtPathToPath<T>(rootNode: DocBlockNode<T>, oldPath: NodePath, newPath: NodePath) {
  const node = findAtPath(rootNode, oldPath);

  return R.pipe(
    R.curry(deleteAtPath(R.__ as any, oldPath)), // TODO: Remove any
    R.curry(insertAtPath(R.__ as any, node, newPath)), // TODO: Remove any
  )(rootNode);
}

export function setAtPath<T>(rootNode: DocBlockNode<T>, nodePath: NodePath, value) {
  return overAtPath(rootNode, nodePath, R.always(value));
}

export function overAtPath<T>(rootNode: DocBlockNode<T>, nodePath: NodePath, func) {
  return R.over(R.lensPath(nodePath), func, rootNode);
}

////////////////////////////////////////
////////////////////////////////////////

// export function addNodesAtUidKeyIndex(rootNode, newNodes, uid, contentKey, index) {
//   const parentPath = findNodePathByUid(rootNode, uid);

//   return insertAllAtPath(rootNode, newNodes, [...parentPath, contentKey, index]);
// }

// export function addNodeAtUidKeyIndex(rootNode, newNode, uid, contentKey, index) {
//   return addNodesAtUidKeyIndex(rootNode, [newNode], uid, contentKey, index);
// }

// export function addNodesAfterUid(rootNode, newNodes, uid) {
//   const siblingPath = findNodePathByUid(rootNode, uid);
//   const afterSiblingPath = R.adjust(-1, R.inc, siblingPath);

//   return insertAllAtPath(rootNode, newNodes, afterSiblingPath);
// }

// export function addNodeAfterUid(rootNode, newNode, uid) {
//   return addNodesAfterUid(rootNode, [newNode], uid);
// }

// export function addNodesBeforeUid(rootNode, newNodes, uid) {
//   const siblingPath = findNodePathByUid(rootNode, uid);

//   return insertAllAtPath(rootNode, newNodes, siblingPath);
// }

// export function addNodeBeforeUid(rootNode, newNode, uid) {
//   return addNodesBeforeUid(rootNode, [newNode], uid);
// }

// export function deleteNodeWithUID(rootNode, uid) {
//   return deleteAtPath(rootNode, findNodePathByUid(rootNode, uid));
// }

// export function replaceNodeWithUID(rootNode, newNode, uid) {
//   return replaceAtPath(rootNode, newNode, findNodePathByUid(rootNode, uid));
// }

// export function replaceNodeWithUIDChildren(rootNode, nodes, uid, contentKey) {
//   const parentPath = findNodePathByUid(rootNode, uid);

//   const arrayPath = [...parentPath, contentKey];
//   const arrayLens = R.lensPath(arrayPath);

//   return R.set(arrayLens, nodes, rootNode);
// }

// export function moveNodeWithUIDToIndex(rootNode, moveUid, newParentUid, contentKey, index) {
//   const oldPath = findNodePathByUid(rootNode, moveUid);
//   const newParentPath = findNodePathByUid(rootNode, newParentUid);
//   const newPath = [...newParentPath, contentKey, index];

//   return moveAtPathToPath(rootNode, oldPath, newPath);
// }

export function editNodeWithUIDKeyValueWithFunction<T>(rootNode: DocBlockNode<T>, uid: UID, key: ContentKey, func) {
  const nodePath = findNodePathByUid(rootNode, uid);

  return nodePath ? R.over(R.lensPath([...nodePath, key]), func, rootNode) : rootNode;
}

export function editNodeWithUIDKeyValue<T>(rootNode: DocBlockNode<T>, uid: UID, key: ContentKey, value) {
  return editNodeWithUIDKeyValueWithFunction(rootNode, uid, key, R.always(value));
}

export function editNodeWithUIDKeysValues<T>(rootNode: DocBlockNode<T>, uid: UID, updates: {}) { // TODO: Remove {}
  const nodePath = findNodePathByUid(rootNode, uid);
  const nodeLens = R.lensPath(nodePath);

  return R.over(nodeLens, R.mergeLeft(updates), rootNode);
}

export function arePathsSameLineage(pathA: NodePath, pathB: NodePath) {
  return R.zip(pathA, pathB).every(([a, b]) => a === b);
}

////////////////////////////////////////
////////////////////////////////////////

function docReduceWithCallbackForKey<T, Acc>(node: DocBlockNode<T>, callback: (a: Acc, n: DocBlockNode<T>) => Acc, accum: Acc, keyCallback: (n: DocBlockNode<T>) => string[]) {
  const newAccum = callback(accum, node);

  const toReduceKeys = keyCallback(node);

  const toReduceChildren = flatten(toReduceKeys.map((key) => {
    return node[key] || [];
  }));

  return reduce(toReduceChildren, (childAccum, childNode) => docReduceWithCallbackForKey(childNode, callback, childAccum, keyCallback), newAccum);
}

export function docReduceAllNodes<T, Acc>(node: DocBlockNode<T>, callback: (a: Acc, n: DocBlockNode<T>) => Acc, accum: Acc) {
  return docReduceWithCallbackForKey(node, callback, accum, nodeAllContentKeys);
}

export function docReduceBlockNodes<T, Acc>(node: DocBlockNode<T>, callback: (a: Acc, n: DocBlockNode<T>) => Acc, accum: Acc) {
  return docReduceWithCallbackForKey(node, callback, accum, nodeBlockContentKeys);
}

export function docReduceInlineNodes<T, Acc>(node: DocBlockNode<T>, callback: (a: Acc, n: DocBlockNode<T>) => Acc, accum: Acc) {
  return docReduceWithCallbackForKey(node, callback, accum, nodeInlineContentKeys);
}

export function docMapAllNodes<T>(node: DocBlockNode<T>, callback: (n: DocBlockNode<T>) => DocBlockNode<T>) {
  const newNode = callback(node);

  const contentKeys = nodeAllContentKeys(newNode);

  contentKeys.forEach((contentKey) => {
    if (newNode[contentKey]) {
      if (Array.isArray(newNode[contentKey])) {
        newNode[contentKey] = newNode[contentKey].map((childNode) => docMapAllNodes(childNode, callback));
      } else if (newNode[contentKey] instanceof Object) {
        newNode[contentKey] = callback(newNode[contentKey]);
      }
    }
  });

  return newNode;
}

export function docMapInlineNodes<T extends DocBlockNode<U>, U>(node: T, callback) {
  const newNode = {...node};

  const blockContentKeys = nodeBlockContentKeys(newNode);

  blockContentKeys.forEach((contentKey) => {
    if (newNode[contentKey]) {
      newNode[contentKey] = newNode[contentKey].map((childNode) => docMapInlineNodes(childNode, callback));
    }
  });

  const inlineContentKeys = nodeInlineContentKeys(newNode);

  inlineContentKeys.forEach((contentKey) => {
    if (newNode[contentKey]) {
      if (Array.isArray(newNode[contentKey])) {
        newNode[contentKey] = newNode[contentKey].map((childNode) => docMapAllNodes(childNode, callback));
      } else {
        newNode[contentKey] = callback(newNode[contentKey]);
      }
    }
  });

  return newNode;
}

export function docMapBlockNodes<T extends DocBlockNode<U>, U>(node: T, callback: (oldNode: T) => T) {
  const newNode = callback(node);

  const contentKeys = nodeBlockContentKeys(newNode);

  contentKeys.forEach((contentKey) => {
    if (newNode[contentKey]) {
      newNode[contentKey] = newNode[contentKey].map((childNode) => docMapBlockNodes(childNode, callback));
    }
  });

  return newNode;
}

export function addMissingUids(node) {
  let newNode = node;
  const toAddUidsTo = node.content || node.rows || node.cells;
  if (Array.isArray(toAddUidsTo)) {
    const newChildren = toAddUidsTo.map((childNode) => {
      return (addMissingUids(childNode));
    });

    if (newChildren.length > 0) {
      switch (node.type) {
        case "table":
          newNode = Object.assign({}, node, {rows: newChildren});
          break;
        case "tableRow":
          newNode = Object.assign({}, node, {cells: newChildren});
          break;
        case "listItem":
        default:
          newNode = Object.assign({}, node, {content: newChildren});
          break;
      }
    }
  }

  if (newNode.uid === undefined) {
    newNode = Object.assign({}, newNode, {uid: generateUUID()});
  }

  return (newNode);
}

////////////////////////////////////////
////////////////////////////////////////

export function replaceAllUIDs<T>(node: DocBlockNode<T>) {
  const clonedNode = R.clone(node);

  findNodes(clonedNode, node => !!node.uid).forEach((nodeToChange) => {
    nodeToChange.uid = generateUUID();
    nodeToChange.changes = [];
    nodeToChange.changeSummaries = [];
  });

  return clonedNode;
}

////////////////////////////////////////
////////////////////////////////////////

export function adjustSelectionStartToIncludeListItemIfNeeded<T>(doc: DocBlockNode<T>, selection: DocSelection) {
  if (selection.start.contentKey !== "content" || selection.start.index !== 0) {
    return selection;
  }

  const startPath = findNodePathByUid(doc, selection.start.uid);
  if (!startPath) {
    return selection;
  }

  const beforeSibling = findNodeSiblingBeforeByPath(doc, startPath);
  if (!beforeSibling || beforeSibling.type !== "listItem") {
    return selection;
  }

  return (
    {
      ...selection,
      start: {
        uid: beforeSibling.uid,
        contentKey: "content",
        index: 0,
      }
    }
  )
}

////////////////////////////////////////
////////////////////////////////////////

// function forEachBlockNode<T extends DocBlockNode>(node: T, fn: ) {
//   return node
// }
// function mapBlockNode(node, fn) {}
// function reduceBlockNode(node, fn) {}
// function forEachDeepBlockNode(node, fn) {}
// function mapDeepBlockNode(node, fn) {}
// function reduceDeepBlockNode(node, fn) {}

// function forEachInlineNode(node, fn) {}
// function mapInlineNode(node, fn) {}
// function reduceInlineNode(node, fn) {}
// function forEachDeepInlineNode(node, fn) {}
// function mapDeepInlineNode(node, fn) {}
// function reduceDeepInlineNode(node, fn) {}
