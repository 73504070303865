import * as React from "react";

import * as classNames from "classnames";

import AqButton from '../../Aq/Button';

import DocStyledText from "../BlockNodeHelpers/StyledText";

import editGradientSmall from 'app/assets/images/edit_gradient_small.svg';

interface DocLearningObjectiveProps extends DocLearningObjectiveType<InlineNode>, BlockNodeAll {
  variantId?: number;
}

function DocLearningObjective(props: DocLearningObjectiveProps) {
  const {
    uid,
    classes,
    content,
    editingContext,
    variantId,
  } = props;

  let handleClick;
  let readOnly = true;
  let editButton;
  if (editingContext.isEditing) {
    if (editingContext.allowLearningObjectivesEdit === uid) {
      readOnly = false;
    } else {
      handleClick = () => { editingContext.setCurrentMenu("LEARNING_OBJECTIVES_CONFIRM_EDIT", {learningObjectiveUid: uid}) }

      editButton = (
        <AqButton
          className="learning-objective-edit-button pink outline small"
          onClick={handleClick}
        >
          <img src={editGradientSmall} style={{height: '12px'}}></img>
        </AqButton>
      )
    }
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-learning-objective", uid, classes, {"locked-lo": editingContext.isEditing && readOnly})}
      contentEditable={false}
      onClick={handleClick}
    >
      <div className="learning-objective-arrow"/>
      {editButton}
      <div className="learning-objective-content">
        <DocStyledText
          uid={uid}
          content={content}
          contentKey={"content"}
          placeholder={"Enter Learning Objective here"}
          editingContext={editingContext}
          independentlyEditable
          variantId={variantId}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}

export default React.memo(DocLearningObjective);
