export const displayModes = {
  STUDENT: "student",
  UNGATED: "ungated",
  FULL: "full",
  OUTLINE: "outline",
} as const;

export function isDisplayMode(str: string): str is DisplayMode {
  return !!Object.values(displayModes).find((displayMode) => str === displayMode)
}
