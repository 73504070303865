import * as React from "react";

import GenButton from "../../Gen/Button/component";
import GenPostButton from "../../Gen/PostButton/component";

import {full_url} from "../../../helpers/url";
import { startCase, lowerCase } from "lodash";

import {
  versionTypes,
  versionTypeShortName,
  versionTypeCssClassPrefix,
  versionPushButtonData,
  determineVersionType,
  publishVersionId,
  ongoingMaintenaceVersionId,
  scheduledReviewVersionId,
} from "../../../helpers/Doc/version_types";
import { changesDisplayModes, isChangesDisplayMode } from "../../../helpers/Doc/changes";
import { displayModes, isDisplayMode } from "../../../helpers/Doc/displayMode";
import { trackChangesEditModes, isTrackChangesEditMode } from "../../../helpers/Doc/trackChangesEditMode";

import { MEDIA_MANAGER } from "../../../helpers/Doc/menu";

interface DocEditBarTopControlsProps {
  documentContext: DocumentContext;
  currentVersion: CurrentVersion;
  userContext: UserContext;
  displayContext: DisplayContext;
  editingContext: EditingContext;
  editingContextExtra: EditingContextExtra;
  featureFlag: FeatureFlag
};

export default function DocEditBarTopControls(props: DocEditBarTopControlsProps) {
  const {
    documentContext,
    currentVersion,
    userContext,
    displayContext,
    editingContext,
    editingContextExtra,
  } = props;

  const permissions: AqPermissions = userContext.permissions || {};

  const versionType = determineVersionType(documentContext, currentVersion.id)
  const {pushButtonName, pushButtonConfirmationText, pushButtonPath, pushButtonReplaceableVariant} = versionPushButtonData(versionType)

  let pushButtonURL;
  if (documentContext.docType === "ReusableObject") {
    pushButtonURL = full_url(`/variants/${currentVersion.id}/replace?replaceable_variant=${pushButtonReplaceableVariant}`)
  } else {
    pushButtonURL = full_url(`/versions/${currentVersion.id}/${pushButtonPath}`)
  }

  let displayModeSelector =
    <select
      className="edit-bar-view-selector"
      value={displayContext.displayMode}
      onChange={(e) => {isDisplayMode(e.target.value) && displayContext.setDisplayMode(e.target.value);}}
    >
      <option value={displayModes.STUDENT}>Student</option>
      <option value={displayModes.UNGATED}>Ungated</option>
      <option value={displayModes.FULL}>Full</option>
      <option value={displayModes.OUTLINE}>Outline</option>
    </select>

  if (!editingContext.shouldShowEditor) {
    // For adding content to the top toolbar in the normal document view mode.
    return(
      <>
      </>
    );
  } else {
    const publishPermission = documentContext.docType === "ReusableObject" ? permissions.publishMultiUseRlo : permissions.publish;

    return (
      <div>
        <div className="edit-bar-control-bar">
          <h3 className={`edit-bar-version-type-header ${versionTypeCssClassPrefix(versionType)}-color`}>
            {versionTypeShortName(versionType)}
          </h3>
          <select
            className="edit-bar-view-selector"
            value={currentVersion.id}
            onChange={(e) => {editingContextExtra.setVersionId(Number(e.target.value));}}
          >
            <option value={publishVersionId(documentContext)} key={"Published"}>Published</option>
            <option value={ongoingMaintenaceVersionId(documentContext)} key={"Ongoing Maintenance"}>Ongoing Maintenance</option>
            <option value={scheduledReviewVersionId(documentContext)} key={"Scheduled Review"}>Scheduled Review</option>
          </select>
          {displayModeSelector}
          <GenButton
            name={<i className="fa fa-undo"></i>}
            highlighted={editingContextExtra.canUndo}
            disabled={!editingContext.isEditing || !editingContextExtra.canUndo}
            small
            onClick={editingContextExtra.undo}
          />
          <GenButton
            name={<i className="fa fa-redo"></i>}
            highlighted={editingContextExtra.canRedo}
            disabled={!editingContext.isEditing || !editingContextExtra.canRedo}
            small
            onClick={editingContextExtra.redo}
          />
          <GenButton
            key={"save-button"}
            name={<i className="fa fa-save"></i>}
            disabled={!editingContext.isEditing || !editingContextExtra.isActiveEditData}
            highlighted={editingContextExtra.isActiveEditData}
            small
            onClick={editingContextExtra.save}
            loading={editingContextExtra.isSyncing}
          />
          <GenButton
            key={"auto-save-toggle-button"}
            name={editingContextExtra.isAutoSaveOn ? <div key="auto-save-on">Auto <i className="fa fa-save"></i> On</div> : <div key="auto-save-off">Auto <i className="fa fa-save"></i> Off</div>}
            highlighted={editingContextExtra.isAutoSaveOn}
            small
            onClick={(e) => {editingContextExtra.toggleAutoSave();}}
          />
          <GenButton
            key="publish-button"
            name="Publish"
            disabled={!editingContext.isEditing || editingContextExtra.isActiveEditData || !publishPermission}
            highlighted={!editingContextExtra.isActiveEditData}
            small
            onClick={() => {editingContextExtra.publish();}}
          />
          { pushButtonName &&
            <GenPostButton
            name={pushButtonName}
            confirmMessage={pushButtonConfirmationText}
            disabled={!editingContext.isEditing || editingContextExtra.isActiveEditData || !publishPermission }
            highlighted={!editingContextExtra.isActiveEditData}
            small
            url={pushButtonURL}
          />}
          <GenButton
            name="Reset"
            highlighted
            small
            onClick={userContext.resetState}
          />
          <GenButton
            name={<i className="fa fa-image"></i>}
            disabled={!editingContext.isEditing || !permissions.viewMediaAssets}
            highlighted
            small
            onClick={(e) => {editingContext.setCurrentMenu(MEDIA_MANAGER, { mediaType: "image" });}}
          />
          <GenButton
            name={<i className="fa fa-music"></i>}
            disabled={!editingContext.isEditing || !permissions.viewMediaAssets}
            highlighted
            small
            onClick={(e) => {editingContext.setCurrentMenu(MEDIA_MANAGER, { mediaType: "audio" });}}
          />
          <GenButton
            name={<i className="fa fa-video"></i>}
            disabled={!editingContext.isEditing || !permissions.viewMediaAssets}
            highlighted
            small
            onClick={(e) => {editingContext.setCurrentMenu(MEDIA_MANAGER, { mediaType: "video" });}}
          />
          <GenButton
            name={<i className="fa fa-file-pdf"></i>}
            disabled={!editingContext.isEditing || !permissions.viewMediaAssets}
            highlighted
            small
            onClick={(e) => {editingContext.setCurrentMenu(MEDIA_MANAGER, { mediaType: "pdf" });}}
          />
          { !props.featureFlag.new_landing_pages && (
            <select
              className="edit-bar-view-selector"
              value={editingContextExtra.trackChangesEditMode}
              onChange={(e) => {isTrackChangesEditMode(e.target.value) && editingContextExtra.setTrackChangesEditMode(e.target.value);}}
              disabled={!editingContext.isEditing || permissions.allowedEditMode === "Suggesting"}
            >
              <option value={trackChangesEditModes.EDITING_MODE}>Editing Mode</option>
              <option value={trackChangesEditModes.SUGGESTING_MODE}>Suggesting Mode</option>
            </select>
          )}
          { !props.featureFlag.new_landing_pages && (
            <select
              className="edit-bar-view-selector"
              value={editingContext.changesDisplayMode}
              onChange={(e) => {isChangesDisplayMode(e.target.value) && editingContextExtra.setChangesDisplayMode(e.target.value);}}
              disabled={versionType === versionTypes.PUBLISHED}
            >
              {Object.values(changesDisplayModes).map((changesDisplayMode) => {
                return <option value={changesDisplayMode} key={changesDisplayMode}>{startCase(lowerCase(changesDisplayMode))}</option>;
              })}
            </select>
          )}
          { !props.featureFlag.new_landing_pages && userContext.permissions.viewLinksReport && (
            <GenButton
              name="Links Report"
              highlighted
              small
              confirmTitle="Just so you know"
              confirmMessage="This report could take a few minutes to produce. You can monitor its completion on the My Reports page. Please note: Any changes made to hyperlinks will be reflected in reporting only after publishing."
              confirmOKText="OK"
              href={`/reports/document_links/${documentContext.id}.csv`}
            />
          )}
        </div>
        {props.featureFlag.new_landing_pages && (
          <div className="edit-bar-control-bar">
            <select
              className="edit-bar-view-selector"
              value={editingContextExtra.trackChangesEditMode}
              onChange={(e) => {isTrackChangesEditMode(e.target.value) && editingContextExtra.setTrackChangesEditMode(e.target.value);}}
              disabled={!editingContext.isEditing || permissions.allowedEditMode === "Suggesting"}
            >
              <option value={trackChangesEditModes.EDITING_MODE}>Editing Mode</option>
              <option value={trackChangesEditModes.SUGGESTING_MODE}>Suggesting Mode</option>
            </select>
            <select
              className="edit-bar-view-selector"
              value={editingContext.changesDisplayMode}
              onChange={(e) => {isChangesDisplayMode(e.target.value) && editingContextExtra.setChangesDisplayMode(e.target.value);}}
              disabled={versionType === versionTypes.PUBLISHED}
            >
              {Object.values(changesDisplayModes).map((changesDisplayMode) => {
                return <option value={changesDisplayMode} key={changesDisplayMode}>{startCase(lowerCase(changesDisplayMode))}</option>;
              })}
            </select>
            {userContext.permissions.viewLinksReport && (
              <GenButton
                name="Links Report"
                highlighted
                small
                confirmTitle="Just so you know"
                confirmMessage="This report could take a few minutes to produce. You can monitor its completion on the My Reports page. Please note: Any changes made to hyperlinks will be reflected in reporting only after publishing."
                confirmOKText="OK"
                href={`/reports/document_links/${documentContext.id}.csv`}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
