import * as React from "react";

import DocStyledTextEditor from "./StyledText/Editor";
import * as DocConvertRangeInlineJSON from "../../../helpers/Doc/Convert/RangeInlineJSON";
import * as DocConvertHTML from "../../../helpers/Doc/Convert/HTML";
import { isRangeInlineJSON } from "../../../helpers/Doc/Functions/RangeInlineJSON";
import analyticsModule from "../../../../application/analytics.js";

const renderContent = (content: InlineNode, onClick?: React.MouseEventHandler<HTMLSpanElement>) => {
  if (typeof content === "string") {
    return <span key={content} onClick={onClick} dangerouslySetInnerHTML={{__html: content}} />;
  } else if(isRangeInlineJSON(content)) {
    return <span key={content.text} onClick={onClick} dangerouslySetInnerHTML={{__html: DocConvertRangeInlineJSON.toHTML(content)}} />;
  } else {
    return <span onClick={onClick} dangerouslySetInnerHTML={{__html: DocConvertHTML.fromInlineJson(content)}} />;
  }
};

interface DocStyledTextProps {
  uid?: string;
  content: InlineNode;
  contentKey?: string;

  disableStyleTextEditing?: boolean;

  editingContext: EditingContext;
  documentContext?: DocumentContext;

  independentlyEditable?: boolean;
  placeholder?: string;

  readOnly?: boolean;

  variantId?: number

  returnPlainText?: boolean; // Can drop?
};

export default function DocStyledText(props: DocStyledTextProps) {
  if (props.editingContext.isEditing && !props.readOnly) {
    return <DocStyledTextEditor {...props} />;
  } else {
    let onClick;
    const [courseNode, _] = Array.from(document.querySelectorAll(".breadcrumb-header-panel a"))
    const caseTitle = props.documentContext ? props.documentContext.title : ''

    if(String(props.content).startsWith('<a') && courseNode && courseNode.textContent == 'Educator Resources' ) {
      onClick = () => { analyticsModule.track("click_on_each_educator_resource_pdf", { page: window.location.href, source: 'Educator Resources', case: caseTitle }) }
    } else if(String(props.content).includes('<a')) {
      onClick = () => { analyticsModule.track("clicks_on_external_resources", { page: window.location.href, source: 'Case', case: caseTitle }) }
    }

    return renderContent(props.content, onClick);
  }
}
