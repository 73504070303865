import React from 'react';

import GenToggleBar from 'Gen/ToggleBar/component'

export default function GenToggleView(props){
  let buttons = props.views.map(function(viewObj){
    return({
      ...viewObj,
      highlighted: viewObj.key === props.activeKey
    })
  })
  let toggleControls = (<GenToggleBar buttons={buttons} value={props.activeViewIndex}/>);

  let view = props.views.find((viewObj) => viewObj.key === props.activeKey)
  if (view) {
    view = view.view
  }

  return(
    <div className="gen-toggle-views">
      <div className="gen-toogle-views-controls">
        {toggleControls}
      </div>
      <div className="gen-toogle-views-view">
        {view}
      </div>
    </div>
  );
}
