import React from 'react'

import * as classNames from 'classnames'

import GenGen from 'Gen/component'

export default function GenParagraph(props) {
  const {
    value,
    onChange,
    canEdit=true,
    isEditing=false,
    onSpanClick,
    onEnterKeyPress,
    placeholder='',
    children='',
    onBlur,
    shouldAutoFocus=false,
    rows=2,
    className,
    isControlled=false
  } = props

  function onKeyPress(e) {
    if ((e.which || e.keyCode) === 13) {
      e.preventDefault()
      onEnterKeyPress(e)
    }
  }

  return <GenGen {...props}>
    {canEdit && isEditing ? (
      isControlled ? (
        <textarea
          key='gen-paragraph-input'
          className='gen-paragraph-input'
          value={children}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          autoFocus={shouldAutoFocus}
          rows={rows}
        />
      ) : (
        <textarea
          key='gen-paragraph-input'
          className='gen-paragraph-input'
          defaultValue={children}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          autoFocus={shouldAutoFocus}
          rows={rows}
        />
      )
    ):(
      <p className={classNames(className, 'gen-paragraph')} onClick={onSpanClick}>
        {children}
      </p>
    )}
  </GenGen>
}
