import * as React from "react";

import { UnwrappedNewEditDocApp } from "../../../startup/NewEditDocApp";
import NewEditDocContainer from "../Container";
import analytics from "../../../../application/analytics";

interface TeachingPointModalProps {
  currentUser: User;
  defaultChangesDisplayMode: ChangesDisplayMode;
  displayUid: string;
  document_id: number;
  documentId: number;
  isAdmin: boolean;
  permissions: AqPermissions;
  publish_version_id: number;
  skipProvider: boolean;
  teaching_point_uid: string;
  title: string;
  versionId: number;
  itemResponseId: number;
};

interface TeachingPointModalState {
  isOpen: boolean;
};

export default class TeachingPointModal extends React.Component<TeachingPointModalProps, TeachingPointModalState> {
  constructor(props: TeachingPointModalProps) {
    super(props);

    this.state = {isOpen: false};
  }

  onClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    if(!this.state.isOpen) {
      analytics.track("click_teaching_point_link", { page: window.location.href, source: 'Calibrate Reports', item_response_id: this.props.itemResponseId })
    }
    this.setState({isOpen: !this.state.isOpen});
  }

  getEmbeded() {
    if (this.props.skipProvider) {
      return <NewEditDocContainer
        docType="ReusableObject"
        documentId={this.props.documentId || this.props.document_id}
        versionId={this.props.versionId || this.props.publish_version_id}
        currentUser={this.props.currentUser}
        isAdmin={this.props.isAdmin}
        permissions={this.props.permissions}
        defaultChangesDisplayMode={this.props.defaultChangesDisplayMode}
        displayUid={this.props.displayUid || this.props.teaching_point_uid}
      />
    } else {
      return <UnwrappedNewEditDocApp
        docType="ReusableObject"
        documentId={this.props.documentId || this.props.document_id}
        versionId={this.props.versionId || this.props.publish_version_id}
        currentUser={this.props.currentUser}
        isAdmin={this.props.isAdmin}
        permissions={this.props.permissions}
        defaultChangesDisplayMode={this.props.defaultChangesDisplayMode}
        displayUid={this.props.displayUid || this.props.teaching_point_uid}
        isEmbeded
      />
    }
  }

  render() {
    return (
      <span>
        <a className="teaching-point-link" href="#" onClick={this.onClick.bind(this)}>{this.props.title}</a>
        {this.state.isOpen && <div className="gen-modal teaching-point-modal">
          <div className="gen-modal-panel">
            <div className="teaching-point-modal-header">
              <span className="close-x" onClick={this.onClick.bind(this)}>X</span>
            </div>
            {this.getEmbeded()}
            <button className="actionBtn" onClick={this.onClick.bind(this)}>Close</button>
          </div>
        </div>}
      </span>
    );
  }
}
