import { generateReducer, generateActions } from './itemReducer'

const itemKey = 'document'
const endpoint = 'api/v2/documents/'

export function generateDocumentActions(id: ItemId) {
  const documentActions = generateActions<Document, DocumentExtraData>(itemKey, id, endpoint)

  return {
    startNewDocument: documentActions.startNewItem,
    cancelNewDocument: documentActions.cancelNewItem,
    startEditDocument: documentActions.startEditItem,
    cancelEditDocument: documentActions.cancelEditItem,
    setAllDataWithFunctionDocument: documentActions.setAllDataWithFunctionItem,
    setSyncDataDocument: documentActions.setSyncDataItem,
    setSyncDataWithFunctionDocument: documentActions.setSyncDataWithFunctionItem,
    setEditDataDocument: documentActions.setEditDataItem,
    setEditDataWithFunctionDocument: documentActions.setEditDataWithFunctionItem,
    resetEditDataDocument: documentActions.resetEditDataItem,
    setExtraDataDocument: documentActions.setExtraDataItem,
    setExtraDataWithFunctionDocument: documentActions.setExtraDataWithFunctionItem,
    resetExtraDataDocument: documentActions.resetExtraDataItem,
    loadDocument: documentActions.loadItem,
    createDocument: documentActions.createItem,
    updateDocument: documentActions.updateItem,
    destroyDocument: documentActions.destroyItem,
    loadIfNeededDocument: documentActions.loadIfNeededItem,
    documentFromState: documentActions.itemFromState,
    documentSyncDataFromState: documentActions.itemSyncDataFromState,
    documentSyncMetaFromState: documentActions.itemSyncMetaFromState,
    documentEditDataFromState: documentActions.itemEditDataFromState,
    documentMergedDataFromState: documentActions.itemMergedDataFromState,
    documentExtraDataFromState: documentActions.itemExtraDataFromState,

  }
}

export const documentCollectionReducer = generateReducer<Document, DocumentExtraData>(itemKey);
