import React from 'react';

export default function GenModal(props) {
  if (props.open) {
    return (
      <div className="gen-modal">
        {props.children}
      </div>
    );
  } else {
    return(null);
  }
}
