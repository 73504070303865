import * as React from "react";

import { toInteger } from "lodash";

import { getSubType } from "../../../../helpers/Doc/Define/base";

import DocEditHeaderBooleanInput from "./BooleanInput";
import DocEditHeaderEnumTagsInput from "./EnumTagsInput";
import DocEditHeaderFalseOrNumberInput from "./FalseOrNumberInput";
import DocEditHeaderSelectInput from "./SelectInput";
import DocEditHeaderTextInput from "./TextInput";
import DocEditHeaderTagsInput from "./TagsInput";
import DocEditHeaderMultiSelectInput from "./MultiSelectInput";

interface DocEditHeaderMetadataInputsProps {
  disableMetadataEdit?: boolean;
  editingContext: EditingContextInEditor;
  node: DocBlockNode<InlineNode>;
  onlyKeys?: Array<string>;
  userContext: UserContext;
};

function DocEditHeaderMetadataInputs(props: DocEditHeaderMetadataInputsProps) {
  const {
    disableMetadataEdit,
    editingContext,
    userContext,
    node,
    onlyKeys,
  } = props;

  const nodeType = getSubType(node);

  const nodeDefinition = editingContext.nodeDefinitions[nodeType].deref(editingContext.nodeDefinitions);

  let editWithInputs = null;
  if (nodeDefinition) {
    let editWithList = nodeDefinition.generateEditWithList();

    if (onlyKeys) {
      editWithList = editWithList.filter((editWithData) => onlyKeys.includes(editWithData[0]));
    }

    if (!userContext.permissions.editNodeInvisible) {
      editWithList = editWithList.filter((editWithData) => "invisible" !== editWithData[0]);
    }

    editWithInputs = editWithList.map((editWithData) => {
      const editKey = editWithData[0];
      const editWithName = editWithData[1];
      const editWithArgs = editWithData[2];
      const displayName = editWithData[3]

      const inAllEditHeaderInputs = {
        key: editKey,
        uid: node.uid,
        label: displayName || editKey,
        contentKey: editKey,
        ...editWithArgs,
        editingContext: editingContext,
        disableMetadataEdit: disableMetadataEdit,
      };

      switch (editWithName) {
        case "booleanInput":
          return (
            <DocEditHeaderBooleanInput
              {...inAllEditHeaderInputs}
              value={node[editKey]}
            />
          );
        case "falseOrNumberInput":
          return (
            <DocEditHeaderFalseOrNumberInput
              {...inAllEditHeaderInputs}
              value={node[editKey]}
            />
          );
        case "textInput":
          return (
            <DocEditHeaderTextInput
              {...inAllEditHeaderInputs}
              value={node[editKey]}
            />
          );
        case "selectInput":
          return (
            <DocEditHeaderSelectInput
              {...inAllEditHeaderInputs}
              value={node[editKey]}
            />
          );
        case "integerTextInput":
          return (
            <DocEditHeaderTextInput
              {...inAllEditHeaderInputs}
              value={node[editKey]}
              beforeSaveFunction={(a) => toInteger(a)}
            />
          );
        case "tagsInput":
          return (
            <DocEditHeaderTagsInput
              {...inAllEditHeaderInputs}
              tags={node[editKey] || []}
            />
          );
        case "enumTagsInput":
          return (
            <DocEditHeaderEnumTagsInput
              {...inAllEditHeaderInputs}
              tags={node[editKey] || []}
            />
          );
        case "multiSelectInput":
          return (
            <DocEditHeaderMultiSelectInput
              {...inAllEditHeaderInputs}
              tags={node[editKey] || []}
            />
          );
        default:
          return null;
      }
    });
  }

  return editWithInputs;
}

export default React.memo(DocEditHeaderMetadataInputs);
