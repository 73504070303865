import {
  camelCase,
  mapKeys,
} from 'lodash'

import { addMissingUids } from '../helpers/Doc/Functions/base'

import { generateReducer, generateActions, itemFromStatePlus } from './itemReducer'

const itemKey = 'variant'
const endpoint = 'variants/'

function inTransformer(data) {
  let transformedData = mapKeys(data, (value, key) => camelCase(key)) as Variant;
  if (typeof transformedData.data !== "string") {
    transformedData.data = addMissingUids(transformedData.data);
  }

  return transformedData
}

export function generateVariantActions(id: ItemId) {
  const variantActions = generateActions<Variant, VariantExtraData>(itemKey, id, endpoint)

  return {
    startNewVariant: variantActions.startNewItem,
    cancelNewVariant: variantActions.cancelNewItem,
    startEditVariant: variantActions.startEditItem,
    cancelEditVariant: variantActions.cancelEditItem,
    setAllDataWithFunctionVariant: variantActions.setAllDataWithFunctionItem,
    setSyncDataVariant: variantActions.setSyncDataItem,
    setSyncDataWithFunctionVariant: variantActions.setSyncDataWithFunctionItem,
    setEditDataVariant: variantActions.setEditDataItem,
    setEditDataWithFunctionVariant: variantActions.setEditDataWithFunctionItem,
    resetEditDataVariant: variantActions.resetEditDataItem,
    setExtraDataVariant: variantActions.setExtraDataItem,
    setExtraDataWithFunctionVariant: variantActions.setExtraDataWithFunctionItem,
    resetExtraDataVariant: variantActions.resetExtraDataItem,
    loadVariant: variantActions.loadItem,
    createVariant: variantActions.createItem,
    updateVariant: variantActions.updateItem,
    destroyVariant: variantActions.destroyItem,
    loadIfNeededVariant: variantActions.loadIfNeededItem,
    variantFromState: variantActions.itemFromState,
    variantSyncDataFromState: variantActions.itemSyncDataFromState,
    variantSyncMetaFromState: variantActions.itemSyncMetaFromState,
    variantEditDataFromState: variantActions.itemEditDataFromState,
    variantMergedDataFromState: variantActions.itemMergedDataFromState,
    variantExtraDataFromState: variantActions.itemExtraDataFromState,
  }
}

export function variantFromStatePlus(id: ItemId, state: ReducerState) {
  return itemFromStatePlus<Variant, VariantExtraData>(itemKey, id, state)
}

export const variantCollectionReducer = generateReducer<Variant, VariantExtraData>(itemKey, {inTransformer: inTransformer, keepKeysOnUpdateFulfilled: ["changeData"]});;
