import * as React from "react";

import { last } from "lodash";

import ReasoningToolBookmarkSummary from "./BookmarksPanel/BookmarkSummary";
import ReasoningToolPanel from "./Panel";

import {
  findNodesLineages,
} from "../../../helpers/Doc/Functions/base";

interface ReasoningToolBookmarksPanelProps {
  documentContext: DocumentContext;
  displayContext: DisplayContext;
  currentVersion: CurrentVersion;
  userContext: UserContext;
  mediaContext: MediaContext;
  featureFlag: FeatureFlag;
  mergedData: MergedData;
};

export default function ReasoningToolBookmarksPanel({
  documentContext,
  displayContext,
  currentVersion,
  userContext,
  mediaContext,
  featureFlag,
  mergedData,
}: ReasoningToolBookmarksPanelProps) {
  const bookmarkedLineages = findNodesLineages(mergedData, (node) => 'bookmarked' in node && !!node.bookmarked);
  const panelChildren = bookmarkedLineages.map((bookmarkedNodeLineage: Array<any>) => { // TODO: Remove any
    const bookmarkedNode = last(bookmarkedNodeLineage);

    return (
      <ReasoningToolBookmarkSummary
        key={bookmarkedNode.uid}
        documentContext={documentContext}
        currentVersion={currentVersion}
        userContext={userContext}
        displayContext={displayContext}
        mediaContext={mediaContext}
        featureFlag={featureFlag}
        bookmarkedNode={bookmarkedNode}
        bookmarkedNodeLineage={bookmarkedNodeLineage}
      />
    );
  });

  return (
    <ReasoningToolPanel
      key="Bookmarks"
      title="Bookmarks"
      infoBox={<span><span className="icon-bookmark" style={{fontSize: "0.75em"}}></span> Hover over text and click the Bookmarks icon to add bookmarks for your reference</span>}
    >
      {panelChildren}
    </ReasoningToolPanel>
  );
}
