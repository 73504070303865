import * as React from "react";

import * as classNames from "classnames";

import DocBookmark from "../BlockNodeHelpers/Bookmark";
import DocNote from "../BlockNodeHelpers/Note";
import DocStyledText from "../BlockNodeHelpers/StyledText";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

interface DocParagraphProps extends DocParagraphType<InlineNode>, BlockNodeAll {}

function DocParagraph(props: DocParagraphProps) {
  return (
    <p
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames({"focused-bookmark": props.isFocusedBookmark && !props.simple}, props.simple ? "doc-paragraph-simple" : "doc-paragraph", props.uid, props.classes)}
      style={backgroundStyleFromNode(props)}
    >
      <ChangeHighlightParagraphMark
        blockChangeHighlight={props.blockChangeHighlight}
        editingContext={props.editingContext}
      />
      <DocStyledText
        uid={props.uid}
        content={props.content}
        contentKey={"content"}
        placeholder={"Enter Paragraph text here"}
        editingContext={props.editingContext}
        documentContext={props.documentContext}
      />
      {!props.simple && <DocBookmark {...props} />}
      {!props.simple && <DocNote {...props} />}
    </p>
  )
}

interface ChangeHighlightParagraphProps {
  blockChangeHighlight?: BlockChangeHighlight;
  editingContext: EditingContext;
}

function ChangeHighlightParagraphMark(props: ChangeHighlightParagraphProps) {
  const {
    blockChangeHighlight,
    editingContext,
  } = props;

  let textColor = ""
  switch (blockChangeHighlight?.type) {
    case "DELETE_CONTENT":
      textColor = editingContext.hasOwnProperty("colorLookup") ? `rgb(${editingContext.colorLookup[blockChangeHighlight.author_id]})` : `rgb(255,0,0))`
      return (
        <span
          className="doc-paragraph-mark"
          style={{
            textDecorationColor: textColor,
            textDecoration: "line-through",
            fontWeight: "bold",
          }}
        >
          &nbsp;&para;&nbsp;
        </span>
      );
    case "ADD_CONTENT":
      textColor = editingContext.hasOwnProperty("colorLookup") ? `rgb(${editingContext.colorLookup[blockChangeHighlight.author_id]})` : `rgb(255,0,0))`
      return (
        <span
          className="doc-paragraph-mark"
          style={{
            color: textColor,
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          &nbsp;&para;&nbsp;
        </span>
      );
    default:
      return null;
  }
}

export default React.memo(DocParagraph);
