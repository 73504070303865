export const CAUSAL_EXPLANATION_CLASSES = [
  "causal_explanation",
]

export const CORE_CONCEPT_CLASSES = [
  "core_concept_header",
]

export const DEEP_DIVE_CLASSES = [
  "expert_comment",
]

export const TEACHING_POINT_CLASSES = [
  "clinical_reasoning",
  "clinical_skills",
  "clinical",
  "knowledge",
  "management",
  "studies",
  "teaching", // TODO: HAS THIS BEEN ADDED?
]

export const POSSIBLE_RO_CLASSES = [
  ...CAUSAL_EXPLANATION_CLASSES,
  ...CORE_CONCEPT_CLASSES,
  ...DEEP_DIVE_CLASSES,
  ...TEACHING_POINT_CLASSES,
]