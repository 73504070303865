import { generateReducer, generateActions } from './itemReducer'

const itemKey = 'reusableObject'
const endpoint = 'reusable_objects/'

export function generateReusableObjectActions(id: ItemId) {
  const reusableObjectActions = generateActions<ReusableObject, ReusableObjectExtraData>(itemKey, id, endpoint)

  return {
    startNewReusableObject: reusableObjectActions.startNewItem,
    cancelNewReusableObject: reusableObjectActions.cancelNewItem,
    startEditReusableObject: reusableObjectActions.startEditItem,
    cancelEditReusableObject: reusableObjectActions.cancelEditItem,
    setAllDataWithFunctionReusableObject: reusableObjectActions.setAllDataWithFunctionItem,
    setSyncDataReusableObject: reusableObjectActions.setSyncDataItem,
    setSyncDataWithFunctionReusableObject: reusableObjectActions.setSyncDataWithFunctionItem,
    setEditDataReusableObject: reusableObjectActions.setEditDataItem,
    setEditDataWithFunctionReusableObject: reusableObjectActions.setEditDataWithFunctionItem,
    resetEditDataReusableObject: reusableObjectActions.resetEditDataItem,
    setExtraDataReusableObject: reusableObjectActions.setExtraDataItem,
    setExtraDataWithFunctionReusableObject: reusableObjectActions.setExtraDataWithFunctionItem,
    resetExtraDataReusableObject: reusableObjectActions.resetExtraDataItem,
    loadReusableObject: reusableObjectActions.loadItem,
    createReusableObject: reusableObjectActions.createItem,
    updateReusableObject: reusableObjectActions.updateItem,
    destroyReusableObject: reusableObjectActions.destroyItem,
    loadIfNeededReusableObject: reusableObjectActions.loadIfNeededItem,
    reusableObjectFromState: reusableObjectActions.itemFromState,
    reusableObjectSyncDataFromState: reusableObjectActions.itemSyncDataFromState,
    reusableObjectSyncMetaFromState: reusableObjectActions.itemSyncMetaFromState,
    reusableObjectEditDataFromState: reusableObjectActions.itemEditDataFromState,
    reusableObjectMergedDataFromState: reusableObjectActions.itemMergedDataFromState,
    reusableObjectExtraDataFromState: reusableObjectActions.itemExtraDataFromState,

  }
}

export const reusableObjectCollectionReducer = generateReducer<ReusableObject, ReusableObjectExtraData>(itemKey);
