import axios from 'axios'
import { full_url } from 'helpers/url'
import { defaultInTransformer } from 'reducers/itemReducer'

const BASE_ASSET_ENDPOINT = full_url("/api/v2/digital_assets/")

const ASSET_TYPES = {
  image:"image",
  video:"video",
  audio:"audio",
  pdf:"pdf",
  caption:"caption"
}

export function getAssetAssociations({externalId, responseHandler = defaultHandler} = {}) {
  return apiRequest({
    path: "asset_associations",
    method: "GET",
    params: {
      external_id: externalId
    },
    responseHandler: responseHandler
  })
}

export function deleteAssetAssociation({modelId = "", modelType = "", externalId = "", responseHandler = defaultHandler} = {}) {
  apiRequest({
    path: "delete_asset_association",
    method: "POST",
    params: {
      model_id: modelId,
      model_type: modelType,
      external_id: externalId
    },
    responseHandler: responseHandler
  })
}

export function initializeMedia({isEmbeded = false, modelId = "", modelType = "", responseHandler = defaultHandler} = {}) {
  if (isEmbeded) {
    return responseHandler({
      "images":   [],
      "audios":   [],
      "videos":   [],
      "pdfs":     [],
      "captions": []
    })
  }
  return syncAssets({
    modelId,
    modelType,
    responseHandler: organizeMediaAssets
  }).then((assets) => {
    return responseHandler(assets)
  })
}

export function mediaKeyHelper(asset) {
  let key = asset.media_type
  if (key == 'document') {
    key = asset.file_ext
  }
  return {
    image: 'images',
    audio: 'audios',
    video: 'videos',
    pdf: 'pdfs',
    vtt: 'captions',
    srt: 'captions'
  }[key]
}

export function organizeMediaAssets(model) {
  const transformedModel = defaultInTransformer(model)
  const checkAndFilterAssets = (doc, type) => {
    if (!doc || !doc.digitalAssets || !doc.digitalAssets.length) {
      return false;
    }
    switch (type) {
      case ASSET_TYPES['image']:
        return doc.digitalAssets.filter(da => da.media_type == "image")
      case ASSET_TYPES['audio']:
        return doc.digitalAssets.filter(da => da.media_type == "audio")
      case ASSET_TYPES['video']:
        return doc.digitalAssets.filter(da => da.media_type == "video")
      case ASSET_TYPES['pdf']:
        return doc.digitalAssets.filter(da => da.media_type == "document" && da.file_ext == "pdf")
      case ASSET_TYPES['caption']:
        return doc.digitalAssets.filter(da => da.media_type == "document" && (da.file_ext == "vtt" || da.file_ext == "srt"))
      default:
        return false
    }
  }

  return {
    "images": checkAndFilterAssets(transformedModel, ASSET_TYPES['image']) || [],
    "audios": checkAndFilterAssets(transformedModel, ASSET_TYPES['audio']) || [],
    "videos": checkAndFilterAssets(transformedModel, ASSET_TYPES['video']) || [],
    "pdfs":   checkAndFilterAssets(transformedModel, ASSET_TYPES['pdf'])   || [],
    "captions": checkAndFilterAssets(transformedModel, ASSET_TYPES['caption']) || [],
  }
}

function syncAssets({modelId = "", modelType = "", responseHandler = defaultHandler} = {}) {
  return apiRequest({
    path: "sync_assets",
    method: "POST",
    params: {
      model_id: modelId,
      model_type: modelType
    },
    responseHandler: responseHandler
  })
}

function apiRequest({path, method = "GET", params = {}, responseHandler = defaultHandler} = {}) {
  const url = BASE_ASSET_ENDPOINT + path
  return axios({
    url,
    method,
    params,
    withCredentials: true,
    headers: ReactOnRails.authenticityHeaders({
      'Accept': 'application/json'
    }),
  })
  .then((response) => {
    return responseHandler(response.data)
  })
  .catch((error) => {
    return responseHandler(error)
  })
}

function formatResource(resource) {
  return {
    external_identifier: resource["ref"],
    media_type: resource["resource_type"],
    file_ext: resource["file_extension"],
    title: resource["field8"]
  }
}

function defaultHandler(x) {
  return x
}
