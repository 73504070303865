import * as React from 'react'
import { Provider } from 'react-redux'
import * as ActionCable from '@rails/actioncable'

import store from '../store/storeNewDocEdit'
import NewEditDocContainer from '../components/NewEditDoc/Container'
const CableApp = {
  cable: ActionCable.createConsumer()
}

const sessionId = Math.floor((Math.random() * 10000000000));

const NewEditDocApp = (props, _railsContext) => (
  () => (
    <Provider store={store()}>
      <NewEditDocContainer cableApp={CableApp} sessionId={sessionId} {...props}/>
    </Provider>
  )
)

export default NewEditDocApp

export function UnwrappedNewEditDocApp(props, _railsContext) {
  return (
    <Provider store={store()}>
      <NewEditDocContainer {...props}/>
    </Provider>
  )
}
