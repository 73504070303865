import React from 'react';

import * as classNames from 'classnames'

const onButtonClick = (e, {
  value,
  selectedValue,
  onUpdate,
  selectedDirection
}) => {
  e.preventDefault()

  let direction
  if ((value === 'documents.last_release_date') && selectedValue !== value) {
    direction = 'descending'
  } else if (selectedValue === value && selectedDirection === 'ascending') {
    direction = 'descending'
  } else {
    direction = 'ascending'
  }

  onUpdate(value, direction)
  return false
}

const GenDirectionalButtons = ({
  selectedValue,
  selectedDirection,
  disabled,
  onUpdate,
  buttons,
}) => (
  <div className="gen-directional-buttons">
    { buttons.map(({ value, disabled, title }) => (
      <a
        className={
          classNames(
            "gen-directional-button",
            {
              'disabled': disabled || disabled,
              'selected': value === selectedValue,
              [selectedDirection]: value === selectedValue
            }
          )
        }
        key={value}
        value={value}
        href="#"
        onClick={(e) => onButtonClick(e, {value, selectedValue, onUpdate, selectedDirection})}>
          <span className="button-group-item">{title}</span>
      </a>
    ))}
  </div>
)

export default GenDirectionalButtons
