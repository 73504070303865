import { mergeStates } from "./documentUserState";

import { full_url } from "../url";

const STATE_ACTIONS = [
  "setNodeInactive",
  "setNodeInvisible",
  "setNodeKVPair",
  "startTimer",
]

const CALLBACK_ACTIONS = [
  "scrollTo",
];

interface HandleActionProps {
  displayContext: DisplayContext
  userContext: UserContext;
}

export function handleActions(actions: Array<DocAction>, props: HandleActionProps) {
  if (props.displayContext) {
    props.displayContext.setFocusedBookmarkUid(null);
  }

  const stateActions = actions.filter((action) => {
    return (STATE_ACTIONS.indexOf(action.action) >= 0);
  });

  const newStates = stateActions.reduce((acc, action) => {
    switch (action.action) {
      case "setNodeInactive":
        return (mergeStates(acc, {
          [action.arguments.uid]: {
            inactive: action.arguments.value,
          },
        }));
      case "setNodeInvisible":
        return (mergeStates(acc, {
          [action.arguments.uid]: {
            invisible: action.arguments.value,
          },
        }));
      case "cmeRedirect":
        window.location.href = "/users/cme_redirect";

        return (1);
      case "setNodeKVPair":
        return (mergeStates(acc, {
          [action.arguments.uid]: {
            [action.arguments.key]: action.arguments.value,
          },
        }));
      case "startTimer":
        return (mergeStates(acc, {
          [action.arguments.uid]: {
            currentTimeSegmentStart: Math.floor(new Date().getTime() / 1000),
          },
        }));
      default:
        Rollbar.warning(`handleActions does not recognize Callback Action of type ${action.action}`);
    }
  }, {});


  const callbackActions = actions.filter((action) => {
    return (CALLBACK_ACTIONS.indexOf(action.action) >= 0);
  });

  const callbacks = callbackActions.map((action) => {
    switch (action.action) {
      case "scrollTo":
        return () => {
          setTimeout(()=> {
            const el = document.getElementsByClassName(action.arguments.uid)[0];
            if (el) {
              window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top - 125);
            }
          }, 100);
        };
      default:
        Rollbar.warning(`handleActions does not recognize Callback Action of type ${action.action}`);
    }
  }).filter((callback): callback is () => void => !!callback);

  props.userContext.addState(newStates, () => {
    callbacks.forEach((callback) => callback());
  });

  const otherActions = actions.filter((action) => {
    return ((STATE_ACTIONS.concat(CALLBACK_ACTIONS)).indexOf(action.action) < 0);
  });

  otherActions.forEach((action) => {
    switch (action.action) {
      case "openExternalLink":
        window.window.open(action.arguments.url,"_blank");
        break;
      case "openInternalLink":
        window.window.open(full_url(action.arguments.path), "_blank");
        break;
      case "submitExam":
        props.userContext.submitExam("Your exam has been successfully submitted");
        break;
      default:
        Rollbar.warning(`handleActions does not recognize Other Action of type ${action.action}`);
    }
  });
}
