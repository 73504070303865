import * as React from "react";
import * as classNames from "classnames";

import GenSpan from "../../Gen/Span/component";

interface ReasoningToolDiagnosisSummaryProps {
  uid: string;
  content: string;
  isEditing: boolean;
  startNodeEdit: () => void;
  endNodeEdit: React.FocusEventHandler<HTMLInputElement>;
  isLeadingDiagnosis: boolean;
  isDangerousDiagnosis: boolean;
  setIsDangerousDiagnosis: (uid: string, value: boolean) => void;
  setIsLeadingDiagnosis: (uid: string) => void;
};

export default function ReasoningToolDiagnosisSummary({
  uid,
  content = "",
  isEditing,
  startNodeEdit,
  endNodeEdit,
  isLeadingDiagnosis = false,
  isDangerousDiagnosis = false,
  setIsDangerousDiagnosis,
  setIsLeadingDiagnosis,
}: ReasoningToolDiagnosisSummaryProps) {
  return (
    <div className="reasoning-tool-diagnosis-summary">
      <div className="reasoning-tool-diagnosis-summary-content">
        <GenSpan
          value={content}
          placeholder="Enter Diagnosis..."
          shouldAutoFocus
          isEditing={isEditing}
          onSpanClick={startNodeEdit}
          onBlur={endNodeEdit}
          onEnterKeyPress={endNodeEdit}
        >
          {content}
        </GenSpan>
      </div>
      <div className="reasoning-tool-diagnosis-summary-buttons">
        <i onClick={() => setIsDangerousDiagnosis(uid, !isDangerousDiagnosis)} className={classNames("icon-warning", "dangerous", {active: isDangerousDiagnosis})}></i>
        <i onClick={() => setIsLeadingDiagnosis(uid)} className={classNames("icon-star", "leading", {active: isLeadingDiagnosis})}></i>
      </div>
    </div>
  );
}
