import * as React from "react";

interface ReasoningToolPanelDeleteCellProps {
  children: React.ReactNode;
  deleteNode: () => void;
};

export default function ReasoningToolPanelDeleteCell({
  children = [],
  deleteNode,
}: ReasoningToolPanelDeleteCellProps) {
  return (
    <div className="reasoning-tool-panel-delete-cell">
      <div
        className="reasoning-tool-panel-delete-cell-delete-button"
        onClick={deleteNode}
      >
        <i className={"icon-trash"}></i>
      </div>
      <div className="reasoning-tool-panel-delete-cell-children">
        {children}
      </div>
    </div>
  );
}
