import * as _ from "lodash";
import * as R from "ramda";
import memoize from "memoize-weak";

import generateUUID from "../../helpers/generateUUID";

import {
  deepFindAllPaths,
  deepFindAll,
  deepFindPath,
  findAtPath,
  setAtPath,
  overAtPath,
  deleteAtPath,
  insertAtPath,
  normalizeDocNode,
  nodeBlockContentKeys,
  nodeInlineContentKeys,
  findNodePathByUid,
  findNodeAtPath,
  editNodeWithUIDKeyValueWithFunction,
  filterPathsByFunction,
  moveAtPathToPath,
  findDeepestLineagePathFromPath,
  arePathsSameLineage,
  findNodeByUid,
  isNode,
  nodeInlineNotFlowContentKeys,
  findNodeParentByUid,
  nodeContentKeyFromPath,
  nodeIndexFromPath,
  nodeParentPathFromPath,
  docMapAllNodes,
} from "./Functions/base";

import {
  generateTableRowWithNTableCells,
  generateTableCell,
  addMissingCellsToTable,
} from "./Functions/table";

import {
  rangesDoTouch,
  rangeLength,
  rangesUnion,
  rangesExcept,
  rangesIntersection,
  shiftRange,
  isRangeCollapsed,
  splitAndShiftRangeAtIndexByValue,
  extendRangeAtIndexByValue,
  deleteRangeAtIndexByValue,
} from "./Functions/Range";

import { convertAllInlineNodesToRangeJSON } from "./Convert/RangeInlineJSON";

import {
  NEW_COMMENT_THREAD,
  EDIT_COMMENT,
} from "./menu"

import * as MenuTypes from "./menu"

export const changesDisplayModes = {
  ONLY_PUBLISHED: "ONLY_PUBLISHED",
  HIDE_SUGGESTIONS: "HIDE_SUGGESTIONS",
  HIGHLIGHT_SUGGESTIONS: "HIGHLIGHT_SUGGESTIONS",
  HIGHLIGHT_ALL_CHANGES: "HIGHLIGHT_ALL_CHANGES",
} as const;

export function isChangesDisplayMode(str: string): str is ChangesDisplayMode {
  return !!Object.values(changesDisplayModes).find((displayMode) => str === displayMode)
}

export const decisionTypes = {
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

// Change Types
export const CHANGE_TEXT_AND_NODES = "CHANGE_TEXT_AND_NODES";
export const MOVE_NODE = "MOVE_NODE";
export const SET_KEYS_VALUES = "SET_KEYS_VALUES";
export const ADD_STYLE = "ADD_STYLE";
export const DELETE_STYLE = "DELETE_STYLE";
export const ADD_TABLE_ROW = "ADD_TABLE_ROW";
export const DELETE_TABLE_ROW = "DELETE_TABLE_ROW";
export const ADD_TABLE_COLUMN = "ADD_TABLE_COLUMN";
export const DELETE_TABLE_COLUMN = "DELETE_TABLE_COLUMN";
export const MERGE_TABLE_CELLS = "MERGE_TABLE_CELLS";
export const UNMERGE_TABLE_CELLS = "UNMERGE_TABLE_CELLS";
export const ACCEPT_CHANGES = "ACCEPT_CHANGES";
export const REJECT_CHANGES = "REJECT_CHANGES";
export const PUBLISH = "PUBLISH";
export const ADD_COMMENT_THREAD = "ADD_COMMENT_THREAD";
export const CLOSE_COMMENT_THREAD = "CLOSE_COMMENT_THREAD";
export const ADD_CHANGE_COMMENT = "ADD_CHANGE_COMMENT";
export const DELETE_CHANGE_COMMENT = "DELETE_CHANGE_COMMENT";
export const ADD_SUMMARY_OF_EDIT = "ADD_SUMMARY_OF_EDIT";
export const DELETE_SUMMARY_OF_EDIT = "DELETE_SUMMARY_OF_EDIT";
export const ADD_STUDENT_FEEDBACK = "ADD_STUDENT_FEEDBACK";
export const DELETE_STUDENT_FEEDBACK = "DELETE_STUDENT_FEEDBACK";

// FAKE CHANGES
export const ADD_NODE = "ADD_NODE";
export const ADD_TEXT_AND_NODES_BEFORE_NODE = "ADD_TEXT_AND_NODES_BEFORE_NODE";
export const ADD_TEXT_AND_NODES_TO_NODE = "ADD_TEXT_AND_NODES_TO_NODE";
export const DELETE_NODE = "DELETE_NODE";
export const MOVE_NODE_UP = "MOVE_NODE_UP";
export const MOVE_NODE_DOWN = "MOVE_NODE_DOWN";

export const ADD_CONTENT = "ADD_CONTENT";
export const DELETE_CONTENT = "DELETE_CONTENT";

// Change Summary Types
export const ADDED_NODE = "ADDED_NODE";
export const DELETED_NODE = "DELETED_NODE";
export const CHANGED_TEXT_AND_NODES = "CHANGED_TEXT_AND_NODES";
export const MOVED_NODE = "MOVED_NODE";
export const CHANGED_KEYS_VALUES = "CHANGED_KEYS_VALUES";
export const CHANGED_UID = "CHANGED_UID";
export const CHANGED_TEXT = "CHANGED_TEXT";
export const ADDED_TEXT = "ADDED_TEXT";
export const DELETED_TEXT = "DELETED_TEXT";
export const ADDED_STYLE = "ADDED_STYLE";
export const DELETED_STYLE = "DELETED_STYLE";
export const ADDED_TABLE_ROW = "ADDED_TABLE_ROW";
export const DELETED_TABLE_ROW = "DELETED_TABLE_ROW";
export const ADDED_TABLE_COLUMN = "ADDED_TABLE_COLUMN";
export const DELETED_TABLE_COLUMN = "DELETED_TABLE_COLUMN";
export const MERGED_TABLE_CELLS = "MERGED_TABLE_CELLS";
export const UNMERGED_TABLE_CELLS = "UNMERGED_TABLE_CELLS";
export const OPENED_INLINE_COMMENT_THREAD = "OPENED_INLINE_COMMENT_THREAD";
export const OPENED_NODE_COMMENT_THREAD = "OPENED_NODE_COMMENT_THREAD";

export const HIDE_TEXT = "HIDE_TEXT";

export function addChangeToDoc(doc, change) {
  // console.log("CHANGE", change);

  if (change.selection) {
    const translatedRange = translateSelectionToFlowRange(doc, change.selection);

    if (translatedRange) {
      // console.log("translatedRange", translatedRange)
      change = R.dissoc('selection', change)
      change.uid = translatedRange.uid;
      change.key = translatedRange.contentKey;
      change.range = translatedRange.range;
    } else {
      console.log("ERROR: COULD NOT TRANSLATE SELECTION TO FLOW RANGE")
      return doc;
    }
  }

  switch (change.type) {
    case CHANGE_TEXT_AND_NODES:
      return addChangeTextAndNodesChange(doc, change);
    case ADD_NODE:
      return addAddNodeChange(doc, change);
    case ADD_TEXT_AND_NODES_BEFORE_NODE:
      return addAddTextAndNodesBeforeNodeChange(doc, change);
    case ADD_TEXT_AND_NODES_TO_NODE:
      return addAddTextAndNodesToNodeChange(doc, change);
    case DELETE_NODE:
      return addDeleteNodeChange(doc, change);
    case MOVE_NODE:
      return addMoveNodeChange(doc, change);
    case MOVE_NODE_UP:
      return addMoveNodeUpChange(doc, change);
    case MOVE_NODE_DOWN:
      return addMoveNodeDownChange(doc, change);
    case SET_KEYS_VALUES:
      return addSetKeyValuesChange(doc, change);
    case ADD_STYLE:
    case DELETE_STYLE:
      return addStyleChange(doc, change);
    case ADD_TABLE_ROW:
      return addAddTableRowChangeToNodeWithUID(doc, change);
    case DELETE_TABLE_ROW:
      return addDeleteTableRowChangeToNodeWithUID(doc, change);
    case ADD_TABLE_COLUMN:
      return addAddTableColumnChange(doc, change);
    case DELETE_TABLE_COLUMN:
      return addDeleteTableColumnChange(doc, change);
    case MERGE_TABLE_CELLS:
    case UNMERGE_TABLE_CELLS:
      return addTableCellSpanChangeToNodeWithUID(doc, change);
    case ACCEPT_CHANGES:
      const acceptChangePaths = findAllChangePathsByChangeId(doc, change.accept_change_id);
      doc = acceptChangePaths.reduce((reduceDoc, changePath) => markChangeAtPathAccepted(reduceDoc, changePath, change), doc);

      // For any move/delete
      // Remove old moveSummary?

      return doc;
    case REJECT_CHANGES:
      const rejectChangePaths = findAllChangePathsByChangeId(doc, change.reject_change_id);
      doc = rejectChangePaths.reduce((reduceDoc, changePath) => markChangeAtPathRejected(reduceDoc, changePath, change), doc);

      // For any move/delete
      // Remove old moveSummary?

      return doc;
    case PUBLISH:
      const changePaths = findAllPublishableChangePaths(doc);
      doc = changePaths.reduce((reduceDoc, changePath) => markChangeAtPathPublished(reduceDoc, changePath, change), doc);

      // Clean out all old published changes and stuff

      return doc;
    case ADD_COMMENT_THREAD:
      return addAddCommentThread(doc, change);
    case CLOSE_COMMENT_THREAD:
      return deleteChangeWithID(doc, change);
    case ADD_CHANGE_COMMENT:
      return addCommentToChangeWithID(doc, change.parent_change_id, change);
    case DELETE_CHANGE_COMMENT:
      return deleteCommentWithID(doc, change.comment_id, change);
    case ADD_SUMMARY_OF_EDIT:
      return addSummaryOfEditChange(doc, change);
    case DELETE_SUMMARY_OF_EDIT:
      return deleteSummaryOfEditChange(doc, change);
    case ADD_STUDENT_FEEDBACK:
      return addStudentFeedbackChange(doc, change);
    case DELETE_STUDENT_FEEDBACK:
      return deleteStudentFeedbackChange(doc, change);
  }
}


///////////////////////////////////////
///////////////////////////////////////
///////////////////////////////////////

export const weakMemoizedApplyAllChanges = memoize(applyAllChanges);
export function applyAllChanges(node, changesDisplayMode, editIndex = 0) {
  if (isMoveSummary(node) || !isRelevantNode(node, changesDisplayMode)) { return null; }

  node = applyEditIndexToNode(node, editIndex);
  node = applyInlineChangesToNode(node, changesDisplayMode);
  node = applyKeyValueChangesToNode(node, changesDisplayMode);
  node = applyTableChangesToNode(node, changesDisplayMode);
  node = applyBlockChangeHighlightsToNode(node, changesDisplayMode);

  // TODO MOVE NODES TO CORRECT PLACE
  if (["section", "tableCell"].includes(node.type)) {
    // TODO ADD CORRECT EDIT INDEX
    node = applyListIndentKeyValueChangesToChildren(node, changesDisplayMode);

    node = {
      ...node,
      content: flatFlowToTreeFlow(node.content, node.contentListNodes, changesDisplayMode)
    }
  }

  node = applyAllChangesToChildren(node, changesDisplayMode);

  return node;
}

// Create a new Map({})
function mergeChangeMaps(map1, map2) {
  for (const [key, value] of map2) {
    if (map1.get(key)) {
      map1.set(key, map1.get(key).concat(value));
    } else {
      map1.set(key, value);
    }
  }

  return map1;
}

function addChangeToChangeMap(changeMap, change) {
  if (changeMap.get(change.change_id)) {
    changeMap.get(change.change_id).push(change);
  } else {
    changeMap.set(change.change_id, [change]);
  }
}

export const weakMemoizedCollectAllSuggestionChanges = memoize(collectAllSuggestionChanges);
function collectAllSuggestionChanges(node, variantId=undefined) {
  const newChangeMap = new Map();

  const newVariantId = node.variantId || variantId

  mergeChangeMaps(newChangeMap, collectSuggestionAddDeleteChanges(node, newVariantId));
  mergeChangeMaps(newChangeMap, collectSuggestionKeyValueChanges(node, newVariantId));
  mergeChangeMaps(newChangeMap, collectSuggestionInlineChanges(node, newVariantId));
  mergeChangeMaps(newChangeMap, collectSuggestionTableChanges(node, newVariantId));
  mergeChangeMaps(newChangeMap, collectSuggestionBlockChanges(node, newVariantId));

  return newChangeMap;
}

function collectSuggestionBlockChanges(node, variantId=undefined) {
  const newChangeMap = new Map();

  nodeBlockContentKeys(node).forEach((nodeKey) => {
    if (node[nodeKey]) {
      node[nodeKey].forEach(blockNode => {
        mergeChangeMaps(newChangeMap, weakMemoizedCollectAllSuggestionChanges(blockNode, variantId));
      })
    }
  })

  return newChangeMap;
}

function collectSuggestionAddDeleteChanges(node, variantId=undefined) {
  const newChangeMap = new Map();

  const allChanges = node.addDeleteChanges || [];
  let suggestionChanges = allChanges.filter((change) => isOpenSuggestionChange(change));

  if (suggestionChanges[0] && suggestionChanges[0].uid !== node.uid) {
    suggestionChanges = suggestionChanges.map(change => {
      return {
        ...change,
        uid: node.uid,
        changeNodeType: node.type
      }
    })
  }

  if (variantId) {
    suggestionChanges = suggestionChanges.map(change => {
      return {
        ...change,
        variantId: variantId,
      }
    })
  }

  suggestionChanges.forEach(change => {
    addChangeToChangeMap(newChangeMap, change);
  })

  return newChangeMap;
}

function collectSuggestionKeyValueChanges(node, variantId=undefined) {
  const newChangeMap = new Map();

  const allChanges = node.key_value_changes || [];
  let suggestionChanges = allChanges.filter((change) => isOpenSuggestionChange(change));

  if (variantId) {
    suggestionChanges = suggestionChanges.map(change => {
      return {
        ...change,
        variantId: variantId,
      }
    })
  }

  suggestionChanges.forEach(change => {
    addChangeToChangeMap(newChangeMap, change);
  });

  return newChangeMap;
}

function collectSuggestionInlineChanges(node, variantId=undefined) {
  const newChangeMap = new Map();

  nodeInlineContentKeys(node).forEach((nodeKey) => {
    if (node[nodeKey]) {
      const allChanges = [
        ...node[nodeKey].addDeleteChanges || [],
        ...node[nodeKey].styleChanges || [],
        ...node[nodeKey].commentThreadChanges || [],
      ]

      let suggestionChanges = allChanges.filter((change) => isOpenSuggestionChange(change));

      if (suggestionChanges[0]) {
        suggestionChanges = suggestionChanges.map(change => {
          return {
            text: node[nodeKey].text.slice(change.range.start, change.range.end),
            ...change,
            uid: node.uid,
            key: nodeKey,
          }
        })
      }

      if (variantId) {
        suggestionChanges = suggestionChanges.map(change => {
          return {
            ...change,
            variantId: variantId,
          }
        })
      }

      suggestionChanges.forEach(change => {
        addChangeToChangeMap(newChangeMap, change);
      })
    }
  })

  return newChangeMap;
}

function collectSuggestionTableChanges(node, variantId=undefined) {
  const {
    rowChanges = [],
    columnChanges = [],
    cellSpanChanges = [],
  } = node

  const newChangeMap = new Map();

  const allTableChanges = [
    ...R.flatten(rowChanges),
    ...R.flatten(columnChanges),
    ...cellSpanChanges
  ]

  allTableChanges.forEach(change => {
    if (isOpenSuggestionChange(change)) {
      change = {
        ...change,
        uid: change.table_uid
      };

      if (variantId) {
        change = {
          ...change,
          variantId: variantId,
        }
      }

      addChangeToChangeMap(newChangeMap, change);
    }
  });

  return newChangeMap;
}

function applyEditIndexToNode(node, editIndex) {
  return R.assoc("editIndex", editIndex, node);
}

function applyInlineChangesToNode(node, changesDisplayMode) {
  return nodeInlineContentKeys(node).reduce((node, nodeKey) => {
    return R.when(
      R.has(nodeKey),
      R.over(R.lensProp(nodeKey), (rangeInlineJSON) => applyInlineChangesToRangeInlineJson(rangeInlineJSON, changesDisplayMode)),
      node,
    );
  }, node);
}

function applyKeyValueChangesToNode(node, changesDisplayMode) {
  return filterRelevantChanges(node.key_value_changes || [], changesDisplayMode).reduce((node, kvChange) => {
    return {...node, ...kvChange.keys_and_values};
  }, node);
}

function applyListIndentKeyValueChangesToChildren(node, changesDisplayMode) {
  return nodeBlockContentKeys(node).reduce((node, nodeKey) => {
    return R.when(
      R.has(nodeKey),
      R.over(
        R.lensProp(nodeKey), R.pipe(
        R.map((blockNode: any) => {
          if (blockNode.type === "listItem") {
            return weakMemoizedApplyListIndentKeyValueChangesToNode(blockNode, changesDisplayMode);
          } else {
            return blockNode;
          }
        }),
      )),
      node,
    );
  }, node);
}


export const weakMemoizedApplyListIndentKeyValueChangesToNode = memoize(applyListIndentKeyValueChangesToNode);
function applyListIndentKeyValueChangesToNode(node, changesDisplayMode) {
  return applyKeyValueChangesToNode(node, changesDisplayMode);
}

function applyAllChangesToChildren(node, changesDisplayMode) {
  return nodeBlockContentKeys(node).reduce((node, nodeKey) => {
    return R.when(
      R.has(nodeKey),
      R.over(R.lensProp(nodeKey), R.pipe(
        R.addIndex(R.map)((blockNode, index) => weakMemoizedApplyAllChanges(blockNode, changesDisplayMode, index)),
        R.reject(R.isNil),
      )),
      node,
    );
  }, node);
}

export const weakMemoizedSetBlockNodesContainsSelection = memoize(setBlockNodesContainsSelection);
function setBlockNodesContainsSelection(doc, docSelection) {
  const uidToLockAround = docSelection.start.uid;
  const nodePath = findNodePathByUid(doc, uidToLockAround);

  if (!nodePath) { return doc; }

  const node = findNodeAtPath(doc, nodePath);

  // REVISE TEST
  if (!["paragraph", "listItem"].includes(node.type) && docSelection.start.contentKey) { return doc; }

  let pathToLockFrom = nodePath
  if (!docSelection.start.contentKey) {
    pathToLockFrom = nodeParentPathFromPath(doc, nodePath);
  }

  const toLockAncestorPath = findDeepestLineagePathFromPath(doc, pathToLockFrom, (node) => ["section", "tableCell"].includes(node.type));

  if (!toLockAncestorPath) { return doc; }

  return setAtPath(doc, [...toLockAncestorPath, "blockNodesContainsSelection"], true);
}

export const weakMemoizedAttachCurrentMenuToNode = memoize(attachCurrentMenuToNode);
function attachCurrentMenuToNode(doc, currentMenu, localClipboard) {
  if (currentMenu.uid) {
    const nodePath = findNodePathByUid(doc, currentMenu.uid);
    if (nodePath) {
      doc = setAtPath(doc, [...nodePath, "currentMenu"], currentMenu);

      if (currentMenu.menu === MenuTypes.ADD_NODE) {
        doc = setAtPath(doc, [...nodePath, "localClipboard"], localClipboard);
      }
    }
  }

  return doc;
}

///////////////////////////////////////
//////////// REORGANIZE ///////////////
///////////////////////////////////////

export const weakMemoizedConvertAllToFlatFlowAndInlineRangeJSON = memoize(convertAllToFlatFlowAndInlineRangeJSON);
export function convertAllToFlatFlowAndInlineRangeJSON(node) {
  node = convertAllTablesToFullCells(node);
  node = convertAllInlineNodesToRangeJSON(node);
  node = convertAllTreeFlowToFlatFlow(node);

  return node;
}

export function convertAllTablesToFullCells(node) {
  if (node.type === "table") {
    node = addMissingCellsToTable(node);
  }

  node = nodeBlockContentKeys(node).reduce((node, nodeKey) => {
    return R.when(
      R.has(nodeKey),
      R.over(R.lensProp(nodeKey), (childNodes) => childNodes.map(childNode => convertAllTablesToFullCells(childNode))),
      node
    );
  }, node);

  return node;
}

export function convertAllTreeFlowToFlatFlow(node) {
  switch (node.type) {
    case "paragraph":
    case "listItem":
      return node;
    case "section":
    case "tableCell":
      if (!node.contentListNodes) {
        const {
          nodes,
          listNodes,
        } = treeFlowToFlatFlow(node.content);

        node = {
          ...node,
          content: nodes,
          contentListNodes: listNodes,
        }
      }
    default:
  }

  node = nodeBlockContentKeys(node).reduce((node, nodeKey) => {
    return R.when(
      R.has(nodeKey),
      R.over(R.lensProp(nodeKey), (childNodes) => childNodes.map(childNode => convertAllTreeFlowToFlatFlow(childNode))),
      node
    );
  }, node);

  return node;
}

function treeFlowToFlatFlow(treeFlowNodes, listUid=null, listIndentation = -1) {
  treeFlowNodes = Array.isArray(treeFlowNodes) ? treeFlowNodes : [treeFlowNodes];
  return treeFlowNodes.reduce((flatFlowData, treeFlowNode) => {
    let localListUid = listUid || treeFlowNode.listUid;
    let localListIndentation = typeof treeFlowNode.listIndentation === "number" ? treeFlowNode.listIndentation : listIndentation;
    localListIndentation = Math.max(localListIndentation, 0);

    if (localListUid) {
      treeFlowNode = {
        ...treeFlowNode,
        listUid: localListUid,
        listIndentation: localListIndentation,
      }
    }

    switch (treeFlowNode.type) {
      case "regularList":
      case "numberedList":
        const returnedListData = treeFlowToFlatFlow(treeFlowNode["content"], localListUid || treeFlowNode.uid, listIndentation + 1);
        const cleanedListNode = R.dissoc("content", treeFlowNode);

        return {
          nodes: R.concat(flatFlowData.nodes, returnedListData.nodes),
          listNodes: R.assoc(treeFlowNode.uid, cleanedListNode, flatFlowData.listNodes),
        }
      case "listItem":
        const returnedListItemData = treeFlowToFlatFlow(treeFlowNode["content"], localListUid, localListIndentation);
        const cleanedListItemNode = R.assoc("content", [], treeFlowNode);

        return {
          ...flatFlowData,
          nodes: [...flatFlowData.nodes, cleanedListItemNode, ...returnedListItemData.nodes],
        }
      case "paragraph":
      default:
        return {
          ...flatFlowData,
          nodes: [...flatFlowData.nodes, treeFlowNode],
        }
    }
  }, {nodes: [], listNodes: {}});
}

export function flatFlowToTreeFlow(flatFlowNodes, listNodes, changesDisplayMode) {
  return flatFlowNodes.reduce((treeFlowNodes, flatFlowNode) => {
    const prevTreeFlowNode = R.last(treeFlowNodes as any[]);
    if (!prevTreeFlowNode) {
      if (isRelevantNode(flatFlowNode, changesDisplayMode)) {
        flatFlowNode = weakMemoizedNewListIfNeeded(flatFlowNode, listNodes);
        return R.append(flatFlowNode, treeFlowNodes)
      } else {
        return treeFlowNodes
      }
    } else {
      switch (prevTreeFlowNode.type) {
        case "paragraph":
          if (isRelevantNode(flatFlowNode, changesDisplayMode)) {
            flatFlowNode = weakMemoizedNewListIfNeeded(flatFlowNode, listNodes);
            return R.append(flatFlowNode, treeFlowNodes);
          } else {
            const newLastNode = weakMemoizedAppendNodeToParagraph(prevTreeFlowNode, flatFlowNode);
            return R.update(-1, newLastNode, treeFlowNodes);
          }
        case "regularList":
        case "numberedList":
          if (isRelevantNode(flatFlowNode, changesDisplayMode)) {
            if (["paragraph", "listItem"].includes(flatFlowNode.type) && flatFlowNode.listUid === prevTreeFlowNode.uid){
              const newLastNode = weakMemoizedAppendNodeToList(prevTreeFlowNode, flatFlowNode);
              return R.update(-1, newLastNode, treeFlowNodes);
            } else {
              flatFlowNode = weakMemoizedNewListIfNeeded(flatFlowNode, listNodes);
              return R.append(flatFlowNode, treeFlowNodes);
            }
          } else {
            const newLastNode = weakMemoizedAppendNodeToLastParagraphInList(prevTreeFlowNode, flatFlowNode);
            return R.update(-1, newLastNode, treeFlowNodes);
          }
        default:
          if (isRelevantNode(flatFlowNode, changesDisplayMode)) {
            flatFlowNode = weakMemoizedNewListIfNeeded(flatFlowNode, listNodes);
            return R.append(flatFlowNode, treeFlowNodes);
          } else {
            return treeFlowNodes;
          }
      }
    }
  }, [])
}

export function findFlatFlowNodesWithFlowRange(doc, flowRange) {
  const parentNode = findNodeByUid(doc, flowRange.uid);
  const contentNodes = parentNode[flowRange.contentKey];
  const contentListNodes = parentNode[`${flowRange.contentKey}ListNodes`];

  if (!isBlock(contentNodes) && !isBlockChangeSet(contentNodes)) { return null; }

  const startNodeOffset = findNodeOffsetFromFlattenedNodes(contentNodes, flowRange.range.start);
  const endNodeOffset = findNodeOffsetFromFlattenedNodes(contentNodes, flowRange.range.end);

  let inRangeFlatFlowNodes = [];

  let startNode = contentNodes[startNodeOffset.nodeIndex];
  if (startNodeOffset.innerIndex < flattenedNodeLength(startNode)) {
    if (startNode.type === "paragraph") {
      let newContent = startNode.content;

      if (startNodeOffset.nodeIndex === endNodeOffset.nodeIndex) {
        newContent = beforeRangeInlineJSONAt(newContent, endNodeOffset.innerIndex - 1);
      }

      newContent = afterRangeInlineJSONAt(newContent, startNodeOffset.innerIndex - 1)

      startNode = {
        ...startNode,
        content: newContent,
      }
    }

    inRangeFlatFlowNodes.push(startNode);
  }

  if (startNodeOffset.nodeIndex + 1 < endNodeOffset.nodeIndex) {
    inRangeFlatFlowNodes = inRangeFlatFlowNodes.concat(contentNodes.slice(startNodeOffset.nodeIndex + 1, endNodeOffset.nodeIndex));
  }

  if (startNodeOffset.nodeIndex != endNodeOffset.nodeIndex && endNodeOffset.innerIndex > 0) {
    let endNode = contentNodes[endNodeOffset.nodeIndex];

    if (endNode.type === "paragraph") {
      endNode = {
        ...endNode,
        content: beforeRangeInlineJSONAt(endNode.content, endNodeOffset.innerIndex - 1),
      }
    }

    inRangeFlatFlowNodes.push(endNode);
  }

  return {
    nodes: inRangeFlatFlowNodes,
    listNodes: contentListNodes,
  }
}

const weakMemoizedNewListIfNeeded = memoize(newListIfNeeded);
function newListIfNeeded(flatFlowNode, listNodes={}) {
  if (flatFlowNode.type === "listItem") {
    const list = listNodes[flatFlowNode.listUid]
    return weakMemoizedCombineListAndListItem(list, flatFlowNode);
  } else {
    return flatFlowNode;
  }
}

const weakMemoizedCombineListAndListItem = memoize(combineListAndListItem);
function combineListAndListItem(list, listItem) {
  return {
    ...list,
    content: safeAppend(list.content, listItem),
  }
}

const weakMemoizedAppendNodeToParagraph = memoize(appendNodeToParagraph);
function appendNodeToParagraph(paragraphNode, otherNode) {
  let newContent = concatRangeInlineJSON(
    paragraphNode.content,
    {
      text: "#",
      addDeleteChanges: [
        {
          type: "DELETE_CONTENT",
          range: {
            start: 0,
            end: 1,
          }
        }
      ]
    }
  )

  if (otherNode.type === "paragraph") {
    newContent = concatRangeInlineJSON(newContent, otherNode.content)
  }

  return {
    ...paragraphNode,
    content: newContent,
  }
}

const weakMemoizedAppendNodeToList = memoize(appendNodeToList);
function appendNodeToList(listNode, otherNode) {
  switch (otherNode.type) {
    case "listItem":
      if (typeof otherNode.listIndentation === "number") {
        const indentedListPath = findLastListOfIndentPath(listNode, otherNode.listIndentation)

        if (indentedListPath) {
          return R.over(R.lensPath([...indentedListPath, "content"]), (content) => safeAppend(content, otherNode), listNode);
        } else {
          const lastListItemPath = findLastListItemPath(listNode);
          if (lastListItemPath) {
            const newListNode = {
              type: listNode.type,
              uid: `list-${otherNode.uid}`,
              content: [otherNode],
            }

            return R.over(R.lensPath([...lastListItemPath, "content"]), (content) => safeAppend(content, newListNode), listNode);
          } else {
            const lastListPath = findLastListPath(listNode);
            return R.over(R.lensPath([...lastListPath, "content"]), (content) => safeAppend(content, otherNode), listNode);
          }
        }
      } else {
        const lastListPath = findLastListPath(listNode)
        return R.over(R.lensPath([...lastListPath, "content"]), (content) => safeAppend(content, otherNode), listNode);
      }
    case "paragraph":
    default:
      const lastListItemPath = findLastListItemPath(listNode);
      return R.over(R.lensPath([...lastListItemPath, "content"]), (content) => safeAppend(content, otherNode), listNode);
  }
}


const weakMemoizedAppendNodeToLastParagraphInList = memoize(appendNodeToLastParagraphInList);
function appendNodeToLastParagraphInList(listNode, otherNode) {
  const lastListItemPath = findLastListItemPath(listNode);

  if (!lastListItemPath) {
    return listNode;
  }

  const lastListItem = R.view(R.lensPath(lastListItemPath), listNode);
  const lastListItemIndex = lastListItem.content.length - 1

  if (lastListItemIndex === -1) {
    return listNode;
  }

  const lastNode = lastListItem.content[lastListItemIndex]

  if (lastNode.type !== "paragraph") {
    return listNode;
  }

  return R.over(R.lensPath([...lastListItemPath, "content", lastListItemIndex]), (paragraphNode) => weakMemoizedAppendNodeToParagraph(paragraphNode, otherNode), listNode);
}


function findLastListOfIndentPath(node, indent) {
  switch (node.type) {
    case "regularList":
    case "numberedList":
      if (indent === 0) {
        return [];
      }

      const lastListIndex = node.content.length - 1;
      if (lastListIndex === -1) {
        return null;
      }

      const remainingListPath = findLastListOfIndentPath(node.content[lastListIndex], indent - 1);
      if (!remainingListPath) {
        return null;
      }

      return ["content", lastListIndex, ...remainingListPath];
    case "listItem":
      const lastListItemIndex = node.content.length - 1;
      if (lastListItemIndex === -1) {
        return null;
      }

      const remainingListItemPath = findLastListOfIndentPath(node.content[lastListItemIndex], indent);
      if (!remainingListItemPath) {
        return null;
      }

      return ["content", lastListItemIndex, ...remainingListItemPath];
    default:
      return null;
  }
}

function findLastListPath(node) {
  switch (node.type) {
    case "regularList":
    case "numberedList":
      const lastListIndex = node.content.length - 1;
      if (lastListIndex === -1) {
        return [];
      }

      const remainingListPath = findLastListPath(node.content[lastListIndex]);
      if (!remainingListPath) {
        return [];
      }

      return ["content", lastListIndex, ...remainingListPath];
    case "listItem":
      const lastListItemIndex = node.content.length - 1;
      if (lastListItemIndex === -1) {
        return null;
      }

      const remainingListItemPath = findLastListPath(node.content[lastListItemIndex]);
      if (!remainingListItemPath) {
        return null;
      }

      return ["content", lastListItemIndex, ...remainingListItemPath];
    default:
      return null;
  }
}

function findLastListItemOfIndentPath(node, indent) {
  const lastListPath = findLastListOfIndentPath(node, indent);

  if (!lastListPath) {
    return null;
  }

  const lastList = R.view(R.lensPath(lastListPath), node);

  const lastListLastIndex = lastList.content.length - 1;

  if (lastListLastIndex === -1) {
    return null;
  }

  if (lastList.content[lastListLastIndex].type === "listItem") {
    return [...lastListPath, "content", lastListLastIndex];
  } else {
    return null;
  }
}

function findLastListItemPath(node) {
  const lastListPath = findLastListPath(node);

  if (!lastListPath) {
    return null;
  }

  const lastList = R.view(R.lensPath(lastListPath), node);

  const lastListLastIndex = lastList.content.length - 1;

  if (lastListLastIndex === -1) {
    return null;
  }

  if (lastList.content[lastListLastIndex].type === "listItem") {
    return [...lastListPath, "content", lastListLastIndex];
  } else {
    return null;
  }
}

function safeAppend(array=[], element) {
  return R.append(element, array);
}

function safeConcat(array1=[], array2=[]) {
  return array2.length > 0 ? R.concat(array1, array2) : array1;
}

function safeMerge(obj1={}, obj2={}) {
  return R.keys(obj2).length > 0 ? R.mergeRight(obj1, obj2) : obj1;
}

function shiftChildRange(rangeParent, value) {
  return {
    ...rangeParent,
    range: shiftRange(rangeParent.range, value),
  }
}

function flattenedNodeLength(node) {
  if (node.type === "paragraph") {
    return 1 + node.content.text.length;
  } else {
    return 1;
  }
}

export function translateSelectionToFlowRange(doc, docSelection) {
  const startPath = findNodePathByUid(doc, docSelection.start.uid);
  const endPath = findNodePathByUid(doc, docSelection.end.uid);

  if (!startPath || !endPath) { return null; }

  const fullStartPath = docSelection.start.contentKey ? [...startPath, docSelection.start.contentKey] : startPath;
  const fullEndPath = docSelection.end.contentKey ? [...endPath, docSelection.end.contentKey] : endPath;

  const startFlowRootPath = flowRootPathFromPath(doc, fullStartPath);
  const endFlowRootPath = flowRootPathFromPath(doc, fullEndPath);

  if (!startFlowRootPath || !R.equals(startFlowRootPath, endFlowRootPath)) { return null; }

  let baseContent = findAtPath(doc, startFlowRootPath);
  const baseNode = findAtPath(doc, R.init(startFlowRootPath));

  if (
    isBlock(baseContent) ||
    isBlockChangeSet(baseContent)
  ) {
    const startNodeIndex = baseContent.findIndex(node => node.uid === docSelection.start.uid);
    const startNode = baseContent[startNodeIndex];
    const beforeStartLength = R.sum(R.slice(0, startNodeIndex, baseContent as any[]).map(node => flattenedNodeLength(node)));
    const startIndex = startNode.type === "paragraph" ? beforeStartLength + docSelection.start.index + 1 : beforeStartLength + docSelection.start.index;

    const endNodeIndex = baseContent.findIndex(node => node.uid === docSelection.end.uid);
    const endNode = baseContent[endNodeIndex];
    const beforeEndLength = R.sum(R.slice(0, endNodeIndex, baseContent as any[]).map(node => flattenedNodeLength(node)));
    const endIndex = endNode.type === "paragraph" ? beforeEndLength + docSelection.end.index + 1 : beforeEndLength + docSelection.end.index;

    return {
      uid: baseNode.uid,
      contentKey: R.last(startFlowRootPath),
      range: {
        start: startIndex,
        end: endIndex,
      }
    }
  } else if (
    isInline(baseContent) ||
    isInlineChangeSet(baseContent)
  ) {
    return {
      uid: baseNode.uid,
      contentKey: R.last(startFlowRootPath),
      range: {
        start: docSelection.start.index,
        end: docSelection.end.index,
      }
    }
  } else {
    // ERROR
  }
}

export function isBlock(content) {
  return Array.isArray(content) && (content.length === 0 || isBlockNode(content[0]));
}

function isBlockNode(content) {
  return typeof content === 'object' && !!content.uid && !!content.type;
}

export function isBlockChangeSet(content) {
  return typeof content === 'object' && !!content.nodes;
}

function isInline(content) {
  return typeof content === 'string';
}

function isInlineChangeSet(content) {
  return typeof content === 'object' && typeof content.text === 'string';
}

export function flowRootPathFromPath(doc, path) {
  const possibleKey = R.last(path);
  const possibleNodePath = R.init(path);

  if (R.isNil(possibleKey)) { return null };

  if (typeof possibleKey !== "string") { return flowRootPathFromPath(doc, possibleNodePath); }

  const possibleNode = findAtPath(doc, possibleNodePath)

  if (!isNode(possibleNode)) { return flowRootPathFromPath(doc, possibleNodePath); }

  if (
    !["paragraph", "listItem", "regularList", "numberedList"].includes(possibleNode.type)
  ) {
    return [...possibleNodePath, possibleKey];
  } else {
    return flowRootPathFromPath(doc, possibleNodePath);
  }
}

function findNodeOffsetFromFlattenedNodes(nodes, index: number) {
  if (index <= 0) {
    return {
      nodeIndex: 0,
      innerIndex: 0,
    }
  }

  let nodeOffset = nodes.reduce((agg, node) => {
    if (!agg.innerIndex) {
      const restOfIndex = agg.remainingIndex - flattenedNodeLength(node)
      if (restOfIndex <= 0) {
        return {
          nodeIndex: agg.nodeIndex,
          innerIndex: agg.remainingIndex,
        }
      } else {
        return {
          remainingIndex: restOfIndex,
          nodeIndex: agg.nodeIndex + 1,
        }
      }
    } else {
      return agg;
    }
  }, {remainingIndex: index, nodeIndex: 0})

  return nodeOffset.innerIndex ? nodeOffset : null
}

// RENAME TO RANGED CHANGE
function appendRangedItemToFlattenedNodes(nodes, rangedItemsName, rangedItem) {
  return nodes.reduce((agg, node) => {
    const nodeLength = flattenedNodeLength(node);

    return {
      offsetRangedItem: {
        ...agg.offsetRangedItem,
        range: shiftRange(agg.offsetRangedItem.range, -1 * nodeLength)
      },
      newNodes: [
        ...agg.newNodes,
        appendRangedItemToFlatNode(node, rangedItemsName, agg.offsetRangedItem)
      ],
    }

  }, {offsetRangedItem: rangedItem, newNodes: []}).newNodes;
}

function appendRangedItemToFlatNode(node, rangedItemsName, rangedItem) {
  if (rangedItem.range.start < 1 && rangedItem.range.end > 0) {
    const newRangedItem = {
      ...rangedItem,
      range: {
        start: 0,
        end: 1,
      }
    }

    let newRangedItems = node[rangedItemsName] || [];
    let toCombineRangeIndex = R.findLastIndex((rangedItemB: any) => {
      return (
        rangedItemB.type === newRangedItem.type &&
        rangedItemB.change_id === newRangedItem.change_id &&
        rangesDoTouch(rangedItemB.range, newRangedItem.range)
      );
    }, newRangedItems);
    if (toCombineRangeIndex >= 0) {
      newRangedItems = R.adjust(toCombineRangeIndex, (rangedItemB: any) => {
        return {
          ...newRangedItem,
          range: rangesUnion(rangedItemB.range, newRangedItem.range)
        };
      }, newRangedItems);
    } else {
      newRangedItems = R.append(newRangedItem, newRangedItems);
    }

    node = R.assoc(rangedItemsName, newRangedItems, node)
  }

  if (node["type"] === "paragraph") {
    if (rangedItem.range.start < node.content.text.length + 1 && rangedItem.range.end > 1) {
      const newRangedItem = {
        ...rangedItem,
        range: {
          start: R.max(0, rangedItem.range.start - 1),
          end: R.min(node.content.text.length, rangedItem.range.end - 1),
        }
      }

      node = R.over(R.lensPath(["content"]), (rangeInlineJSON) => {
        return appendRangedItemToRangeInlineJSON(rangeInlineJSON, rangedItemsName, newRangedItem);
      }, node)
    }
  }

  return node;
}

function appendRangedItemToRangeInlineJSON(rangeInlineJSON, rangedItemsName, rangedItem) {
  let rangesToRemove = [];
  if (rangedItemsName === "addDeleteChanges" && rangedItem.type === DELETE_CONTENT && rangeInlineJSON[rangedItemsName]) {
    const addRangeItemsToCheck = rangeInlineJSON[rangedItemsName].filter(rangedItemB => {
      return (
        canCompressChanges(rangedItem, rangedItemB) &&
        rangesDoTouch(rangedItem.range, rangedItemB.range) &&
        rangedItemB.type === ADD_CONTENT
      );
    })

    rangesToRemove = addRangeItemsToCheck.map(addRangeItem => rangesIntersection(addRangeItem.range, rangedItem.range));
  }

  rangeInlineJSON = R.over(R.lensPath([rangedItemsName]), (rangedItems) => {
    let newRangedItems = rangedItems || [];
    let toCombineRangeIndex = R.findLastIndex((rangedItemB: any) => {
      return (
        rangedItemB.type === rangedItem.type &&
        rangedItemB.change_id === rangedItem.change_id &&
        rangesDoTouch(rangedItemB.range, rangedItem.range)
      );
    }, newRangedItems);

    if (toCombineRangeIndex >= 0) {
      newRangedItems = R.adjust(toCombineRangeIndex, (rangedItemB: any) => {
        return {
          ...rangedItem,
          range: rangesUnion(rangedItemB.range, rangedItem.range)
        };
      }, newRangedItems);
    } else {
      newRangedItems = R.append(rangedItem, newRangedItems);
    }

    return newRangedItems;
  }, rangeInlineJSON);

  R.reverse(rangesToRemove).forEach(rangeToRemove => {
    rangeInlineJSON = removeRangeFromRangeInlineJSON(rangeInlineJSON, rangeToRemove)
  })

  return rangeInlineJSON;
}

function isFlowTypeAndKey(type, key) {
  return (
    (type === "section" && key === "content") ||
    (type === "tableCell" && key === "content")
  )
}

function isSecondaryTypeAndKey(type, key) {
  return (
    (type === "section" && key === "title") ||
    (type === "image" && key === "title") ||
    (type === "audio" && key === "title") ||
    (type === "video" && key === "title")
    // TODO FILL IN WITH REST OF ITEMS
  )
}

function appendChangeToChanges(changes, change) {
  if (!changes) {changes = [];}

  const previousChange = R.last(changes);
  if (previousChange) {
    const compressedChange = compressTwoChangesIfPossible(previousChange, change);

    if (compressedChange) {
      return R.update(-1, compressedChange, changes);
    }
  }

  return R.append(change, changes);
}

const possibleRangeKeys = [
  "styleRanges",
  "styleChanges",
  "addDeleteChanges",
  "commentThreadChanges",
  // ADD MORE POSSIBLE RANGES
]

function concatRangeInlineJSON(rangeInline1, rangeInline2) {
  let newRangeInlineJSON = {
    ...rangeInline1,
    text: `${rangeInline1.text}${rangeInline2.text}`,
  }

  possibleRangeKeys.forEach(possibleRangeKey => {
    if (rangeInline2[possibleRangeKey]) {
      const shiftedRanges = rangeInline2[possibleRangeKey].map(possibleRange => shiftChildRange(possibleRange, rangeInline1.text.length));
      newRangeInlineJSON[possibleRangeKey] = safeConcat(newRangeInlineJSON[possibleRangeKey], shiftedRanges)
    }
  })

  return newRangeInlineJSON;
}

function insertRangeInlineJSONAtIndex(rangeInline1, rangeInline2, index: number) {
  const beforeText = rangeInline1.text.slice(0, index);
  const afterText = rangeInline1.text.slice(index);

  let newRangeInlineJSON = {
    ...rangeInline1,
    text: `${beforeText}${rangeInline2.text}${afterText}`,
  }

  const insertLength = rangeInline2.text.length;

  possibleRangeKeys.forEach(possibleRangeKey => {
    if (newRangeInlineJSON[possibleRangeKey]) {
      if (possibleRangeKey.includes("addDeleteChanges")) {
        newRangeInlineJSON[possibleRangeKey] = newRangeInlineJSON[possibleRangeKey].flatMap((rangedItem) =>
          splitAndShiftRangeAtIndexByValue(rangedItem.range, index, insertLength).map((newRanges) => (
            {
              ...rangedItem,
              range: newRanges,
            }
          ))
        );
      } else {
        newRangeInlineJSON[possibleRangeKey] = newRangeInlineJSON[possibleRangeKey].map((rangedItem) => ({
          ...rangedItem,
          range: extendRangeAtIndexByValue(rangedItem.range, index, insertLength),
        }));
      }
    }

    if (rangeInline2[possibleRangeKey]) {
      const shiftedRanges = rangeInline2[possibleRangeKey].map(possibleRange => shiftChildRange(possibleRange, rangeInline1.text.length));
      newRangeInlineJSON[possibleRangeKey] = safeConcat(newRangeInlineJSON[possibleRangeKey], shiftedRanges)
    }
  })

  return newRangeInlineJSON;
}

function removeRangeFromRangeInlineJSON(rangeInline, range) {
  const beforeText = rangeInline.text.slice(0, range.start);
  const afterText = rangeInline.text.slice(range.end);

  let newRangeInlineJSON = {
    ...rangeInline,
    text: `${beforeText}${afterText}`,
  }

  possibleRangeKeys.forEach(possibleRangeKey => {
    if (newRangeInlineJSON[possibleRangeKey]) {
      newRangeInlineJSON[possibleRangeKey] = newRangeInlineJSON[possibleRangeKey].map((rangedItem) => {
        const newRange = deleteRangeAtIndexByValue(rangedItem.range, range.start, rangeLength(range));
        if (newRange) {
          return {
            ...rangedItem,
            range: newRange
          }
        } else {
          return null;
        }
      }).filter(x => x);
    }
  })

  return newRangeInlineJSON;
}

function beforeRangeInlineJSONAt(rangeInline, index: number) {
  let newBeforeRangeInlineJSON = {
    ...rangeInline,
    text: rangeInline.text.slice(0, index),
  }

  possibleRangeKeys.forEach(possibleRangeKey => {
    if (newBeforeRangeInlineJSON[possibleRangeKey]) {
      newBeforeRangeInlineJSON[possibleRangeKey] = newBeforeRangeInlineJSON[possibleRangeKey]
        .filter(rangeParent => rangeParent.range.start < index)
        .map(rangeParent => (
          {
            ...rangeParent,
            range: {
              start: rangeParent.range.start,
              end: R.min(rangeParent.range.end, index)
            }
          }
        ))
    }
  })

  return newBeforeRangeInlineJSON;
}

function afterRangeInlineJSONAt(rangeInline, index: number) {
  let newAfterRangeInlineJSON = {
    ...rangeInline,
    text: rangeInline.text.slice(index),
  }

  possibleRangeKeys.forEach(possibleRangeKey => {
    if (newAfterRangeInlineJSON[possibleRangeKey]) {
      newAfterRangeInlineJSON[possibleRangeKey] = newAfterRangeInlineJSON[possibleRangeKey]
        .filter(rangeParent => rangeParent.range.end > index)
        .map(rangeParent => (
          {
            ...rangeParent,
            range: {
              start: R.max(rangeParent.range.start - index, 0),
              end: rangeParent.range.end - index
            }
          }
        ))
    }
  })

  return newAfterRangeInlineJSON;
}

function splitRangeInlineJSONAt(rangeInline, index: number) {
  return [
    beforeRangeInlineJSONAt(rangeInline, index),
    afterRangeInlineJSONAt(rangeInline, index),
  ]
}

function addChangeTextAndNodesChange(doc, change) {
  const node = findNodeByUid(doc, change.uid);

  if (!nodeInlineNotFlowContentKeys(node).includes(change.key)) {
    return addChangeTextAndNodesChangeToFlow(doc, change);
  } else {
    return addChangeTextAndNodesChangeToSecondary(doc, change);
  }
}

function reduceFlatNodesWithOffset(flowNodes, reduceFunc, initialValue) {
  const finalResultContainer = flowNodes.reduce((resultContainer, node) => {
    const newResult = reduceFunc(
      resultContainer.result,
      node,
      resultContainer.offset
    );

    const newOffset = resultContainer.offset + flattenedNodeLength(node);

    return {
      result: newResult,
      offset: newOffset
    }
  }, {result: initialValue, offset: 0})

  return finalResultContainer.result;
}

function addChangeTextAndNodesChangeToFlow(doc, change) {
  const nodePath = findNodePathByUid(doc, change.uid)
  const flowPath = [...nodePath, change.key]
  const flowListNodesPath = [...nodePath, `${change.key}ListNodes`]

  let listNodesToAdd = {}

  doc = overAtPath(doc, flowPath, (flowNodes) => {
    let nodes = flowNodes || [];

    const compressableChange = reduceFlatNodesWithOffset(nodes, (result, node, offset) => {
      if ( result ) { return result };

      if (node.addDeleteChanges) {
        const adjustedRange = shiftRange(change.range, -1 * offset);

        const compressableNodeAddDropChange = node.addDeleteChanges.find(addDropChange => {
          canCompressChanges(change, addDropChange) &&
          rangesDoTouch(adjustedRange, addDropChange.range)
        })

        if (compressableNodeAddDropChange) { return compressableNodeAddDropChange }
      }

      if (node.type === "paragraph" && node.content.addDeleteChanges) {
        const adjustedRange = shiftRange(change.range, -1 * offset - 1);
        const compressableContentAddDropChange = node.content.addDeleteChanges.find(addDropChange => {
          return (
            canCompressChanges(change, addDropChange) &&
            rangesDoTouch(adjustedRange, addDropChange.range)
          )
        })

        if (compressableContentAddDropChange) { return compressableContentAddDropChange }
      }
    }, null);

    if (compressableChange) {
      change = {
        ...change,
        ...changeMetadata(compressableChange),
        change_id: compressableChange.change_id,
      }
    }

    let nodesToAdd = change.nodes || [];
    const nodesToAddData = treeFlowToFlatFlow(nodesToAdd);

    nodesToAdd = nodesToAddData.nodes;
    listNodesToAdd = nodesToAddData.listNodes;

    let styledTextToAdd = change.styledText;

    const startNodeOffset = findNodeOffsetFromFlattenedNodes(nodes, change.range.start);
    const startNode = startNodeOffset && nodes[startNodeOffset.nodeIndex];

    if (styledTextToAdd) {
      if (
        !startNode ||
        startNode.type !== "paragraph" ||
        startNodeOffset.innerIndex === 0
      ) {
        const newParagraph = {
          type: "paragraph",
          uid: generateUUID(),
          content: styledTextToAdd,
        }

        styledTextToAdd = null;
        nodesToAdd = R.append(newParagraph, nodesToAdd);
      }
    }

    let newLength = R.sum(nodesToAdd.map(node => flattenedNodeLength(node)))
    if (styledTextToAdd) {
      newLength = newLength + styledTextToAdd.text.length;
    }

    if (nodesToAdd.length > 0) {
      if (
        startNode &&
        startNode.type === "paragraph" &&
        startNodeOffset.innerIndex > 0 &&
        startNode.content.text.length + 1 > startNodeOffset.innerIndex
      ) {
        const [
          beforeInline,
          afterInline
        ] = splitRangeInlineJSONAt(startNode.content, startNodeOffset.innerIndex - 1);

        nodes = R.set(R.lensPath([startNodeOffset.nodeIndex, "content"]), beforeInline, nodes);

        if (R.last(nodesToAdd as any[]).type === "paragraph") {
          nodesToAdd = R.over(R.lensPath([nodesToAdd.length - 1, "content"]), content => concatRangeInlineJSON(content, afterInline), nodesToAdd)
        } else {
          const newParagraph = {
            type: "paragraph",
            uid: generateUUID(),
            content: afterInline,
          }

          newLength = newLength + 1;
          nodesToAdd = R.append(newParagraph, nodesToAdd);
        }
      }
    }

    if (styledTextToAdd) {
      nodes = R.over(R.lensPath([startNodeOffset.nodeIndex, "content"]), (oldContent) => {
        return insertRangeInlineJSONAtIndex(oldContent, styledTextToAdd, startNodeOffset.innerIndex - 1);
      }, nodes)
    }

    if (nodesToAdd.length > 0) {
      let insertIndex = 0;

      if (startNodeOffset) {
        if (startNodeOffset.innerIndex === 0) {
          insertIndex = startNodeOffset.nodeIndex;
        } else {
          insertIndex = startNodeOffset.nodeIndex + 1;
        }
      }

      nodes = R.insertAll(insertIndex, nodesToAdd, nodes)
    }

    const cleanedChange = R.omit(["nodes", "styledText"], change)

    if (!isRangeCollapsed(change.range)) {
      const deleteChange = {
        ...cleanedChange,
        type: DELETE_CONTENT,
        range: shiftRange(change.range, newLength),
      };

      nodes = appendRangedItemToFlattenedNodes(nodes, "addDeleteChanges", deleteChange);
    }

    // ADD ADD CHANGES TO NEW NODES
    if (newLength > 0) {
      const addRange = {
        ...cleanedChange,
        type: ADD_CONTENT,
        range: {
          start: change.range.start,
          end: change.range.start + newLength,
        }
      }

      nodes = appendRangedItemToFlattenedNodes(nodes, "addDeleteChanges", addRange);
    }

    return nodes;
  })

  doc = overAtPath(doc, flowListNodesPath, (listNodes) => safeMerge(listNodes, listNodesToAdd));

  return doc;
}

function addChangeTextAndNodesChangeToSecondary(doc, change) {
  const nodePath = findNodePathByUid(doc, change.uid)
  const secondaryTextPath = [...nodePath, change.key]

  doc = overAtPath(doc, secondaryTextPath, (rangeInlineJSON) => {
    const compressableChange = rangeInlineJSON.addDeleteChanges?.find(addDropChange => {
      return (
        canCompressChanges(change, addDropChange) &&
        rangesDoTouch(change.range, addDropChange.range)
      )
    })

    if (compressableChange) {
      change = {
        ...change,
        ...changeMetadata(compressableChange),
        change_id: compressableChange.change_id,
      }
    }

    const styledText = change.styledText || {text: "", styleRanges: []}

    rangeInlineJSON = insertRangeInlineJSONAtIndex(rangeInlineJSON, styledText, change.range.start);

    const cleanedChange = R.omit(["nodes", "styledText"], change)

    if (!isRangeCollapsed(change.range)) {
      const deleteChange = {
        ...cleanedChange,
        type: DELETE_CONTENT,
        range: shiftRange(change.range, styledText.text.length),
      };

      rangeInlineJSON = appendRangedItemToRangeInlineJSON(rangeInlineJSON, "addDeleteChanges", deleteChange);
    }

    if (styledText.text.length > 0) {
      const addChange = {
        ...cleanedChange,
        type: ADD_CONTENT,
        range: {
          start: change.range.start,
          end: change.range.start + styledText.text.length,
        }
      }

      rangeInlineJSON = appendRangedItemToRangeInlineJSON(rangeInlineJSON, "addDeleteChanges", addChange);
    }

    return rangeInlineJSON;
  })

  return doc;
}

function addAddNodeChange(doc, change) {
  const parentPath = findNodePathByUid(doc, change.parent_uid);
  const content = findAtPath(doc, [...parentPath, change.parent_key]);
  const beforeContent = content.slice(0, change.parent_index);

  const beforeLength = R.sum(beforeContent.map(node => flattenedNodeLength(node)));

  const modifiedChange = {
    ...change,
    type: CHANGE_TEXT_AND_NODES,
    uid: change.parent_uid,
    key: change.parent_key,
    range: {
      start: beforeLength,
      end: beforeLength
    },
    nodes: [convertAllInlineNodesToRangeJSON(change.node)],
  }

  return addChangeToDoc(doc, modifiedChange);
}

function addAddTextAndNodesBeforeNodeChange(doc, change) {
  const {
    nodes,
    styledText,
    sibling_uid,
  } = change

  const siblingPath = findNodePathByUid(doc, sibling_uid);
  const sibling = findNodeAtPath(doc, siblingPath);

  let parent, parentKey, parentIndex;
  if (["regularList", "numberedList"].includes(sibling.type)) {
    // Truncate path to find parent
    let parentPath = R.takeWhile(n => n !== "contentListNodes", siblingPath) as NodePath; // TODO: Remove as when can
    parent = findNodeAtPath(doc, parentPath);

    const listIndex = parent.content.findIndex(node => node.listUid === sibling.uid);
    // FIND FIRST NON DELETED ITEM
    if (listIndex < 0) { return doc; }

    parentKey = "content";
    parentIndex = listIndex;
  } else {
    parent = findNodeParentByUid(doc, sibling_uid);
    parentKey = nodeContentKeyFromPath(doc, siblingPath);
    parentIndex = nodeIndexFromPath(doc, siblingPath);
  }

  const beforeContent = parent.content.slice(0, parentIndex);
  const beforeLength = R.sum(beforeContent.map(node => flattenedNodeLength(node)));

  const modifiedChange = {
    ...change,
    type: CHANGE_TEXT_AND_NODES,
    uid: parent.uid,
    key: parentKey,
    range: {
      start: beforeLength,
      end: beforeLength,
    },
    styledText: styledText,
    nodes: nodes,
  }

  return addChangeToDoc(doc, modifiedChange);
}

function addAddTextAndNodesToNodeChange(doc, change) {
  const {
    nodes,
    styledText,
    parent_uid,
    parent_key,
  } = change;

  const parent = findNodeByUid(doc, parent_uid);
  const parentLength =   R.sum(parent[parent_key].map(node => flattenedNodeLength(node)));

  const modifiedChange = {
    ...change,
    type: CHANGE_TEXT_AND_NODES,
    uid: parent_uid,
    key: parent_key,
    range: {
      start: parentLength,
      end: parentLength,
    },
    styledText: styledText,
    nodes: nodes,
  }

  return addChangeToDoc(doc, modifiedChange);
}

function addDeleteNodeChange(doc, change) {
  const nodePath = findNodePathByUid(doc, change.uid);
  const node = findAtPath(doc, nodePath);

  let parent, parentKey, parentStartIndex, parentEndIndex;
  if (["regularList", "numberedList"].includes(node.type)) {
    // Truncate path to find parent
    let parentPath = R.takeWhile(n => n !== "contentListNodes", nodePath) as NodePath; // TODO: Remove when ready
    parent = findNodeAtPath(doc, parentPath);

    parentKey = "content";

    parentStartIndex = parent.content.findIndex(n => n.listUid === node.uid);
    // FIND FIRST NON DELETED ITEM
    if (parentStartIndex < 0) { return doc; }

    parentEndIndex = parent.content.findLastIndex(n => n.listUid === node.uid);
    // FIND FIRST NON DELETED ITEM
    if (parentEndIndex < 0) { return doc; }

    parentEndIndex = parentEndIndex+1;
  } else {
    parent = findNodeParentByUid(doc, node.uid);
    parentKey = nodeContentKeyFromPath(doc, nodePath);
    parentStartIndex = nodeIndexFromPath(doc, nodePath);

    if (["listItem"].includes(node.type)) {
      parentEndIndex = parentStartIndex + 2;
    } else {
      parentEndIndex = parentStartIndex + 1;
    }
  }

  const content = parent[parentKey];

  const beforeContent = content.slice(0, parentStartIndex);
  const beforeLength = R.sum(beforeContent.map(node => flattenedNodeLength(node)));

  const nodeLength = R.sum(content.slice(parentStartIndex, parentEndIndex).map(node => flattenedNodeLength(node)));

  const modifiedChange = {
    ...change,
    type: CHANGE_TEXT_AND_NODES,
    uid: parent.uid,
    key: parentKey,
    range: {
      start: beforeLength,
      end: beforeLength + nodeLength,
    },
  }

  return addChangeToDoc(doc, modifiedChange);
}

function addMoveNodeChange(doc, change) {
  return doc;

  // const oldPath = findNodePathByUid(doc, change.uid);
  // const newPath = [...findNodePathByUid(doc, change.parent_uid), change.parent_key, change.parent_index];

  // const node = findAtPath(doc, oldPath);

  // const oldEditStage = blockEditStageFromNode(node);
  // const newEditStage = editStageFromChange(change);

  // doc = addBlockChangeToNodeAtPath(doc, oldPath, change);
  // doc = moveAtPathToPath(doc, oldPath, newPath);

  // switch (editStageComparison(oldEditStage, newEditStage)) {
  //   case EditStage.MORE_FINAL:
  //     let summaryPath = oldPath;

  //     if (newPath.length <= summaryPath.length && arePathsSameLineage(R.init(newPath), summaryPath) && R.last(newPath) <= summaryPath[newPath.length - 1]) {
  //       summaryPath = R.adjust(newPath.length - 1, R.inc, summaryPath);
  //     }

  //     doc = insertAtPath(doc, newMoveSummary(node.uid, oldEditStage), summaryPath);
  //     break;
  //   case EditStage.LESS_FINAL:
  //     doc = removeMoveSummary(doc, node.uid, newEditStage);
  //     break;
  // }
}

function addMoveNodeUpChange(doc, change) {
  return addMoveNodeUpOrDownChange(doc, change, true);
}

function addMoveNodeDownChange(doc, change) {
  return addMoveNodeUpOrDownChange(doc, change, false);
}

function addMoveNodeUpOrDownChange(doc, change, isUp) {
  const nodePath = findNodePathByUid(doc, change.uid);
  let unadjustedNode = findNodeAtPath(doc, nodePath);

  let parent = findNodeParentByUid(doc, change.uid);
  let parentPath = findNodePathByUid(doc, parent.uid);

  let parentKey, parentIndex;

  let node;
  if (["regularList", "numberedList"].includes(unadjustedNode.type)) {
    parentPath = nodePath.slice(0, -2);
    parent = findNodeAtPath(doc, parentPath);

    const listIndex = parent.content.findIndex(nodeB => nodeB.listUid === unadjustedNode.uid);
    // FIND FIRST NON DELETED ITEM
    if (listIndex < 0) { return doc; }

    parentKey = "content";
    parentIndex = listIndex;
    node = parent.content[parentIndex];
  } else {
    parentKey = nodeContentKeyFromPath(doc, nodePath);
    parentIndex = nodeIndexFromPath(doc, nodePath);
    node = unadjustedNode;
  }

  const parentContent = parent[parentKey];

  const afterSiblings = parentContent.slice(parentIndex + 1);

  const moveAfterSiblings = R.takeWhile((nodeB:any) => {
    switch (unadjustedNode.type) {
      case "paragraph":
        return (
          isMoveSummary(nodeB) ||
          isDeletedOrProposedDeletedNode(nodeB)
        );
      case "listItem":
        return (
          isMoveSummary(nodeB) ||
          isDeletedOrProposedDeletedNode(nodeB) ||
          (
            node.listUid === nodeB.listUid &&
            (node.listIndentation || 0) <= (nodeB.listIndentation || 0) &&
            nodeB.type !== "listItem"
          )
        )
      case "regularList":
      case "numberedList":
        return (
          isMoveSummary(nodeB) ||
          isDeletedOrProposedDeletedNode(nodeB) ||
          unadjustedNode.uid === nodeB.listUid
        )
      default:
        return false;
    }
  }, afterSiblings);

  const nodesToMove = [node, ...moveAfterSiblings];

  let newIndex;
  if (isUp) {
    const beforeSiblings = parentContent.slice(0, parentIndex);

    const endOfValidSiblingIndex = R.findLastIndex((nodeB:any) => (
      !isMoveSummary(nodeB) &&
      !isDeletedOrProposedDeletedNode(nodeB) &&
      (!unadjustedNode.listUid || (listLevel(unadjustedNode) <= listLevel(nodeB) && unadjustedNode.listUid === nodeB.listUid))
    ), beforeSiblings);

    if (endOfValidSiblingIndex === -1) { return doc; }

    const endOfValidSibling = parentContent[endOfValidSiblingIndex];
    const toEndOfValidSiblingSiblings = parentContent.slice(0, endOfValidSiblingIndex + 1);

    let validSiblingIndex
    if (unadjustedNode.listUid) {
      validSiblingIndex = R.findLastIndex((nodeB:any) => (
        unadjustedNode.listUid === nodeB.listUid && listLevel(unadjustedNode) >= listLevel(nodeB)
      ), toEndOfValidSiblingSiblings);

      if (validSiblingIndex === -1) { return doc; }
    } else {
      if (endOfValidSibling.listUid) {
        const beforeValidSiblingIndex = R.findLastIndex((nodeB:any) => (
          endOfValidSibling.listUid !== nodeB.listUid
        ), toEndOfValidSiblingSiblings);

        validSiblingIndex = beforeValidSiblingIndex + 1;
      } else {
        validSiblingIndex = endOfValidSiblingIndex;
      }
    }

    newIndex = validSiblingIndex === -1 ? 0 : validSiblingIndex;
  } else {
    const afterSiblingsIndex = parentIndex + nodesToMove.length;
    const afterSiblings = parentContent.slice(afterSiblingsIndex);

    const validSiblingOffset = R.findIndex((nodeB:any) => (
      !isMoveSummary(nodeB) &&
      !isDeletedOrProposedDeletedNode(nodeB) &&
      (
        unadjustedNode.listUid ?
          listLevel(unadjustedNode) === listLevel(nodeB) && unadjustedNode.listUid === nodeB.listUid :
          listLevel(nodeB) <= 0 // Future can just be true
      )
    ), afterSiblings);

    if (validSiblingOffset === -1) { return doc; }

    const validSiblingIndex = afterSiblingsIndex + validSiblingOffset;

    const validSibling = parentContent[validSiblingIndex];
    const afterValidSiblingSiblings = parentContent.slice(validSiblingIndex + 1);


    let endOfValidSiblingOffset = R.findIndex((nodeB:any) => (
      !validSibling.listUid ||
        (
          unadjustedNode.listUid ?
            listLevel(validSibling) >= listLevel(nodeB) || validSibling.listUid !== nodeB.listUid :
            listLevel(nodeB) === -1 || validSibling.listUid !== nodeB.listUid
        )
    ), afterValidSiblingSiblings);

    newIndex = endOfValidSiblingOffset === -1 ? parentContent.length : validSiblingIndex + 1 + endOfValidSiblingOffset;
  }

  if (newIndex > parentIndex) {
    newIndex = Math.max(parentIndex, newIndex - nodesToMove.length);
  }

  let newParentContent = parentContent;
  newParentContent = R.remove(parentIndex, nodesToMove.length, newParentContent);
  newParentContent = R.insertAll(newIndex, nodesToMove, newParentContent);

  return setAtPath(doc, [...parentPath, parentKey], newParentContent);
}

function listLevel(node) {
  if (node.listUid) {
    const unadjustedListLevel = node.listIndentation || 0;

    if (node.type === "listItem") {
      return unadjustedListLevel
    } else {
      return unadjustedListLevel + 1;
    }
  } else {
    return -1;
  }
}

function isDeletedOrProposedDeletedNode(node) {
  return !isRelevantNode(node, changesDisplayModes.HIGHLIGHT_SUGGESTIONS);
}

function addStyleChange(doc, change) {
  const node = findNodeByUid(doc, change.uid);

  if (isFlowTypeAndKey(node.type, change.key)) {
    return addStyleChangeToFlow(doc, change);
  } else {
    return addStyleChangeToSecondary(doc, change);
  }
}


function addStyleChangeToFlow(doc, change) {
  const nodePath = findNodePathByUid(doc, change.uid)
  const flowPath = [...nodePath, change.key]

  doc = overAtPath(doc, flowPath, (flowNodes) => appendRangedItemToFlattenedNodes(flowNodes, "styleChanges", change)); // SHOULD NARROW CHANGE?

  return doc;
}

function addStyleChangeToSecondary(doc, change) {
  const nodePath = findNodePathByUid(doc, change.uid)
  const secondaryTextPath = [...nodePath, change.key]

  doc = overAtPath(doc, secondaryTextPath, (rangeInlineJSON) => {
    return {
      ...rangeInlineJSON,
      styleChanges: safeAppend(rangeInlineJSON.styleChanges, change), // SHOULD NARROW CHANGE?
    }
  })

  return doc;
}

function addAddCommentThread(doc, change) {
  const node = findNodeByUid(doc, change.uid);

  if (isFlowTypeAndKey(node.type, change.key)) {
    return addAddCommentThreadChangeToFlow(doc, change);
  } else {
    return addAddCommentThreadChangeToSecondary(doc, change);
  }
}

function addAddCommentThreadChangeToFlow(doc, change) {
  const nodePath = findNodePathByUid(doc, change.uid)
  const flowPath = [...nodePath, change.key]

  doc = overAtPath(doc, flowPath, (flowNodes) => appendRangedItemToFlattenedNodes(flowNodes, "commentThreadChanges", change)); // SHOULD NARROW CHANGE?

  return doc;
}

function addAddCommentThreadChangeToSecondary(doc, change) {
  const nodePath = findNodePathByUid(doc, change.uid);
  const secondaryTextPath = [...nodePath, change.key];

  doc = overAtPath(doc, secondaryTextPath, (rangeInlineJSON) => {
    return {
      ...rangeInlineJSON,
      commentThreadChanges: appendChangeToChanges(rangeInlineJSON.commentThreadChanges, change),
    }
  })

  return doc;
}

function addBlockChangeToNodeAtPath(doc, path, change) {
  return overAtPath(doc, [path, "changes"], (changes) => appendChangeToChanges(changes, change));
}

function addSetKeyValuesChange(doc, change) {
  return editNodeWithUIDKeyValueWithFunction(doc, change.uid, "key_value_changes", (changes) => appendChangeToChanges(changes, change));
}

// function addInlineTextChangeToNodeWithUID(doc, uid, key, change) {
//   const nodePath = findNodePathByUid(doc, uid);
//   return overAtPath(doc, [...nodePath, key, "changes"], (changes) => appendChangeToChanges(changes, change));
// }

function addAddTableRowChangeToNodeWithUID(doc, change) {
  const nodePath = findNodePathByUid(doc, change.table_uid);

  doc = addInEmptyRowChanges(doc, nodePath);
  doc = overAtPath(doc, [...nodePath, "rowChanges"], R.insert(change.index, []))
  doc = overAtPath(doc, [...nodePath, "rowChanges", change.index], (changes) => appendChangeToChanges(changes, change));

  const firstRow = findNodeAtPath(doc, [...nodePath, "rows", 0]);
  const columnCount = firstRow.cells.length;
  const newRow = convertAllInlineNodesToRangeJSON(generateTableRowWithNTableCells(columnCount))

  doc = overAtPath(doc, [...nodePath, "rows"], R.insert(change.index, newRow))

  return doc;
}

function addDeleteTableRowChangeToNodeWithUID(doc, change) {
  const nodePath = findNodePathByUid(doc, change.table_uid);

  doc = addInEmptyRowChanges(doc, nodePath);
  doc = overAtPath(doc, [...nodePath, "rowChanges", change.index], (changes) => appendChangeToChanges(changes, change));
  return doc;
}

function addInEmptyRowChanges(doc, nodePath) {
  const rows = findNodeAtPath(doc, [...nodePath, "rows"]);
  const rowCount = rows.length;

  return overAtPath(doc, [...nodePath, "rowChanges"], R.defaultTo(R.repeat([], rowCount)))
}

function addAddTableColumnChange(doc, change) {
  const nodePath = findNodePathByUid(doc, change.table_uid);

  doc = addInEmptyColumnChanges(doc, nodePath);
  doc = overAtPath(doc, [...nodePath, "columnChanges"], R.insert(change.index, []));
  doc = overAtPath(doc, [...nodePath, "columnChanges", change.index], (changes) => appendChangeToChanges(changes, change));

  doc = overAtPath(doc, [...nodePath, "rows"], R.map((row: any) => { // TODO: Remove any when done
    const newCell = convertAllInlineNodesToRangeJSON(generateTableCell());
    return overAtPath(row, ["cells"], R.insert(change.index, newCell))
  }))

  return doc;
}

function addDeleteTableColumnChange(doc, change) {
  const nodePath = findNodePathByUid(doc, change.table_uid);

  doc = addInEmptyColumnChanges(doc, nodePath);
  doc = overAtPath(doc, [...nodePath, "columnChanges", change.index], (changes) => appendChangeToChanges(changes, change));
  return doc;
}

function addInEmptyColumnChanges(doc, nodePath) {
  const firstRow = findNodeAtPath(doc, [...nodePath, "rows", 0]);
  const columnCount = firstRow.cells.length;

  return overAtPath(doc, [...nodePath, "columnChanges"], R.defaultTo(R.repeat([], columnCount)))
}

function addTableCellSpanChangeToNodeWithUID(doc, change) {
  const nodePath = findNodePathByUid(doc, change.table_uid);
  return overAtPath(doc, [...nodePath, "cellSpanChanges"], (changes) => appendChangeToChanges(changes, change));
}

function deleteChangeWithID(doc, change) {
  const nodePath = deepFindPath(doc, (node) => node.change_id === change.comment_thread_change_id);
  return deleteAtPath(doc, nodePath);
}

function addCommentToChangeWithID(doc, parent_change_id, change) {
  const nodePath = deepFindPath(doc, (node) => node.change_id === parent_change_id);
  return overAtPath(doc, [...nodePath, "comments"], (changes) => appendChangeToChanges(changes, change));
}

function deleteCommentWithID(doc, comment_id, change) {
  const nodePath = deepFindPath(doc, (node) => node.comment_id === comment_id);
  return deleteAtPath(doc, nodePath);
}

function addSummaryOfEditChange(doc, change) {
  return overAtPath(doc, ["summary_of_edits"], (changes) => appendChangeToChanges(changes, change));
}

function deleteSummaryOfEditChange(doc, change) {
  return overAtPath(doc, ["summary_of_edits"], (changes) => changes.filter((changeN) => changeN.summary_of_edit_id !== change.summary_of_edit_id));
}

function addStudentFeedbackChange(doc, change) {
  return overAtPath(doc, ["student_feedbacks"], (changes) => appendChangeToChanges(changes, change));
}

function deleteStudentFeedbackChange(doc, change) {
  return overAtPath(doc, ["student_feedbacks"], (changes) => changes.filter((changeN) => changeN.student_feedback_id !== change.student_feedback_id));
}

function findAllChangePathsByChangeId(doc, change_id) {
  return deepFindAllPaths(doc, (obj) => obj.change_id === change_id);
}

function findAllPublishableChangePaths(doc) {
  return deepFindAllPaths(doc, (obj) => obj.change_id && isPublishableChange(obj));
}

function markChangeAtPathAccepted(doc, path, change) {
  return R.over(R.lensPath(path), R.merge({decision: decisionTypes.ACCEPTED, decision_at: change.created_at, decision_by: change.author_id}), doc);
}

function markChangeAtPathRejected(doc, path, change) {
  return R.over(R.lensPath(path), R.merge({decision: decisionTypes.REJECTED, decision_at: change.created_at, decision_by: change.author_id}), doc);
}

function markChangeAtPathPublished(doc, path, change) {
  return R.over(R.lensPath(path), R.merge({published_at: change.created_at, published_by: change.author_id}), doc);
}

function isMoveSummary(node) {
  return node.type === "move_summary";
}

function newMoveSummary(uid, editStage) {
  return {
    type: "move_summary",
    move_uid: uid,
    edit_stage: editStage,
  };
}

function findMoveSummariesPaths(doc) {
  return deepFindAllPaths(doc, isMoveSummary);
}

function removeMoveSummary(doc, uid, editStage) {
  const moveSummaryPaths = findMoveSummariesPaths(doc);
  const toRemovePaths = filterPathsByFunction(doc, moveSummaryPaths, (node) => node.moved_uid === uid && node.edit_stage === editStage);

  return toRemovePaths.reduce((redDoc, path) => deleteAtPath(redDoc, path), doc);
}


const EditStage = {
  PUBLISHED: "EditStage/PUBLISHED",
  ACCEPTED: "EditStage/ACCEPTED",
  PROPOSED: "EditStage/PROPOSED",
  REJECTED: "EditStage/REJECTED",

  MORE_FINAL: "EditStage/MORE_FINAL",
  AS_FINAL: "EditStage/AS_FINAL",
  LESS_FINAL: "EditStage/LESS_FINAL",
};


function blockEditStageFromNode(node) {
  const lastBlockChange = R.last(node.changes || []);
  return lastBlockChange ? editStageFromChange(lastBlockChange) : EditStage.PUBLISHED;
}


function editStageFromChange(change) {
  if (change.published_at) {
    return EditStage.PUBLISHED;
  } else if (!change.is_suggestion) {
    return EditStage.ACCEPTED;
  } else if (change.decision) {
    return change.decision;
  } else {
    return EditStage.PROPOSED;
  }
}

const EDIT_STAGE_VALUE_MAP = {
  [EditStage.REJECTED]: 0,
  [EditStage.PROPOSED]: 1,
  [EditStage.ACCEPTED]: 2,
  [EditStage.PUBLISHED]: 3,
};

function editStageComparison(editStage1, editStage2) {
  const value1 = EDIT_STAGE_VALUE_MAP[editStage1];
  const value2 = EDIT_STAGE_VALUE_MAP[editStage2];

  if (value1 > value2) {
    return EditStage.MORE_FINAL;
  } else if (value1 === value2) {
    return EditStage.AS_FINAL;
  } else {
    return EditStage.LESS_FINAL;
  }
}


////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

export const weakMemoizedCloneAndNormalizeDoc = memoize(cloneAndNormalizeDoc);
function cloneAndNormalizeDoc(doc) {
  return normalizeDocNode(_.cloneDeep(doc));
}

// const weakMemoizedCacheTableInfo = memoize(cacheTableInfo);
// function cacheTableInfo(node, changesDisplayMode) {
function applyTableChangesToNode(node, changesDisplayMode) {
  if (node.type !== "table") { return node; }

  const allRowCount = node.rows.length;
  const allColumnCount = node.rows[0]?.cells?.length || 0;

  const originalCellSpans = getOriginalCellSpans(node);

  const rowChangesByIndex = node.rowChanges || _.times(allRowCount, () => []);
  const columnChangesByIndex = node.columnChanges || _.times(allColumnCount, () => []);
  const cellSpanChanges = node.cellSpanChanges || [];

  const isRowRelevantByIndex = rowChangesByIndex.map(rowChanges => isRelevantRow(rowChanges, changesDisplayMode));
  const isColumnRelevantByIndex = columnChangesByIndex.map(columnChanges => isRelevantColumn(columnChanges, changesDisplayMode));

  const relevantCellSpanChanges = filterRelevantChanges(cellSpanChanges, changesDisplayMode);
  let collapsedCellSpans = convertCellSpanChangesToCellSpans(relevantCellSpanChanges, originalCellSpans);

  const markedRows = node.rows.map((row, rowIndex) => {
    const isRelevantRow = isRowRelevantByIndex[rowIndex];

    const markedCells = row.cells.map((cell, columnIndex) => {
      const isRelevantColumn = isColumnRelevantByIndex[columnIndex]

      let isRelevantCell;
      let rowSpan = cell.rowSpan || 1;
      let columnSpan = cell.columnSpan || 1;
      if (isRelevantRow && isRelevantColumn) {
        const overlappingCellSpan = isPointInSpan(rowIndex, columnIndex, collapsedCellSpans);

        if (overlappingCellSpan) {
          if (!overlappingCellSpan.claimed) {
            overlappingCellSpan.claimed = true;
            rowSpan = isRowRelevantByIndex.slice(rowIndex, overlappingCellSpan.rowRange.end + 1).filter(x => x === true).length
            columnSpan = isColumnRelevantByIndex.slice(columnIndex, overlappingCellSpan.columnRange.end + 1).filter(x => x === true).length
            isRelevantCell = true;
          } else {
            isRelevantCell = false;
          }
        } else {
          isRelevantCell = true;
        }
      } else {
        isRelevantCell = false;
      }

      return {
        ...cell,
        isRelevant: isRelevantCell,
        rowIndex,
        columnIndex,
        rowSpan,
        columnSpan,
      }
    })

    const filteredCells = markedCells.filter(markedCell => markedCell.isRelevant);

    return {
      ...row,
      index: rowIndex,
      cells: filteredCells,
      isRelevant: isRelevantRow,
    }
  })

  const filteredRows = markedRows.filter(markedRow => markedRow.isRelevant);

  const relevantRowIndices = isRowRelevantByIndex.map((item, index) => item && index).filter((item) => _.isNumber(item));
  const relevantColumnIndices = isColumnRelevantByIndex.map((item, index) => item && index).filter((item) => _.isNumber(item));

  return {
    ...node,
    rows: filteredRows,
    relevantColumnIndices,
    // changeSummaries: R.concat(columnChangeSummaries, cellSpanChangeSummaries),
  };
}

function applyBlockChangeHighlightsToNode(node, changesDisplayMode) {
  const {
    addDeleteChanges = [],
  } = node;

  const releventAddDeleteChanges = addDeleteChanges.filter((change) => isRelevantChange(change, changesDisplayMode))
  const acceptedDeleteChange = releventAddDeleteChanges.find((change) => isAcceptedChange(change) && change.type === DELETE_CONTENT);

  if (acceptedDeleteChange && changesDisplayMode !== changesDisplayModes.HIGHLIGHT_ALL_CHANGES) {
    return node
  }

  const highlightDeleteChange = releventAddDeleteChanges.find((change) => change.type === DELETE_CONTENT && isHighlightChange(change, changesDisplayMode));

  if (highlightDeleteChange) {
    return {
      ...node,
      blockChangeHighlight: highlightDeleteChange,
    }
  }

  const highlightAddChange = releventAddDeleteChanges.find((change) => change.type === ADD_CONTENT && isHighlightChange(change, changesDisplayMode));

  if (highlightAddChange) {
    return {
      ...node,
      blockChangeHighlight: highlightAddChange,
    }
  }

  return node;
}

function getOriginalCellSpans(table) {
  return (
    table.rows.flatMap((row, rowIndex) =>
      row.cells.flatMap((cell, columnIndex) => {
        const rowSpan = cell.rowSpan || 1
        const columnSpan = cell.columnSpan || 1
        if (rowSpan > 1 || columnSpan > 1) {
          return [
            {
              rowRange: {
                start: rowIndex,
                end: rowIndex + rowSpan - 1,
              },
              columnRange: {
                start: columnIndex,
                end: columnIndex + columnSpan - 1,
              },
            }
          ]
        } else {
          return [];
        }
      })
    )
  )
}

function convertCellSpanChangesToCellSpans(cellSpanChanges, originalSpans=[]) {
  return cellSpanChanges.reduce((cellSpans, cellSpanChange) => {
    const newSpan = {
      rowRange: cellSpanChange.rowRange,
      columnRange: cellSpanChange.columnRange,
    }

    let [overlappingSpans, restSpans] = R.partition((cellSpan) => doSpansOverlap(newSpan, cellSpan), cellSpans);

    switch (cellSpanChange.type) {
      case MERGE_TABLE_CELLS:
        const combinedSpan = [newSpan, ...overlappingSpans].reduce((spanA, spanB) => unionSpans(spanA, spanB));
        return [...restSpans, combinedSpan];
      case UNMERGE_TABLE_CELLS:
        return restSpans;
      default:
        return cellSpans;
    }
  }, originalSpans)
}

// TODO MOVE
function isPointInSpan(rowIndex, columnIndex, collapsedCellSpans) {
  return collapsedCellSpans.find(cellSpan =>
    cellSpan.rowRange.start <= rowIndex
    && cellSpan.rowRange.end >= rowIndex
    && cellSpan.columnRange.start <= columnIndex
    && cellSpan.columnRange.end >= columnIndex
  )
}

// TODO MOVE
function doSpansOverlap(spanA, spanB) {
  return rangesDoTouch(spanA.rowRange, spanB.rowRange) && rangesDoTouch(spanA.columnRange, spanB.columnRange)
}

function unionSpans(spanA, spanB) {
  return {
    rowRange: rangesUnion(spanA.rowRange, spanB.rowRange),
    columnRange: rangesUnion(spanA.columnRange, spanB.columnRange)
  }
}

function normalizedDocAllBlockChanges(normalizedDoc) {
  const allNodes = _.values(normalizedDoc);
  return _.compact(allNodes.map((nodeToAccept) => {
    return normalizedNodeBlockChanges(nodeToAccept);
  }).flat());
}

function normalizedNodeBlockChanges(node) {
  return _.compact(nodeBlockContentKeys(node).map((blockNodeKey) => node[blockNodeKey] ).flat()).filter((uidOrChange) => (typeof uidOrChange) === "object");
}

const weakMemoizedAddSelectionMimic = memoize(addSelectionMimic);
function addSelectionMimic(normalizedDoc, selectionMimic) {
  if (selectionMimic) {
    return R.set(
      R.lensPath([selectionMimic.uid, selectionMimic.contentKey, "selectionMimics"]),
      [selectionMimic],
      normalizedDoc,
    );
  } else {
    return normalizedDoc;
  }
}

const weakMemoizedMoveNodeChangesGroupedByUid = memoize(moveNodeChangesGroupedByUid);
function moveNodeChangesGroupedByUid(normalizedDoc) {
  const allMoveNodeChanges = normalizedDocAllBlockChanges(normalizedDoc).filter((change) => change.type === MOVE_NODE);
  return _.groupBy(allMoveNodeChanges, (change) => change.uid);
}

export function isRelevantNode(node, changesDisplayMode) {
  return isRelevantFunctionGenerator(ADD_CONTENT, DELETE_CONTENT)(node.addDeleteChanges || [], changesDisplayMode);
}

export const isRelevantRow = isRelevantFunctionGenerator(ADD_TABLE_ROW, DELETE_TABLE_ROW);

export const isRelevantColumn = isRelevantFunctionGenerator(ADD_TABLE_COLUMN, DELETE_TABLE_COLUMN);

export function isRelevantFunctionGenerator(addChangeType, deleteChangeType) {
  return (changes, changesDisplayMode) => {
    const allAddChanges = changes.filter((change) => change.type === addChangeType);
    const relevantChanges = changes.filter((change) => isRelevantChange(change, changesDisplayMode));
    const relevantAddChanges = relevantChanges.filter((change) => change.type === addChangeType);
    const relevantDeleteChanges = relevantChanges.filter((change) => change.type === deleteChangeType);

    return (
      (allAddChanges.length === 0 || relevantAddChanges.length > 0)
      && (relevantDeleteChanges.length === 0 || relevantDeleteChanges.every((change) => isHighlightChange(change, changesDisplayMode)))
    );
  };
}

function filterRelevantChanges(changes, changesDisplayMode) {
  return changes.filter((change) => isRelevantChange(change, changesDisplayMode));
}

export function isOpenSuggestionChange(change) {
  return change.is_suggestion && !change.decision;
}

export function isAcceptedChange(change) {
  return !change.is_suggestion || change.decision === decisionTypes.ACCEPTED;
}

export function isRejectedChange(change) {
  return change.decision === decisionTypes.REJECTED;
}

export function isPublishedChange(change) {
  return change.published_at;
}

function isPublishableChange(change) {
  return isAcceptedChange(change) && !isPublishedChange(change);
}

export function isRelevantChange(change, changesDisplayMode) {
  switch (changesDisplayMode) {
    case changesDisplayModes.ONLY_PUBLISHED:
      return isPublishedChange(change);
    case changesDisplayModes.HIDE_SUGGESTIONS:
      return isAcceptedChange(change);
    case changesDisplayModes.HIGHLIGHT_SUGGESTIONS:
    case changesDisplayModes.HIGHLIGHT_ALL_CHANGES:
      return !isRejectedChange(change);
    default:
      // TODO ADD ERROR WARNING
  }
}

export function isHighlightChange(change, changesDisplayMode) {
  switch (changesDisplayMode) {
    case changesDisplayModes.ONLY_PUBLISHED:
    case changesDisplayModes.HIDE_SUGGESTIONS:
      return false;
    case changesDisplayModes.HIGHLIGHT_SUGGESTIONS:
      return isOpenSuggestionChange(change);
    case changesDisplayModes.HIGHLIGHT_ALL_CHANGES:
      return !isRejectedChange(change) && !isPublishedChange(change);
    default:
      // TODO ADD ERROR WARNING
  }
}

export function isRelevantHighlightChange(change, changesDisplayMode) {
  return isRelevantChange(change, changesDisplayMode)
         && isHighlightChange(change, changesDisplayMode);
}

export function backgroundStyleFromNode(props) {
  const blockChangeHighlight = props.blockChangeHighlight;
  const colorLookup = props.editingContext.colorLookup;

  switch (props.type) {
    case "paragraph":
      switch (blockChangeHighlight?.type) {
        case "DELETE_CONTENT":
        case "MOVE_CONTENT":
        case "ADD_CONTENT":
        default:
          return {};
      }

      break;
    case "listItem":
      switch (blockChangeHighlight?.type) {
        case "DELETE_CONTENT":
        case "MOVE_CONTENT":
        case "ADD_CONTENT":
        default:
          return {};
      }

      break;
    default:
      switch (blockChangeHighlight?.type) {
        case "DELETE_CONTENT":
          // STRIKE THROUGH PARAGRAPH SYMBOL
          return {
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='rgb(${colorLookup[blockChangeHighlight.author_id]})' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
          };
        case "MOVE_CONTENT":
          return {};
        case "ADD_CONTENT":
          // PARAGRAPH SYMBOL
          return {
            backgroundColor: `rgba(${colorLookup[blockChangeHighlight.author_id]},0.25)`
          };
        default:
          return {};
      }

      break;
  }

  // return backgroundStyleFromChangeSummaries(
  //   props.changeSummaries || [],
  //   props.editingContext.changesDisplayMode,
  //   props.editingContext.colorLookup,
  //   props.userContext.id,
  //   [NEW_COMMENT_THREAD, EDIT_COMMENT].includes(props.currentMenu?.menu) && props.type !== "paragraph",
  // );
}

function backgroundStyleFromChangeSummaries(changes, changesDisplayMode, colorLookup, currentAuthorId, isCurrentlyEditingNodeComment) {
  const highlightChangeSummaries = changes.filter((change) => {
    return isRelevantHighlightChange(change, changesDisplayMode);
  });

  const backgroundStyles: any = {};

  const isNewNode = highlightChangeSummaries.find((change) => change.type === ADDED_NODE);
  if (isNewNode) {
    // `url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
    backgroundStyles.backgroundColor = `rgba(${colorLookup[isNewNode.author_id]},0.25)`;
  }

  const isMovedNode = highlightChangeSummaries.find((change) => change.type === MOVED_NODE);
  if (isMovedNode) {
    backgroundStyles.borderLeft = `5px solid rgba(${colorLookup[isMovedNode.author_id]},0.5)`;
  }

  const isDeletedNode = highlightChangeSummaries.find((change) => change.type === DELETED_NODE);
  if (isDeletedNode) {
    backgroundStyles.backgroundImage = `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='rgb(${colorLookup[isDeletedNode.author_id]})' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
  }

  const isCommentedNode = highlightChangeSummaries.find((change) => change.type === OPENED_NODE_COMMENT_THREAD);
  if (isCommentedNode) { // Existing comment
    backgroundStyles.border = `5px solid rgba(
      ${colorLookup[isCommentedNode.author_id]},
      ${isCurrentlyEditingNodeComment ? "0.5" : "0.25"})`;
  } else if (isCurrentlyEditingNodeComment) { // New Comment
    backgroundStyles.border = `5px solid rgba(${colorLookup[currentAuthorId]},0.5)`;
  }

  return backgroundStyles;
}

export function clotheChange(nakedChange, author, is_tracking) {
  return {
    author_id: author.id,
    author_full_name: `${author.first_name} ${author.last_name}`,
    is_suggestion: is_tracking,
    created_at: (new Date()).getTime(),
    ...nakedChange,
  };
}

export function newChange(type, detail) {
  return {
    change_id: generateUUID(),
    type: type,
    ...detail,
  };
}

export function newAddNodeChange(node, parent_uid, parent_key, parent_index) {
  return newChange(ADD_NODE, {
    uid: node.uid,
    node: convertAllInlineNodesToRangeJSON(node),
    parent_uid: parent_uid,
    parent_key: parent_key,
    parent_index: parent_index,
  });
}

export function newAddTextNodesBeforeNodeChange(sibling_uid, styledText, nodes=[]) {
  return newChange(ADD_TEXT_AND_NODES_BEFORE_NODE, {
    sibling_uid: sibling_uid,
    styledText: styledText,
    nodes: nodes.map(node => convertAllInlineNodesToRangeJSON(node)),
  });
}

export function newAddTextAndNodesToNodeChange(parent_uid, parent_key, styledText, nodes=[]) {
  return newChange(ADD_TEXT_AND_NODES_TO_NODE, {
    parent_uid: parent_uid,
    parent_key: parent_key,
    styledText: styledText,
    nodes: nodes.map(node => convertAllInlineNodesToRangeJSON(node)),
  });
}

export function newDeleteNodeChange(uid) {
  return newChange(DELETE_NODE, {
    uid: uid,
  });
}

export function newChangeTextAndNodesChange(selection, styledText, nodes=[]) {
  return newChange(CHANGE_TEXT_AND_NODES, {
    selection: selection,
    styledText: styledText,
    nodes: nodes.map(node => convertAllInlineNodesToRangeJSON(node)),
  })
}

export function newMoveNodeChange(uid, parent_uid, parent_key, parent_index) {
  return newChange(MOVE_NODE, {
    uid: uid,
    parent_uid: parent_uid,
    parent_key: parent_key,
    parent_index: parent_index,
  });
}

export function newMoveNodeUpChange(uid) {
  return newChange(MOVE_NODE_UP, {
    uid: uid,
  });
}

export function newMoveNodeDownChange(uid) {
  return newChange(MOVE_NODE_DOWN, {
    uid: uid,
  });
}

export function newSetKeysValuesChange(uid, keys_and_values) {
  return newChange(SET_KEYS_VALUES, {
    uid: uid,
    keys_and_values: keys_and_values,
  });
}

export function newSetKeyValueChange(uid, key, value) {
  return newSetKeysValuesChange(uid, {[key]: value});
}

export function newAddStyleChange(selection, style, args = null) {
  const detail: any = {
    selection: selection,
    style: style,
  };

  if (args) {
    detail.args = args;
  }

  return newChange(ADD_STYLE, detail);
}

export function newDeleteStyleChange(selection, style) {
  return newChange(DELETE_STYLE, {
    selection: selection,
    style: style,
  });
}

export function newAddTableRowChange(table_uid, index: number) {
  return newChange(ADD_TABLE_ROW, {
    table_uid: table_uid,
    index: index,
  });
}

export function newDeleteTableRowChange(table_uid, index: number) {
  return newChange(DELETE_TABLE_ROW, {
    table_uid: table_uid,
    index: index,
  });
}

export function newAddTableColumnChange(table_uid, index: number) {
  return newChange(ADD_TABLE_COLUMN, {
    table_uid: table_uid,
    index: index,
  });
}

export function newDeleteTableColumnChange(table_uid, index: number) {
  return newChange(DELETE_TABLE_COLUMN, {
    table_uid: table_uid,
    index: index,
  });
}

export function newMergeTableCells(table_uid, rowRange, columnRange) {
  return newChange(MERGE_TABLE_CELLS, {
    table_uid: table_uid,
    rowRange: rowRange,
    columnRange: columnRange,
  });
}

export function newUnmergeTableCells(table_uid, rowRange, columnRange) {
  return newChange(UNMERGE_TABLE_CELLS, {
    table_uid: table_uid,
    rowRange: rowRange,
    columnRange: columnRange,
  });
}

export function newAcceptChangesChange(change_id) {
  return newChange(ACCEPT_CHANGES, {
    accept_change_id: change_id,
  });
}

export function newRejectChangesChange(change_id) {
  return newChange(REJECT_CHANGES, {
    reject_change_id: change_id,
  });
}

export function newPublishChange() {
  return newChange(PUBLISH, {});
}

export function newAddCommentThreadChange(selection, text) {
  return newChange(ADD_COMMENT_THREAD, {
    selection: selection,
    text: text,
    comment_thread_id: generateUUID(), // GET RID OF?
    is_suggestion: true,
  });
}

export function newCloseCommentThreadChange(comment_thread_change_id) {
  return newChange(CLOSE_COMMENT_THREAD, {
    comment_thread_change_id: comment_thread_change_id,
  });
}

export function newAddChangeCommentChange(parent_change_id, text) {
  return newChange(ADD_CHANGE_COMMENT, {
    parent_change_id: parent_change_id,
    comment_id: generateUUID(),
    text: text,
  });
}

export function newDeleteChangeCommentChange(comment_id) {
  return newChange(DELETE_CHANGE_COMMENT, {
    comment_id: comment_id,
  });
}

export function newAddSummaryOfEditChange(uid, key, index, text) {
  return newChange(ADD_SUMMARY_OF_EDIT, {
    uid: uid,
    key: key,
    index: index,
    summary_of_edit_id: generateUUID(),
    text: text,
  });
}

export function newDeleteSummaryOfEditChange(summary_of_edit_id) {
  return newChange(DELETE_SUMMARY_OF_EDIT, {
    summary_of_edit_id: summary_of_edit_id,
  });
}

export function newAddStudentFeedbackChange(text) {
  return newChange(ADD_STUDENT_FEEDBACK, {
    student_feedback_id: generateUUID(),
    text: text,
  });
}

export function newDeleteStudentFeedbackChange(student_feedback_id) {
  return newChange(DELETE_STUDENT_FEEDBACK, {
    student_feedback_id: student_feedback_id,
  });
}

export function canCompressChanges(change1, change2) {
  return (
    change1.user_id === change2.user_id &&
    change1.is_suggestion === change2.is_suggestion &&
    change1.decision === change2.decision &&
    change1.published_at === change2.published_at
  )
}

const changeMetadataFields = [
  "created_at",
  "author_id",
  "author_full_name",
  "is_suggestion",
  "comments",
  "decision",
  "decision_at",
  "decision_by",
  "published_at",
  "published_by",
]

function changeMetadata(change) {
  return R.pick(changeMetadataFields, change);
}

//Given two Changes returns a compressed Change if possible, otherwise null
export function compressTwoChangesIfPossible(change1, change2) {
  if (change1.user_id !== change2.user_id || change1.is_suggestion !== change2.is_suggestion ) {
    return null;
  }

  switch (change1.type) {
    case ADD_STYLE:
    case DELETE_STYLE:
      switch (change2.type) {
        case ADD_STYLE:
        case DELETE_STYLE:
          if (
            change1.type === change2.type
            && change1.uid === change2.uid
            && change1.key === change2.key
            && change1.style === change2.style
            && _.isEqual(change1.args, change1.args)
            && rangesDoTouch(change1.range, change2.range)
          ) {
            return {
              ...change1,
              range: {
                start: Math.min(change1.range.start, change2.range.start),
                end: Math.max(change1.range.end, change2.range.end),
              },
            };
          } else {
            return null;
          }
          break;
        default:
          return null;
      }
      break;
    case MOVE_NODE:
      switch (change2.type) {
        case MOVE_NODE:
          if (
            change1.uid === change2.uid
          ) {
            if (
              change1.parent_uid === change2.parent_uid
              && change1.parent_key === change2.parent_key
              && change1.parent_index < change2.parent_index
            ) {
              return {
                ...change2,
                parent_index: change2.parent_index - 1,
              };
            } else {
              return change2;
            }
          }
          break;
        default:
          return null;
      }
    default:
      return null;
  }
}

function changeTextAfterRange(change) {
  return {
    start: change.range.start,
    end: change.range.start + change.text.length,
  };
}

export function changeSummaryDescription(changeSummary, node) {
  switch (changeSummary.type) {
    case ADD_CONTENT:
    case DELETE_CONTENT:
      const firstAddTextChanges = changeSummary.allChanges.find(change => change.type === ADD_CONTENT && change.text);
      const firstDeleteTextChanges = changeSummary.allChanges.find(change => change.type === DELETE_CONTENT && change.text);
      const firstAddNodeChanges = changeSummary.allChanges.find(change => change.type === ADD_CONTENT && change.changeNodeType);
      const firstDeleteNodeChanges = changeSummary.allChanges.find(change => change.type === DELETE_CONTENT && change.changeNodeType);

      if (firstAddTextChanges) {
        if (firstDeleteTextChanges) {
          return `Changed Text: ${firstAddTextChanges.text}`;
        } else {
          return `Added: ${firstAddTextChanges.text}`;
        }
      } else if (firstDeleteTextChanges) {
        return `Deleted: ${firstDeleteTextChanges.text}`;
      } else if (firstAddNodeChanges) {
        return `Added Node: ${firstAddNodeChanges.changeNodeType}`;
      } else if (firstDeleteNodeChanges) {
        return `Deleted Node: ${firstDeleteNodeChanges.changeNodeType}`;
      }
    case ADDED_NODE:
      return `Added Node`;
    case DELETED_NODE:
      return `Deleted Node`;
    case CHANGED_TEXT:
      return `Changed Text: ${changeSummary.text}`;
    case ADDED_TEXT:
      return `Added: ${changeSummary.text}`;
    case DELETED_TEXT:
      return `Deleted: ${changeSummary.text}`;
    case MOVE_NODE:
      return `Moved Node`;
    case SET_KEYS_VALUES:
      return `Changed Metadata: ${Object.keys(changeSummary.keys_and_values).join(", ")}`;
    case ADD_STYLE:
      return `Added ${changeSummary.style}: ${changeSummary.text}`;
    case DELETE_STYLE:
      return `Removed ${changeSummary.style}: ${changeSummary.text}`;
    case ADD_TABLE_ROW:
      return "Added Row";
    case DELETE_TABLE_ROW:
      return "Deleted Row";
    case ADD_TABLE_COLUMN:
      return "Added Column";
    case DELETE_TABLE_COLUMN:
      return "Deleted Column";
    case MERGE_TABLE_CELLS:
      return "Merged Cells";
    case UNMERGE_TABLE_CELLS:
      return "Unmerged Cells";
    default:
      return "Change";
  }
}

export function applyInlineChangesToRangeInlineJson(rangeInlineJSON, changesDisplayMode: ChangesDisplayMode) {
  const {
    addDeleteChanges = [],
    styleChanges = [],
    commentThreadChanges = [],
  } = rangeInlineJSON;

  let {
    styleRanges = [],
    inlineIrrlevantRanges = [],
    inlineChangeHighlights = [],
  } = rangeInlineJSON;

  addDeleteChanges.forEach((change) => {
    if (isRelevantChange(change, changesDisplayMode)) {
      if (isHighlightChange(change, changesDisplayMode)) {
        inlineChangeHighlights.push(
          {
            type: change.type,
            change_id: change.change_id,
            range: change.range,
            author_id: change.author_id,
          }
        );
      } else {
        if (change.type === DELETE_CONTENT) {
          inlineIrrlevantRanges.push(
            {
              type: HIDE_TEXT,
              change_id: change.change_id,
              range: change.range,
            }
          );
        } else {
          return null;
        }
      }
    } else {
      if (change.type === ADD_CONTENT) {
        inlineIrrlevantRanges.push(
          {
            type: HIDE_TEXT,
            change_id: change.change_id,
            range: change.range,
          }
        );
      } else {
        return null;
      }
    }
  });

  styleChanges.forEach((change) => {
    if (isRelevantChange(change, changesDisplayMode)) {
      switch (change.type) {
        case ADD_STYLE:
          const newStyleRange: any = {
            type: change.style,
            range: {...change.range},
          };

          if (change.args) {
            newStyleRange.args = {...change.args};
          }

          styleRanges = styleRanges.flatMap((oldStyle) => {
            if (oldStyle.type === newStyleRange.type && rangesDoTouch(oldStyle.range, newStyleRange.range)) {
              const newRanges = rangesExcept(oldStyle.range, newStyleRange.range);
              return newRanges.map(newRange => ({...oldStyle, range: newRange}));
            } else {
              return [oldStyle];
            }
          })

          styleRanges = styleRanges.concat([newStyleRange]);

          if (isHighlightChange(change, changesDisplayMode) && change.style !== "color") {
            inlineChangeHighlights.push(
              {
                type: ADDED_STYLE,
                change_id: change.change_id,
                range: change.range,
                style: change.style,
                author_id: change.author_id,
              }
            )
          }

          break;
        case DELETE_STYLE:
          styleRanges = _.compact(_.flatten(styleRanges.map((styleRange) => {
            if (styleRange.type === change.style) {
              if (styleRange.range.start < change.range.start) {
                if (styleRange.range.end > change.range.end) {
                  return [
                    {
                      ...styleRange,
                      range: {
                        start: styleRange.range.start,
                        end: change.range.start,
                      },
                    },
                    {
                      ...styleRange,
                      range: {
                        start: change.range.end,
                        end: styleRange.range.end,
                      },
                    },
                  ];

                } else if (styleRange.range.end > change.range.start) {
                  // styleRange.range.end = change.range.start
                  return R.assocPath(["range", "end"], change.range.start, styleRange);
                }
              } else {
                if (styleRange.range.end <= change.range.end) {
                  return null;
                } else if (styleRange.range.start < change.range.end) {
                  // styleRange.range.start = change.range.end
                  return R.assocPath(["range", "start"], change.range.end, styleRange);
                }
              }
            }

            return styleRange;
          })));

          if (isHighlightChange(change, changesDisplayMode)) {
            inlineChangeHighlights.push(
              {
                type: DELETED_STYLE,
                change_id: change.change_id,
                range: change.range,
                style: change.style,
                author_id: change.author_id,
              }
            )
          }

          break;
        default:
          // ADD ERROR
          break;
      }
    }
  });

  commentThreadChanges.forEach((change) => {
    if (isRelevantChange(change, changesDisplayMode) && isHighlightChange(change, changesDisplayMode)) {
      inlineChangeHighlights.push(
        {
          type: change.type,
          change_id: change.change_id,
          range: change.range,
          author_id: change.author_id,
        }
      );
    }
  });

  return {
    ...rangeInlineJSON,
    styleRanges,
    inlineIrrlevantRanges,
    inlineChangeHighlights,
  }
}

//////////////////////////////////

export function computeSpacedChangeSummaries(doc, currentMenu, currentDocSelection: DocSelection, currentPrioritizedOffsetChangeSummary) {
  let changeSummaries: (ChangeSummary | NewCommentThreadChangeSummary)[] = Array.from(weakMemoizedCollectAllSuggestionChanges(doc).values()).map((arr) => ({...arr[0], allChanges: arr}));

  if (currentMenu?.menu === NEW_COMMENT_THREAD) {
    const newCommentThread = {
      type: "NEW_COMMENT_THREAD" as const,
      change_set_id: "newCommentThread" as const,
      selection: currentDocSelection,
      uid: currentDocSelection.start.uid,
      contentKey: currentDocSelection.start.contentKey,
      range: {
        start: currentDocSelection.start.index,
        end: currentDocSelection.end.index,
      },
      variantId: currentDocSelection.variantId,
    }

    changeSummaries = changeSummaries.concat([newCommentThread]);
  }

  const htmlDocument = window.document;
  const bodyElement = htmlDocument.getElementById("doc-body-wrapper")
  const bodyOffset = bodyElement ? bodyElement.getBoundingClientRect().top : 70;

  let desiredOffestChangeSummaries = changeSummaries.map((changeSummary) => {
    const nodeElement = htmlDocument.getElementById(changeSummary.uid);
    const changeSummaryElement = htmlDocument.getElementById(`changeSummary--${changeSummary.change_set_id}`);

    return {
      changeSummary: changeSummary,
      desiredOffset: nodeElement ? nodeElement.getBoundingClientRect().top - bodyOffset : -1000000,
      height: changeSummaryElement?.clientHeight,
      prioritizeOffset: false,
    };
  });

  desiredOffestChangeSummaries = desiredOffestChangeSummaries.sort((a, b) => {
    const offsetDiff = a.desiredOffset - b.desiredOffset;

    if (offsetDiff !== 0) {
      return offsetDiff;
    } else {
      return (a.changeSummary.range?.start || 0) - (b.changeSummary.range?.start || 0);
    }
  });

  let prioritizeOffsetIndex = -1;
  if (currentMenu?.menu === NEW_COMMENT_THREAD) {
    prioritizeOffsetIndex = desiredOffestChangeSummaries.findIndex((offsetChangeSummary) => offsetChangeSummary.changeSummary.type === "NEW_COMMENT_THREAD")
  } else if (currentMenu?.menu === EDIT_COMMENT) {
    prioritizeOffsetIndex = R.findIndex((offsetChangeSummary) => (
      offsetChangeSummary.changeSummary.change_set_id === currentMenu.change_set_id
    ), desiredOffestChangeSummaries);
    console.log(prioritizeOffsetIndex)
  } else if (currentDocSelection) {
    prioritizeOffsetIndex = R.findLastIndex((offsetChangeSummary) => (
      offsetChangeSummary.changeSummary.uid === currentDocSelection.start?.uid
      && offsetChangeSummary.changeSummary.range?.start <= currentDocSelection.start?.index
    ), desiredOffestChangeSummaries);
  } else if(currentPrioritizedOffsetChangeSummary) {
    prioritizeOffsetIndex = R.findIndex((offsetChangeSummary) => (
      offsetChangeSummary.changeSummary.change_set_id === currentPrioritizedOffsetChangeSummary.change_set_id
    ), desiredOffestChangeSummaries);
  }

  if (prioritizeOffsetIndex >= 0) {
    desiredOffestChangeSummaries[prioritizeOffsetIndex].prioritizeOffset = true;
  }

  const MARGIN = 10;
  const DEFAULT_HEIGHT = 120;

  // ADJUST TO NOT CONFILT
  let offsetChangeSummaries = desiredOffestChangeSummaries.reduce(({minOffset, returnChangeSummaries}, offsetChangeSummary) => {
    let offset = 0;
    let newMinOffset = minOffset;
    if (offsetChangeSummary.desiredOffset < 0) {
      offset = offsetChangeSummary.desiredOffset;
    } else if (offsetChangeSummary.prioritizeOffset) {
      offset = offsetChangeSummary.desiredOffset;
      newMinOffset = offset + MARGIN + (offsetChangeSummary.height || DEFAULT_HEIGHT);
    } else {
      offset = Math.max(minOffset, offsetChangeSummary.desiredOffset);
      newMinOffset = offset + MARGIN + (offsetChangeSummary.height || DEFAULT_HEIGHT);
    }

    return {minOffset: newMinOffset, returnChangeSummaries: [...returnChangeSummaries, {...offsetChangeSummary, offset: offset}]};
  }, {minOffset: 0, returnChangeSummaries: [] as OffsetChangeSummary[]}).returnChangeSummaries;

  // ADJUST AROUND PRIORITY
  offsetChangeSummaries = offsetChangeSummaries.reduceRight(({maxOffset, returnChangeSummaries}, offsetChangeSummary) => {
    let offset = 0;
    let newMaxOffset = maxOffset;
    if (offsetChangeSummary.desiredOffset < 0) {
      offset = offsetChangeSummary.desiredOffset;
    } else if (offsetChangeSummary.prioritizeOffset) {
      offset = offsetChangeSummary.desiredOffset;
      newMaxOffset = offset - MARGIN;
    } else {
      const offsetChangeSummaryHeight = (offsetChangeSummary.height || DEFAULT_HEIGHT);
      const possibleMaxHeight = maxOffset - offsetChangeSummaryHeight;

      offset = Math.min(possibleMaxHeight, offsetChangeSummary.offset);
      newMaxOffset = offset - MARGIN;
    }

    return {maxOffset: newMaxOffset, returnChangeSummaries: [...returnChangeSummaries, {...offsetChangeSummary, offset: offset}]};
  }, {maxOffset: Number.MAX_SAFE_INTEGER, returnChangeSummaries: []}).returnChangeSummaries;

  return offsetChangeSummaries;
}

/////////////////////////
/////////////////////////

export function stripExtraChangeDataFields(doc) {
  return docMapAllNodes(doc, (node) => (
    R.omit([
      "_newStartWith",
      "editIndex",
      "listIndentation",
      "listUid",
      "blockChangeHighlight",
      "addDeleteChanges",
      "key_value_changes",
      "styleChanges",
      "rowChanges",
      "columnChanges",
      "cellSpanChanges",
      "contentListNodes",
      "contentItemsListNodes",
      "contentCategoriesListNodes",
      "imagesListNodes",
      "cdqFeaturesListNodes",
    ], node as any) as any // TODO: Remove any
  ))
}
