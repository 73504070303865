import BaseObserver from './baseObserver'

interface CopyObserverDelegate {
  copyDidFire?: (mode: ClipboardEvent) => void;
  cutDidFire?: (mode: ClipboardEvent) => void;
  pasteDidFire?: (mode: ClipboardEvent) => void;
}

export default class CopyObserver extends BaseObserver<CopyObserverDelegate> {
  startEventListeners() {
    document.addEventListener("copy", this.notifyOnCopy.bind(this), true);
    document.addEventListener("cut", this.notifyOnCut.bind(this), true);
    document.addEventListener("paste", this.notifyOnPaste.bind(this), true);
  }

  stopEventListeners() {
    document.removeEventListener("copy", this.notifyOnCopy.bind(this), true);
    document.removeEventListener("cut", this.notifyOnCut.bind(this), true);
    document.removeEventListener("paste", this.notifyOnPaste.bind(this), true);
  }

  notifyOnCopy(event: ClipboardEvent) {
    this.delegates.forEach((delegate) => {
      if (delegate.copyDidFire) {
        delegate.copyDidFire(event);
      }
    });
  }

  notifyOnCut(event: ClipboardEvent) {
    this.delegates.forEach((delegate) => {
      if (delegate.cutDidFire) {
        delegate.cutDidFire(event);
      }
    });
  }

  notifyOnPaste(event: ClipboardEvent) {
    this.delegates.forEach((delegate) => {
      if (delegate.pasteDidFire) {
        delegate.pasteDidFire(event);
      }
    });
  }
}
