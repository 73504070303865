import React from 'react'

const StickyTable = () => {
  const setStickyHeader = function() {
    document.querySelectorAll(".sticky-table").forEach(stickyTable => {
      let stickyRowHeights = 0
      stickyTable.querySelectorAll(".sticky-row").forEach(stickyRow => {
        stickyRow.querySelectorAll("th, td").forEach(stickyCell => {
          stickyCell.style.top = `${stickyRowHeights}px`
        })

        stickyRowHeights += stickyRow.offsetHeight
      })

      // stickyTable.querySelectorAll("tr").forEach(row => {
      //   let stickyCellWidths = 0
      //   row.querySelectorAll(".sticky-cell").forEach(stickyCell => {
      //     stickyCell.style.left = `${stickyCellWidths}px`
      //
      //     stickyCellWidths += stickyCell.offsetWidth
      //   })
      // })
    })
  }

  setStickyHeader()

  window.onresize = setStickyHeader
}

export default StickyTable
