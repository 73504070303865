import * as React from 'react'
import ReactOnRails from 'react-on-rails'
import axios from 'axios'

export default class FileUploader extends React.Component {
  onSubmit(e) {
    e.preventDefault();

    // Chrome triggers the onChange when the file picker "Cancel" is clicked
    // after a file is uploaded. This prevents an error from being spawend.
    if (!e.target.files[0]) { return; }

    if (this.props.changeLoadingCountByValue) {
      this.props.changeLoadingCountByValue(1)
    }

    const formData = new FormData();
    formData.append(this.props.name, e.target.files[0]);
    Object.entries(this.props.extraData || {}).forEach(([key, val]) => formData.append(key, val))
    const firstFileName = e.target.files[0].name

    axios.post(
      this.props.fileUploadPath,
      formData,
      ReactOnRails.authenticityHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      })
    ).then(response => {
      if (this.props.onSuccess) {
        this.props.onSuccess(response.data)
      }

      if (this.props.changeLoadingCountByValue) {
        this.props.changeLoadingCountByValue(-1)
      }

      alert(`${firstFileName} uploaded!`)
    }).catch(error => {
      console.error(error)

      if (this.props.changeLoadingCountByValue) {
        this.props.changeLoadingCountByValue(-1)
      }

      alert(`There was an error encountered when uploading ${firstFileName}.`)
      throw(error)
    })
  }

  render() {
    return (
      <span>
        {(this.props.loadingCount && this.props.loadingCount > 0) ? ` (${this.props.loadingCount} Uploading)` : ""}
        <form>
          <input label='Upload File' type='file' onChange={this.onSubmit.bind(this)}/>
        </form>
      </span>
    )
  }
}
