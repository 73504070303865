import {
  camelCase,
  mapKeys,
} from 'lodash'

import { addMissingUids } from '../helpers/Doc/Functions/base'

import { generateReducer, generateActions } from './itemReducer'

const itemKey = 'version'
const endpoint = 'api/v2/versions/'

function inTransformer(data) {
  let transformedData = mapKeys(data, (value, key) => camelCase(key)) as Version;
  transformedData.data = addMissingUids(transformedData.data);

  transformedData.changeAuthorIds = [] // TODO ADD BACK IN IDS

  return transformedData
}

export function generateVersionActions(id: ItemId) {
  const versionActions = generateActions<Version, VersionExtraData>(itemKey, id, endpoint)

  return {
    startNewVersion: versionActions.startNewItem,
    cancelNewVersion: versionActions.cancelNewItem,
    startEditVersion: versionActions.startEditItem,
    cancelEditVersion: versionActions.cancelEditItem,
    setAllDataWithFunctionVersion: versionActions.setAllDataWithFunctionItem,
    setSyncDataVersion: versionActions.setSyncDataItem,
    setSyncDataWithFunctionVersion: versionActions.setSyncDataWithFunctionItem,
    setEditDataVersion: versionActions.setEditDataItem,
    setEditDataWithFunctionVersion: versionActions.setEditDataWithFunctionItem,
    resetEditDataVersion: versionActions.resetEditDataItem,
    setExtraDataVersion: versionActions.setExtraDataItem,
    setExtraDataWithFunctionVersion: versionActions.setExtraDataWithFunctionItem,
    resetExtraDataVersion: versionActions.resetExtraDataItem,
    loadVersion: versionActions.loadItem,
    createVersion: versionActions.createItem,
    updateVersion: versionActions.updateItem,
    destroyVersion: versionActions.destroyItem,
    loadIfNeededVersion: versionActions.loadIfNeededItem,
    versionFromState: versionActions.itemFromState,
    versionSyncDataFromState: versionActions.itemSyncDataFromState,
    versionSyncMetaFromState: versionActions.itemSyncMetaFromState,
    versionEditDataFromState: versionActions.itemEditDataFromState,
    versionMergedDataFromState: versionActions.itemMergedDataFromState,
    versionExtraDataFromState: versionActions.itemExtraDataFromState,
  }
}

export const versionCollectionReducer = generateReducer<Version, VersionExtraData>(itemKey, {inTransformer: inTransformer, keepKeysOnUpdateFulfilled: ["changeData"]});
