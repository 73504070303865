// EDITOR PANELS
export const SET_KEYS  = 'relier-frontend/docSetting/SET_KEY'

//Intial State
const initialState = {
}

//Reducer
export default function reducer(state:any = initialState, action:any = {}) { // TODO: Remove any
  switch (action.type) {
    case SET_KEYS:
      return {...state, ...action.payload.fields}
    default:
      return state
  }
}

//Actions
export function setSettings(fields={}) {
  return {
    type: SET_KEYS,
    payload: {fields}
  }
}

export function settingFromState(key: string, state: ReducerState) {
  return state.docSettings[key]
}
