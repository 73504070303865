import {
  filter,
  findIndex,
  isString,
  keys,
  minBy,
  omit,
} from "lodash";

import {docMapBlockNodes, nodeInlineContentKeys} from "../Functions/base";
import * as TreeInlineJSON from "../Functions/TreeInlineJSON";

import * as DocConvertHTML from "./HTML";
import * as DocConvertPlainText from "./PlainText";

import {sortItemsWithRangesByStart} from "../Functions/Range";
import {compressStyleRanges} from "../Functions/RangeInlineJSON";
import {isTreeInlineJSON} from "../Functions/TreeInlineJSON";

export function fromInlineJson(treeInlineNodes: Array<InlineTreeNode>){
  const th =  omit(innerFromInlineJson(treeInlineNodes), "length")
  return compressStyleRanges(omit(innerFromInlineJson(treeInlineNodes), "length") as any); // TODO: Remove any
}

function innerFromInlineJson(treeInlineNodes: Array<InlineTreeNode>){
  return treeInlineNodes.reduce((currentState, node) => {
    const newCurrentState = {...currentState};
    switch (node.type) {
      case "text":
        newCurrentState.text += node.content;
        newCurrentState.length += node.content.length;
        break;
      default:
        const childRange = innerFromInlineJson(node.type === "link" ? node.title : node.content);

        const newStyle = {
          type: node.type,
          range: {
            start: 0,
            end: childRange.length,
          },
        } as any; // TODO: Remove any

        const args = omit(node, ["type", "content", "title", "uid"]);
        if (keys(args).length > 0) {
          newStyle.args = args;
        }

        const newStates = [newStyle].concat(childRange.styleRanges).map((childStyle) => {
          const newChildStyle = {...childStyle};

          newChildStyle.range.start += currentState.length;
          newChildStyle.range.end += currentState.length;

          return newChildStyle;
        });

        newCurrentState.text += childRange.text;
        newCurrentState.length += childRange.length;
        newCurrentState.styleRanges = newCurrentState.styleRanges.concat(newStates);
    }

    return newCurrentState;
  }, {text: "", styleRanges: [], length: 0});
}

export function toInlineJson(rangedStyle, colorLookup = {}){
  const {
    text,
    inlineIrrlevantRanges = [],
    inlineChangeHighlights = [],
  } = rangedStyle;

  let newStyleRanges = rangedStyle.styleRanges as Array<StyleRange>; // TODO: Remove as

  if (inlineIrrlevantRanges.length > 0) {
    newStyleRanges = newStyleRanges.concat(inlineIrrlevantRanges);
  }

  if (inlineChangeHighlights.length > 0) {
    const inlineChangeHighlightsWithColor = inlineChangeHighlights.map((inlineChangeHighlight) => {
      const color = colorLookup[inlineChangeHighlight.author_id] || [255,0,0];

      return {
        ...inlineChangeHighlight,
        args: {
          ...inlineChangeHighlight.args,
          color,
        },
      }
    })
    newStyleRanges = newStyleRanges.concat(inlineChangeHighlightsWithColor);
  }

  if (rangedStyle.selectionMimics) {
    const selectionMimicsAsStyles = rangedStyle.selectionMimics.map((selectionMimic) => {
      return {
        type: "SELECTION_MIMIC",
        range: selectionMimic.range,
        args: {
          color: [0,0,255],
        },
      };
    });
    newStyleRanges = newStyleRanges.concat(selectionMimicsAsStyles);
  }

  const sortedStyles = sortItemsWithRangesByStart(newStyleRanges);

  const reduceReturn = sortedStyles.reduce((currentState, newStyleRange, newStyleRangeIndex) => {
    const addNewStyleTo = TreeInlineJSON.contentRightNDeep(currentState.returnValue, currentState.styleRangeStack.length);

    if (currentState.textLeftIndex < newStyleRange.range.start) {
      addNewStyleTo.push({
        type: "text",
        content: text.slice(currentState.textLeftIndex, newStyleRange.range.start),
      });
      currentState.textLeftIndex = newStyleRange.range.start;
    }

    addNewStyleTo.push(rangeInlineToEmptyTreeInline(newStyleRange));
    currentState.styleRangeStack.push(newStyleRange);

    const letNextItem = sortedStyles[newStyleRangeIndex + 1];
    let minEnd = minBy(currentState.styleRangeStack, (styleRange: any) => styleRange.range.end); // TODO: Remove any
    while (minEnd && (!letNextItem || minEnd.range.end <= letNextItem.range.start)) {
      if (currentState.textLeftIndex < minEnd.range.end) {
        const currentLastNode = TreeInlineJSON.nodeRightNDeep(currentState.returnValue, currentState.styleRangeStack.length - 1);
        TreeInlineJSON.appendTextToNode(currentLastNode, text.slice(currentState.textLeftIndex, minEnd.range.end));
        currentState.textLeftIndex = minEnd.range.end;
      }

      const indexOfFirstMin = findIndex(currentState.styleRangeStack, (styleRange: any) => (styleRange.range.end <= minEnd.range.end)); // TODO: Remove any
      const styleRangeStackWithMinsRemoved = filter(currentState.styleRangeStack, (styleRange) => (styleRange.range.end > minEnd.range.end));

      const remainingStylesWhole = styleRangeStackWithMinsRemoved.slice(0, indexOfFirstMin);
      const remainingStylesSplit = styleRangeStackWithMinsRemoved.slice(indexOfFirstMin);

      if (remainingStylesSplit.length > 0) {
        const addSplitStylesTo = TreeInlineJSON.contentRightNDeep(currentState.returnValue, indexOfFirstMin);

        addSplitStylesTo.push(styleRangeStackToStyleTree(remainingStylesSplit));
      }

      currentState.styleRangeStack = styleRangeStackWithMinsRemoved;

      minEnd = minBy(currentState.styleRangeStack, (styleRange) => styleRange.range.end);
    }

    return currentState;
  }, {
    returnValue: [],
    textLeftIndex: 0,
    styleRangeStack: [],
  });

  if (reduceReturn.textLeftIndex < text.length) {
    reduceReturn.returnValue.push({
      type: "text",
      content: text.slice(reduceReturn.textLeftIndex),
    });
  }

  return reduceReturn.returnValue;
}

export function toHTML(rangedStyle, colorLookup = {}) {
  return DocConvertHTML.fromInlineJson(toInlineJson(rangedStyle, colorLookup));
}

export function toPlainText(rangedStyle, colorLookup = {}) {
  return DocConvertPlainText.fromInlineJson(toInlineJson(rangedStyle, colorLookup));
}

function styleRangeStackToStyleTree(styleRangeStack) {
  if (styleRangeStack.length > 0) {
    const rangeInline = rangeInlineToEmptyTreeInline(styleRangeStack[0]);

    if (styleRangeStack.length > 1) {
      const content = styleRangeStackToStyleTree(styleRangeStack.slice(1));

      switch (rangeInline.type) {
        case "link":
          rangeInline.title = [content];
          break;
        default:
          rangeInline.content = [content];
      }
    }

    return rangeInline;
  } else {
    return null;
  }
}

function rangeInlineToEmptyTreeInline(rangeInline) {
  const returnValue = {
    type: rangeInline.type,
    ...rangeInline.args,
  };

  switch (rangeInline.type) {
    case "link":
      returnValue.title = [];
      break;
    default:
      returnValue.content = [];
  }

  return returnValue;
}

export function convertAllInlineNodesToRangeJSON(node) {
  return docMapBlockNodes(node, (blockNode) => {
    const inlineContentKeys = nodeInlineContentKeys(blockNode);

    inlineContentKeys.forEach((contentKey) => {
      let newInlineNode = blockNode[contentKey];

      if (isString(newInlineNode)) {
        newInlineNode = DocConvertHTML.toInlineJson(newInlineNode);
      }

      if (newInlineNode && !isString(newInlineNode["text"])) {
        // EXCEPTION UNTILL ALL LISTS ARE NODE TYPES
        if (blockNode.type !== "listItem" || isTreeInlineJSON(newInlineNode)) {
          blockNode[contentKey] = fromInlineJson(newInlineNode);
        } else {
          blockNode[contentKey] = blockNode[contentKey].map((listItemChildNode) => convertAllInlineNodesToRangeJSON(listItemChildNode));
        }
      }
    });

    return blockNode;
  });
}
