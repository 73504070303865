import * as React from 'react';

interface Props extends React.ComponentPropsWithoutRef<'input'> {}

export default function AqSwitch(props: Props): React.ReactElement {
  const {
    checked,
    disabled,

    onChange,
  } = props;

  return (
    <label className="aq-switch">
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}

        onChange={onChange}
      />
      <span className="switch"/>
    </label>
  );
}