import * as React from "react";

import * as classNames from "classnames";

import { handleActions } from "../../../helpers/Doc/handleActions";
import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

interface DocRatingBarProps extends DocRatingBarType, BlockNodeAll {};

function DocRatingBar(props: DocRatingBarProps) {
  const onClick = (clickValue: number, e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    if (props.inactiveBool) { return; }

    const allActions = (props.onClick || []);
    allActions.push({
      type: "action",
      action: "setNodeKVPair",
      arguments: {
        uid: props.uid,
        key: "value",
        value: props.maxValue - clickValue,
      },
    });

    handleActions(allActions, props);
  };

  const stars = [];
  for (let i = 0; i < props.maxValue; i += 1) {
    const className = classNames("doc-rating-bar-star", {"doc-rating-bar-star-active": (i >= props.maxValue - props.value)});
    stars.push(<span className={className} key={i} onClick={(e) => onClick(i, e)}>☆</span>);
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-rating-bar", props.uid, {inactive: props.inactiveBool})}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    >
      {stars}
    </div>
  );
}

export default React.memo(DocRatingBar);
