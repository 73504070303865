import React from 'react'

import GenList from 'Gen/List/component'
import ReusableObjectSummary from 'Summary/component'
import { reusableObjectListDefaults } from './defaults'

const TableHeader = () => (
  <tr className = "document-list-table-header" style = {{height: '100%', padding: "0px"}}>
    <th style = {{width: '90px', padding: "0px"}}/>
    <th style = {{width: '100px', padding: "0px"}}/>
    <th style = {{width: '100%', padding: "0px"}}/>
    <th style = {{width: '250px', padding: "0px"}}/>
  </tr>
)

const ReusableObjectList = ({
  context,
  itemsURL,
  documentLookupTable,
  setAddModalOpen,
  permissions
}) => {
  return (
    <div className = "document-list">
      <GenList
        context="RC"
        isMultiSelect
        historyPersistance
        { ...reusableObjectListDefaults }
        { ...{ documentLookupTable, itemsURL } }
        tableHeader = { TableHeader() }
        itemView = {(rlo) => <ReusableObjectSummary { ...{ rlo, context, setAddModalOpen, permissions} } key = { `doc-${rlo.id}` } />}
      />
    </div>
  )
}

export default ReusableObjectList;
