import * as React from "react";

import { get } from "lodash";

import * as R from "ramda";

import GenButton from "../../Gen/Button/component";

import DocEditHeaderColorPicker from "./NodeGeneralControls/ColorPicker";
import DocEditHeaderLinkInput from "./NodeGeneralControls/LinkInput";
import DocEditHeader from "./EditHeader";
import ConvertToRLOModal from "../ConvertToRLOModal";

import ActiveEditors from "../../Channels/ActiveEditors";

import * as selection from "../../../helpers/Doc/selection";

import {
  EDIT_LINK,
  FIND_AND_REPLACE,
  NEW_COMMENT_THREAD,
  RLO_MODAL,
} from "../../../helpers/Doc/menu"

import { LIST_RLO_MODAL } from "../../../helpers/Doc/menu";
import ListRLOModal from "../ListRLOModal";

import {
  newSetKeyValueChange,
} from "../../../helpers/Doc/changes";

interface DocEditBarNodeGeneralControlsProps {
  documentContext: DocumentContext;
  editingContext: EditingContext;
  editingContextExtra: EditingContextExtra;
  mergedData: MergedData;
  userContext: UserContext;
  mappingContext: MappingContext;
  projectContext: ProjectContext;
  featureFlag: FeatureFlag
};

export default function DocEditBarNodeGeneralControls(props: DocEditBarNodeGeneralControlsProps) {
  const {
    documentContext,
    editingContext,
    editingContextExtra,
    mergedData,
    userContext,
    mappingContext,
    projectContext,
  } = props;

  const {
    activeEditors,
    lockState,
    lockIfFree,
    unlockIfOwned,
    forceLock,
  } = editingContextExtra;

  let activeEditorsDisplay;
  if (editingContext.shouldShowEditor && activeEditors && lockState) {
    activeEditorsDisplay = (
      <ActiveEditors
        key="ActiveEditors"
        userContext={userContext}
        activeEditors={activeEditors}
        lockState={lockState}
        lockIfFree={lockIfFree}
        unlockIfOwned={unlockIfOwned}
        forceLock={forceLock}
      />
    )
  }

  if (!editingContext.isEditing) {
    if (activeEditorsDisplay) {
      return (
        <div className="edit-bar-control-bar">
          {activeEditorsDisplay}
        </div>
      );
    } else {
      return null;
    }
  }

  const styles: Array<StyleName> = [
    "bold",
    "italic",
    "underline",
    "superscript",
    "subscript",
    "link"
  ];

  const controls = [];

  styles.forEach((style) => {
    const isActiveStyle = get(editingContextExtra, "activeStyles", [] as Array<StyleName>).includes(style);
    const isLinkStyle = style === "link";

    if (isLinkStyle && editingContextExtra.currentMenu?.menu === EDIT_LINK) {
      controls.push(<DocEditHeaderLinkInput
        key="DocEditHeaderLinkInput"
        editingContext={editingContext}
        editingContextExtra={editingContextExtra}
      />);
    }

    controls.push(<GenButton
      key={`${style}-style`}
      name={<i className={`fa fa-${style}`}></i>}
      highlighted={isActiveStyle}
      small
      disabled={!editingContextExtra.currentDocSelection || (isLinkStyle && selection.isCollapsed(editingContextExtra.currentDocSelection))}
      onClick={() => {
        if (isLinkStyle) {
          editingContext.setCurrentMenu(EDIT_LINK);
        } else {
          editingContextExtra.toggleStyle(style);
        }
      }}
    />);
  });

  controls.push(
    <DocEditHeaderColorPicker
      key="DocEditHeaderColorPicker"
      editingContext={editingContext}
      editingContextExtra={editingContextExtra}
    />
  )

  controls.push(<div key="divider-1" className="edit-bar-control-bar-divider"></div>);

  controls.push(
    <GenButton
      key="Find/Replace"
      name="Find/Replace"
      small
      onClick={() => {
        editingContext.setCurrentMenu(FIND_AND_REPLACE);
      }}
    />,
  );

  controls.push(<div key="divider-2" className="edit-bar-control-bar-divider"></div>);

  // Disability checks for Inline/Node comments
  const tempForType: Array<any> = editingContextExtra.currentlyEditingNodeLineage || [] // TODO: Narrow down any
  const isDisabled =
    !userContext.permissions.createComments ||
    (editingContextExtra.currentMenu?.menu !== NEW_COMMENT_THREAD &&
    (!editingContextExtra.currentDocSelection || !selection.isInOneNode(editingContextExtra.currentDocSelection) || (selection.isCollapsed(editingContextExtra.currentDocSelection) && !["image", "audio", "video"].includes(R.last(tempForType)?.type))));

  controls.push(
    <GenButton
      key="Add Comment"
      name="Add Comment"
      highlighted={editingContextExtra.currentMenu?.menu === NEW_COMMENT_THREAD}
      small
      disabled={isDisabled}
      onClick={() => {
        if (editingContextExtra.currentMenu?.menu === NEW_COMMENT_THREAD) {
          editingContext.closeCurrentMenu();
        } else {
          editingContext.setCurrentMenu(NEW_COMMENT_THREAD);
        }
      }}
    />,
  );

  if (documentContext.docType !== "ReusableObject" && userContext.permissions.createExistingContentRlo) {
    if (editingContextExtra.currentRO?.roData) {
      controls.push(
        <GenButton
          key="Undo RC"
          name="Undo RC"
          disabled={(editingContextExtra.currentRO?.roDocumentCount || 1) > 1}
          highlighted
          small
          onClick={() => {
            editingContext.addChange(newSetKeyValueChange(editingContextExtra.currentRO.uid, "roData", null))
          }}
        />,
      );
    } else {
      controls.push(
        <GenButton
          key="Make RC"
          name="Make RC"
          disabled={!editingContextExtra.currentRO}
          highlighted
          small
          onClick={() => {
            if (editingContextExtra.currentMenu?.menu === RLO_MODAL) {
              editingContext.closeCurrentMenu();
            } else {
              editingContext.setCurrentMenu(RLO_MODAL);
            }
          }}
        />,
      );
    }
  }

  if (activeEditorsDisplay) {
    controls.push(activeEditorsDisplay)
  }

  if (projectContext.activeMemberName) {
    controls.push(<span key="Workflow Editor" style={{float: 'right', marginRight: '15px'}}><b>Current Workflow Editor is:</b> {projectContext.activeMemberName}</span>);
  } else if (projectContext.projectProductionAssociate) {
    controls.push(<span key="Project Production Associate" style={{float: 'right', marginRight: '15px'}}><b>Project Production Associate is:</b> {projectContext.projectProductionAssociate}</span>);
  }

  return (
    <div>
      <div className="edit-bar-control-bar">
        {controls}
        {editingContextExtra.currentMenu?.menu === LIST_RLO_MODAL && (
          <ListRLOModal
            editingContextExtra={editingContextExtra}
            editingContext={editingContext}
            permissions={userContext.permissions}
          />
        )}
        {editingContextExtra.currentMenu?.menu === RLO_MODAL && (
          <ConvertToRLOModal
            mappingContext={mappingContext}
            handleCancel={() => editingContext.closeCurrentMenu()}
            editingContextExtra={editingContextExtra}
            editingContext={editingContext}
            rlo={editingContextExtra.currentMenu.data}
          />
        )}
        {!props.featureFlag.new_landing_pages && (
          <DocEditHeader
            editingContext={editingContext}
            editingContextExtra={editingContextExtra}
            userContext={userContext}
          />
        )}
      </div>
      {props.featureFlag.new_landing_pages && (
        <div className="edit-bar-control-bar">
          <DocEditHeader
            editingContext={editingContext}
            editingContextExtra={editingContextExtra}
            userContext={userContext}
          />
        </div>
      )}
    </div>
  );
}
