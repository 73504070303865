import * as DocConvertHTML from "./HTML";
import * as DocConvertRangeInlineJSON from "./RangeInlineJSON";

import {isRangeInlineJSON} from "../Functions/RangeInlineJSON";
import {isTreeInlineJSON} from "../Functions/TreeInlineJSON";

export function fromInlineJson(styledTreeNodes, showHideText=false) {
  return styledTreeNodes.map((node) => {
    switch (node.type) {
      case "link":
        return fromInlineJson(node.title, showHideText);
      case "text":
        return node.content;
      case "HIDE_TEXT":
        return showHideText ? fromInlineJson(node.content, showHideText) : "";
      default:
        return fromInlineJson(node.content, showHideText);
    }
  }).join("");
}

export function fromHTML(html: InlineHtmlNode) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
}

// export function toHTML(plainTextString: InlinePlainTextNode) {
//   return DocConvertHTML.fromInlineJson(toInlineJson(plainTextString));
// }

// export function toRangeInlineJson(plainTextString: InlinePlainTextNode) {
//   return DocConvertRangeInlineJSON.fromInlineJson(toInlineJson(plainTextString));
// }

export function fromAnyType(inlineNode) {
  if (isRangeInlineJSON(inlineNode)) {
    return DocConvertRangeInlineJSON.toPlainText(inlineNode);
  } else if (isTreeInlineJSON(inlineNode)) {
    return fromInlineJson(inlineNode);
  } else if (typeof inlineNode === "string") {
    return fromHTML(inlineNode);
  } else {
    return ""
  }
}
