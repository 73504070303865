import * as React from "react";

import {
  newAcceptChangesChange,
  newRejectChangesChange,
  newAddCommentThreadChange,
  newAddChangeCommentChange,
  newDeleteChangeCommentChange,
  newCloseCommentThreadChange,
  changeSummaryDescription,
  ADD_COMMENT_THREAD,
} from "../../../helpers/Doc/changes";

import { EDIT_COMMENT } from "../../../helpers/Doc/menu";
import {
  scrollToUid,
  scrollToUidAndContentKey,
} from "../../../helpers/Doc/scrollTo";

import DocCommentInput from "./CommentInput";
import DocAuthorHeader from "../AuthorHeader";

const borderStyle = {border: "solid black 1px", marginLeft: "5px", marginBottom: "5px", padding: "5px"};

interface DocChangeSummaryProps {
  editingContext: EditingContextInEditor;
  userContext: UserContext;
  changeSummary: ChangeSummary | NewCommentThreadChangeSummary;
  currentVersion: CurrentVersion;
  offset: number;
};

function isNewCommentThread(changeSummary: ChangeSummary | NewCommentThreadChangeSummary): changeSummary is NewCommentThreadChangeSummary {
  return changeSummary.type === "NEW_COMMENT_THREAD"
}

function DocChangeSummary(props: DocChangeSummaryProps) {
  const {
    editingContext,
    userContext,
    changeSummary,
    currentVersion,
    offset,
  } = props;

  const sendNotifications = (text: string) =>  {
    fetch(`/versions/${currentVersion.id}/send_tag_notification`, {
      method: 'POST',
      headers: ReactOnRails.authenticityHeaders({
        'Accept': "application/json",
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ text: text, tagger_id: props.userContext.id })
    });
  };

  const generateCommentText = (text: string) => {
    var out = text.replace(/{[^}]*}/g,'')
    return out
  }

  if (isNewCommentThread(changeSummary)) {
    const author = userContext;
    const isInOneInlineComment = !!changeSummary.range;

    return (
      <div
        id={"changeSummary--newCommentThread"}
        className="doc-change-summary-item doc-inline-comment-tread"
        key={changeSummary.change_set_id}
        style={{...borderStyle, width: "300px", position: "absolute", top: `${offset}px`, right: "10px"}}
      >
        <DocAuthorHeader
          authorName={`${author.firstName} ${author.lastName}`}
          colorArray={editingContext.colorLookup[author.id]}
        />
        <DocCommentInput
          isActive
          onAddClick={(text) => {
            sendNotifications(text)
            editingContext.addChange(newAddCommentThreadChange(changeSummary.selection, text), changeSummary.variantId);
            editingContext.closeCurrentMenu();
          }}
          onCancelClick={() => editingContext.closeCurrentMenu()}
          onFocus={() => {}}
          autoFocus
          editors={editingContext.editors}
        />
      </div>
    );
  } else {
    let changeDescription;
    let headerButtons = [];

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (changeSummary.key) {
        if (changeSummary.changeNodeType && changeSummary.changeNodeType !== "paragraph") {
          scrollToUid(changeSummary.uid)
        } else {
          editingContext.setCurrentDocSelection({
            start: {uid: changeSummary.uid, contentKey: changeSummary.key, index: changeSummary.range.start},
            end: {uid: changeSummary.uid, contentKey: changeSummary.key, index: changeSummary.range.start}
          })

          scrollToUidAndContentKey(changeSummary.uid, changeSummary.key)
        }
      }
    }

    if (ADD_COMMENT_THREAD === changeSummary.type) {
      changeDescription = generateCommentText(changeSummary.text);
      if (changeSummary.author_id === userContext.id || userContext.permissions.resolveComments) {
        headerButtons.push(
          <i
            key="close-comments"
            style={{marginRight: "5px", fontSize: "1.5em", lineHeight: "1em"}}
            className="fa fa-check"
            onClick={(e) => {
              editingContext.addChange(newCloseCommentThreadChange(changeSummary.change_id), changeSummary.variantId);
              e.stopPropagation();
            }}
          />
        );
      }
    } else {
      changeDescription = changeSummaryDescription(changeSummary, props);
      if (userContext.permissions.acceptTrackedChanges) {
        headerButtons.push(
          <i
            key="accept"
            style={{marginRight: "5px", fontSize: "1.5em", lineHeight: "1em"}}
            className="fa fa-check"
            onClick={(e) => {
              editingContext.addChange(newAcceptChangesChange(changeSummary.change_id), changeSummary.variantId);
              e.stopPropagation();
            }}
          />
        )
      }
      if (userContext.permissions.rejectTrackedChanges || userContext.id === changeSummary.author_id) {
        headerButtons.push(
          <i
            key="reject"
            style={{marginRight: "5px", fontSize: "1.5em", lineHeight: "1em"}}
            className="fa fa-times"
            onClick={(e) => {
              editingContext.addChange(newRejectChangesChange(changeSummary.change_id), changeSummary.variantId);
              e.stopPropagation();
            }}
          />
        )
      }
    }

    const firstChangeId = changeSummary.change_id;

    const renderedComments = (changeSummary.comments || []).map((comment) => {
      return (
        <div key={comment.comment_id}>
          <DocAuthorHeader
            authorName={comment.author_full_name}
            colorArray={editingContext.colorLookup[comment.author_id]}
            createdAt={comment.created_at}
          >
            {comment.author_id === userContext.id && (
              <i
                style={{marginRight: "5px"}}
                className="fa fa-trash"
                onClick={(e) => {
                  editingContext.addChange(newDeleteChangeCommentChange(comment.comment_id), changeSummary.variantId);
                  e.stopPropagation();
                }}
              />
            )}
          </DocAuthorHeader>
          <p style={{marginBottom: 0}}>{generateCommentText(comment.text)}</p>
          <hr style={{margin: "5px 0"}} />
        </div>
      );
    });

    const commentResponseInput = () => {
      if (userContext.permissions.respondToComments) {
        // TODO: Inspect to make sure editingContext.currentMenu actully gets the currentMenu
        return (
          <DocCommentInput
            isActive={editingContext.currentMenu?.menu === EDIT_COMMENT && editingContext.currentMenu?.change_uid === firstChangeId}
            onAddClick={(text) => {
              sendNotifications(text)
              editingContext.addChange(newAddChangeCommentChange(firstChangeId, text), changeSummary.variantId);
              editingContext.closeCurrentMenu();
            }}
            onCancelClick={() => editingContext.closeCurrentMenu()}
            onFocus={() => {
              editingContext.clearCurrentDocSelection();
              editingContext.setCurrentMenu(EDIT_COMMENT, {change_uid: firstChangeId, change_set_id: changeSummary.change_set_id});

              if (changeSummary.key) {
                if (changeSummary.changeNodeType && changeSummary.changeNodeType !== "paragraph") {
                  scrollToUid(changeSummary.uid)
                } else {
                  scrollToUidAndContentKey(changeSummary.uid, changeSummary.key)
                }
              }
            }}
            editors={editingContext.editors}
          />
        )
      }
    }

    return (
      <div
        id={`changeSummary--${changeSummary.change_set_id}`}
        className="doc-change-summary-item"
        key={changeSummary.change_set_id}
        style={{...borderStyle, width: "300px", position: "absolute", top: `${offset}px`, right: "10px"}}
        onClick={onClick}
      >
        <DocAuthorHeader
          authorName={changeSummary.author_full_name}
          colorArray={editingContext.colorLookup[changeSummary.author_id]}
          createdAt={changeSummary.created_at}
        >
          {headerButtons}
        </DocAuthorHeader>
        <p className="doc-change-summary-description" style={{marginBottom: 0}}>{changeDescription}</p>
        <hr style={{margin: "5px 0"}} />
        {renderedComments}
        {commentResponseInput()}
      </div>
    );
  }
}

export default React.memo(DocChangeSummary);
