import * as React from "react";
import { HotKeys } from "react-hotkeys";

import GenButton from "../../Gen/Button/component";
import { MentionsInput, Mention, OnChangeHandlerFunc } from 'react-mentions'

import mentionsInputStyle from "./CommentInput/mentionsInputStyle";
import mentionStyle from "./CommentInput/mentionStyle";

interface DocCommentInputProps {
  editors?: Array<EditorSpecialType>;

  isActive: boolean;
  onAddClick: (value: string) => void;
  onCancelClick: () => void;
  onFocus: () => void;

  autoFocus?: boolean; // Can get rid of???
};

interface DocCommentInputState {
  textAreaValue: string;
};

export default class DocCommentInput extends React.Component<DocCommentInputProps, DocCommentInputState> {
  constructor(props: DocCommentInputProps) {
    super(props);

    this.state = {
      textAreaValue: "",
    };
  }

  handleChange(event: { target: { value: string } }) {
    this.setState({textAreaValue: event.target.value});
  }

  render () {
    const onAdd = () => {
      this.props.onAddClick(this.state.textAreaValue);
      this.setState({textAreaValue: ""});
    };
    const fetchUsers = (query: string) => {
      const users = this.props.editors
      if (!query) {
        return users
      }

      const filteredUsers = users.filter((user) =>
        user.display.toLowerCase().includes(query)
      );
     return filteredUsers
    };

    const HotKeysAnyType: any = HotKeys; // TODO: Remove when able to import type from HotKeys
    return (
      <HotKeysAnyType
        handlers={{
          return: (event: KeyboardEvent) => {onAdd(); return false;},
          modReturn: (event: KeyboardEvent) => {onAdd(); return false;},
        }}
      >
        <div className="doc-comment-input" onClick={(e) => e.stopPropagation()}>
        <MentionsInput
          style={mentionsInputStyle}
          value={this.state.textAreaValue}
          onChange={this.handleChange.bind(this)}
          placeholder="Comment"
          autoFocus={this.props.autoFocus}
          onFocus={this.props.onFocus}
        >
          <Mention
            style={mentionStyle}
            trigger="@"
            data={fetchUsers}
            markup={'@__display__{__id__}'}
          />
        </MentionsInput>


        {(this.state.textAreaValue.length > 0 || this.props.isActive) && (
          <div className="doc-comment-input-buttons">
            <GenButton
              name="Add"
              highlighted
              disabled={this.state.textAreaValue.length === 0}
              onClick={onAdd}
            />
            <GenButton
              name="Cancel"
              highlighted
              onClick={() => {this.setState({textAreaValue: ""}); this.props.onCancelClick();}}
            />
          </div>
        )}
        </div>
      </HotKeysAnyType>
    );
  }
}
