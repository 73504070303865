import { combineReducers } from 'redux'

import { documentCollectionReducer as documents } from './documents'
import { documentUserStateCollectionReducer as documentUserStates } from './documentUserStates'
import { learningObjectiveCollectionReducer as learningObjectives } from './learningObjectives'
import { reusableObjectCollectionReducer as reusableObjects } from './reusableObjects'
import { variantCollectionReducer as variants } from './variants'
import { versionCollectionReducer as versions } from './versions'

import docSettings from './docSettings';
import reasoningTool from './ui/reasoningTool'

const ui = combineReducers({
  reasoningTool,
})

export default combineReducers({
  ui,

  documents,
  documentUserStates,
  learningObjectives,
  reusableObjects,
  variants,
  versions,
  docSettings,
})
