import * as React from "react";

import * as classNames from "classnames";

import DocStyledText from "../BlockNodeHelpers/StyledText";

import { newSetKeyValueChange, newSetKeysValuesChange, backgroundStyleFromNode } from "../../../helpers/Doc/changes";

import { compact, uniq } from "lodash";

import * as DocConvertHTML from "../../../helpers/Doc/Convert/HTML";

import * as DocConvertRangeInlineJSON from "../../../helpers/Doc/Convert/RangeInlineJSON";
import * as DocConvertPlainText from "../../../helpers/Doc/Convert/PlainText";

interface DocCDQQuestionProps extends DocCdqQuestionType<InlineNode>, BlockNodeAll {}

function DocCDQQuestion(props: DocCDQQuestionProps) {
  const {
    editingContext,
  } = props;

  const classes = classNames("doc-cdq", {inactive: props.inactiveBool, graded: props.graded});

  const answersSelected = props.content.filter((answer) => answer.value);
  const answersSelectedCount = answersSelected.length;
  const answers = props.content.map((answer, index) => {
    const answerOnClick = (e: React.MouseEvent<HTMLLIElement>) => {
      if (editingContext.isEditing) {
        e.stopPropagation();
      } else {
        let uscs = {};

        if (answer.value || (props.limit === false) || !props.isExplicitLimit || (answersSelectedCount < props.limit)) {
          uscs = {
            [answer.uid]: {value: !answer.value},
          };
        } else if (props.isExplicitLimit && answersSelectedCount >= props.limit) {
          alert("You have chosen the maximum number of answers for this question. In order to choose a different answer, you will first need to de-select one that you have previously chosen.");
        }

        props.userContext.addState(uscs);
      }
    };

    const letter = (index < 26) ? String.fromCharCode(65 + index) : String.fromCharCode(71 + index);

    let answerSummary, answerEditor = null;
    if (props.userContext.isAdmin) {
      let answerLetter;
      switch (answer.answer) {
        case "poison":
          answerLetter = "P";
          break;
        case true:
          answerLetter = "Y";
          break;
        default:
          answerLetter = "N";
      }

      let featureIndex;
      if (answer.feature) {
        featureIndex = props.cdqFeatures.map((cdqFeature) => cdqFeature.uid).indexOf(answer.feature) + 1;
      }

      answerSummary = <span>{answerLetter}{featureIndex} -&nbsp;</span>;

      if (editingContext.isEditing) {
        const cdqFeatureSelections = props.cdqFeatures.map((cdqFeature) => {
          return (
            <option key={cdqFeature.uid} value={cdqFeature.uid}>
              {(typeof cdqFeature.content === "string") ? cdqFeature.content : DocConvertHTML.fromInlineJson(DocConvertRangeInlineJSON.toInlineJson(cdqFeature.content))}
            </option>
          );
        });

        answerEditor = [
          <select key="doc-cdq-answer-select-list" value={answer.feature || "no-selection"} className="doc-cdq-answer-select-list" onChange={(event) => {
            if (event.target.value === "no-selection") {
              editingContext.addChange(newSetKeysValuesChange(answer.uid, {feature: null, answer: false}));
            } else {
              editingContext.addChange(newSetKeyValueChange(answer.uid, "feature", event.target.value));
            }
          }}>
            <option key="no-selection" value="no-selection">--No Key Feature--</option>
            {cdqFeatureSelections}
          </select>,
          <input className="doc-cdq-answer-select" type="checkbox" checked={answer.answer === true} onClick={(e) => {
            if (!answer.feature) { alert("Select a key feature first"); return; }

            editingContext.addChange(newSetKeyValueChange(answer.uid, "answer", (answer.answer === true) ? false : true));
            e.stopPropagation();
          }} />,
          <i key="doc-cdq-answer-poison" className={classNames("fa fa-ban doc-cdq-answer-poison", {"is-poison": answer.answer === "poison"})} onClick={(e) => {
            if (!answer.feature) { alert("Select a key feature first"); return; }

            editingContext.addChange(newSetKeyValueChange(answer.uid, "answer", (answer.answer === "poison") ? false : "poison"));
            e.stopPropagation();
          }}></i>,
        ];
      }
    }

    return (
      <li key={answer.uid} className={classNames("doc-cdq-answer", {checked: answer.value})} onClick={!props.inactiveBool && answerOnClick}>
        <div className="doc-cdq-answer-flex-wrapper">
          <span className="doc-cdq-answer-outer"></span>
          {answerSummary}
          <span className="doc-cdq-answer-letter">{`${letter}. `}</span>
          &nbsp;
          <span className="doc-cdq-answer-title">
            <DocStyledText
              uid={answer.uid}
              content={answer.content}
              contentKey={"content"}
              editingContext={editingContext}
              independentlyEditable
              disableStyleTextEditing={props.disableStyleTextEditing}
            />
          </span>
          {answerEditor}
        </div>
      </li>
    );
  });

  let limitExplinationText = null;
  let limitExplinationTextExtra = null;
  if (props.limit !== false) {
    if (props.isExplicitLimit) {
      if (props.limit === 1) {
        limitExplinationText = `You will be able to select ${props.limit} option.`;
      } else {
        limitExplinationText = `You will be able to select up to ${props.limit} options.`;
        limitExplinationTextExtra = "Please note that this is the maximum number of responses, but not all of the responses may contribute to your score for this question.";
      }
    } else {
      limitExplinationText = "Select as many options as are appropriate.";
      limitExplinationTextExtra = "Remember to prioritize, you may lose the point for this case if you select too many responses.";
      if (props.userContext.isAdmin) {
        limitExplinationTextExtra = <span><u>{`The implicit limit is ${props.limit}.`}</u> {limitExplinationText}</span>;
      }
    }
  }

  let limitExplinationDiv = null;
  if (limitExplinationText) {
    limitExplinationDiv = <p className="doc-cdq-limit-text"><span className="doc-cdq-limit-text-main">{limitExplinationText}</span> <span className="doc-cdq-limit-text-extra">{limitExplinationTextExtra}</span></p>;
  }

  let selectedVsLimitView = null;
  if (props.limit !== false && props.isExplicitLimit) {
    selectedVsLimitView = (
      <p className="doc-cdq-selected-count">
        <strong>
          <span className="doc-cdq-selected-count-number">{answersSelectedCount}</span> selected of {props.limit} maximum
        </strong>
      </p>
    );
  }

  let scorePreview = null;
  if (props.userContext.isAdmin) {
    let score:any = 0; // TODO: Remove any
    if (props.limit !== false && answersSelectedCount > props.limit) {
      score = 0;
    } else {
      const features = uniq(compact(props.content.map((answer) => answer.feature)));
      const featureScores = {questionScore: 0};
      features.forEach((feature) => {
        const cdqFeature = props.cdqFeatures.find((cdqFeature) => cdqFeature.uid === feature);
        const cdqFeatureName =  DocConvertPlainText.fromAnyType(cdqFeature ? cdqFeature.content : feature);
        if (props.limit !== false && answersSelectedCount > props.limit) {
          featureScores[cdqFeatureName] = 0;
        } else {
          const featureAnswers = props.content.filter((answer) => {return answer.feature === feature;});

          const poisonFeatureAnswers = featureAnswers.filter((answer) => {return answer.answer === "poison";});
          const isPoisoned = poisonFeatureAnswers.reduce((acc, answer) => {return acc || answer.value;}, false);
          if (isPoisoned) {
            featureScores[cdqFeatureName] = 0;
          } else {
            const correctFeatureAnswers = featureAnswers.filter((answer) => {return answer.answer === true;});
            const correctCorrectFeatureAnswers = correctFeatureAnswers.filter((answer) => answer.value);

            featureScores[cdqFeatureName] = correctCorrectFeatureAnswers.length / correctFeatureAnswers.length;
          }
        }

        featureScores.questionScore = featureScores[cdqFeatureName] + featureScores.questionScore;
      });

      featureScores.questionScore = featureScores.questionScore / features.length;
      score = JSON.stringify(featureScores);
    }
    scorePreview = <p>Score Preview: {score}</p>;
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={`doc-cdq-wrapper ${props.uid}`}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    >
      {limitExplinationDiv}
      {selectedVsLimitView}
      <ul className={classes}>
        {answers}
      </ul>
      {scorePreview}
    </div>
  );
}

export default React.memo(DocCDQQuestion);
