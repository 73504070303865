import React from 'react'

import _ from 'lodash'
import stickyTable from 'components/StickyTable/component'

import GenLoadingSpinner from 'Gen/LoadingSpinner/component'

import {full_url} from 'helpers/url'

export default class GenSortTable extends React.Component {
  static defaultProps = {
    searchKeys: [],
    searchPlaceholder: "Search...",
    columnWidths: null,
    headerView: null,
    rowView: null,
    rowDataGenerator: null,
    dataURL: null,
    data: null,
    exportButtonURL: null,
  };

  constructor(props, context) {
    super(props, context);
    var defaults = {
      searchValue: "",
      sortValue: null,
      sortDirection: null,
      data: this.props.data,
    };

    if (this.props.data && this.props.rowDataGenerator) {
      defaults.rowData = this.props.rowDataGenerator(this.props.data)
    } else {
      defaults.rowData = this.props.data
    }

    this.state = defaults
  }

  componentDidMount() {
    if (!this.props.data) {
      this.getUpdatedList()
    } else {
      stickyTable()
    }
    if (this.props.defaultSort){
      this.setState({sortValue: this.props.defaultSort,
                     sortDirection: "ascending"})
    }
  }

  componentWillUnmount() {
  }

  handleSearchChange = (e) => {
    this.setState({searchValue: e.target.value});
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  updateSort = (newSortValue, newSortDirection) => {
    this.setState({ sortValue: newSortValue,
                    sortDirection: newSortDirection });
  };

  getPage = (successCallback) => {
    $.ajax({
      type: "GET",
      url: this.props.dataURL,
      dataType: 'json',
      data: {},
      contentType: 'application/json',
      success: successCallback.bind(this),
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  };

  getUpdatedList = () => {
    this.getPage(function(data) {
      let rowData = data
      if (this.props.rowDataGenerator) {
         rowData = this.props.rowDataGenerator(data)
      }

      this.setState({data: data, rowData: rowData}, () => {stickyTable()});
    });
  };

  render() {
    let data = this.props.data || this.state.data
    let searchWidth = "twocol"
    let exportWidth = "twocol txt-rt"

    let controlPanel = null
    if (this.props.searchKeys.length > 0 || this.props.exportButtonURL || this.props.infoText || this.props.extraButtons) {
      let title = null
      if (this.props.title) {
        title = <div className="header-title">
          <h3>{this.props.title}</h3>
        </div>
      }

      let infoText = null
      if (this.props.infoText) {
        infoText = <div key="info-text" className="seventywidth txt-lft" dangerouslySetInnerHTML={{__html:this.props.infoText}}/>
        searchWidth = "twentywidth"
        exportWidth = "tenwidth txt-rt"
      }

      let searchBox = null
      if (this.props.searchKeys.length > 0) {
        searchBox = <div key="search-box" className={searchWidth}>
          <form className="" role="search">
            <input placeholder={this.props.searchPlaceholder} type="search" className="searchform-short" value={this.state.searchValue} onChange={this.handleSearchChange} onKeyPress={this.handleSearchKeyPress}/>
          </form>
        </div>
      }

      let exportButton = null
      if (this.props.exportButtonURL) {
        exportButton = <div key="export-button" className={exportWidth}>
          <a href={this.props.exportButtonURL} className="report-button">Export</a>
        </div>
      }

      let extraButtons = null
      if (this.props.extraButtons) {
        extraButtons = <div key="extra-buttons" className="extra-buttons">
          {this.props.extraButtons}
        </div>
      }

      controlPanel = <div className="header_controls">
        {title}
        {searchBox}
        {infoText}
        {exportButton}
        {extraButtons}
        <div className="cleardiv"></div>
      </div>
    }

    let table
    if (data === null) {
      table = <GenLoadingSpinner/>;
    } else {
      let newDirection = this.state.sortDirection === 'descending' ? 'ascending' : 'descending'

      let columnGroup = null
      if (this.props.columnWidths) {
        let calculatedWidths = this.props.columnWidths(this.state)
        columnGroup = <colgroup key="colgroup">
          {calculatedWidths.map((width, i)=> {return <col key={width+'-'+i}style={{width: width + 'px'}}></col>})}
        </colgroup>
      }

      let header = this.props.headerView(this.state, this.updateSort.bind(this))
      let rowData = this.state.rowData

      if (this.props.searchKeys.length > 0 && this.state.searchValue && this.state.searchValue != "") {
        let lowerCaseSearchValue = this.state.searchValue.toLowerCase()
        let words = lowerCaseSearchValue.split(" ").filter((word) => word != "")

        rowData = rowData.filter((rowDatum) => {
          return words.reduce((acc, word) => {
            return acc && (this.props.searchKeys.map((searchKey) => {
              return rowDatum[searchKey].toLowerCase().indexOf(word) >= 0
            }).reduce((res, item) => {
              return res || item
            }))
          }, true)
        })
      }

      if (this.state.sortValue &&  this.state.sortDirection) {
        rowData = rowData.sort((a, b) => {
          let compare
          if (this.state.sortValue == "progress") {
            if (a["progress"]["percent_complete"] > b["progress"].percent_complete) {
              compare = 1
            }
            else if (a["progress"]["percent_complete"] < b["progress"].percent_complete){
              compare = -1
            }
            else {
              compare = 0
            }
          }
          else if (this.state.sortValue == "percentages") {
            if (a["percentages"].reduce((prev,next) => prev + next,0) > b["percentages"].reduce((prev,next) => prev + next,0)) {
              compare = 1
            }
            else if (a["percentages"].reduce((prev,next) => prev + next,0) < b["percentages"].reduce((prev,next) => prev + next,0)) {
              compare = -1
            }
            else {
              compare = 0
            }
          }
          else {
            if (typeof a[this.state.sortValue] !== typeof b[this.state.sortValue]) {
              if (a[this.state.sortValue] === null) {
                compare = 1
              } else if (b[this.state.sortValue] === null) {
                compare = -1
              } else {
                if (typeof a[this.state.sortValue] === typeof b[this.state.sortValue]) {
                  compare = 0
                } else if (typeof a[this.state.sortValue] < typeof b[this.state.sortValue]) {
                  compare = -1
                } else {
                  compare = 1
                }
              }
            } else {
              switch (typeof a[this.state.sortValue]) {
                case "string":
                  compare = a[this.state.sortValue].localeCompare(b[this.state.sortValue])
                  break;
                case "number":
                  compare = a[this.state.sortValue] - b[this.state.sortValue]
                default:
                if (a[this.state.sortValue] === b[this.state.sortValue]) {
                  compare = 0
                } else if (a[this.state.sortValue] < b[this.state.sortValue]) {
                  compare = -1
                } else {
                  compare = 1
                }
              }
            }
          }

          if (this.state.sortDirection === 'ascending') {
            compare = compare * -1
          }

          return compare
        })
      }


      let rows = rowData.map((rowDatum) => this.props.rowView(rowDatum))

      table = <div className="gen-sort-table data_table_wrapper">
        <div className="table_content sticky-table">
          <table>
            {columnGroup}
            <thead>
              {header}
            </thead>
            <tbody>
              {
                (rows.length < 1 && this.props.noRowsMessage) ?
                <tr>
                  <td colSpan="100%">{this.props.noRowsMessage}</td>
                </tr>
                :
                rows
              }
            </tbody>
          </table>
        </div>
      </div>
    }

    return(
      <div className="report-panel">
        {controlPanel}
        {table}
      </div>
    );
  }
}
