import * as React from "react";

import * as classNames from "classnames";

import GenModal from "../Gen/Modal/component";
import GenButton from "../Gen/Button/component";

import { OPENED_DOCUMENT_LOG } from "../../helpers/DocumentUserLog";
import {
  initializeMedia,
  getAssetAssociations,
  deleteAssetAssociation,
  mediaKeyHelper,
} from "../../helpers/media_manager";

import { trackChangesEditModes } from "../../helpers/Doc/trackChangesEditMode";

import DocEditBar from "./EditBar";
import DocSection from "./BlockNodes/Section";
import ReasoningTool from "./ReasoningTool";
import DocModals from "./Modals";
import DocContentMappingTool from "./ContentMappingTool";
import DocChangeSummaryColumn from "./ChangeSummaryColumn";
import DocEditBarMediaManager from "./EditBar/MediaManager";

import { displayModes, isDisplayMode } from "../../helpers/Doc/displayMode";

import DocROName from "./ROName";

import { MEDIA_MANAGER } from "../../helpers/Doc/menu";
import AssessmentQuestionForm from "./AssessmentQuestionForm";

import { generateLearningObjectiveActions } from "../../reducers/learningObjectives";

interface NewEditDocProps {
  documentContext: DocumentContext;
  currentVersion: CurrentVersion;
  userContext: UserContext;
  displayContext: DisplayContext;
  editingContext: EditingContext;
  editingContextExtra: EditingContextExtra;
  mappingContext: MappingContext;
  mediaContext: MediaContext;
  projectContext: ProjectContext;
  reasoningToolContext: ReasoningToolContext;
  integrationInfo: IntegrationInfo;
  mergedData: MergedData;
  changeData: ChangeData;
  displayUid?: string;
  changeSummaries: Array<OffsetChangeSummary>;
  userState: UserState;

  featureFlag: FeatureFlag;

  documentSetId?: number;
  isEditor: boolean;

  allLearningObjectiveOptions?: Array<{id: number, name: string, documents: Array<{id: number, title: string}>}>;
  learningObjectiveActionsGenerator: typeof generateLearningObjectiveActions;
};

export default function NewEditDoc(props: NewEditDocProps) {
  const {
    documentContext,
    currentVersion,
    userContext,
    displayContext,
    editingContext,
    editingContextExtra,
    mappingContext,
    mediaContext,
    projectContext,
    reasoningToolContext,
    integrationInfo,
    mergedData,
    changeData,
    displayUid,
    changeSummaries,
    userState,
    featureFlag,
    allLearningObjectiveOptions,
    learningObjectiveActionsGenerator,
  } = props;

  React.useEffect(() => {
    const {
      userContext,
      documentSetId,
      editingContext,
      editingContextExtra,
    } = props;

    if (editingContext.isEditing && userContext.permissions.allowedEditMode === "Suggesting") {
      editingContextExtra.setTrackChangesEditMode(trackChangesEditModes.SUGGESTING_MODE);
    }

    const openedDocumentLogArgs = documentSetId ? {document_set_id: documentSetId} : {};
    userContext.logEvent(OPENED_DOCUMENT_LOG, openedDocumentLogArgs);
  }, []);

  const handleTurbolinksPageChange = React.useCallback(event => {
      if (editingContext.isEditing && editingContextExtra.isActiveEditData) {
        if (!confirm("You have unsaved changes! Are you sure you want to leave this page? Unsaved changes will be lost.")) {
          event.preventDefault();
        }
      }
  }, [editingContext.isEditing, editingContextExtra.isActiveEditData]);

  React.useEffect(() => {
      window.addEventListener("turbo:before-visit", handleTurbolinksPageChange);
      return () => {
          window.removeEventListener("turbo:before-visit", handleTurbolinksPageChange);
      };
  }, [handleTurbolinksPageChange]);

  ////////////
  // MEDIA //
  //////////

  const [mediaState, setMediaState] = React.useState(mediaContext);

  React.useEffect(() => {
    initializeMedia({
      isEmbeded: displayContext.isEmbeded,
      modelId: documentContext.id as any, // TODO: Remove any
      modelType: "Document",
      responseHandler: setMediaState,
    });
  }, []);

  const handleMediaCreate = (data) => {
    const mediaKey = mediaKeyHelper(data);
    setMediaState((prev) => ({
      ...prev,
      [mediaKey]: [...prev[mediaKey], data],
    }));
  }

  const handleMediaDelete = (data) => {
    const mediaKey = mediaKeyHelper(data);
    setMediaState((prev) => ({
      ...prev,
      [mediaKey]: prev[mediaKey].filter((item) => item.external_identifier !== data.external_identifier),
    }));
  }

  const handleMediaReplace = (data) => {
    if (data.new) {
      handleMediaCreate(data.new);
    }
    if (data.old) {
      handleMediaDelete(data.old);
    }
  }

  const offlineModal = (
    <GenModal open={!displayContext.isOnline && !displayContext.isEmbeded}>
      <div className="gen-modal-panel">
        {
          mergedData.timeLimit
          ? <p>You have lost your internet connectivity. In order to save your work on the exam to this point, please see your proctor. Ask your proctor to pause your exam. Once an internet connection is restored, your proctor can re-start your exam and you can continue your work.</p>
          : <p>You have lost connection to the system, but your activity prior to disconnect has been saved. When connectivity is restored you can resume where you left off.</p>
        }
      </div>
    </GenModal>
  );

  let mediaModal = null;
  if (editingContext.isEditing && editingContextExtra.currentMenu?.menu === MEDIA_MANAGER) {
    const mediaType = editingContextExtra.currentMenu?.mediaType
    mediaModal = (
      <DocEditBarMediaManager
        key={`${mediaType}Menu`}
        documentContext={documentContext}
        currentVersion={currentVersion}
        userContext={userContext}
        editingContext={editingContext}
        editingContextExtra={editingContextExtra}
        mediaContext={mediaState}
        createHandler={handleMediaCreate}
        deleteHandler={handleMediaDelete}
        replaceHandler={handleMediaReplace}
      />
    );
  }

  const outsideClasses = classNames(
    "doc-outside-wrapper",
  );

  const insideClasses = classNames(
    "doc-body",
    `${displayContext.displayMode}-display-mode`,
    {
      "edit-bar-bump": editingContext.shouldShowEditor,
      "is-editing": editingContext.isEditing,
    },
  );

  const showTopBar = editingContext.shouldShowEditor && !displayUid && !displayContext.isEmbeded;

  let docControlsWrapper = null;
  if (props.isEditor && !editingContext.shouldShowEditor) {
    let docControls = [
      <select className="doc-controls-select doc-controls-view-mode"
      key="doc-controls-select"
      value={displayContext.displayMode}
      onChange={(e) => {isDisplayMode(e.target.value) && displayContext.setDisplayMode(e.target.value);}}>
        <option value={displayModes.STUDENT}>Student</option>
        <option value={displayModes.UNGATED}>Ungated</option>
        <option value={displayModes.FULL}>Full</option>
        <option value={displayModes.OUTLINE}>Outline</option>
      </select>
    ];

    docControlsWrapper =
      <div className="doc-controls">
        {docControls}
      </div>;
  }

  const showReasoningTool = !displayUid && !displayContext.isEmbeded;
  let showControlsInReadOnly = userContext.permissions.viewTopBar
  let renderTopBar = showTopBar || showControlsInReadOnly
  let docTopWrapperSideNavChanges = featureFlag?.new_landing_pages ? "doc-top-wrapper-side-nav" : ""
  let docBodyWrapperSideNavChanges = featureFlag?.new_landing_pages ? "doc-body-wrapper-side-nav" : ""
  return (
    <div className={outsideClasses}>
      <div className="doc-inside-wrapper">
        {renderTopBar &&
          <div className={`doc-top-wrapper ${docTopWrapperSideNavChanges}`}>
            {showTopBar && <DocContentMappingTool
              documentContext={documentContext}
              editingContext={editingContext}
              editingContextExtra={editingContextExtra}
              displayContext={displayContext}
              mappingContext={mappingContext}
              userContext={userContext}
              featureFlag={featureFlag}
              {...mergedData}
            />}
            <DocEditBar
              key="edit-bar"
              documentContext={documentContext}
              currentVersion={currentVersion}
              userContext={userContext}
              displayContext={displayContext}
              editingContext={editingContext}
              editingContextExtra={editingContextExtra}
              mediaContext={mediaState}
              projectContext={projectContext}
              mergedData={mergedData}
              mappingContext={mappingContext}

              featureFlag={featureFlag}
            />
          </div>
        }
        <div
          id="doc-body-wrapper"
          className={`doc-body-wrapper ${docBodyWrapperSideNavChanges}`}
          style={{position: "relative"}}
        >
          <div
            className={insideClasses}
            style={{paddingRight: editingContext.isEditing ? "320px" : 0 }}
          >
            {offlineModal}
            {mediaModal}
            {docControlsWrapper}
            <DocROName
              documentContext={documentContext}
              editingContext={editingContext}
              editingContextExtra={editingContextExtra}

              featureFlag={featureFlag}
            />
            <DocSection
              {...mergedData}
              documentContext={documentContext}
              currentVersion={currentVersion}
              userContext={userContext}
              displayContext={displayContext}
              editingContext={editingContext}
              mediaContext={mediaState}

              featureFlag={featureFlag}

              level={0}
              integrationInfo={integrationInfo}
            />
          </div>

          { editingContextExtra.assessmentQuestion && <AssessmentQuestionForm editingContextExtra={editingContextExtra} />}

          <DocChangeSummaryColumn
            editingContext={editingContext}
            userContext={userContext}
            displayContext={displayContext}
            changeSummaries={changeSummaries}
            currentVersion={currentVersion}

            featureFlag={featureFlag}
          />
        </div>
      </div>
      {showReasoningTool
        && <ReasoningTool
          key="reasoning-tool"
          documentContext={documentContext}
          currentVersion={currentVersion}
          userContext={userContext}
          displayContext={displayContext}
          editingContext={editingContext}
          editingContextExtra={editingContextExtra}
          mediaContext={mediaState}
          projectContext={projectContext}
          reasoningToolContext={reasoningToolContext}
          mappingContext={mappingContext}
          mergedData={mergedData}
          changeData={changeData}
          userState={userState}

          featureFlag={featureFlag}
        />
      }
      <DocModals
        documentContext={documentContext}
        currentVersion={currentVersion}
        userContext={userContext}
        displayContext={displayContext}
        editingContext={editingContext}
        editingContextExtra={editingContextExtra}
        mediaContext={mediaState}
        projectContext={projectContext}
        mergedData={mergedData}
        mappingContext={mappingContext}
        allLearningObjectiveOptions={allLearningObjectiveOptions}
        learningObjectiveActionsGenerator={learningObjectiveActionsGenerator}

        featureFlag={featureFlag}
      />
    </div>
  );
}
