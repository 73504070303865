const already_url = /^https?:\/\//

export function full_url(path, options={}){
  if (!path) throw "no path specified"

  if (path.match(already_url)) {
    return path
  }

  let hostname = window.location.hostname

  if (options.subdomain) {
    let splitHostname = hostname.split(".")
    if (splitHostname.length >= 3) {
      splitHostname[0] = options.subdomain
      hostname = splitHostname.join('.')
    }
  }

  // CAN NOT USE window.location.origin BECAUSE OF INTERNET EXPLORER
  return window.location.protocol + "//" + hostname + (window.location.port ? ':' + window.location.port: '') + path
}

export function getUrlParameter(sParam){
    var sPageURL = window.location.search.substring(1)
    var sURLVariables = sPageURL.split('&')
    for (var i = 0; i < sURLVariables.length; i++)
    {
        var sParameterName = sURLVariables[i].split('=')
        if (sParameterName[0] === sParam)
        {
            return sParameterName[1]
        }
    }
}
