import * as React from "react";

import { compact, flatten } from "lodash";

import DocAddNode from "./BlockNodeHelpers/AddNode";

import DocAudio from "./BlockNodes/Audio";
import DocButton from "./BlockNodes/Button";
import DocCategoryMatcher from "./BlockNodes/CategoryMatcher";
import DocCDQQuestion from "./BlockNodes/CDQQuestion";
import DocCheckbox from "./BlockNodes/Checkbox";
import DocHorizontalRule from "./BlockNodes/HorizontalRule";
import DocImage from "./BlockNodes/Image";
import DocImageGallery from "./BlockNodes/ImageGallery";
import DocLearningObjective from "./BlockNodes/LearningObjective"
import DocLearningObjectivesList from "./BlockNodes/LearningObjectivesList";
import DocList from "./BlockNodes/List";
import DocMultipleChoice from "./BlockNodes/MultipleChoice";
import DocParagraph from "./BlockNodes/Paragraph";
import DocRatingBar from "./BlockNodes/RatingBar";
import DocSection from "./BlockNodes/Section";
import DocTable from "./BlockNodes/Table";
import DocTextInput from "./BlockNodes/TextInput";
import DocVariantPlaceholder from "./BlockNodes/VariantPlaceholder";
import DocVideo from "./BlockNodes/Video";

export type DocBlockNodesProps = (
    DocSectionType<InlineNode> |
    DocTableCellType<InlineNode> |
    DocListItemNorrowedType<InlineNode> |
    DocLearningObjectivesListType<InlineNode>
  ) & {
  blockNodesContainsSelection?: boolean;
  cdqFeatures?: Array<CdqFeature>;
  content: Array<DocBlockNodeSection>;
  contentKey: string;
  currentMenu?: DocMenu;
  currentVersion: CurrentVersion;
  disableStyleTextEditing?: boolean;
  displayContext: DisplayContext;
  documentContext: DocumentContext;
  editingContext: EditingContext;
  featureFlag: FeatureFlag;
  inactiveBool?: boolean;
  independentlyEditable?: boolean;
  integrationInfo?: IntegrationInfo;
  level?: number;
  localClipboard?: LocalClipboardData;
  mediaContext: MediaContext;
  openSiblingSectionUIDs?: Array<string>;
  shouldHaveAddNodes?: boolean;
  simple?: boolean;
  userContext: UserContext;
};

export default function DocBlockNodes(props: DocBlockNodesProps) {
  const {
    content,
    inactiveBool,
    documentContext,
    currentVersion,
    editingContext,
    userContext,
    displayContext,
    mediaContext,
    featureFlag,
    currentMenu,
    localClipboard,
    simple,
    cdqFeatures,
    level,
    openSiblingSectionUIDs,
    shouldHaveAddNodes,
    contentKey,
    disableStyleTextEditing,
    independentlyEditable,
    blockNodesContainsSelection,
    integrationInfo,
  } = props;

  const nodeLookup = {
    audio: DocAudio,
    button: DocButton,
    categoryMatcher: DocCategoryMatcher,
    cdqQuestion: DocCDQQuestion,
    checkbox: DocCheckbox,
    horizontalRule: DocHorizontalRule,
    image: DocImage,
    imageGallery: DocImageGallery,
    learningObjective: DocLearningObjective,
    learningObjectivesList: DocLearningObjectivesList,
    multipleChoice: DocMultipleChoice,
    numberedList: DocList,
    paragraph: DocParagraph,
    ratingBar: DocRatingBar,
    regularList: DocList,
    section: DocSection,
    table: DocTable,
    textInput: DocTextInput,
    variantPlaceholder: DocVariantPlaceholder,
    video: DocVideo,
  } as const;

  let lastNodeType;

  function checkMultipleSelect(data) {
    const multipleSelectElement = data.find(item =>
      ["multipleChoice", "cdqQuestion", "textInput", "ratingBar", "categoryMatcher"].includes(item.type)
    );

    if (!multipleSelectElement) return false;

    switch (multipleSelectElement.type) {
      case "multipleChoice":
      case "cdqQuestion":
        return Array.isArray(multipleSelectElement.content) &&
               multipleSelectElement.content.some(option => option.value === true);

      case "textInput":
      case "categoryMatcher":
        return multipleSelectElement.value !== "";

      case "ratingBar":
        return multipleSelectElement.value > 0;

      default:
        return false;
    }
  }

  let contentNodes = content.filter((node) => node).map((node, index) => {
    const newInactiveBool = node.hasOwnProperty("inactive") && (node.inactive !== 0) ? node.inactive === 2 : inactiveBool;

    const trueOrEmptyOptions = blockNodesContainsSelection || disableStyleTextEditing ?
      {disableStyleTextEditing: true} : {}

    let onlyIfSectionOptions;
    if (node.type === "section") {
      onlyIfSectionOptions = {
        level: level + 1,
        openSiblingSectionUIDs: openSiblingSectionUIDs,
        cdqFeatures: node.cdqFeatures || cdqFeatures,
        integrationInfo: integrationInfo,
      };
    }

    let addNode: React.JSX.Element;
    if (shouldHaveAddNodes) {
      addNode = (
        <DocAddNode
          key={`${node.uid}--${index + 1}`}
          editIndex={node.editIndex}
          siblingUid={node.uid}
          documentContext={documentContext}
          editingContext={editingContext}
          userContext={userContext}
          currentMenu={currentMenu}
          localClipboard={localClipboard}
          parentNode={props}
          parentContentKey={contentKey}
          shouldHaveSpace={
            !["paragraph", "regularList", "numberedList"].includes(node.type) &&
            !["paragraph", "regularList", "numberedList"].includes(lastNodeType)
          }
        />
      );
    }

    lastNodeType = node.type;

    const NodeTag = nodeLookup[node.type];
    if (NodeTag) {
      return ([
        addNode,
        <NodeTag
          {...node}
          key={node.uid}
          documentContext={documentContext}
          currentVersion={currentVersion}
          editingContext={editingContext}
          userContext={userContext}
          displayContext={displayContext}
          mediaContext={mediaContext}
          featureFlag={featureFlag}
          simple={simple}
          inactiveBool={(node.type=='button' && node.title=='Submit')? !checkMultipleSelect(content) : newInactiveBool}
          cdqFeatures={cdqFeatures}
          {...trueOrEmptyOptions}
          {...onlyIfSectionOptions}
        />,
      ]);
    } else {
      // Rollbar.warning(`DocBlockNodes does not recognize node of type ${node.type}`);
      return [];
    }
  });

  if (shouldHaveAddNodes) {
    contentNodes.push([
      <DocAddNode
        key={"--0"}
        documentContext={documentContext}
        editingContext={editingContext}
        userContext={userContext}
        currentMenu={currentMenu}
        localClipboard={localClipboard}
        parentNode={props}
        parentContentKey={contentKey}
        shouldHaveSpace={
          !["paragraph", "regularList", "numberedList"].includes(lastNodeType)
        }
      />
    ]);
  }


  if (editingContext.isEditing) {
    let editableData = {}
    if (independentlyEditable) {
      editableData = {
        "data-is-independently-editable": true,
        style: {
          "userSelect": "auto",
          "WebkitUserSelect": "auto",
        },
      };

      if (!disableStyleTextEditing) {
        editableData = {
          ...editableData,
          contentEditable: true
        }
      }
    }

    return (
      <div className="doc-children"
        data-is-block-nodes
        {...editableData}
        suppressContentEditableWarning
        autoCorrect={"off"}
        autoCapitalize={"off"}
        spellCheck={false}
        onDrop={(e) => {e.stopPropagation(); e.preventDefault();}}
      >
        {compact(flatten(contentNodes))}
      </div>
    );
  } else {
    return (
      <div className="doc-children">
        {compact(flatten(contentNodes))}
      </div>
    );
  }
}
