import BaseObserver from './baseObserver'

interface BeforeInputObserverDelegate {
  beforeInputDidFire: (mode: InputEvent) => void;
}

export default class BeforeInputObserver extends BaseObserver<BeforeInputObserverDelegate> {
  startEventListeners() {
    document.addEventListener("beforeinput", this.notifyOnBeforeInput.bind(this), true)
  }

  stopEventListeners() {
    document.removeEventListener("beforeinput", this.notifyOnBeforeInput.bind(this), true)
  }

  notifyOnBeforeInput(event: InputEvent) {
    this.delegates.forEach(delegate => delegate.beforeInputDidFire(event));
  }
}
