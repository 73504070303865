import generateUUID from "../../generateUUID";

import * as R from 'ramda';

import * as DocConvertPlainText from "./PlainText";
import * as DocConvertRangeInlineJSON from "./RangeInlineJSON";

import {docMapBlockNodes, nodeInlineContentKeys} from "../Functions/base";

import {isRangeInlineJSON} from "../Functions/RangeInlineJSON";
import {isTreeInlineJSON} from "../Functions/TreeInlineJSON";

const TAG_PAIRS = [
  ["italic", "em"],
  ["bold", "strong"],
  ["underline", "u"],
  ["superscript", "sup"],
  ["subscript", "sub"],
  ["span", "span"],
  ["deleted_text", "del"],
];

export function fromInlineJson(styledTreeNodes: Array<InlineTreeNodeExpanded>, includeEditStyles: boolean = true): string{
  return styledTreeNodes.map((node) => {
    switch (node.type) {
      case "link":
        return `<a href="${node.url}" target="_blank">${fromInlineJson(node.title, includeEditStyles)}</a>`;
      case "color":
        return `<span style="color:${node.hexCode};">${fromInlineJson(node.content, includeEditStyles)}</span>`;
      case "text":
        return addHTMLAmprersandCharacters(node.content);
      case "SELECTION_MIMIC":
        return includeEditStyles ? `<span class="added_text" style="background-color:rgba(${node.color}, 0.25);">${fromInlineJson(node.content)}</span>` : fromInlineJson(node.content, includeEditStyles);
      case "ADD_COMMENT_THREAD":
        return includeEditStyles ? `<span class="added_text" style="background-color:rgba(${node.color}, 0.25);">${fromInlineJson(node.content)}</span>` : fromInlineJson(node.content, includeEditStyles);
      case "ADD_CONTENT":
      case "ADDED_TEXT":
      case "CHANGED_TEXT":
        return includeEditStyles ? `<span class="added_text" style="text-decoration:underline; text-decoration-color:rgb(${node.color});">${fromInlineJson(node.content)}</span>` : fromInlineJson(node.content, includeEditStyles);
      case "DELETE_CONTENT":
      case "DELETED_TEXT":
        return includeEditStyles ? `<del style="text-decoration-color:rgb(${node.color});" contenteditable="false">${fromInlineJson(node.content)}</del>` : "";
      case "ADDED_STYLE":
      case "DELETED_STYLE":
        return includeEditStyles ? `<span class="added_style" style="color:rgb(${node.color});">${fromInlineJson(node.content)}</span>` : fromInlineJson(node.content, includeEditStyles);
      case "HIDE_TEXT":
        return includeEditStyles ? `<span class="hide_text" style="display:none;">${DocConvertPlainText.fromInlineJson(node.content, true)}</span>` : "";
      default:
        const tagData = TAG_PAIRS.find((tagData) => {
          return tagData[0] === node.type;
        });

        if (tagData) {
          const [type, htmlStyle] = tagData;
          return `<${htmlStyle}>${fromInlineJson(node.content, includeEditStyles)}</${htmlStyle}>`;
        } else {
          return "";
        }
    }
  }).join("");
}

const NODE_NAME_MAP = {
  "EM": "italic" as const,
  "STRONG": "bold" as const,
  "U": "underline" as const,
  "SUP": "superscript" as const,
  "SUB": "subscript" as const,
}

function nodeNameToStyleName(nodeName: string) {
  switch (nodeName) {
    case "EM":
      return "italic" as const
    case "STRONG":
      return "bold" as const
    case "U":
      return "underline" as const
    case "SUP":
      return "superscript" as const
    case "SUB":
      return "subscript" as const
  }

}

export function toInlineJson(htmlString: InlineHtmlNode) {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');

  return toInlineJsonFromNodeList(htmlDoc.body.childNodes)
}

function toInlineJsonFromNodeList(nodeList: NodeListOf<Node>) : Array<InlineTreeNode> {
  const inlineJson = [...nodeList].flatMap((node)  => {
    switch (node.nodeType) {
      case Node.TEXT_NODE:
        return ({
          type: "text" as const,
          uid: generateUUID(),
          content: node.textContent || "",
        });
        break;
      case Node.ELEMENT_NODE:
        // TODO: REFACTOR FuLL SWITCH TO IF STATEMENT WHEN TYPE
        if (!isElement(node)) {return null;}

        const mappedType = nodeNameToStyleName(node.nodeName)
        if (mappedType) {
          return ({
            type: mappedType,
            uid: generateUUID(),
            content: toInlineJsonFromNodeList(node.childNodes),
          });
        }

        if (node.nodeName === "A") {
          return ({
            type: "link" as const,
            uid: generateUUID(),
            title: toInlineJsonFromNodeList(node.childNodes),
            url: node.getAttribute("href") || "", // TODO: HANDLE MISSING CASE BETTER
          })
        }

        if (node.nodeName === "SPAN" && node instanceof HTMLElement && rgb2hex(node.style.color) && node.className === "") {
          return ({
            type: "color" as const,
            uid: generateUUID(),
            content: toInlineJsonFromNodeList(node.childNodes),
            hexCode: rgb2hex(node.style.color) || "", // TODO: HANDLE MISSING CASE BETTER
          })
        }

        return toInlineJsonFromNodeList(node.childNodes);
      default:
        return null;
    }
  }).filter(notEmpty)

  return inlineJson;
}

function rgb2hex(rgb: string) {
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

  if (match) {
    return `#${match.slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`;
  } else {
    return null;
  }
}

export function toPlainText(htmlString: InlineHtmlNode) {
  return DocConvertPlainText.fromInlineJson(toInlineJson(htmlString));
}

export function toRangeInlineJson(htmlString: InlineHtmlNode) {
  return DocConvertRangeInlineJSON.fromInlineJson(toInlineJson(htmlString));
}

export function fromAnyType(inlineNode: InlineNode) {
  if (isRangeInlineJSON(inlineNode)) {
    return DocConvertRangeInlineJSON.toHTML(inlineNode);
  } else if (isTreeInlineJSON(inlineNode)) {
    return fromInlineJson(inlineNode);
  } else if (typeof inlineNode === "string") {
    return inlineNode
  }
}

function removeHTMLAmprersandCharacters(string: InlineHtmlNode) {
  return string && string
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&quot;/g, "\"")
    .replace(/&apos;/g, "'")
    .replace(/&#39;/g, "'");
}

function addHTMLAmprersandCharacters(string: string) {
  return string && string
    .replace(/&/g, "&amp;")
    .replace(/>/g, "&gt;")
    .replace(/</g, "&lt;");
}

export function convertAllInlineNodesToHTML(node: DocBlockNode<InlineRangeNode>, includeEditStyles = true) {
  return docMapBlockNodes(node, (blockNode) => {
    const inlineContentKeys = nodeInlineContentKeys(blockNode);

    inlineContentKeys.forEach((contentKey) => {
      let newInlineNode = blockNode[contentKey];

      if (isRangeInlineJSON(newInlineNode)) {
        newInlineNode = DocConvertRangeInlineJSON.toInlineJson(newInlineNode);
      }

      if (newInlineNode && isTreeInlineJSON(newInlineNode)) {
        // EXCEPTION UNTILL ALL LISTS ARE NODE TYPES
        if (blockNode.type !== "listItem" || isTreeInlineJSON(newInlineNode)) {
          blockNode[contentKey] = fromInlineJson(newInlineNode, false);
        } else {
          blockNode[contentKey] = blockNode[contentKey].map((listItemChildNode) => convertAllInlineNodesToHTML(listItemChildNode, includeEditStyles));
        }
      }
    });

    return blockNode;
  });
}

export function standardizeSpaces(string: string) {
  return string && string.replace(/&nbsp;|\u202F|\u00A0/g, " ");
}

export function isElement(node: Node): node is Element { return node.nodeType === 1; }

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
