import * as React from "react";

import DocAuthorHeader from "../AuthorHeader";

import { newDeleteStudentFeedbackChange } from "../../../helpers/Doc/changes";

interface ReasoningToolStudentFeedbackProps {
  userContext: UserContext;
  editingContext: EditingContextInEditor;
  studentFeedback: StudentFeedback;
};

export default function ReasoningToolStudentFeedback(props: ReasoningToolStudentFeedbackProps) {
  const {
    userContext,
    editingContext,
    studentFeedback,
  } = props;

  return (
    <div className="reasoning-tool-summary-of-edit">
      <DocAuthorHeader
        authorName={studentFeedback.author_full_name}
        colorArray={editingContext.colorLookup[studentFeedback.author_id]}
        createdAt={studentFeedback.created_at}
      >
        {(studentFeedback.author_id === userContext.id || editingContext.shouldShowEditor) && <i style={{marginRight: "5px"}} className="fa fa-trash" onClick={(e) => {
          e.stopPropagation();
          editingContext.addChange(newDeleteStudentFeedbackChange(studentFeedback.student_feedback_id));
        }}></i>}
      </DocAuthorHeader>
      <p>{studentFeedback.text}</p>
    </div>
  );
}
