import {
  last,
  tail,
} from "lodash";

export function scrollToLineage(lineage: Array<any>, addStateCallback: AddStateFunction) {
  const ancestorSections = lineage.filter((ancestor) => ancestor.type === "section");
  const ancestorSectionsUids = ancestorSections.map((ancestor) => ancestor.uid);

  const openSectionsState = tail(ancestorSectionsUids).reduce((acc, ancestorUid) => ({
    ...acc,
    [ancestorUid]: {collapsed: false},
  }), {})

  addStateCallback(openSectionsState);

  setTimeout(() => {
    const el = document.getElementById(`bookmark${last(lineage).uid}`) || document.getElementsByClassName(last(lineage).uid)[0];
    if (el) {
      window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top - 240);
    }
  }, 300);
}

export function scrollToUidAndContentKey(uid: string, contentKey: string) {
  setTimeout(() => {
    const el = document.getElementById(`${uid}//${contentKey}`);
    if (el) {
      window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top - 240);
    }
  }, 300);
}

export function scrollToUid(uid: string) {
  setTimeout(() => {
    const el = document.querySelector(`[data-uid="${uid}"]`);
    if (el) {
      window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top - 240);
    }
  }, 300);
}

export function scrollToElementWithUid(uid_candidates: Array<string>) {
  const matching_elements = uid_candidates
    .map((uid) => document.getElementsByClassName(uid)[0])
    .filter((x) => !!x);
  const el = matching_elements[0];

  if (el) {
    window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top - 300);

    // We can't only click on the given element.
    // We must also click on the nested elements, and experimentation shows that we only want to
    // click on nested elements that have text content.
    const recursiveClick = (elem: Element) => {
      if (elem instanceof HTMLElement && !!elem.textContent) {
        elem.click();
      }
      [...elem.children].forEach((child) => {
        recursiveClick(child);
      });
    };
    recursiveClick(el);
  }
  return el;
}
