import React from 'react';

import GenButton from 'Gen/Button/component'

export default function GenToggleBar(props) {
  let toggleButtons = props.buttons.map((button) => {
    return(
      <GenButton
        {...button}
        disabled={props.disabled || button.disabled}
      />
    )
  })

  return(
    <div className="gen-toggle-bar">
      {toggleButtons}
    </div>
  )

}

GenToggleBar.defaultProps = {
  disabled: false
}
