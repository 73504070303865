import * as React from "react";

import * as classNames from "classnames";

import DocTableCell from "../Table/Cell";

import {
  newAddTableRowChange,
  newDeleteTableRowChange,
  backgroundStyleFromNode
} from "../../../../helpers/Doc/changes";

interface DocTableRowProps extends DocTableRowType<InlineNode>, BlockNodeAll {
  firstRow: boolean;
  index: number;
  parentTableUID: string;
}

function DocTableRow(props: DocTableRowProps) {
  const {
    editingContext,
  } = props;

  const cells = props.cells.map((cell, index) => {
    return (
      <DocTableCell
        key={cell.uid}
        {...cell}
        documentContext={props.documentContext}
        currentVersion={props.currentVersion}
        editingContext={editingContext}
        userContext={props.userContext}
        displayContext={props.displayContext}
        parentTableUID={props.parentTableUID}
        featureFlag={props.featureFlag}
        mediaContext={props.mediaContext}
        firstRow={props.firstRow}
        firstColumn={index === 0}
        disableStyleTextEditing={props.disableStyleTextEditing}
      />
    );
  });

  if (editingContext.isEditing) {
    cells.unshift(
      <td className="doc-table-edit-row-cell" key="doc-table-edit-row-cell">
        <div
          key="doc-table-cell-add-row-after"
          className="doc-table-cell-add-row-after no-document-selection"
          onClick={() => {
            editingContext.clearCurrentDocSelection();
            editingContext.addChange(newAddTableRowChange(props.parentTableUID, props.index + 1));
          }}
        ><i className="fa fa-plus"></i></div>
        <div
          key="doc-table-cell-remove-row"
          className="doc-table-cell-remove-row no-document-selection"
          onClick={() => {
            editingContext.clearCurrentDocSelection();
            editingContext.addChange(newDeleteTableRowChange(props.parentTableUID, props.index));
          }}
        ><i className="fa fa-times"></i></div>
      </td>
    );
  }

  return (
    <tr
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-table-row", props.uid)}
      style={backgroundStyleFromNode(props)}
    >
      {cells}
    </tr>
  );
}

export default React.memo(DocTableRow);
