import * as R from 'ramda'

import {
  assignWith,
  isFunction,
} from 'lodash'

import { Dispatch } from "redux";

import { generateReducer, generateActions } from './itemReducer'

import { mergeStates } from '../helpers/Doc/documentUserState'

const itemKey = 'documentUserState'
const endpoint = 'api/v2/document_user_states/'

export function generateDocumentUserStateActions(id: ItemId) {
  const documentUserStateActions = generateActions<DocumentUserState, DocumentUserStateExtraData>(itemKey, id, endpoint, {}, {editDataMergeFunction: editDataMergeFunction})

  return {
    startNewDocumentUserState: documentUserStateActions.startNewItem,
    cancelNewDocumentUserState: documentUserStateActions.cancelNewItem,
    startEditDocumentUserState: documentUserStateActions.startEditItem,
    cancelEditDocumentUserState: documentUserStateActions.cancelEditItem,
    setAllDataWithFunctionDocumentUserState: documentUserStateActions.setAllDataWithFunctionItem,
    setSyncDataDocumentUserState: documentUserStateActions.setSyncDataItem,
    setSyncDataWithFunctionDocumentUserState: documentUserStateActions.setSyncDataWithFunctionItem,
    setEditDataDocumentUserState: documentUserStateActions.setEditDataItem,
    setEditDataWithFunctionDocumentUserState: documentUserStateActions.setEditDataWithFunctionItem,
    resetEditDataDocumentUserState: documentUserStateActions.resetEditDataItem,
    setExtraDataDocumentUserState: documentUserStateActions.setExtraDataItem,
    setExtraDataWithFunctionDocumentUserState: documentUserStateActions.setExtraDataWithFunctionItem,
    resetExtraDataDocumentUserState: documentUserStateActions.resetExtraDataItem,
    loadDocumentUserState: documentUserStateActions.loadItem,
    createDocumentUserState: documentUserStateActions.createItem,
    updateDocumentUserState: documentUserStateActions.updateItem,
    destroyDocumentUserState: documentUserStateActions.destroyItem,
    loadIfNeededDocumentUserState: documentUserStateActions.loadIfNeededItem,
    documentUserStateFromState: documentUserStateActions.itemFromState,
    documentUserStateSyncDataFromState: documentUserStateActions.itemSyncDataFromState,
    documentUserStateSyncMetaFromState: documentUserStateActions.itemSyncMetaFromState,
    documentUserStateEditDataFromState: documentUserStateActions.itemEditDataFromState,
    documentUserStateMergedDataFromState: documentUserStateActions.itemMergedDataFromState,
    documentUserStateExtraDataFromState: documentUserStateActions.itemExtraDataFromState,
    addStateDocumentUserState: (state: UserStateStripped, callback?: () => void) => addStateDocumentUserState(id, state, callback)
  }
}

export const documentUserStateCollectionReducer = generateReducer<DocumentUserState, DocumentUserStateExtraData>(itemKey);

function editDataMergeFunction(dusOld: DocumentUserState, dusNew: Partial<DocumentUserState>) {
  return assignWith({}, dusOld, dusNew, (valueDataOld, valueDataNew, key) => {
    if (key === 'state') {
      return mergeStates(valueDataOld, valueDataNew)
    } else {
      return valueDataNew || valueDataOld
    }
  })
}

export function addStateDocumentUserState(id: ItemId, state: UserStateStripped, callback?: () => void) {
  return (dispatch: Dispatch, getState: () => ReducerState) => {
    const documentUserStateActions = generateDocumentUserStateActions(id)

    let mergedDocumentUserState = documentUserStateActions.documentUserStateMergedDataFromState(getState())
    let editDocumentUserState = documentUserStateActions.documentUserStateEditDataFromState(getState()) || {}

    let mergedState = mergedDocumentUserState.state || {}
    let editState = editDocumentUserState.state || {}

    let newState = R.mapObjIndexed((nodeData, uid, obj1) => {
      return R.mapObjIndexed((value, key, obj2) => {
        let newValue = isFunction(value) ? value(R.path([uid, key, 'value'], mergedState)) : value
        return {value: newValue, updatedAt: (new Date).getTime()}
      }, nodeData)
    }, state)

    dispatch(documentUserStateActions.setEditDataDocumentUserState({state: mergeStates(editState, newState)}))
    dispatch(documentUserStateActions.updateDocumentUserState() as any)

    if (callback) {
      callback()
    }
  }
}
