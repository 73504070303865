import * as React from "react";
import * as classNames from 'classnames'
import GenLoadingSpinner from '../LoadingSpinner/component'

interface OnClickWrapperProps {
  confirmMessage?: string;
  confirmOKText?: string;
  confirmTitle?: string;
  disabled?: boolean;
  href?: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const onClickWrapper = (props: OnClickWrapperProps) => {
  const {
    confirmTitle,
    confirmMessage,
    confirmOKText,
    disabled,
    onClick,
    href,
  } = props;

  return (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (disabled) {return null}

    const action = onClick ? () => onClick(e) : () => { if(href) { window.location.href = href; }}

    if (confirmTitle || confirmMessage) {
      window.customConfirmBox({
        message: confirmMessage,
        callback: action,
        title: confirmTitle,
        okLabel: confirmOKText,
      })
    } else {
      action()
    }
  }
}

interface GenButtonProps {
  ariaLabel?: string;
  className?: string;
  confirmMessage?: string;
  confirmOKText?: string;
  confirmTitle?: string;
  disabled?: boolean;
  highlighted?: boolean;
  href?: string;
  loading?: boolean;
  loadingName?: string;
  name: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  small?: boolean;
}

const GenButton = (props: GenButtonProps) => {
  const {
    className = "",
    highlighted = false,
    disabled = false,
    small = false,
    loading = false,
    loadingName,
    name,
    href,
    onClick,
    confirmOKText,
    confirmTitle,
    confirmMessage,
    ariaLabel,
  } = props;

  return (
    <div className={classNames('gen-button', className, { disabled, highlighted, small})} >
      { loading ? (
        <span className="button-name">
          <GenLoadingSpinner/>
          {loadingName}
        </span>
      ) : (
        <a
          aria-label={ariaLabel}
          href="#"
          onClick={onClickWrapper({ href, onClick, disabled, confirmTitle, confirmMessage, confirmOKText })}
          className="button-name"
        >
          {name}
        </a>
      )}
    </div>
  )
}

export default GenButton
