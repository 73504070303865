import * as React from "react";

const randRGBLvl = () => {
  return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
}

interface ActiveEditorProps {
  userContext: UserContext;
  editor: PressenceEditor;
  hasLock: boolean;
  hasLockInSession: boolean;
  lockIfFree: () => void;
  unlockIfOwned: () => void;
  forceLock: () => void;
};

export default function ActiveEditor(props: ActiveEditorProps) {
  const {
    userContext,
    editor,
    hasLock,
    hasLockInSession,
    lockIfFree,
    unlockIfOwned,
    forceLock,
  } = props;

  const [color, setColor] = React.useState(randRGBLvl())
  const [isHovering, setIsHovering] = React.useState(false)

  const onClickAction = () => {
    if (editor.id === userContext.id) {
      if (hasLock) {
        if (hasLockInSession) {
          unlockIfOwned()
        } else {
          forceLock()
        }
      } else {
        lockIfFree()
      }
    } else {
      if (hasLock) {
        forceLock()
      }
    }
  }

  let extraMessage;
  if (editor.id === userContext.id && hasLock && !hasLockInSession) {
    extraMessage = <strong>(You hold the lock in different window. Click to claim here.)</strong>
  }

  return (
    <div className="locker" onClick={onClickAction}>
      {hasLock && (
        <div className="lock-icon-wrapper">
          <div className={`${ isHovering ? "unlocked" : "locked"}-icon`}>
          </div>
        </div>
      )}
      <div onMouseOver={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} className="active-editor" style={{backgroundColor: color}}>
        <p className="editor-display">{editor.display}</p>
        {isHovering && (
          <span className="editor-details">
            {editor.email} {extraMessage}
          </span>
        )}
      </div>
    </div>
  );
}
