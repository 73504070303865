import * as React from "react";

import * as R from "ramda";

import GenButton from "../../Gen/Button/component";

import ReasoningToolPanel from "./Panel";

import ReasoningToolStudentFeedback from "./StudentFeedback";

import DocAuthorHeader from "../AuthorHeader";
import DocCommentInput from "../ChangeSummaryColumn/CommentInput";

import { NEW_STUDENT_FEEDBACK } from "../../../helpers/Doc/menu";

import {
  newAddStudentFeedbackChange,
} from "../../../helpers/Doc/changes";

interface StudentFeedbackPanelProps {
  documentContext: DocumentContext;
  userContext: UserContext;
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  mergedData: MergedData;
};

export default function StudentFeedbackPanel(props: StudentFeedbackPanelProps) {
  const {
    documentContext,
    userContext,
    editingContext,
    editingContextExtra,
    mergedData,
  } = props;

  let newStudentFeedback = null;
  if (editingContextExtra.currentMenu?.menu === NEW_STUDENT_FEEDBACK) {
    const author = userContext;
    newStudentFeedback = (
      <div className="reasoning-tool-summary-of-edit">
        <DocAuthorHeader
          authorName={`${author.firstName} ${author.lastName}`}
          colorArray={editingContext.colorLookup[author.id]}
        />
        <DocCommentInput
          isActive
          onAddClick={(text) => {
            editingContext.addChange(newAddStudentFeedbackChange(text));
            editingContext.closeCurrentMenu();
          }}
          onCancelClick={() => editingContext.closeCurrentMenu()}
          onFocus={() => {}}
        />
      </div>
    );
  }

  let studentFeedbackChanges = mergedData.student_feedbacks || [];
  studentFeedbackChanges = R.sortBy((studentFeedbackChange: any) => studentFeedbackChange.created_at, studentFeedbackChanges).reverse(); // TODO: Remove any
  const panelChildren = studentFeedbackChanges.map((studentFeedbackChange) => {
    return (
      <ReasoningToolStudentFeedback
        key={studentFeedbackChange.uid}
        userContext={userContext}
        editingContext={editingContext}
        studentFeedback={studentFeedbackChange}
      />
    );
  });

  return (
    <ReasoningToolPanel
      key="STUDENT_FEEDBACK"
      title="Student Feedback"
      headerButton={
        <GenButton
          key="NewStudentFeedback"
          name="New Student Feedback"
          highlighted
          small
          onClick={() => {
            editingContext.setCurrentMenu(NEW_STUDENT_FEEDBACK);
          }}
        />
      }
    >
      {newStudentFeedback}
      {panelChildren}
    </ReasoningToolPanel>
  );
}
