import BaseObserver from './baseObserver'

interface MouseClickObserverDelegate {
  mouseDidDownClick: (mode: MouseEvent) => void;
  mouseDidUpClick: (mode: MouseEvent) => void;
}


export default class MouseClickObserver extends BaseObserver<MouseClickObserverDelegate> {
  startEventListeners() {
    document.addEventListener("mousedown", this.notifyOnMouseDownClick.bind(this), true);
    document.addEventListener("mouseup", this.notifyOnMouseUpClick.bind(this), true);
  }

  stopEventListeners() {
    document.removeEventListener("mousedown", this.notifyOnMouseDownClick.bind(this), true);
    document.removeEventListener("mouseup", this.notifyOnMouseUpClick.bind(this), true);
  }

  notifyOnMouseDownClick(event: MouseEvent) {
    this.delegates.forEach(delegate => delegate.mouseDidDownClick(event));
  }

  notifyOnMouseUpClick(event: MouseEvent) {
    this.delegates.forEach(delegate => delegate.mouseDidUpClick(event));
  }
}
