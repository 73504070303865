import { escapeRegExp } from "lodash";

import {
  nodeInlineContentKeys,
  docReduceBlockNodes
} from "./Functions/base";

export function findAllSelectionsByText(baseNode: DocBlockNode<InlineNode>, findText: string) {
  const findRegExp = new RegExp(escapeRegExp(findText), "ig");

  return (docReduceBlockNodes(baseNode, (accum, node) => {
    nodeInlineContentKeys(node).forEach((inlineContentKey) => {
      const findRegExp = new RegExp(escapeRegExp(findText), "ig");

      let regResult;
      while ((regResult = findRegExp.exec(node[inlineContentKey].text)) !== null) {
        accum = accum.concat([{
          start: {
            uid: node.uid,
            contentKey: inlineContentKey,
            index: regResult.index,
          },
          end: {
            uid: node.uid,
            contentKey: inlineContentKey,
            index: regResult.index + findText.length,
          },
        }]);
      }
    });

    return (accum);
  }, []));
}
