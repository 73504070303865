import * as React from "react";
import { isNumber, keyBy } from "lodash";
import * as R from "ramda";
import * as classNames from "classnames";

import {
  findNode,
  docMapAllNodes
} from "../../../helpers/Doc/Functions/base";
import {
  newAddTextNodesBeforeNodeChange,
  newAddTextAndNodesToNodeChange,
} from "../../../helpers/Doc/changes";
import { isCurrentlyEditing } from "../../../helpers/Doc/currentlyEditing";
import { ADD_NODE } from "../../../helpers/Doc/menu";
import { getSubType } from "../../../helpers/Doc/Define/base";
import generateUUID from "../../../helpers/generateUUID";
import { LIST_RLO_MODAL } from "../../../helpers/Doc/menu";

import { DocBlockNodesProps } from "../BlockNodes"

const QUESTION_NODE_TYPES = [
  "category_matcher_question",
  "free_text_question",
  "mcq_diff_diagnosis",
  "mcq_feq",
  "mcq_multiple",
  "mcq_no_best",
  "mcq_single",
  "summary_statement",
  "cdq_question",
]

interface DocAddNodeProps {
  currentMenu?: DocMenu;
  editIndex?: number;
  parentContentKey: string;
  parentNode: DocBlockNodesProps;
  siblingUid?: string;
  localClipboard?: LocalClipboardData;
  shouldHaveSpace: boolean;

  documentContext: DocumentContext;
  editingContext: EditingContext;
  userContext: UserContext;
};

function DocAddNode(props: DocAddNodeProps) {
  const {
    currentMenu,
    documentContext,
    userContext,
    editIndex,
    editingContext,
    parentContentKey,
    parentNode,
    siblingUid,
    localClipboard,
    shouldHaveSpace,
  } = props

  if (!editingContext.isEditing) { return null; }
  if (
    (
      !userContext.permissions.createContentNodes ||
      (parentNode.type === "section" && parentNode.level === 0 && !userContext.permissions.createPages)
    ) && (
      documentContext.kind !== "assessment_question_mcq" ||
      !userContext.permissions.editMediaAssets ||
      parentNode.type !== "section" ||
      !parentNode.classes?.includes("question_vignette")
    )
  ) { return null; }

  const classes = classNames(
    "doc-add-node",
    {"level-one": parentNode.type === "section" && parentNode.level === 0},
  );

  const style = {marginLeft: (parentNode.type === "section" && parentNode.level === 0 && documentContext.docType !== "ReusableObject") ? "20px" : "0px"};

  const index = isNumber(editIndex) ? editIndex + 1 : 0;

  let addNodeMenu;
  const isOpen = currentMenu?.menu === ADD_NODE && isCurrentlyEditing(currentMenu, { menu: ADD_NODE, uid: parentNode.uid, contentKey: parentContentKey, index: index });

  if (isOpen) {
    const parentType = getSubType(parentNode);
    const nodeDefinition = editingContext.nodeDefinitions[parentType].deref(editingContext.nodeDefinitions);
    const parentDisplayName = nodeDefinition.displayName(editingContext.nodeDefinitions);

    let addText = `Add Content to ${parentDisplayName}`;
    if (parentNode.type === "section") {
      addText = (parentNode.level === 0 && documentContext.docType !== "ReusableObject") ? "Add Page" : addText;
    } else if (parentNode.type === "tableCell") {
      addText = "Add Table Content";
    }

    const options = nodeDefinition.objectModel[parentContentKey].generateOptions(editingContext.nodeDefinitions);
    const optionsMap = keyBy(options, (option) => {
      return option.description(editingContext.nodeDefinitions);
    });
    let selectOptionSummaries = Object.keys(optionsMap).map((optionName) => {
      const displayName = optionsMap[optionName].displayName(editingContext.nodeDefinitions);
      return [displayName, optionName];
    }).sort();

    if (!userContext.permissions.insertRloToDocuments) {
      selectOptionSummaries = selectOptionSummaries.filter(([optionTitle, optionKey]) => "RLOModal" !== optionKey )
    }

    const selectOptions = selectOptionSummaries.map(([displayName, optionName]) => {
      return <option value={optionName} key={optionName}>{displayName}</option>;
    });

    if (localClipboard) {
      selectOptions.unshift(
        <option value="PASTE_CONTENT" key="PASTE_CONTENT">PASTE CONTENT</option>
      );
    }

    const handleSelectChange = (selectNode: React.ChangeEvent<HTMLSelectElement>) => {
      const name = selectNode.target.value;

      if (name === "RLOModal") {
        editingContext.setCurrentMenu(LIST_RLO_MODAL, {...currentMenu, menu: LIST_RLO_MODAL})
      } else if (name === "PASTE_CONTENT") {
        if (localClipboard) {
          const typesToAdd = localClipboard.map(node => getSubType(node));
          const allowableTypes = options.map(option => option.refName);

          if (!R.isEmpty(R.intersection(QUESTION_NODE_TYPES, allowableTypes))) {
            allowableTypes.push("question");
          }

          let canAddContent = R.isEmpty(R.difference(typesToAdd, allowableTypes));

          if (canAddContent) {
            // Remove Extra Data
            let cleanedLocalClipboard = localClipboard.map(node => docMapAllNodes(node, (nodeP) => ({...nodeP, uid: generateUUID()})));

            if (currentMenu.siblingUid) {
              editingContext.addChange(newAddTextNodesBeforeNodeChange(currentMenu.siblingUid, null, cleanedLocalClipboard))
            } else {
              editingContext.addChange(newAddTextAndNodesToNodeChange(currentMenu.uid, currentMenu.contentKey, null, cleanedLocalClipboard));
            }
          } else {
            alert("Content type not allowed");
          }
        }
        editingContext.closeCurrentMenu();
      } else {
        const option = optionsMap[name];
        const newNode = option.generate(editingContext.nodeDefinitions, {});

        if (currentMenu.siblingUid) {
          editingContext.addChange(newAddTextNodesBeforeNodeChange(currentMenu.siblingUid, null, [newNode]))
        } else {
          editingContext.addChange(newAddTextAndNodesToNodeChange(currentMenu.uid, currentMenu.contentKey, null, [newNode]));
        }
        editingContext.closeCurrentMenu();
      }

    };

    addNodeMenu = (
      <div className="doc-add-node-menu" contentEditable={false}>
        <h4>{addText}</h4>
        <select onChange={handleSelectChange}>
          <option value="Pick an item" key="Pick an item">--Pick an item--</option>
          {selectOptions}
        </select>
      </div>
    );
  }

  return (
    <div className={classes} style={style} contentEditable={false}>
      {
        shouldHaveSpace && (
          <div className="doc-add-node-space">
            &nbsp;
          </div>
        )
      }
      <div
        className="doc-add-node-tag"
        contentEditable={false}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isOpen) {
            editingContext.closeCurrentMenu();
          } else {
            editingContext.setCurrentMenu(ADD_NODE, {
              uid: parentNode.uid,
              contentKey: parentContentKey,
              index: index,
              siblingUid: siblingUid
            });
          }
        }}
      >
        <i className={`fa fa-${isOpen ? "times" : "plus"}`}></i>
      </div>
      {addNodeMenu}
    </div>
  );
}

export default React.memo(DocAddNode);
