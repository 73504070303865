import * as React from "react";

import * as classNames from "classnames";

import DocBlockNodes from "../../BlockNodes";

import {
  newMergeTableCells,
  newUnmergeTableCells,
} from "../../../../helpers/Doc/changes";

interface DocTableCellProps extends DocTableCellType<InlineNode>, BlockNodeAll {
  columnIndex: number;
  rowIndex: number;
  firstColumn: boolean;
  firstRow: boolean;
  parentTableUID: string;
}

function DocTableCell(props: DocTableCellProps) {
  const {
    editingContext,
    rowSpan = 1,
    columnSpan = 1,
  } = props

  return (
    <td
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-table-cell", props.uid)}
      rowSpan={rowSpan}
      colSpan={columnSpan}
    >
      <DocBlockNodes
        {...props}
        content={props.content}
        contentKey="content"
        independentlyEditable
        simple
        shouldHaveAddNodes={editingContext.isEditing}
      />
      {(rowSpan > 1 || columnSpan > 1) && editingContext.isEditing &&
        <div
          key="doc-table-cell-unmerge-cell"
          className="doc-table-cell-unmerge-cell no-document-selection"
          onClick={() => {
            const rowRange = { start: props.rowIndex, end: props.rowIndex + rowSpan - 1 };
            const columnRange = { start: props.columnIndex, end: props.columnIndex + columnSpan - 1 };

            editingContext.clearCurrentDocSelection();
            editingContext.addChange(newUnmergeTableCells(props.parentTableUID, rowRange, columnRange));
          }}
        ><i className="fa fa-columns"></i></div>
      }
      {!props.firstColumn && editingContext.isEditing &&
        <div
          key="doc-table-cell-merge-row-cell"
          className="doc-table-cell-merge-row-cell no-document-selection"
          onClick={() => {
            const rowRange = { start: props.rowIndex, end: props.rowIndex };
            const columnRange = { start: props.columnIndex - 1, end: props.columnIndex };

            editingContext.clearCurrentDocSelection();
            editingContext.addChange(newMergeTableCells(props.parentTableUID, rowRange, columnRange));
          }}
        ><i className="fa fa-times"></i></div>
      }
      {!props.firstRow && editingContext.isEditing &&
        <div
          key="doc-table-cell-merge-column-cell"
          className="doc-table-cell-merge-column-cell no-document-selection"
          onClick={() => {
            const rowRange = { start: props.rowIndex - 1, end: props.rowIndex };
            const columnRange = { start: props.columnIndex, end: props.columnIndex };

            editingContext.clearCurrentDocSelection();
            editingContext.addChange(newMergeTableCells(props.parentTableUID, rowRange, columnRange));
          }}
        ><i className="fa fa-times"></i></div>
      }
    </td>
  );
}

DocTableCell.defaultProps = {
  rowSpan: 1,
  columnSpan: 1,
};

export default React.memo(DocTableCell);
