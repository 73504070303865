import { batch } from "react-redux";

import {
  newChangeTextAndNodesChange
} from "../changes";

import * as selection from "../selection";

import {
} from "../Functions/base";

export default function insertAtSelection(newText, {
  data,
  variantDataById,
  currentMenu,
  addChanges,
  setCurrentDocSelection,
  newActiveStyles = [],
  newInactiveStyles = [],
}: EditActionContext) {
  const currentDocSelection = selection.getDocSelection();

  if (!currentDocSelection || currentMenu || newText.length === 0) { return false; }

  // ADD STYLE RANGES
  // FIND NODE AND OFFSET
  // GET CURRENT STYLES
  // ADD CHANGED STYLES

  const initalStyles = newActiveStyles.map(style => ({
    type: style,
    range: {
      start: 0,
      end: newText.length,
    }
  }))

  const newStyledText = {
    text: newText,
    styleRanges: initalStyles
  }

  batch(() => {
    addChanges([newChangeTextAndNodesChange(currentDocSelection, newStyledText)], currentDocSelection.variantId);
    setCurrentDocSelection(selection.shiftDocSelectionBy(selection.collapseDocSelectionToStart(currentDocSelection), newText.length));
  });

  return true;
}
