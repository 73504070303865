import React from 'react';

class CustomMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,        // Control dropdown visibility
    };
    this.dropdownRef = React.createRef(); // Ref for the dropdown
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false }); // Close dropdown if click is outside
    }
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleOptionChange = (optionValue) => {
    const { selectedOptions } = this.props;
    const isSelected = selectedOptions.includes(optionValue);

    const updatedOptions = isSelected
      ? selectedOptions.filter(option => option !== optionValue)  // Deselect if already selected
      : [...selectedOptions, optionValue];                       // Select if not selected

    this.props.onChange(updatedOptions);  // Trigger parent's onChange for external handling
  };


  render() {
    const { options, placeholder, selectedOptions } = this.props;
    const {isOpen } = this.state;

    return (
      <div className="custom-multi-select" ref={this.dropdownRef} style={{ position: 'relative' }}>
        {/* Dropdown-like div styled like a select element */}
        <div className="form-control filterSelect" onClick={this.toggleDropdown} style={{ cursor: 'pointer' }}>
          {placeholder}
        </div>

        {/* Dropdown content with options */}
        {isOpen && (
          <div className="dropdown-menu" style={{ display: 'block' }}>
            {options.map(option => (
              <li
                key={option}
                className={selectedOptions.includes(option) ? 'selected' : ''}
                onClick={() => this.handleOptionChange(option)}
              >
                {option}
              </li>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default CustomMultiSelect;
