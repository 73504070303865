import * as React from "react";

import GenModal from "../Gen/Modal/component";
import ReusableObjectList from "../ReusableObject/List/component";

import {
  newAddTextNodesBeforeNodeChange,
  newAddTextAndNodesToNodeChange,
} from "../../helpers/Doc/changes";
import { RLO_MODAL, LIST_RLO_MODAL } from "../../helpers/Doc/menu";

interface ListRLOModalProps {
  editingContextExtra: EditingContextExtra;
  editingContext: EditingContextInEditor;
  permissions: AqPermissions;
};

const ListRLOModal = (props: ListRLOModalProps) => {
  const {
    editingContextExtra,
    editingContext,
    permissions
  } = props;

  const [rloModalOpen, setRloModalOpen] = React.useState(true);
  const [rlo, setRlo] = React.useState(null)
  const [addModalOpen, setAddModalOpen] = React.useState(false)
  const [showRLOResponse, setShowRLOResponse] = React.useState(null);

  React.useEffect(()=> {
    fetch('/reusable_objects/show_rlo_list')
    .then(res => res.json())
      .then(
        (response) => {
          setShowRLOResponse(response)
        },
        (error) => {
          console.error(error)
        }
      )
  }, [])

  const closeRloModal = () => {
    setRloModalOpen(false)
  }

  const handleAddModalOpen = (rlo) => {
    setRlo(rlo)
    setAddModalOpen(true)
  }

  const closeAddModal = () => {
    setRlo(null)
    setAddModalOpen(false)
  }

  const addModalContinue = () => {
    const currentMenu = editingContextExtra.currentMenu;
    if (currentMenu?.menu !== LIST_RLO_MODAL) { return }

    const node = {
      ...rlo.review_variant.change_data,
      roDocumentCount: (rlo.data.roDocumentCount || 1) + 1,
    }
    if (currentMenu.siblingUid) {
      editingContext.addChange(newAddTextNodesBeforeNodeChange(currentMenu.siblingUid, null, [node]))
    } else {
      editingContext.addChange(newAddTextAndNodesToNodeChange(currentMenu.uid, currentMenu.contentKey, null, [node]));
    }
    editingContextExtra.setCurrentRO(node)
    editingContext.setCurrentMenu(RLO_MODAL, {data: rlo, menu: RLO_MODAL})
  }

  return (
    <>
    <GenModal open={rloModalOpen} >
      <div className="gen-modal-panel gen-modal-panel-large gen-modal-media-menu" style={{marginTop: 90 + 'px', width: 92 + '%', marginLeft: 20 + 'px'}}>
        <div style={{textAlign: "end"}}>
          <button onClick={closeRloModal}>X</button>
        </div>
        {showRLOResponse ? (<ReusableObjectList
          itemsURL={showRLOResponse.item_url}
          permissions={permissions}
          documentLookupTable={showRLOResponse.document_lookup_table}
          context="editor"
          setAddModalOpen={handleAddModalOpen}
        />) : (<div>Loading...</div>)}
      </div>
    </GenModal>

    <GenModal open={addModalOpen} >
      <div className="gen-modal-panel gen-modal-media-menu" style={{marginTop: 90 + 'px'}}>
        <div style={{textAlign: "center"}}>
          <div style={{marginBottom: "20px"}}>Click <b>Continue</b> to insert this Reusable Content to your document or <b>Cancel</b> to discard.</div>
          <button onClick={addModalContinue} style={{marginRight: "20px"}} >Continue</button>
          <button onClick={closeAddModal}>Cancel</button>
        </div>
      </div>
    </GenModal>
    </>
  );
}

export default ListRLOModal;
