import * as React from "react";

interface DocGradedFeedbackBannerProps {
  type: "correct" | "partial" | "incorrect" | "no-single-answer" | "no-grade";
}

export default function DocGradedFeedbackBanner(props: DocGradedFeedbackBannerProps) {
  switch (props.type) {
    case "correct":
      return (
        <div className="doc-graded-feedback-banner correct">
          <span className="doc-graded-feedback-banner-icon icon-star"></span>
          <span className="doc-graded-feedback-banner-text">CORRECT</span>
        </div>
      );
    case "partial":
      return (
        <div className="doc-graded-feedback-banner partial">
          <span className="doc-graded-feedback-banner-icon icon-warning"></span>
          <span className="doc-graded-feedback-banner-text">PARTIALLY CORRECT</span>
        </div>
      );
    case "incorrect":
      return (
        <div className="doc-graded-feedback-banner incorrect">
          <span className="doc-graded-feedback-banner-icon icon-warning"></span>
          <span className="doc-graded-feedback-banner-text">INCORRECT</span>
        </div>
      );
    case "no-single-answer":
      return (
        <div className="doc-graded-feedback-banner no-single-answer">
          <span className="doc-graded-feedback-banner-icon icon-star"></span>
          <span className="doc-graded-feedback-banner-text">THERE IS NO SINGLE BEST ANSWER</span>
        </div>
      );
    case "no-grade":
    default:
      return (
        <div className="doc-graded-feedback-banner not-graded">
          <span className="doc-graded-feedback-banner-icon icon-star"></span>
          <span className="doc-graded-feedback-banner-text">NOT GRADED</span>
        </div>
      );
  }
}
