import * as React from "react";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

import Select from 'react-select';

interface DocEditHeaderMultiSelectInputProps {
  contentKey: string;
  default: Array<string>;
  disableMetadataEdit: boolean;
  editingContext: EditingContextInEditor;
  label: string;
  options: Array<string>;
  tags: Array<string>;
  uid: string;
};

export default function DocEditHeaderMultiSelectInput(props: DocEditHeaderMultiSelectInputProps) {
  const values = props.tags || props.default || [];

  const options = props.options.map((optionName) => {
    return {value: optionName, label: optionName};
  })

  return (
    <div className="doc-edit-header-input doc-edit-header-select-input">
      <label style={{fontSize: '14px'}}>{props.label}</label>
      <div style={{width: '350px'}}>
        <Select
          isMulti
          options={options}
          defaultValue={options.filter(option=> values.includes(option["value"]))}
          isDisabled={props.disableMetadataEdit}
          onChange={(onChangeValue)=>{
            let newValue: Array<string> = []
            if(onChangeValue) {
              newValue = onChangeValue.map((obj) => obj["value"])
            }
            props.editingContext.addChange(newSetKeyValueChange(props.uid, props.contentKey, newValue));
          }}
        />
      </div>
      <br/>
    </div>
  );
}
