import * as React from 'react';

import * as R from 'ramda';

import * as classNames from 'classnames';

import Smile1 from 'images/smile-rating-1.svg';
import Smile2 from 'images/smile-rating-2.svg';
import Smile3 from 'images/smile-rating-3.svg';
import Smile4 from 'images/smile-rating-4.svg';
import Smile5 from 'images/smile-rating-5.svg';

const smileImageSrcs = [
  Smile1,
  Smile2,
  Smile3,
  Smile4,
  Smile5,
];

interface Props {
  value: number
  disabled?: boolean

  onChange: (number: number) => void
}

export default function AqSmileRating(props: Props): React.ReactElement {
  const {
    value,
    disabled,

    onChange,
  } = props;

  return (
    <div>
      {R.range(1, 6).reverse().map((val) => (
        <button
          key={val}
          className={classNames('aq-smile-rating-button', { active: value === val })}
          disabled={disabled}
          onClick={() => { onChange(val); }}
        >
          <img src={smileImageSrcs[val - 1]} />
        </button>
      ))}
    </div>
  );
}
