import React, { useState } from 'react';

const CollapsibleListItem = ({ header, content }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleClick = () => {
    setCollapsed(!collapsed);
  }

  const listContent = () => {
    return(
      [...[content]].flat().map((e) => {
        return <div key={e.key} className="collapsible-list-content-item">{e.content}</div>
      })
    );
  }

  return (
    <div className="collapsible-list-item">
      <div className="collapsible-list-header" onClick={handleClick}>
        <div className={"collapsible-list-handle"}>
          <i className={collapsed ? "fas fa-plus-square fa-sm" : "fas fa-minus-square fa-sm"}></i>
        </div>
        <div className="collapsible-list-header-text">{header}</div>
      </div>
      {!collapsed && <div className="collapsible-list-content">{listContent()}</div>}
    </div>
  );
};

export default props => <CollapsibleListItem {...props} />;
