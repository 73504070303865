import * as React from "react";

import {full_url} from "../../../helpers/url";

import ReasoningToolPanel from "./Panel";
import CollapsibleListItem from "../../Gen/CollapsibleList/Item/component";

interface ReasoningToolEditorsChecklistTableProps {
  activeMemberId: number;
  checklistContents: Array<ChecklistContent>;
  currentAssignmentId: number;
  nextVersionId: number;
};

type ReasoningToolEditorsChecklistTableState = Record<number, {
  body: string,
  checked: boolean,
  collapsed: boolean,
}>;

export default class ReasoningToolEditorsChecklistTable extends React.Component<ReasoningToolEditorsChecklistTableProps, ReasoningToolEditorsChecklistTableState> {
  constructor(props: ReasoningToolEditorsChecklistTableProps) {
    super(props);

    let initialState = this.props.checklistContents.reduce((map: ReasoningToolEditorsChecklistTableState, item) => {
      map[item.id] = { body: item.body, checked: item.checked, collapsed: false };
      return map;
    }, {});

    this.state = initialState
  }

  handleCheckboxChange = (event: ChecklistContent) => {
    fetch(full_url(`/projects/checklist_items/${event.id}`), {
      method: 'PUT',
      headers: ReactOnRails.authenticityHeaders({
        'Accept': "application/json",
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        projects_checklist_item: {
          checked: !event.checked
        }
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    })
    .catch(error => {
      console.log(error);
    });

    let id = event.id
    let checked = !this.state[id].checked

    this.setState({[id]: {...this.state[id], checked: checked}});
  }

  render() {
    let completeButtonStyle = {marginLeft: "50px"}
    let checklistItems = this.props.checklistContents
    let editingCompleteLink = window.location.origin + '/projects/project_members/' + this.props.activeMemberId
    let project_id = new URLSearchParams(window.location.search).get('project_id');
    if (!this.props.nextVersionId) {
      editingCompleteLink +='/editing_complete'
    } else {
      editingCompleteLink += '/assignments/' + this.props.currentAssignmentId + '/complete?next_link=' + window.location.origin + '/versions/' + this.props.nextVersionId + '/edit?project_id=' + project_id
    }

    let renderedTable;
    if (checklistItems?.length) {
      renderedTable = <table className='reasoning-tool-lab-values-table'>
        <tbody>
          {checklistItems.map(( item, index ) => {
            let list = item.body.split(/(?= -)/g)
            let content = list.splice(1).map((e, i) => {
              return { key: `${item.id}-${i}`, content: e }
            });
            return (
              <tr>
                <td style={{width: "5%", verticalAlign:"top", paddingTop:"12px"}}>
                  <input type="checkbox" checked={this.state[item.id].checked} onChange={this.handleCheckboxChange.bind(this, item)} />
                </td>
                <td>
                  <CollapsibleListItem header={list} content={content} />
                </td>
              </tr>
            );
          })}
        </tbody>
        <button type='button' style={completeButtonStyle} onClick={(e) => {
          e.preventDefault();
          window.location.href = editingCompleteLink;
          }}> Editing Complete </button>
      </table>
    } else {
      renderedTable = <table className='reasoning-tool-lab-values-table'>
        <tbody>
          <tr>
            <td style={{width: "10%"}}>
            </td>
            <td>
              <strong>You are a simple reviewer</strong>
            </td>
          </tr>
        </tbody>
        <button type='button' style={completeButtonStyle} onClick={(e) => {
          e.preventDefault();
          window.location.href = editingCompleteLink;
          }}> Editing Complete </button>
      </table>
    }

    return (
      <ReasoningToolPanel
        key="Editor's Checklist"
        title="Editor's Checklist"
        wide="400px"
      >
        {renderedTable}
      </ReasoningToolPanel>
    )
  }
}
