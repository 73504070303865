import BaseObserver from './baseObserver'

import { full_url } from "../../url";

const OFFLINE_TIME_TO_CHECK_FOR_UPDATES = 1000
const ONLINE_TIME_TO_CHECK_FOR_UPDATES = 30000

interface OfflineObserverDelegate {
  didGoOnline: () => void;
  didGoOffline: () => void;
}

export default class OfflineObserver extends BaseObserver<OfflineObserverDelegate> {
  testIfOnlineTimer?: NodeJS.Timeout;

  startEventListeners() {
    window.addEventListener("offline", this.notifyOffline.bind(this), true);
    window.addEventListener("online", this.notifyOnline.bind(this), true);
    this.testIfOnlineTimer = setTimeout(this.testIfOnline.bind(this), ONLINE_TIME_TO_CHECK_FOR_UPDATES);
  }

  stopEventListeners() {
    window.removeEventListener("offline", this.notifyOffline.bind(this), true);
    window.removeEventListener("online", this.notifyOnline.bind(this), true);
    if ( this.testIfOnlineTimer ) { clearTimeout(this.testIfOnlineTimer); }
  }

  afterRegistrationInitializer(delegate: OfflineObserverDelegate) {
    navigator.onLine ? delegate.didGoOnline() : delegate.didGoOffline();
  }

  notifyOffline() {
    this.delegates.forEach(delegate => delegate.didGoOffline());
  }

  notifyOnline() {
    this.delegates.forEach(delegate => delegate.didGoOnline());
  }

  testIfOnline() {
    const xhr = new XMLHttpRequest();

    const noResponseTimer = setTimeout(() => {
      xhr.abort();
      this.notifyOffline();
      this.testIfOnlineTimer = setTimeout(this.testIfOnline.bind(this), OFFLINE_TIME_TO_CHECK_FOR_UPDATES);
    }, 5000);

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState !== 4) {
        return;
      }

      clearTimeout(noResponseTimer);
      if (xhr.status === 200) {
        this.notifyOnline();
        this.testIfOnlineTimer = setTimeout(this.testIfOnline.bind(this), ONLINE_TIME_TO_CHECK_FOR_UPDATES);
      } else {
        this.notifyOffline();
        this.testIfOnlineTimer = setTimeout(this.testIfOnline.bind(this), OFFLINE_TIME_TO_CHECK_FOR_UPDATES);
      }
    };
    xhr.open("HEAD", full_url("/check/online"), true);
    xhr.send();
  }
}
