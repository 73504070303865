export function undo<UndoRedoBody>(undoRedoState: UndoRedoState<UndoRedoBody>, currentData: UndoRedoBody): [UndoRedoBody, UndoRedoState<UndoRedoBody>] {
  undoRedoState = undoRedoState || resetState();

  if (undoRedoState.undoStack.length !== 0) {
    let newRedoStack = undoRedoState.redoStack;
    const newData = undoRedoState.undoStack[0];
    const newUndoStack = undoRedoState.undoStack.slice(1);

    if (currentData) {
      newRedoStack = [currentData].concat(newRedoStack as NonNullable<UndoRedoBody>[]); // TODO: Remove as
    }

    newRedoStack = newRedoStack.slice(0, 50);

    return [
      newData,
      {
        redoStack: newRedoStack,
        undoStack: newUndoStack,
        undoCompressCount: 0,
      },
    ];
  } else {
    alert("No undo action");
    return [currentData, undoRedoState];
  }
}

export function redo<UndoRedoBody>(undoRedoState: UndoRedoState<UndoRedoBody>, currentData: UndoRedoBody): [UndoRedoBody, UndoRedoState<UndoRedoBody>] {
  undoRedoState = undoRedoState || resetState();

  if (undoRedoState.redoStack.length !== 0) {
    const newRedoStack = undoRedoState.redoStack.slice(1);
    const newData = undoRedoState.redoStack[0];
    let newUndoStack = undoRedoState.undoStack;

    if (currentData) {
      newUndoStack = [currentData].concat(newUndoStack as NonNullable<UndoRedoBody>[]); //TODO: Remove as
    }

    newUndoStack = newUndoStack.slice(0, 50);

    return [
      newData,
      {
        redoStack: newRedoStack,
        undoStack: newUndoStack,
        undoCompressCount: 0,
      },
    ];
  } else {
    alert("No redo action");
    return [currentData, undoRedoState];
  }
}

const UNDO_REDO_DROP_LAST_STEP_SIZE = 10;
export function addToUndoStack<UndoRedoBody>(undoRedoState: UndoRedoState<UndoRedoBody>, currentData: UndoRedoBody, shouldMaybeDropLast: boolean) {
  undoRedoState = undoRedoState || resetState();

  if (shouldMaybeDropLast && undoRedoState.undoCompressCount < UNDO_REDO_DROP_LAST_STEP_SIZE) {
    return {
      ...undoRedoState,
      redoStack: [],
      undoCompressCount: undoRedoState.undoCompressCount + 1,
    };
  } else {
    return {
      redoStack: [],
      undoStack: [currentData].concat(undoRedoState.undoStack).slice(0, 25),
      undoCompressCount: 0,
    };
  }
}

export function resetState() {
  return {
    redoStack: [],
    undoStack: [],
    undoCompressCount: 0,
  };
}

export function canUndo(undoRedoState: UndoRedoState<unknown>) {
  return (undoRedoState?.undoStack?.length || 0) > 0;
}

export function canRedo(undoRedoState: UndoRedoState<unknown>) {
  return (undoRedoState?.redoStack?.length || 0) > 0;
}
