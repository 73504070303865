import * as React from "react";

import { newSetKeyValueChange } from "../../../../helpers/Doc/changes";

interface DocPrerequisiteMetadataProps {
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  userContext: UserContext;
  rootUid: string;
  possible_prerequisite?: boolean;
  prerequisite_document_id?: number;
};

export default function DocPrerequisiteMetadata(props: DocPrerequisiteMetadataProps) {
  const {
    editingContext,
    editingContextExtra,
    userContext,
    rootUid,
    possible_prerequisite = false,
    prerequisite_document_id,
  } = props

  if (!userContext.permissions.updatePrerequisiteSubservient) {
    return null;
  }

  return (
    <div style={{padding: "0 5px"}}>
      <p><strong>Set this case as a Prerequisite case (not available when this case is set as subervient)</strong></p>

      <label>
        <input
          type="checkbox"
          checked={possible_prerequisite}
          onChange={() => {
            editingContext.addChange(newSetKeyValueChange(rootUid, "possible_prerequisite", !possible_prerequisite));
          }}
          disabled={!!prerequisite_document_id || !userContext.permissions.updatePrerequisiteSubservient}
        />
        Make this case a prerequisite</label>

      <p style={{marginTop: "20px"}}><strong>Make this case subservient to an existing prerequisite case (not available when this case is set as prerequisite).</strong></p>

      <select
        value={prerequisite_document_id}
        onChange={(e) => {
          const new_prerequisite_document_id = e.target.value === "" ? null : e.target.value;
          editingContext.addChange(newSetKeyValueChange(rootUid, "prerequisite_document_id", new_prerequisite_document_id));
        }}
        disabled={possible_prerequisite || !userContext.permissions.updatePrerequisiteSubservient}
      >
        <option value={""}>Select Prerequisite Case</option>
        {editingContextExtra.possiblePrerequisiteDocuments.map(possiblePrerequisiteDocument => (
          <option
            key={possiblePrerequisiteDocument.id}
            value={possiblePrerequisiteDocument.id}
          >
            {possiblePrerequisiteDocument.title}
          </option>
        ))}
      </select>
    </div>
  )
}
