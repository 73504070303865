import * as React from "react";

import * as classNames from "classnames";

import { backgroundStyleFromNode } from "../../../../helpers/Doc/changes";
import { isInlineNode } from "../../../../helpers/Doc/Functions/Inline";

import DocStyledText from "../../BlockNodeHelpers/StyledText";
import DocBlockNodes from "../../BlockNodes";

interface DocListItemProps extends DocListItemType<InlineNode>, BlockNodeAll {}

function DocListItem(props: DocListItemProps) {
  if (isInlineNode(props.content)) {
    return (
      <li
        id={props.uid}
        data-is-node
        data-uid={props.uid}
        className={classNames("doc-list-item", props.uid)}
      >
        <DocStyledText
          uid={props.uid}
          content={props.content}
          contentKey={"content"}
          editingContext={props.editingContext}
          placeholder={"Enter List Item text here"}
        />
      </li>
    );
  } else {
    return (
      <li
        id={props.uid}
        data-is-node
        data-uid={props.uid}
        className={classNames("doc-list-item", props.uid)}
        style={backgroundStyleFromNode(props)}
      >
        <ChangeHighlightListItemMark
          blockChangeHighlight={props.blockChangeHighlight}
          editingContext={props.editingContext}
        />
        <DocBlockNodes
          {...props}
          content={props.content}
          contentKey="content"
          simple
        />
      </li>
    );
  }
}

interface ChangeHighlightListItemMarkProps {
  blockChangeHighlight?: BlockChangeHighlight;
  editingContext: EditingContext;
}

function ChangeHighlightListItemMark(props: ChangeHighlightListItemMarkProps) {
  const {
    blockChangeHighlight,
    editingContext,
  } = props;

  switch (blockChangeHighlight?.type) {
    case "DELETE_CONTENT":
      return (
        <span
          className="doc-list-item-mark"
          style={{
            textDecorationColor: `rgb(${editingContext.colorLookup[blockChangeHighlight.author_id]})`,
            textDecoration: "line-through",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      );
    case "ADD_CONTENT":
      return (
        <span
          className="doc-list-item-mark"
          style={{
            color: `rgb(${editingContext.colorLookup[blockChangeHighlight.author_id]})`,
            textDecoration: "underline",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      );
    default:
      return null;
  }
}


export default React.memo(DocListItem);
