import * as React from "react";

import * as classNames from "classnames";

import DocBookmark from "../BlockNodeHelpers/Bookmark";
import DocNote from "../BlockNodeHelpers/Note";
import DocStyledText from "../BlockNodeHelpers/StyledText";

import GenButton from "../../Gen/Button/component";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";
import { MEDIA_PICKER } from "../../../helpers/Doc/menu";

import PlaceholderVideo from "images/placeholder-video.png";

interface DocVideoProps extends DocVideoType<InlineNode>, BlockNodeAll {};

function DocVideo(props: DocVideoProps) {
  const {
    editingContext,
  } = props;

  const openPicker = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    if (editingContext.isEditing) {
      editingContext.setCurrentMenu(MEDIA_PICKER, { mediaType: "video", uid: props.uid });
    }
  };

  let title = null;
  if ((typeof props.title === "string" && props.title.length > 0) || editingContext.isEditing) {
    title = (
      <figcaption>
        <DocStyledText
          uid={props.uid}
          content={props.title}
          contentKey={"title"}
          editingContext={editingContext}
          placeholder={"Enter Video text here"}
          independentlyEditable
          disableStyleTextEditing={props.disableStyleTextEditing}
        />
      </figcaption>
    );
  }

  let videoPlayer = null;
  switch (props.source) {
    case "local":
      let captionsTrack = null;
      if (props.url) {
        const videoAsset: Partial<DigitalAsset> = props.mediaContext.videos.find((v)=>{return v.distribution_url == props.url || props.url.includes(v.asset_path)}) || {};
        const captions = props.mediaContext.captions.filter((c)=>{return c.title == videoAsset.title});
        if (captions.length) {
          let caption = null;
          if (captions.length > 1) {
            caption = captions.reduce((capn_1, capn) => (capn_1.external_identifier > capn.external_identifier) ? capn_1 : capn);
          } else {
            caption = captions[0];
          }
          captionsTrack = <track key="en" label="English" kind="subtitles" srcLang="en" src={caption.distribution_url} />;
        }
        videoPlayer = (
          <video key={props.url} crossOrigin="anonymous" preload="none" controls>
            <source src={props.url} />
            {captionsTrack}
          </video>
        );
      } else if (props.uri && props.uri != "blank.mp4") {
        Rollbar.warning("Deprecated render DocVideo with uri called");
        const captionName = `${props.uri.split(".")[0]}.vtt`;
        if (props.documentContext.captions.includes(captionName)) {
          captionsTrack = <track key="en" label="English" kind="subtitles" srcLang="en" src={props.currentVersion.localCaptionURL(captionName)} />;
        }
        videoPlayer = (
          <div className="comment-container" style={backgroundStyleFromNode(props)}>
            <video crossOrigin="anonymous" preload="none" controls>
              <source src={props.currentVersion.localVideoURL(props.uri)} />
              {captionsTrack}
            </video>
          </div>
        );
      } else {
        videoPlayer = (
          <div className="comment-container" style={backgroundStyleFromNode(props)}>
            <img src={PlaceholderVideo} />
          </div>
        )
      }

      break;
    case "youtube":
      videoPlayer = <iframe width="560" height="315" src={`https://www.youtube.com/embed/${props.uri}`} frameBorder="0" allowFullScreen></iframe>;
      break;
    case "vimeo":
      videoPlayer = <iframe src={`https://player.vimeo.com/video/${props.uri}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>;
      break;
    default:
      Rollbar.warning(`DocVideo does not recognize source of type ${props.source}`);
      return null;
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-video", `doc-video-${props.source}`, props.uid, {"focused-bookmark": props.isFocusedBookmark && !props.simple})}
      contentEditable={false}
    >
      <div className="doc-video-wrapper">
        {videoPlayer}
        {editingContext.isEditing && props.userContext.permissions.viewMediaAssets && <GenButton
            key="picker-button"
            className="doc-video-picker-button"
            name={<i className="fa fa-video"></i>}
            highlighted
            small
            onClick={openPicker}
          />}
      </div>
      {title}
      <DocBookmark {...props} />
      <DocNote {...props} />
    </div>
  );
}

export default React.memo(DocVideo);
