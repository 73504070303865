import * as R from "ramda";

import * as rangeInlineJSON from "../Functions/RangeInlineJSON";

import {
  findNodePathByUid,
  findNodeSiblingAfterByPath,
  findNodeSiblingBeforeByPath,
  findNodeParentByUid,
  findNodeByUid,
} from "../Functions/base";

export function isDeletableNode(node: DocBlockNode<unknown>, contentKey: ContentKey) {
  switch (node.type) {
    case "paragraph":
    case "listItem":
    case "multipleChoiceAnswer":
      return true;
    default:
      return !contentKey;
  }
}

export function blockNodeFlowContentKey(node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "paragraph":
    case "listItem":
    case "multipleChoiceAnswer":
    case "matchItem":
      return "content";
    default:
      return null;
  }
}

export function afterReturnNodeFor(nodeDefinitions: NodeDefinitions, node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "paragraph":
    case "listItem":
    case "multipleChoiceAnswer":
    case "matchItem":
      const newNode = nodeDefinitions[node.type].generate(nodeDefinitions, {});

      return newNode;
    default:
      return null;
  }
}

export function isFlowNode(node: DocBlockNode<unknown>) {
  return !!blockNodeFlowContentKey(node);
}

export function addTextToBlockNodeFlow(node, text: string) {
  const nodeFlowKey = blockNodeFlowContentKey(node);
  if (nodeFlowKey) {
    node = R.assoc(nodeFlowKey, rangeInlineJSON.rangeInlineJSONWithText(text), node);
  }

  return node;
}

export function genrateNodesForAfterReturnNodeWithTextLines(nodeDefinitions: NodeDefinitions, node: DocBlockNode<unknown>, lines: string[]) {
  return lines.map((line) => {
    const newNode = afterReturnNodeFor(nodeDefinitions, node);
    return addTextToBlockNodeFlow(newNode, line);
  });
}

export function findPreviousFlowNodeByTest<T>(data: DocBlockNode<T>, uid: string, tester: (node: DocBlockNode<T>) => boolean) {
  const nodePath = findNodePathByUid(data, uid);
  const beforeNode = findNodeSiblingBeforeByPath(data, nodePath);

  if (beforeNode) {
    const lastDecendentFlowNodeByTest = findLastDecendentFlowNodeByTest(data, beforeNode.uid, tester);

    if (lastDecendentFlowNodeByTest) {
      return lastDecendentFlowNodeByTest
    } else {
      return findPreviousFlowNodeByTest(data, beforeNode.uid, tester);
    }
  }

  const parentNode = findNodeParentByUid(data, uid);

  if (!isFlowContainer(parentNode)) {
    return findPreviousFlowNodeByTest(data, parentNode.uid, tester);
  } else if (tester(parentNode)) {
    return parentNode;
  } else {
    return undefined;
  }
}

export function findNextFlowNodeByTest<T>(data: DocBlockNode<T>, uid: string, tester: (node: DocBlockNode<T>) => boolean) {
  const nodePath = findNodePathByUid(data, uid);
  const afterNode = findNodeSiblingAfterByPath(data, nodePath);

  if (afterNode) {
    const firstDecendentFlowNodeByTest = findFirstDecendentFlowNodeByTest(data, afterNode.uid, tester);

    if (firstDecendentFlowNodeByTest) {
      return firstDecendentFlowNodeByTest
    } else {
      return findNextFlowNodeByTest(data, afterNode.uid, tester);
    }
  }

  const parentNode = findNodeParentByUid(data, uid);

  if (!isFlowContainer(parentNode)) {
    return findNextFlowNodeByTest(data, parentNode.uid, tester);
  } else if (tester(parentNode)) {
    return parentNode;
  } else {
    return undefined;
  }
}

export function findLastDecendentFlowNodeByTest<T>(data: DocBlockNode<T>, uid: string, tester: (node: DocBlockNode<T>) => boolean) {
  const node = findNodeByUid(data, uid);

  if (tester(node)) {
    return node;
  }

  const blockKeys = flowBlockKeys(node);
  return blockKeys.reduceRight((flowNodeA, blockKey) => {
    return flowNodeA || node[blockKey].reduceRight((flowNodeB, childNode) => flowNodeB || findLastDecendentFlowNodeByTest(data, childNode.uid, tester))
  }, undefined)
}

export function findFirstDecendentFlowNodeByTest<T>(data: DocBlockNode<T>, uid: string, tester: (node: DocBlockNode<T>) => boolean) {
  const node = findNodeByUid(data, uid);

  if (tester(node)) {
    return node;
  }

  const blockKeys = flowBlockKeys(node);
  return blockKeys.reduce((flowNodeA, blockKey) => {
    return flowNodeA || node[blockKey].reduce((flowNodeB, childNode) => flowNodeB || findLastDecendentFlowNodeByTest(data, childNode.uid, tester))
  }, undefined)
}

export function findPreviousInlineFlowNode<T>(data: DocBlockNode<T>, uid: string) {
  return findPreviousFlowNodeByTest(data, uid, node => isFlowInline(node));
}

export function findNextInlineFlowNode<T>(data: DocBlockNode<T>, uid: string) {
  return findNextFlowNodeByTest(data, uid, node => isFlowInline(node));
}

export function blockContentKeys(node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "cdqQuestion":
    case "listItem":
    case "multipleChoice":
    case "numberedList":
    case "regularList":
    case "section":
    case "tableCell":
      return ["content"];
    case "categoryMatcher":
      return ["contentItems"];
      // return ["contentItems", "contentCategories"];
    case "imageGallery":
      return ["images"];
    case "table":
      return ["rows"];
    case "tableRow":
      return ["cells"];
    case "audio":
    case "button":
    case "cdqFeature":
    case "cdqQuestionAnswer":
    case "checkbox":
    case "horizontalRule":
    case "image":
    case "matchItem":
    case "multipleChoiceAnswer":
    case "paragraph":
    case "ratingBar":
    case "textInput":
    case "video":
    default:
      return [];
  }
}

export function inlineContentKeys(node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "audio":
    case "button":
    case "image":
    case "section":
    case "video":
      return ["title"];
    case "cdqFeature":
    case "cdqQuestionAnswer":
    case "checkbox":
    case "matchItem":
    case "multipleChoiceAnswer":
    case "paragraph":
      return ["content"];
    case "categoryMatcher":
    case "cdqQuestion":
    case "horizontalRule":
    case "imageGallery":
    case "listItem":
    case "multipleChoice":
    case "numberedList":
    case "ratingBar":
    case "regularList":
    case "table":
    case "tableCell":
    case "tableRow":
    case "textInput":
    default:
      return [];
  }
}

export function flowContainerContentKeys(node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "section":
    case "tableCell":
      return ["content"];
    case "categoryMatcher":
    case "cdqQuestion":
    case "multipleChoice":
      // COULD BE
    case "audio":
    case "button":
    case "cdqFeature":
    case "cdqQuestionAnswer":
    case "checkbox":
    case "horizontalRule":
    case "image":
    case "imageGallery":
    case "listItem":
    case "matchItem":
    case "multipleChoiceAnswer":
    case "numberedList":
    case "paragraph":
    case "ratingBar":
    case "regularList":
    case "table":
    case "tableRow":
    case "textInput":
    case "video":
    default:
      return [];
  }
}

export function flowBlockKeys(node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "listItem":
    case "numberedList":
    case "regularList":
    case "section":
    case "tableCell":
      return ["content"];
    case "multipleChoice":
    case "cdqQuestion":
    case "categoryMatcher":
      // Should these be flow or not
    case "audio":
    case "button":
    case "cdqFeature":
    case "cdqQuestionAnswer":
    case "checkbox":
    case "horizontalRule":
    case "image":
    case "imageGallery":
    case "matchItem":
    case "multipleChoiceAnswer":
    case "paragraph":
    case "ratingBar":
    case "table":
    case "tableRow":
    case "textInput":
    case "video":
    default:
      return [];
  }
}

export function flowInlineKeys(node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "checkbox":
    case "paragraph":
    case "cdqFeature":
    case "cdqQuestionAnswer":
    case "matchItem":
    case "multipleChoiceAnswer":
      return ["content"];
    case "audio":
    case "button":
    case "categoryMatcher":
    case "cdqQuestion":
    case "horizontalRule":
    case "image":
    case "imageGallery":
    case "listItem":
    case "multipleChoice":
    case "numberedList":
    case "ratingBar":
    case "regularList":
    case "section":
    case "table":
    case "tableCell":
    case "tableRow":
    case "textInput":
    case "video":
    default:
      return [];
  }
}

export function isolatedInlineKeys(node: DocBlockNode<unknown>) {
  switch (node.type) {
    case "section":
    case "image":
    case "video":
    case "audio":
    case "button":
      return ["title"];
    case "cdqFeature":
    case "cdqQuestionAnswer":
    case "matchItem":
    case "multipleChoiceAnswer":
    case "categoryMatcher":
    case "cdqQuestion":
    case "checkbox":
    case "horizontalRule":
    case "imageGallery":
    case "listItem":
    case "multipleChoice":
    case "numberedList":
    case "paragraph":
    case "ratingBar":
    case "regularList":
    case "table":
    case "tableCell":
    case "tableRow":
    case "textInput":
    default:
      return [];
  }
}

export function isFlowContainer(node: DocBlockNode<unknown>) {
  return flowContainerContentKeys(node).length > 0;
}

export function isFlowBlock(node: DocBlockNode<unknown>) {
  return flowBlockKeys(node).length > 0;
}

export function isFlowInline(node: DocBlockNode<unknown>) {
  return flowInlineKeys(node).length > 0;
}

export function isIsolatedInline(node: DocBlockNode<unknown>) {
  return isolatedInlineKeys(node).length > 0;
}
