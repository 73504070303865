export const versionTypes = Object.freeze({
  PUBLISHED: "PUBLISHED",
  ONGOING_MAINTENANCE: "ONGOING_MAINTENANCE",
  SCHEDULED_REVIEW: "SCHEDULED_REVIEW",
  OTHER: "OTHER",
});

export const variantTypes = Object.freeze({
  PUBLISHED: "PUBLISHED",
  MAINTENANCE: "MAINTENANCE",
  REVIEW: "REVIEW",
  OTHER: "OTHER",
});

export function versionTypeShortName(versionType: VersionType) {
  switch (versionType) {
    case versionTypes.PUBLISHED:
      return "PUB";
    case versionTypes.ONGOING_MAINTENANCE:
      return "OM";
    case versionTypes.SCHEDULED_REVIEW:
      return "SR";
    default:
      // TODO ADD ERROR
  }
}

export function versionTypeFullName(versionType: VersionType) {
  switch (versionType) {
    case versionTypes.PUBLISHED:
      return "Published";
    case versionTypes.ONGOING_MAINTENANCE:
      return "Ongoing Maintenance";
    case versionTypes.SCHEDULED_REVIEW:
      return "Scheduled Review";
    default:
      // TODO ADD ERROR
  }
}

export function versionTypeCssClassPrefix(versionType: VersionType) {
  switch (versionType) {
    case versionTypes.PUBLISHED:
      return "published";
    case versionTypes.ONGOING_MAINTENANCE:
      return "ongoing-maintenance";
    case versionTypes.SCHEDULED_REVIEW:
      return "scheduled-review";
    default:
      // TODO ADD ERROR
  }
}

export function versionPushButtonData(versionType: VersionType) {
  switch (versionType) {
    case versionTypes.SCHEDULED_REVIEW:
      return {
        pushButtonName: "Push To OM",
        pushButtonConfirmationText: "Are you sure you want to replace the current Ongoing Maintenance Version with the Scheduled Review Version?",
        pushButtonPath: "replace_edit",
        pushButtonReplaceableVariant: "maintenance_variant",
      }
    case versionTypes.ONGOING_MAINTENANCE:
      return {
        pushButtonName: "Push To SR" ,
        pushButtonConfirmationText: "Are you sure you want to replace the current Scheduled Review Version with the Ongoing Maintenance Version?",
        pushButtonPath: "replace_scheduled_review",
        pushButtonReplaceableVariant: "review_variant",
      }
    default:
      return {}
  }
}

export function determineVersionType(documentContext: DocumentContext, versionID: number) {
  switch (versionID) {
    case publishVersionId(documentContext):
      return versionTypes.PUBLISHED;
    case ongoingMaintenaceVersionId(documentContext):
      return versionTypes.ONGOING_MAINTENANCE;
    case scheduledReviewVersionId(documentContext):
      return versionTypes.SCHEDULED_REVIEW;
    default:
      return versionTypes.OTHER;
  }
}

export function publishVersionId(documentContext: DocumentContext) {
  return documentContext.docType == "ReusableObject" ? documentContext.publishVariantId : documentContext.publishVersionId;
}

export function ongoingMaintenaceVersionId(documentContext: DocumentContext) {
  return documentContext.docType == "ReusableObject" ? documentContext.maintenanceVariantId : documentContext.editVersionId;
}

export function scheduledReviewVersionId(documentContext: DocumentContext) {
  return documentContext.docType == "ReusableObject" ? documentContext.reviewVariantId : documentContext.scheduledReviewVersionId;
}

export function determineVariantTypeFromVersionType(versionType: VersionType) {
  switch (versionType) {
    case versionTypes.PUBLISHED:
      return variantTypes.PUBLISHED;
    case versionTypes.ONGOING_MAINTENANCE:
      return variantTypes.MAINTENANCE;
    case versionTypes.SCHEDULED_REVIEW:
      return variantTypes.REVIEW;
    default:
      return variantTypes.OTHER;
  }
}

export function variantKeyFromVariantVersion(variantType: VariantType) {
  switch (variantType) {
    case variantTypes.PUBLISHED:
      return 'publishVariantId';
    case variantTypes.MAINTENANCE:
      return 'maintenanceVariantId';
    case variantTypes.REVIEW:
      return 'reviewVariantId';
    default:
      return 'publishVariantId';
  }
}
