import BaseObserver from './baseObserver'

interface ActiveUserObserverDelegate {
  activeUserDidFire: (mode: MouseEvent | KeyboardEvent) => void;
}

export default class ActiveUserObserver extends BaseObserver<ActiveUserObserverDelegate> {
  startEventListeners() {
    document.addEventListener("mousemove", this.notifyOnActiveUser.bind(this), true);
    document.addEventListener("keydown", this.notifyOnActiveUser.bind(this), true);
  }

  stopEventListeners() {
    document.removeEventListener("mousemove", this.notifyOnActiveUser.bind(this), true);
    document.addEventListener("keydown", this.notifyOnActiveUser.bind(this), true);
  }

  notifyOnActiveUser(event: MouseEvent | KeyboardEvent) {
    this.delegates.forEach((delegate) => {
      if (delegate.activeUserDidFire) {
        delegate.activeUserDidFire(event);
      }
    });
  }
}
