import React from 'react'

export default function GenGen(props) {
  const {
    canShow=true,
    children=[]
  } = props

  if (canShow) {
    return(children)
  } else {
    return(null)
  }
}
