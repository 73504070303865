import * as React from "react";

import GenModal from "../../Gen/Modal/component";
import FileUploader from "../../FileUploader/component";

import {
  getAssetAssociations,
  deleteAssetAssociation,
} from "../../../helpers/media_manager";

import { MEDIA_MANAGER } from "../../../helpers/Doc/menu";

interface DocEditBarMediaManagerProps {
  createHandler?: (data: DigitalAsset) => void;
  currentVersion: CurrentVersion;
  deleteHandler?: (data: DigitalAsset) => void;
  documentContext: DocumentContext;
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  mediaContext: MediaContext;
  replaceHandler?: (data: {new?: DigitalAsset; old?: DigitalAsset}) => void;
  userContext: UserContext;
};

export default function DocEditBarMediaManager(props: DocEditBarMediaManagerProps) {
  const {
    documentContext,
    currentVersion,
    userContext,
    editingContext,
    editingContextExtra,
    mediaContext,
    createHandler,
    deleteHandler,
    replaceHandler,
  } = props;

  const currentMenu = editingContextExtra.currentMenu;
  if (currentMenu?.menu !== MEDIA_MANAGER) { return null; }

  const handleNewResourcesResponse = (data) => {
    if (data.digital_assets && data.digital_assets.length) {
      for (const asset of data.digital_assets) {
        createHandler(asset);
      }
    }
  };

  const handleResourceReplacement = (data) => {
    const assets = {};
    if (data.digital_assets && data.digital_assets.length) {
      assets["new"] = data.digital_assets[0];
    }
    if (data.old_asset && data.old_asset !== "null") {
      assets["old"] = data.old_asset;
    }
    replaceHandler(assets);
  };

  const deleteWithConfirmFunction = (e: React.MouseEvent<HTMLButtonElement>) => {
    const data = (e.target as any).dataset; // TODO: Remove any
    const externalId = data.id;
    const mediaType = data.media_type;

    const confirmAndDelete = (associations) => {
      let message = "";
      if (associations.length > 1) {
        message += `This ${mediaType} is also associated with the following:\n `;
        for (const a of associations) {
          if (a.id !== documentContext.id) {
            message += `${a.type} with ID: ${a.id}\n`;
          }
        }
      }
      message += "Are you sure you would like to delete?";
      const result = confirm(message);
      if (result) {
        deleteAssetAssociation({
          modelId: documentContext.id as any, // TODO: Remove any
          modelType: "Document",
          externalId: externalId,
          responseHandler: deleteHandler,
        });
        return true;
      } else {
        return false;
      }
    };

    return getAssetAssociations({
      externalId: externalId,
      responseHandler: confirmAndDelete,
    });
  };

  const makeCover = (e) => {
    const data = e.target.dataset;
    editingContextExtra.setVersionCoverImage(data.url);
  };

  const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    editingContext.closeCurrentMenu();
    if (currentVersion.coverImage
        && editingContextExtra.isAutoSaveOn) {
      editingContextExtra.save();
    }
  };

  const uploadButton = () => {
    if (!userContext.permissions.createMediaAssets) {
      return null;
    }
    const mediaType = currentMenu?.mediaType;
    return(
      <button className="upload-button" onClick={(e) => {}}>
        Upload
        <FileUploader
          name={`document[${mediaType}_file]`}
          fileUploadPath={"/api/v2/dam_file_uploads"}
          extraData={{"document[id]": documentContext.id}}
          onSuccess={handleNewResourcesResponse}
          loadingCount={editingContextExtra.getUploadCountByMediaType(mediaType)}
          changeLoadingCountByValue={(countChange: number) => {
            editingContextExtra.changeUploadCountForMediaTypeByValue(mediaType, countChange);
          }}
        />
      </button>
    );
  }

  const captionsUploadButton = (item, replacing) => {
    if (!userContext.permissions.editMediaAssets) {
      return null;
    }
    const extraData = {
      "document[caption_video_file_name]": item.title,
      "document[id]": documentContext.id,
    };
    if (replacing) {
      extraData["document[old_caption_id]"] = replacing.external_identifier;
    }
    return (
      <button className="upload-button" onClick={(e) => {}}>
        {replacing ? "Replace Caption" : "Upload New Caption"}
        <FileUploader
          name={"document[caption_file]"}
          fileUploadPath={"/api/v2/dam_file_uploads"}
          onSuccess={handleResourceReplacement}
          loadingCount={editingContextExtra.getUploadCountByMediaType(`caption-${item.title}`)}
          changeLoadingCountByValue={(countChange: number) => {
            editingContextExtra.changeUploadCountForMediaTypeByValue(`caption-${item.title}`, countChange);
          }}
          extraData={extraData}
        />
      </button>
    );
  };

  const makeCoverButton = (item: DigitalAsset) => {
    if (item.distribution_url === currentVersion.coverImage || !userContext.permissions.editMediaAssets) {
      return null;
    }
    return (
      <div className="doc-media-menu-media-item-buttons">
        <button data-url={item.distribution_url} onClick={makeCover}>Make Cover Image</button>
      </div>
    );
  };

  const deleteButton = (item: DigitalAsset) => {
    if (!userContext.permissions.deleteMediaAssets) {
      return null;
    }
    return <button data-id={item.external_identifier} data-media_type={item.media_type} onClick={deleteWithConfirmFunction}>Delete</button>
  }

  let title = "";
  let mediaItems = [];
  const mediaType = currentMenu?.mediaType;
  switch (mediaType) {
    case "image":
      title = "Image Files";
      mediaItems = mediaContext.images.map((item) => {
        return (
          <div key={item.external_identifier} className="doc-media-menu-media-item">
            <img src={item.distribution_url} />
            <h6>{item.title}</h6>
            <div className="doc-media-menu-media-item-buttons-inline">
              <div className="doc-media-menu-media-item-buttons">
                {deleteButton(item)}
              </div>
              {makeCoverButton(item)}
            </div>
            <hr />
          </div>
        );
      });
      break;
    case "audio":
      title = "Audio Files";
      mediaItems = mediaContext.audios.map((item) => {
        return (
          <div key={item.external_identifier} className="doc-media-menu-media-item">
            <audio preload="none" controls>
              <source src={item.distribution_url} />
            </audio>
            <h6>{item.title}</h6>
            <div className="doc-media-menu-media-item-buttons">
              {deleteButton(item)}
            </div>
            <hr />
          </div>
        );
      });
      break;
    case "video":
      title = "Video Files";
      mediaItems = mediaContext.videos.map((item) => {
        let captionsTrack = null;
        const captionItem = mediaContext.captions.filter((c)=>{return c.title === item.title;}).pop() || null;
        if (captionItem) {
          captionsTrack = <track key="en" label="English" kind="subtitles" srcLang="en" src={captionItem.distribution_url} />;
        }
        return (
          <div key={item.external_identifier} className="doc-media-menu-media-item">
            <video crossOrigin="anonymous" preload="none" controls>
              <source src={item.distribution_url} />
              {captionsTrack}
            </video>
            <h6>{item.title}</h6>
            <div className="doc-media-menu-media-item-buttons">
              {captionsUploadButton(item, captionItem)}
              {deleteButton(item)}
            </div>
            <hr />
          </div>
        );
      });
      break;
    case "pdf":
      title = "PDF Files";
      mediaItems = mediaContext.pdfs.map((item) => {
        return (
          <div key={item.external_identifier} className="doc-media-menu-media-item">
            <embed width="191" height="246" src={item.distribution_url} type="application/pdf" />
            <h6>{item.title}</h6>
            <h6>{item.distribution_url}</h6>
            <div className="doc-media-menu-media-item-buttons">
              {deleteButton(item)}
            </div>
            <hr />
          </div>
        );
      });
      break;
    default:
      // TODO ADD ERROR
      return null;
  }

  let uploadingParagraph = null;
  if (editingContextExtra.getUploadCountByMediaType(mediaType) > 0) {
    uploadingParagraph = (
      <p>
        Your file is being uploaded; depending on the file size this might take some time.
        You can click the “Uploading” button to upload additional files or click the “Close” button to continue editing.
        The system will notify you when the upload is complete.
      </p>
    );
  }

  return (
    <GenModal open>
      <div className="gen-modal-panel gen-modal-media-menu">
        <div className="gen-modal-header">
          <div className="gen-modal-header-left">
            <h1 className="title">{title}</h1>
          </div>
          <div className="gen-modal-header-right">
            {uploadButton()}
            <button className="close-button" onClick={onClose}><i className={"fa fa-times"}></i></button>
          </div>
        </div>
        <hr />
        {uploadingParagraph}
        {mediaItems}
      </div>
    </GenModal>
  );
}
