import * as React from "react";

import {
  find,
  pickBy,
  size,
  startsWith,
} from "lodash";

import GenButton from "../../Gen/Button/component";

import ReasoningToolPanel from "./Panel";

import ReasoningToolFeedbackSummary from "./FeedbackSummary";

import {stripState} from "../../../helpers/Doc/documentUserState";

const PANEL_QUESTIONS = [
  "Studying this Integrated Illness Script was a valuable use of my time.",
  "I will apply what I learned from this Integrated Illness Script to patient care.",
  "I would recommend this Integrated Illness Script to another student.",
];

interface ReasoningToolFeedbackPanelProps {
  userState: UserState;
  userContext: UserContext;
  displayContext: DisplayContext;
  currentVersion: CurrentVersion;
  documentContext: DocumentContext;
  featureFlag: FeatureFlag;
  mediaContext: MediaContext;
};

export default function ReasoningToolFeedbackPanel({
  userState,
  userContext,
  displayContext,
  currentVersion,
  documentContext,
  featureFlag,
  mediaContext,
}: ReasoningToolFeedbackPanelProps) {
  const strippedState = stripState(userState);

  const feedbackToAquifer = {
    ratingBars: pickBy(strippedState, (value, key) => value && startsWith(key, "feedback_rating_bar")),
    comments: pickBy(strippedState, (value, key) => value && startsWith(key, "feedback_text_input")),
    submitted: find(strippedState, {feedback_submitted: true}) !== undefined,
  };

  const panelChildren = PANEL_QUESTIONS.map((question, index) => {
    const prettyIndex = index + 1;

    return (
      <ReasoningToolFeedbackSummary
        key={`feedback_summary_${prettyIndex}`}
        index={prettyIndex}
        question={question}
        value={feedbackToAquifer["ratingBars"][`feedback_rating_bar_${prettyIndex}`] && feedbackToAquifer["ratingBars"][`feedback_rating_bar_${prettyIndex}`].value}
        submitted={feedbackToAquifer["submitted"]}
        comment={feedbackToAquifer["comments"][`feedback_text_input_${prettyIndex}`] && feedbackToAquifer["comments"][`feedback_text_input_${prettyIndex}`].value}
        userContext={userContext}
        displayContext={displayContext}
        currentVersion={currentVersion}
        documentContext={documentContext}
        featureFlag={featureFlag}
        mediaContext={mediaContext}
      />
    );
  });

  const completedFeedback = size(feedbackToAquifer["ratingBars"]);
  const partiallyCompleteFeedback = completedFeedback > 0 && completedFeedback !== PANEL_QUESTIONS.length;
  const noFeedback = completedFeedback === 0;

  return (
    <ReasoningToolPanel
      key="Feedback to Aquifer"
      title="Feedback to Aquifer"
      infoBox="We value your input! Please provide your feedback to Aquifer using the easy 5-star ratings below, and enter your comments if desired."
      displayContext={displayContext}
    >
      {panelChildren}
      <GenButton
        name={partiallyCompleteFeedback ? `${completedFeedback} out of ${PANEL_QUESTIONS.length}` : feedbackToAquifer["submitted"] ? "Submitted" : "Submit"}
        confirmTitle="Ready to Submit?"
        confirmMessage="If so, click Submit to send your feedback to Aquifer."
        confirmOKText="Submit"
        highlighted
        disabled={noFeedback || partiallyCompleteFeedback || feedbackToAquifer["submitted"]}
        onClick={(e) => {
          userContext.addState({
            ["feedback_submit_button"]: {
              feedback_submitted: true,
            },
          });

          window.customConfirmBox({
            title: "Thank you!",
            message: "Thank you for submitting feedback to Aquifer, which we use to improve our products. Aquifer does not respond to individual feedback. If you require a response, please use the Feedback form accessed from the top menu bar.",
            callback: () => {},
            showsCancel: false,
          });
        }}
      />
    </ReasoningToolPanel>
  );
}
